
import FantasyOverview from '../component/FantasyOverview';
import React, { Component } from 'react';

class fantasyoverview extends Component {
	render() {
		return (
            <FantasyOverview></FantasyOverview>
		)
	}
}

export default fantasyoverview;

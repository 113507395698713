import React, { useCallback, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useSession } from '../App';
import MenuItem from '@mui/material/MenuItem';
import { Typography, TextField } from '@mui/material';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import firebase from '../firebase';

const PREFIX = 'CreateMatchSchedule';

const classes = {
	root : `${PREFIX}-root`
};

const Root = styled('form')(({ theme }) => ({
	[`&.${classes.root}`]: {
		'& .MuiTextField-root' : {
			margin : theme.spacing(1),
			width  : '25ch'
		}
	}
}));

const teams = [
	{
		value : 'ANFC',
		label : 'Albirex Niigata'
	},
	{
		value : 'BKFC',
		label : 'Balestier Khalsa'
	},
	{
		value : 'GIFC',
		label : 'Geylang International'
	},
	{
		value : 'HUFC',
		label : 'Hougang United'
	},
	{
		value : 'LCSF',
		label : 'Lion City Sailors'
	},
	{
		value : 'TPFC',
		label : 'Tanjong Pagar'
	},
	{
		value : 'TRFC',
		label : 'Tampines Rovers'
	},
	{
		value : 'YLFC',
		label : 'Young Lions'
	},
	{
		value : 'YLFC',
		label : 'Young Lions'
	},
	{
		value : 'WANFC',
		label : 'Albirex Niigata (W)'
	},
	{
		value : 'WTPFC',
		label : 'Tanjong Pagar (W)'
	},
	{
		value : 'WBKFC',
		label : 'Balestier Khalsa (W)'
		
	},
	{
		value : 'WTBFC',
		label : 'Tiong Bahru (W)'
	},
	{
		value : 'WHUFC',
		label : 'Hougang United (W)'
	},
	{
		value : 'WSAFC',
		label : 'Still Aerion (W)'
	},
	{
		value : 'WLCSF',
		label : 'Lion City Sailors (W)'
	}

];

const rounds = [
	{
		value : '1',
		label : 'Round 1'
	},
	{
		value : '2',
		label : 'Round 2'
	},
	{
		value : '3',
		label : 'Round 3'
	},
	{
		value : '4',
		label : 'Round 4'
	}
];

const selections = {
	albirex_niigata       : {
		name   : 'Albirex niigata',
		logo   : '/images/team_logos/anfc.png',
		avatar : 'A'
	},
	balestier_khalsa      : {
		name   : 'Balestier Khalsa',
		logo   : '/images/team_logos/bkfc.png',
		avatar : 'B'
	},
	geylang_international : {
		name   : 'Geylang International',
		logo   : '/images/team_logos/gifc.png',
		avatar : 'G'
	},
	hougang_united        : {
		name   : 'Hougang United',
		logo   : '/images/team_logos/hufc.png',
		avatar : 'H'
	},
	lion_city_sailors     : {
		name   : 'Lion City Sailors',
		logo   : '/images/team_logos/lcsf.png',
		avatar : 'L'
	},
	tanjong_pagar         : {
		name   : 'Tanjong Pagar',
		logo   : '/images/team_logos/tpfc.png',
		avatar : 'T'
	},
	tampines_rovers       : {
		name   : 'Tampines Rovers',
		logo   : '/images/team_logos/trfc.png',
		avatar : 'T'
	},
	young_lions           : {
		name   : 'Young Lions',
		logo   : '/images/team_logos/ylfc.png',
		avatar : 'Y'
	},
	women_albirex_niigata       : {
		name   : 'Albirex niigata (W)',
		logo   : '/images/team_logos/anfc.png',
		avatar : 'A'
	},
	women_tanjong_pagar         : {
		name   : 'Tanjong Pagar (W)',
		logo   : '/images/team_logos/tpfc.png',
		avatar : 'T'
	},
	women_balestier_khalsa      : {
		name   : 'Balestier Khalsa (W)',
		logo   : '/images/team_logos/bkfc.png',
		avatar : 'B'
	},
	women_tiong_bahru      : {
		name   : 'Tiong Bahru (W)',
		logo   : '/images/team_logos/tbfc.png',
		avatar : 'T'
	},
	women_hougang_united        : {
		name   : 'Hougang United (W)',
		logo   : '/images/team_logos/hufc.png',
		avatar : 'H'
	},
	women_still_aerion      : {
		name   : 'Still Aerion (W)',
		logo   : '/images/team_logos/safc.png',
		avatar : 'B'
	},
	women_lion_city_sailors     : {
		name   : 'Lion City Sailors (W)',
		logo   : '/images/team_logos/lcsf.png',
		avatar : 'L'
	}
}

function Alert(props) {
	return <MuiAlert elevation={6} variant='filled' {...props} />;
}

export default function CreateMatchSchedule() {
	const user = useSession();
	const [ authorized, setAuthorized ] = useState(false);
	const [ state, setState ] = useState(false);

	const [ homeTeam, setHomeTeam ] = useState('');
	const [ awayTeam, setAwayTeam ] = useState('');
	const [ year, setYear ] = useState(2022);
	const [ round, setRound ] = useState(4);
	const [ week, setWeek ] = useState(8);
	const [ matchDate, setMatchDate ] = useState('');
	const [ matchTime, setMatchTime ] = useState('');
	const [ venue, setVenue ] = useState('');
	const [ open, setOpen ] = React.useState(false);

	const handleClick = () => {
		setOpen(true);
	};

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};

	const handleHomeTeamChange = (event) => {
		setHomeTeam(event.target.value);
		console.log('Home Team selected: ' + event.target.value);

		if (event.target.value === 'GIFC' || event.target.value === 'TRFC') {
			setVenue('Our Tampines Hub');
			console.log('Venue auto selected: ' + 'Our Tampines Hub');
		} else if (event.target.value === 'BKFC') {
			setVenue('Toa Payoh Stadium');
			console.log('Venue auto selected: ' + 'Toa Payoh Stadium');
		} else if (event.target.value === 'HUFC') {
			setVenue('Hougang Stadium');
			console.log('Venue auto selected: ' + 'Hougang Stadium');
		} else if (event.target.value === 'TPFC' || event.target.value === 'ANFC') {
			setVenue('Jurong East Stadium');
			console.log('Venue auto selected: ' + 'Jurong East Stadium');
		} else if (event.target.value === 'YLFC' || event.target.value === 'LCSF') {
			setVenue('Jalan Besar Stadium');
			console.log('Venue auto selected: ' + 'Jalan Besar Stadium');
		} else {
			setVenue('');
		}
	};

	const handleAwayTeamChange = (event) => {
		setAwayTeam(event.target.value);
		console.log('Away Team selected: ' + event.target.value);
	};

	const handleYearChange = (event) => {
		setYear(event.target.value);
	};

	const handleRoundChange = (event) => {
		if (parseInt(event.target.value).isNumber)
			setRound(parseInt(event.target.value));
		else
			setRound(parseFloat(event.target.value));
		console.log('Round selected: ' + event.target.value);
	};

	const handleWeekChange = (event) => {
		setWeek(event.target.value);
		console.log('Week selected: ' + event.target.value);
	};

	const handleDateChange = (event) => {
		setMatchDate(event.target.value);
		console.log('Date selected: ' + event.target.value);
	};

	const handleTimeChange = (event) => {
		setMatchTime(event.target.value);
		console.log('Time selected: ' + event.target.value);
	};

	const handleVenueChange = (event) => {
		setVenue(event.target.value);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		console.log('submit');
	};

	function handleCreateMatch(event) {
		let awayTeamLogo = '';
		let awayTeamName = '';
		let awayTeamScore = 0;
		let awayTeamAvatar = '';
		let homeTeamLogo = '';
		let homeTeamName = '';
		let homeTeamScore = 0;
		let homeTeamAvatar = '';
		let matchAvatar = '';
		let matchCompetitionStatus = 'SPL';
		let matchId = '';
		let matchPredictionStatus = 'open';
		let matchStatus = 'matchday';
		let playerRatingStatus = 'closed';

		event.preventDefault();
		console.log('submit');

		if (awayTeam == 'ANFC') {
			awayTeamLogo = selections.albirex_niigata.logo;
			awayTeamName = selections.albirex_niigata.name;
			awayTeamAvatar = selections.albirex_niigata.avatar;
		} else if (awayTeam === 'BKFC') {
			awayTeamLogo = selections.balestier_khalsa.logo;
			awayTeamName = selections.balestier_khalsa.name;
			awayTeamAvatar = selections.balestier_khalsa.avatar;
		} else if (awayTeam === 'GIFC') {
			awayTeamLogo = selections.geylang_international.logo;
			awayTeamName = selections.geylang_international.name;
			awayTeamAvatar = selections.geylang_international.avatar;
		} else if (awayTeam === 'HUFC') {
			awayTeamLogo = selections.hougang_united.logo;
			awayTeamName = selections.hougang_united.name;
			awayTeamAvatar = selections.hougang_united.avatar;
		} else if (awayTeam === 'LCSF') {
			awayTeamLogo = selections.lion_city_sailors.logo;
			awayTeamName = selections.lion_city_sailors.name;
			awayTeamAvatar = selections.lion_city_sailors.avatar;
		} else if (awayTeam === 'TPFC') {
			awayTeamLogo = selections.tanjong_pagar.logo;
			awayTeamName = selections.tanjong_pagar.name;
			awayTeamAvatar = selections.tanjong_pagar.avatar;
		} else if (awayTeam === 'TRFC') {
			awayTeamLogo = selections.tampines_rovers.logo;
			awayTeamName = selections.tampines_rovers.name;
			awayTeamAvatar = selections.tampines_rovers.avatar;
		} else if (awayTeam === 'YLFC') {
			awayTeamLogo = selections.young_lions.logo;
			awayTeamName = selections.young_lions.name;
			awayTeamAvatar = selections.young_lions.avatar;
		}else if (awayTeam === 'WANFC') {
			awayTeamLogo = selections.women_albirex_niigata.logo;
			awayTeamName = selections.women_albirex_niigata.name;
			awayTeamAvatar = selections.women_albirex_niigata.avatar;
		}else if (awayTeam === 'WTPFC') {
			awayTeamLogo = selections.women_tanjong_pagar.logo;
			awayTeamName = selections.women_tanjong_pagar.name;
			awayTeamAvatar = selections.women_tanjong_pagar.avatar;
		}else if (awayTeam === 'WBKFC') {
			awayTeamLogo = selections.women_balestier_khalsa.logo;
			awayTeamName = selections.women_balestier_khalsa.name;
			awayTeamAvatar = selections.women_balestier_khalsa.avatar;
		}else if (awayTeam === 'WTBFC') {
			awayTeamLogo = selections.women_tiong_bahru.logo;
			awayTeamName = selections.women_tiong_bahru.name;
			awayTeamAvatar = selections.women_tiong_bahru.avatar;
		}else if (awayTeam === 'WHUFC') {
			awayTeamLogo = selections.women_hougang_united.logo;
			awayTeamName = selections.women_hougang_united.name;
			awayTeamAvatar = selections.women_hougang_united.avatar;
		}else if (awayTeam === 'WSAFC') {
			awayTeamLogo = selections.women_still_aerion.logo;
			awayTeamName = selections.women_still_aerion.name;
			awayTeamAvatar = selections.women_still_aerion.avatar;
		}else if (awayTeam === 'WLCSF') {
			awayTeamLogo = selections.women_lion_city_sailors.logo;
			awayTeamName = selections.women_lion_city_sailors.name;
			awayTeamAvatar = selections.women_lion_city_sailors.avatar;
		}


		if (homeTeam === 'ANFC') {
			homeTeamLogo = selections.albirex_niigata.logo;
			homeTeamName = selections.albirex_niigata.name;
			homeTeamAvatar = selections.albirex_niigata.avatar;
		} else if (homeTeam === 'BKFC') {
			homeTeamLogo = selections.balestier_khalsa.logo;
			homeTeamName = selections.balestier_khalsa.name;
			homeTeamAvatar = selections.balestier_khalsa.avatar;
		} else if (homeTeam === 'GIFC') {
			homeTeamLogo = selections.geylang_international.logo;
			homeTeamName = selections.geylang_international.name;
			homeTeamAvatar = selections.geylang_international.avatar;
		} else if (homeTeam === 'HUFC') {
			homeTeamLogo = selections.hougang_united.logo;
			homeTeamName = selections.hougang_united.name;
			homeTeamAvatar = selections.hougang_united.avatar;
		} else if (homeTeam === 'LCSF') {
			homeTeamLogo = selections.lion_city_sailors.logo;
			homeTeamName = selections.lion_city_sailors.name;
			homeTeamAvatar = selections.lion_city_sailors.avatar;
		} else if (homeTeam === 'TPFC') {
			homeTeamLogo = selections.tanjong_pagar.logo;
			homeTeamName = selections.tanjong_pagar.name;
			homeTeamAvatar = selections.tanjong_pagar.avatar;
		} else if (homeTeam === 'TRFC') {
			homeTeamLogo = selections.tampines_rovers.logo;
			homeTeamName = selections.tampines_rovers.name;
			homeTeamAvatar = selections.tampines_rovers.avatar;
		} else if (homeTeam === 'YLFC') {
			homeTeamLogo = selections.young_lions.logo;
			homeTeamName = selections.young_lions.name;
			homeTeamAvatar = selections.young_lions.avatar;
		}else if (homeTeam === 'WANFC') {
			homeTeamLogo = selections.women_albirex_niigata.logo;
			homeTeamName = selections.women_albirex_niigata.name;
			homeTeamAvatar = selections.women_albirex_niigata.avatar;
		}else if (homeTeam === 'WTPFC') {
			homeTeamLogo = selections.women_tanjong_pagar.logo;
			homeTeamName = selections.women_tanjong_pagar.name;
			homeTeamAvatar = selections.women_tanjong_pagar.avatar;
		}else if (homeTeam === 'WBKFC') {
			homeTeamLogo = selections.women_balestier_khalsa.logo;
			homeTeamName = selections.women_balestier_khalsa.name;
			homeTeamAvatar = selections.women_balestier_khalsa.avatar;
		}else if (homeTeam === 'WTBFC') {
			homeTeamLogo = selections.women_tiong_bahru.logo;
			homeTeamName = selections.women_tiong_bahru.name;
			homeTeamAvatar = selections.women_tiong_bahru.avatar;
		}else if (homeTeam === 'WHUFC') {
			homeTeamLogo = selections.women_hougang_united.logo;
			homeTeamName = selections.women_hougang_united.name;
			homeTeamAvatar = selections.women_hougang_united.avatar;
		}else if (homeTeam === 'WSAFC') {
			homeTeamLogo = selections.women_still_aerion.logo;
			homeTeamName = selections.women_still_aerion.name;
			homeTeamAvatar = selections.women_still_aerion.avatar;
		}else if (homeTeam === 'WLCSF') {
			homeTeamLogo = selections.women_lion_city_sailors.logo;
			homeTeamName = selections.women_lion_city_sailors.name;
			homeTeamAvatar = selections.women_lion_city_sailors.avatar;
		}

		let data = {
			awayteam_id              : awayTeam,
			awayteam_logo_url        : awayTeamLogo,
			awayteam_name            : awayTeamName,
			awayteam_score           : awayTeamScore,
			hometeam_id              : homeTeam,
			hometeam_logo_url        : homeTeamLogo,
			hometeam_name            : homeTeamName,
			hometeam_score           : homeTeamScore,
			match_avatar             : homeTeamAvatar + awayTeamAvatar,
			match_competition_status : matchCompetitionStatus,
			match_date               : firebase.firestore.Timestamp.fromDate(new Date(matchDate)), //Convert straight to timestamp
			match_id                 :
				year + '_ROUND_' + round + '_WEEK_' + week + '_' + homeTeam + '_' + awayTeam + '_SPL',
			match_prediction_status  : matchPredictionStatus,
			match_status             : matchStatus,
			match_time               : matchTime,
			match_title              : homeTeamName + ' vs ' + awayTeamName,
			match_venue              : venue,
			match_week               : Number(week),
			player_rating_status     : playerRatingStatus,
			Round                    : round,
      hometeam_predictions_win : 0,
      awayteam_predictions_win : 0,
      hometeam_predictions_draw: 0,
      total_predictions        : 0
		};

		const db = firebase.firestore();
		
		if (homeTeam.length === 5 && awayTeam.length ===5) {


		let setDoc = db
		.collection('2022WPLMatches')
		.doc(year + '_ROUND_' + round + '_WEEK_' + week + '_' + homeTeam + '_' + awayTeam + '_SPL')
		.set(data, { merge: true })
		.then(function() {
			console.log(
				'Data Written: ' +
					year +
					'_ROUND_' +
					round +
					'_WEEK_' +
					week +
					'_' +
					homeTeam +
					'_' +
					awayTeam +
					'_SPL'
			);
			setState(true);
			setOpen(true);
		})
		.catch((error) => console.log(error));
		} else {

		

		let setDoc = db
			.collection('2022Matches')
			.doc(year + '_ROUND_' + round + '_WEEK_' + week + '_' + homeTeam + '_' + awayTeam + '_SPL')
			.set(data, { merge: true })
			.then(function() {
				console.log(
					'Data Written: ' +
						year +
						'_ROUND_' +
						round +
						'_WEEK_' +
						week +
						'_' +
						homeTeam +
						'_' +
						awayTeam +
						'_SPL'
				);
				setState(true);
				setOpen(true);
			})
			.catch((error) => console.log(error));
		}
	}

	useEffect(() => {
		console.log('Checking user: ' + user.uid);
		if (
			user.uid === 'glKicD84lLejtJ5M9qOLaczt5ny1' ||
			user.uid === 'YP1dpgESghPw7WiAoIL1Sd0HARw1' ||
			user.uid === 'MmkWUYFwTwT7DKWfnsgXoKoYgxo2'
		) {
			console.log('Authorized User');
			setAuthorized(true);
		} else {
			console.log('Unauthorized User');
			setAuthorized(false);
		}
	}, []);

	return (
		<Root className={classes.root} Validate onSubmit={handleCreateMatch} autoComplete='off' align='center'>
			{authorized ? (
				<div>
					<div>
						<Typography variant='h5'>Create a Match Schedule</Typography>
					</div>
					<div>
						<TextField
							required={true}
							id='select-year'
							label='Year'
							value={year}
							onChange={handleYearChange}
							helperText='Please select the year'
						/>
						<TextField
							required={true}
							id='select-round'
							select
							label='Round'
							value={round}
							onChange={handleRoundChange}
							helperText='Please select the round'
						>
							{rounds.map((option) => (
								<MenuItem key={option.value} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</TextField>
						<TextField
							required
							id='select-week'
							label='Week'
							value={week}
							onChange={handleWeekChange}
							helperText='Please select the week'
						/>
					</div>
					<div>
						<TextField
							required={true}
							id='select-home-team'
							select
							label='Home Team'
							value={homeTeam}
							onChange={handleHomeTeamChange}
							helperText='Please select your home team'
						>
							{teams.map((option) => (
								<MenuItem key={option.value} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</TextField>
						<TextField
							required={true}
							id='select-away-team'
							select
							label='Away Team'
							value={awayTeam}
							onChange={handleAwayTeamChange}
							helperText='Please select your away team'
						>
							{teams.map((option) => (
								<MenuItem key={option.value} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</TextField>
					</div>
					<div>
						<TextField
							required={true}
							id='select-date'
							label='Date'
							type='date'
							value={matchDate}
							onChange={handleDateChange}
							className={classes.textField}
							InputLabelProps={{
								shrink : true
							}}
						/>
						<TextField
							required={true}
							id='select-time'
							label='Time'
							type='time'
							value={matchTime}
							onChange={handleTimeChange}
							className={classes.textField}
							InputLabelProps={{
								shrink : true
							}}
							inputProps={{
								step : 300 // 5 min
							}}
						/>
						<TextField
							required={true}
							id='select-venue'
							label='Venue'
							value={venue}
							onChange={handleVenueChange}
							helperText='Please select the venue'
						/>
					</div>
					<div>
						<Button
							variant='contained'
							color='primary'
							className={classes.button}
							startIcon={<CloudUploadIcon />}
							//onClick={handleCreateMatch}
							type='submit'
						>
							Create
						</Button>
					</div>
				</div>
			) : (
				<Typography>Unauthorized</Typography>
			)}
			{state ? (
				<Snackbar open={open} autoHideDuration={6000} onClose={handleClose} message='Success' />
			) : (
				<Snackbar
					open={open}
					autoHideDuration={6000}
					onClose={handleClose}
					message='Match creation unsuccessful!'
				/>
			)}
		</Root>
	);
}

import React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { NavLink } from 'react-router-dom';
import { createBrowserHistory as history } from 'history';
import { ExternalLink } from 'react-external-link';
import { Box } from '@mui/material';

const PREFIX = 'Home';

const classes = {
	root           : `${PREFIX}-root`,
	paper          : `${PREFIX}-paper`,
	paperContainer : `${PREFIX}-paperContainer`
};

const Root = styled('div')(({ theme }) => ({
	[`&.${classes.root}`]: {
		flexGrow : 1
	},

	[`& .${classes.paper}`]: {
		padding   : theme.spacing(2),
		textAlign : 'center',
		color     : theme.palette.text.secondary
	},

	[`& .${classes.paperContainer}`]: {
		height : 300
	}
}));

export default function CenteredGrid() {
	const preventDefault = (event) => event.preventDefault();

	return (
		<Root className={classes.root}>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<Card>
						<CardActionArea>
							<div
								style={{
									display        : 'flex',
									alignItem      : 'center',
									justifyContent : 'center'
								}}
							>
								<CardMedia
									style={{
										width     : 'auto',
										maxHeight : '200px'
									}}
									component='img'
									image='/images/generic/spl_main.png'
									title='Voiddeck Football'
								/>
							</div>
							<CardContent>
								<Typography gutterBottom variant='h4' component='h4' align='center'>
									Voiddeck Football 2021
								</Typography>
								<Typography gutterBottom variant='caption' component='h2' align='center'>
									Bringing Together Football Fans Using Technology
								</Typography>
							</CardContent>
							<CardContent align='center'>
								<NavLink to='/login'>Sign In</NavLink>
							</CardContent>
							<CardContent align='center'>
								<Typography variant='subtitle1'> Photo Credits</Typography>
								<ExternalLink href='https://www.fas.org.sg/tatsuma-names-lions-squad-world-cup-qualifiers-june/'>
									<span>Football Association of Singapore</span>
								</ExternalLink>
								<Box m={1} />
								<ExternalLink href='https://www.facebook.com/sgfootballpodcast'>
									<span>The Unofficial S.League Podcast</span>
								</ExternalLink>
							</CardContent>
						</CardActionArea>
					</Card>
				</Grid>
			</Grid>
		</Root>
	);
}

import React from 'react';
import Merchandise from '../component/Merchandise';

export default function merchandise() {
	return (
		<div>
			<Merchandise />
		</div>
	);
}

import React, { useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';
import { useSession } from '../App';
import { Typography, Card, TextField, CardContent, CardActions } from '@mui/material';
import Grid from '@mui/material/Grid';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import SaveIcon from '@mui/icons-material/Save';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';

import firebase from '../firebase';
import { AndroidTwoTone } from '@mui/icons-material';

const PREFIX = 'AdminDashboardUsers';

const classes = {
	root        : `${PREFIX}-root`,
	paper       : `${PREFIX}-paper`,
	button      : `${PREFIX}-button`,
	formControl : `${PREFIX}-formControl`,
	grouplabel  : `${PREFIX}-grouplabel`,
	large       : `${PREFIX}-large`
};

const StyledGrid = styled(Grid)(({ theme }) => ({
	[`&.${classes.root}`]: {
		flexgrow : 1,
		margin   : 'auto'
	},

	[`& .${classes.paper}`]: {
		width    : 130,
		height   : 230,
		overflow : 'auto'
	},

	[`& .${classes.button}`]: {
		margin : theme.spacing(1)
	},

	[`& .${classes.formControl}`]: {
		margin   : theme.spacing(3),
		minWidth : 150,
		maxWidth : 250
	},

	[`& .${classes.grouplabel}`]: {
		fontsize : 10
	},

	[`& .${classes.large}`]: {
		width  : theme.spacing(7),
		height : theme.spacing(7)
	}
}));

export default function AdminDashboardUsers() {
	const user = useSession();
	const db = firebase.firestore();

	const [ authorized, setAuthorized ] = useState(false);
	const [ topUsers, setTopUsers ] = useState([]);
	const [ users, setUsers ] = useState([]);

	const [ allPredictions, setAllPredictions ] = useState([]);

	//const matchWeeks = [ 8, 9, 10, 11, 12, 12.1, 13, 13.1, 14 ];
	const matchWeeks = [ 15, 15.1, 16, 17, 18, 18.1, 19, 19.1, 20, 21, 21.1 ];


	useEffect(() => {
		console.log('Checking user: ' + user.uid);
		if (
			user.uid === 'glKicD84lLejtJ5M9qOLaczt5ny1' ||
			user.uid === 'YP1dpgESghPw7WiAoIL1Sd0HARw1' ||
			user.uid === 'MmkWUYFwTwT7DKWfnsgXoKoYgxo2'
		) {
			console.log('Authorized User');
			setAuthorized(true);
		} else {
			console.log('Unauthorized User');
			setAuthorized(false);
		}

		const fetchAllPredictions = async () => {
			const db = firebase.firestore();
			db.collectionGroup('2022_Match_Predictions').get().then(function(querySnapshot2) {
				setAllPredictions(querySnapshot2.docs.map((prediction) => prediction.data()));
			});
		};
		fetchAllPredictions();
	}, []);

	function updateUsersRanking() {
		console.log('Update Users Ranking');
		db
			.collection('Users')
			.orderBy('prediction_score_2022', 'desc')
			.get()
			.then(function(querySnapshot) {
				let rankCounter = 0;
				querySnapshot.forEach(function(doc) {
					rankCounter++;
					// doc.data() is never undefined for query doc snapshots
					console.log(doc.id, ' => ', doc.data());
					db.collection('Users').doc(doc.id).set(
						{
							prediction_ranking_2022_by_rounds : { round_1: rankCounter }
						},
						{ merge: true }
					);
				});
			})
			.catch(function(error) {
				console.log('Error getting documents: ', error);
			});
	}

	function showUsersRanking() {
		console.log('Show User Ranking');
		db
			.collection('Users')
			.orderBy('prediction_score_2022', 'desc')
			.get()
			.then(function(querySnapshot) {
				var temp = [];
				querySnapshot.forEach(function(doc) {
					// doc.data() is never undefined for query doc snapshots
					console.log(doc.id, ' => ', doc.data());
					temp.push(doc.id, { ...doc.data() });
				});
				//setTopUsers(querySnapshot.docs.map((matches) => matches.data()));
				setTopUsers(temp);
			})
			.catch(function(error) {
				console.log('Error getting documents: ', error);
			});
	}

	function transferPlayer() {
		//Moving Players from one club to another
		//Afiq Eunos
		db
			.collection('/2022Teams/HUFC/2022Players')
			.doc('eae5ec0ee8599a6cc06558023324b9d1_AfiqYunos')
			.get()
			.then((doc) => {
				if (doc.exists) {
					console.log('Document data:', doc.data());
					var playerRef = db
						.collection('/2022Teams/GIFC/2022Players')
						.doc('eae5ec0ee8599a6cc06558023324b9d1_AfiqYunos');

					var setWithMerge = playerRef
						.set(doc.data(), { merge: true })
						.then(() => {
							console.log('Document successfully written!');
						})
						.catch((error) => {
							console.error('Error writing document: ', error);
						});
				} else {
					// doc.data() will be undefined in this case
					console.log('No such document!');
				}
			})
			.catch((error) => {
				console.log('Error getting document:', error);
			});
		//end of player transfer code

		/*
		db.collection('/2022Teams/trfc/2022Players').where("active", "==", true)
		.get()
		.then(function(querySnapshot) {
			querySnapshot.forEach(function(player) {
				// doc.data() is never undefined for query doc snapshots
				console.log(player.id, " => ", player.data());


		db.collectionGroup('TRFC_PLAYERS').where('id', '==', player.id)
		.get().then(function (querySnapshot) {					

			querySnapshot.forEach(function (doc) {
				//console.log(doc.ref.path);
				var docPath = doc.ref.path;
				var index = docPath.indexOf(doc.id);
				var collection = docPath.substring(0, index);
				//var playerRatingId = 

				//console.log(doc.data().firstname + ' ' + doc.data().id);
				console.log(collection + ' ' + doc.data().id + ' ' + doc.data().firstname);
				db.collection(collection).doc(doc.id)	
				.set(
					{player_id: doc.data().id},
					{merge:true}
				)		
				.then(function() {

					console.log("Document successfully written!");
				})
				.catch(function(error) {
					console.error("Error writing document: ", error);
				});	

			});

		});
			});
		})
		.catch(function(error) {
			console.log("Error getting documents: ", error);
		});
		*/

		/*

		db.collectionGroup('GIFC_PLAYERS').where('id', '==', 'AaDdg5HEQXbej0KkR4Kv')
		.get().then(function (querySnapshot) {					

			querySnapshot.forEach(function (doc) {
				//console.log(doc.ref.path);
				var docPath = doc.ref.path;
				var index = docPath.indexOf(doc.id);
				var collection = docPath.substring(0, index);
				//var playerRatingId = 

				//console.log(doc.data().firstname + ' ' + doc.data().id);
				console.log(collection + ' ' + doc.data().id + ' ' + doc.data().firstname);
				db.collection(collection).doc(doc.id)	
				.set(
					{player_id: doc.data().id},
					{merge:true}
				)		
				.then(function() {

					console.log("Document successfully written!");
				})
				.catch(function(error) {
					console.error("Error writing document: ", error);
				});	

			});

		});
		*/
	}

	function roundPredictionCalculation() {
		console.log('round 3 predictionzz');

		var batch1 = db.batch();
		var batch2 = db.batch();
		var recordCounter = 0;

		var usersRef = db.collection('/Users');
		usersRef.get().then((querySnapshot) => {
			//setUsers(querySnapshot.docs.map((user) => user.data()));
			querySnapshot.forEach((doc) => {
				//console.log(doc.id, ' => ', doc.data());
				//console.log(doc.data());
				var user = doc.data();
				var roundPredictionPoints = 0;

				//console.log('User: ' + userId);

				for (let i = 0; i < matchWeeks.length; i++) {
					//console.log('Week: ' + matchWeeks[i]);
					var userPredictions = allPredictions.filter(function(el) {
						return el.uid === doc.id && el.match_week === matchWeeks[i];
					});

					if (userPredictions.length > 0) {
						for (let j = 0; j < userPredictions.length; j++) {
							roundPredictionPoints = roundPredictionPoints + userPredictions[j].prediction_points;
						}

						console.log(
							userPredictions.length +
								' predictions in Week: ' +
								matchWeeks[i] +
								' : ' +
								roundPredictionPoints +
								' points '
						);
					} else {
						//console.log('No predictions in Week: ' + matchWeeks[i] + ' : 0 points');
					}
				}

				//console.log(user.prediction_score_2022_by_rounds['round_2'] = roundPredictionPoints);
				//console.log(user);

				//updatedUsersArray.push(user, user["prediction_score_2022_by_rounds"]= {round_2: roundPredictionPoints}});

				// Update the population of 'SF'
				//batch.update(userRef, {"prediction_score_2022_by_rounds": {round_2: roundPredictionPoints}});

				//console.log(user.prediction_score_2022);

				var data = {
					...user,
					prediction_score_2022_by_rounds : {
						round_3 : roundPredictionPoints
					}
				};

				//console.log(db.doc(doc.ref.path));
				if (recordCounter < 500) {
					batch1.set(db.doc(doc.ref.path), data, { merge: true });
					recordCounter = recordCounter + 1;
				} else {
					batch2.set(db.doc(doc.ref.path), data, { merge: true });
					recordCounter = recordCounter + 1;
				}
			});

			batch1.commit().then(() => {
				console.log('Batch 1 successfully written!');
				batch2.commit().then(() => {
					console.log('Batch 2 successfully written!');
				});
			});

			//console.log(updatedUsersArray);
		});
	}

	function roundPredictionRankingCalculation() {
		console.log('Update Users Ranking');
		var batch = db.batch();
		var batch2 = db.batch();
		var userMap = new Map();
		const usersRef = db.collection('Users');
		usersRef.get().then((querySnapshot) => {
			querySnapshot.forEach((doc) => {
				userMap.set(
					doc.ref.path,
					doc.data().prediction_score_2022_by_rounds['round_3'] === undefined
						? 0
						: doc.data().prediction_score_2022_by_rounds['round_3']
				);
			});

			//sort the map
			const mapSort1 = new Map([ ...userMap.entries() ].sort((a, b) => b[1] - a[1]));
			//console.log(mapSort1);

			var ranking = 0;
			mapSort1.forEach((value, key, map) => {
				//console.log(key + ' --> ' + value);
				ranking = ranking + 1;
				var usersRef = db.doc(key.trim());
				console.log('User: ' + key + ' Pts: ' + value + ' Ranking: ' + ranking);

				let data = { prediction_ranking_2022_by_rounds: { round_3: ranking } };
				console.log(value);

				if (ranking < 500) batch.set(usersRef, data, { merge: true });
				else batch2.set(usersRef, data, { merge: true });
			});

			console.log(mapSort1);
			batch.commit().then(() => {
				console.log('Batch 1 committed');
				batch2.commit().then(() => {
					console.log('Batch 2 committed');
				});
			});
		});
	}

	function roundFLPointsCalculation() {
		var round = 3;
		var batch = db.batch();

		console.log('FL Points Calculating Round: ' + round);
		var usersRef = db.collection('/FantasyLeague/2022/gamers');
		usersRef.get().then((querySnapshot) => {
			querySnapshot.forEach((doc) => {
				var roundPoints = 0;
				console.log(doc.data());
				var user = doc.data();

				for (let i = 0; i < matchWeeks.length; i++) {
					if (doc.data().weekly_points !== undefined) {
						console.log('Week: ' + matchWeeks[i] + ' ' + doc.data().weekly_points['week_' + matchWeeks[i]]);
						roundPoints += doc.data().weekly_points['week_' + matchWeeks[i]];
					}
				}

				var data = {
					...user,
					fl_score_2022_by_rounds : {
						round_3 : roundPoints
					}
				};

				console.log('Total Points for Round 3: ' + roundPoints);
				batch.set(db.doc(doc.ref.path), data, {merge:true});
			});
			batch.commit().then(() => {
				console.log('Batch successfully written!');
			});
		});
	}

	function roundFLRankingCalculation() {
		console.log('FL Ranking Calculating Round: ' + round);

		var round = 3;
		var batch = db.batch();
		var userMap = new Map();
		const usersRef = db.collection('/FantasyLeague/2022/gamers');
		usersRef.get().then((querySnapshot) => {
			querySnapshot.forEach((doc) => {
				userMap.set(
					doc.ref.path,
					doc.data().fl_score_2022_by_rounds['round_3'] === undefined
						? 0
						: doc.data().fl_score_2022_by_rounds['round_3'],
					{merge:true}
				);
			});

			//sort the map
			const mapSort1 = new Map([ ...userMap.entries() ].sort((a, b) => b[1] - a[1]));
			//console.log(mapSort1);

			var ranking = 0;
			mapSort1.forEach((value, key, map) => {
				//console.log(key + ' --> ' + value);
				ranking = ranking + 1;
				var usersRef = db.doc(key.trim());
				console.log('User: ' + key + ' Pts: ' + value + ' Ranking: ' + ranking);

				let data = { fl_ranking_2022_by_rounds: { round_3: ranking } };
				console.log(value);

				if (ranking < 500) batch.set(usersRef, data, { merge: true });
			});

			console.log(mapSort1);
			batch.commit().then(() => {
				console.log('Batch 1 committed');
				
			});
		});
	}

	return (
		<StyledGrid container spacing={4} className={classes.root} justifyContent='center' alignItems='center'>
			{authorized ? (
				<div>
					<Grid item xs={12}>
						<Card>
							<Typography variant='h5' align='center'>
								User Admin Dashboard 2022
							</Typography>
						</Card>
					</Grid>

					<Grid item xs={12}>
						<Card>
							<Typography variant='h5'>Top 10 Users Ranking</Typography>
							{topUsers.map((row, index) => (
								<Typography key={index}>
									{row.displayName} Points: {row.prediction_score_2022}
								</Typography>
							))}
							<Button variant='contained' color='primary' align='center' onClick={showUsersRanking}>
								Show Users Ranking
							</Button>
							<Button variant='contained' color='primary' align='center' onClick={updateUsersRanking}>
								Update Users Ranking
							</Button>
							<Button variant='contained' color='primary' align='center' onClick={transferPlayer}>
								fix issues
							</Button>
							<Button
								variant='contained'
								color='primary'
								align='center'
								onClick={roundPredictionCalculation}
							>
								Calculate Points Round 3 Predictions
							</Button>
							<Button
								variant='contained'
								color='primary'
								align='center'
								onClick={roundPredictionRankingCalculation}
							>
								Update Ranking for Round 3 Predictions
							</Button>
							<Button
								variant='contained'
								color='primary'
								align='center'
								onClick={roundFLPointsCalculation}
							>
								Calculate FL Round 3 Predictions
							</Button>
							<Button
								variant='contained'
								color='primary'
								align='center'
								onClick={roundFLRankingCalculation}
							>
								Update Ranking for FL Round 3
							</Button>
						</Card>
					</Grid>
				</div>
			) : (
				<Typography>Unauthrorized</Typography>
			)}
		</StyledGrid>
	);
}

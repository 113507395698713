import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useSession } from '../App';
import firebase from '../firebase';

import { Typography} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const PREFIX = 'PredictionTopUsers';

const classes = {
  head: `${PREFIX}-head`,
  body: `${PREFIX}-body`,
  root: `${PREFIX}-root`,
  table: `${PREFIX}-table`
};

const StyledTableContainer = styled(TableContainer)({
  [`& .${classes.table}`]: {
    minWidth: 700,
  },
});

const StyledTableCell = TableCell;

const StyledTableRow = TableRow;


export default function PredictionTopUsers() {


  const user = useSession();
  const db = firebase.firestore();
  const [authorized, setAuthorized] = useState(false);
  const [topUsers, setTopUsers] = useState([]);
  const [users, setUsers] = useState([]);


	useEffect(() => {
		console.log('Checking user: ' + user.uid);
		if (user.uid === 'glKicD84lLejtJ5M9qOLaczt5ny1' || user.uid === 'YP1dpgESghPw7WiAoIL1Sd0HARw1' || user.uid === 'MmkWUYFwTwT7DKWfnsgXoKoYgxo2') {
			console.log('Authorized User');
			setAuthorized(true);
		} else {
			console.log('Unauthorized User');
			setAuthorized(false);
		}

        console.log('Show User Ranking');
		db.collection('Users').orderBy('prediction_score_2021','desc')
		.get()
		.then(function(querySnapshot) {
			var temp = [];
			querySnapshot.forEach(function(doc) {
				// doc.data() is never undefined for query doc snapshots
				console.log(doc.id, " => ", doc.data());
				temp.push(doc.id, {...doc.data()});
			});
			setTopUsers(querySnapshot.docs.map((matches) => matches.data()));
			//setTopUsers(temp);
		})
		.catch(function(error) {
			console.log("Error getting documents: ", error);
		});

	}, []);

  return (
    <StyledTableContainer component={Paper}>
      { authorized ? (
      <Table className={classes.table} aria-label="customized table">
        <TableHead>   
          <TableRow>
            <StyledTableCell
              classes={{
                head: classes.head,
                body: classes.body
              }}>Rank:</StyledTableCell>
            <StyledTableCell
              classes={{
                head: classes.head,
                body: classes.body
              }}>Username:</StyledTableCell>
            <StyledTableCell
              classes={{
                head: classes.head,
                body: classes.body
              }}>Club:</StyledTableCell>
            <StyledTableCell
              classes={{
                head: classes.head,
                body: classes.body
              }}>Points:</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {topUsers.map((row) => (
            <StyledTableRow
              key={row.rank}
              classes={{
                root: classes.root
              }}>
              <StyledTableCell
                component="th"
                scope="row"
                classes={{
                  head: classes.head,
                  body: classes.body
                }}>
                {row.rank}
              </StyledTableCell>
              <StyledTableCell
                classes={{
                  head: classes.head,
                  body: classes.body
                }}>{row.displayName}</StyledTableCell>
              <StyledTableCell
                classes={{
                  head: classes.head,
                  body: classes.body
                }}>{row.club_name}</StyledTableCell>
              <StyledTableCell
                classes={{
                  head: classes.head,
                  body: classes.body
                }}>{row.prediction_score_2021}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      ) : (
				<Typography>Unauthorized</Typography>
			)}
    </StyledTableContainer>
  );
}


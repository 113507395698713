import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';

import Typography from '@mui/material/Typography';
import { CardHeader, Grid } from '@mui/material';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';

import TextField from '@mui/material/TextField';

//icons
import IconButton from '@mui/material/IconButton';
import AddBoxIcon from '@mui/icons-material/AddBox';
import SaveIcon from '@mui/icons-material/Save';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';

//Card
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

//Button
import Button from '@mui/material/Button';

//Table
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

//Dialog
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

//Mui
import { ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material';

//MUIDataTable
import MUIDataTable from 'mui-datatables';

import { db } from '../firebase';
import { useSession } from '../App';
import { LocalConvenienceStoreOutlined } from '@mui/icons-material';

const PREFIX = 'FantasyLeagueTransfer';

const classes = {
	head        : `${PREFIX}-head`,
	body        : `${PREFIX}-body`,
	root        : `${PREFIX}-root`,
	cardheader  : `${PREFIX}-cardheader`,
	media       : `${PREFIX}-media`,
	table       : `${PREFIX}-table`,
	formControl : `${PREFIX}-formControl`,
	selectEmpty : `${PREFIX}-selectEmpty`,
	button      : `${PREFIX}-button`
};

const StyledGrid = styled(Grid)(({ theme }) => ({
	[`& .${classes.root}`]: {
		flexGrow : 1
	},

	[`& .${classes.cardheader}`]: {
		maxHeight : 100
	},

	[`& .${classes.media}`]: {
		height : 140
	},

	[`& .${classes.table}`]: {
		minWidth : 450
	},

	[`& .${classes.formControl}`]: {
		margin   : theme.spacing(1),
		minWidth : 120
	},

	[`& .${classes.selectEmpty}`]: {
		marginTop : theme.spacing(2)
	},

	[`& .${classes.button}`]: {
		margin : theme.spacing(1)
	}
}));

const StyledTableCell = TableCell;

const columns = [
	{
		name    : 'fullname',
		label   : 'Name',
		options : {
			filter : true,
			sort   : true
		}
	},
	{
		name    : 'team_name',
		label   : 'Team',
		options : {
			filter : true,
			sort   : true
		}
	},
	{
		name    : 'fl_points',
		label   : 'FL Points',
		options : {
			filter : true,
			sort   : true
		}
	},
	{
		name    : 'value',
		label   : 'Value (Million)',
		options : {
			filter : true,
			sort   : true
		}
	},
	{
		name    : 'position',
		label   : 'Position',
		options : {
			filter : true,
			sort   : false
		}
	},
	{
		name    : 'u23',
		label   : 'U23',
		options : {
			filter           : true,
			sort             : false,
			customBodyRender : (value, tableMeta, updateValue) => {
				if (value) return <div>Yes</div>;
				else return <div>No</div>;
			}
		}
	}
];

export default function FantasyLeagueTransfer() {
	const CURRENT_SEASON = '2022';
	const MAX_NO_OF_PLAYERS_IN_A_TEAM = 15;
	const MIN_NO_OF_U23_PLAYERS = 1;
	const MAX_NO_OF_PLAYERS_IN_SAME_TEAM = 3;
	const SEED_FUND = 100;

	const [ players, setPlayers ] = useState([]);
	const [ selectedPlayers, setSelectedPlayers ] = useState([]);
	const [ selectedRows, setSelectedRows ] = useState([]);
	const [ availableFunds, setAvailableFunds ] = useState(SEED_FUND);
	const [ selectedPlayersCost, setSelectedPlayersCost ] = useState(0);
	const [ openErrorDialog, setErrorDialogOpen ] = useState(false);
	const [ noOfTransfersAllowed, setNoOfTransfersAllowed ] = useState(99); //-1 indicates a new setup, it will either be 1 or 2 transfers
	const [ errorMessage, setErrorMessage ] = useState('');
	const [ teamName, setTeamName ] = useState('');
	const [ newPlayer, setNewPlayer ] = useState(false);
	const [ open, setOpen ] = React.useState(false);
	const [ teamNameDialog, setTeamNameDialog ] = React.useState('');
	const [ transferWindowStatus, setTransferWindowStatus ] = useState('closed');
	const [ currentWeek, setCurrentWeek ] = useState(0);

	const [ playersOut, setPlayersOut ] = useState([]);
	const [ playersIn, setPlayersIn ] = useState([]);
	const [ currentSquad, setCurrentSquad ] = useState([]);
	const [ currentTeam, setCurrentTeam ] = useState([]);
	const [ gamer, setGamer ] = useState();

	const [ openSaveDialog, setOpenSaveDialog ] = useState(false);
	const [ saveButtonDialogText, setSaveButtonDialogText ] = useState('');

	const user = useSession();

	const options = {
		selectableRows       : 'multiple',
		selectableRowsHeader : selectedRows.length > 0,
		//selectableRowsOnClick: true,
		//rowsSelected: selectedRows,
		onRowSelectionChange : (rowsSelected, allRows) => {
			//check enough available funds before adding players
			console.log(allRows);
			var cost = 0;			
			allRows.forEach(function(rows) {
				cost = cost +  players[rows.dataIndex].value
			});
			setSelectedPlayersCost(cost);
			//console.log(selectedRows);
			setSelectedRows({ rowsSelected: allRows.map((row) => row.dataIndex) });
		},
		customToolbarSelect  : (selectedRows, displayData, setSelectedRows) => (
			<div>
				<Tooltip title='edit'>
					<IconButton
						onClick={() => {
							//console.log(selectedRows);
							var error = false;
							var playerlist = [];
							var selectedPlayerList = [];
							var selectedPlayerTotalValue = 0;

							//check squad size
							if (selectedRows.data.length + selectedPlayers.length > MAX_NO_OF_PLAYERS_IN_A_TEAM) {
								setErrorMessage(
									'Exceed number of allowed players in a team. \n Max no: ' +
										MAX_NO_OF_PLAYERS_IN_A_TEAM
								);
								setErrorDialogOpen(true);
							} else {
								//current selection doesn't exceed 15 players in squad
								//Extract all selected rows index to players
								selectedRows.data.forEach(function(item, index) {
									selectedPlayerList.push(players[selectedRows.data[index].dataIndex]);
								});

								//check enough available funds before adding players
								var totalcost = 0;
								selectedPlayerList.forEach(function(player) {
									totalcost = totalcost + player.value;
								});

								//console.log('Total cost of selected players: ' + totalcost + ' / ' + availableFunds);
								//Iterate the list of players seletion from the search list
								if (totalcost <= availableFunds) {
									selectedPlayerList.forEach(function(player, index) {
										//check if the list have exceeded no of players from the same team
										var sameTeamCheck = selectedPlayerList.filter(function(item) {
											return item.team_id === player.team_id;
										});

										//console.log(sameTeamCheck);

										//check 1: Check if there are more than 3 players from the same team in the list
										if (sameTeamCheck.length > MAX_NO_OF_PLAYERS_IN_SAME_TEAM) {
											setErrorMessage(
												'Exceed number of allowed players from the same team. \n Max no: ' +
													MAX_NO_OF_PLAYERS_IN_SAME_TEAM
											);
											setErrorDialogOpen(true);
											error = true;
										} else {
											//check if player player already in selected list
											var playerFound = selectedPlayers.filter(function(item) {
												return item.player_id === player.player_id;
											});

											//if (playerFound.length !== 0) console.log('Player Found');
											//else console.log('Player Not Found');

											if (playerFound.length === 0) {
												//console.log(index + ' Checking for: ' + player.firstname);
												//check if exceed no of players in the same team
												var sameTeamCount = selectedPlayers.filter(function(item) {
													return item.team_id === player.team_id;
												});

												if (sameTeamCount.length >= MAX_NO_OF_PLAYERS_IN_SAME_TEAM) {
													setErrorMessage(
														'Exceed number of allowed players from the same team. \n Max no: ' +
															MAX_NO_OF_PLAYERS_IN_SAME_TEAM
													);
													setErrorDialogOpen(true);
													error = true;
												}

												if (player.value > availableFunds) {
													setErrorMessage(
														'Insuffcient Funds for player : ' +
															player.firstname +
															' ' +
															player.value
													);
													setErrorDialogOpen(true);
													selectedPlayerTotalValue = selectedPlayerTotalValue + player.value;
													error = true;
												} else {
													selectedPlayerTotalValue = selectedPlayerTotalValue + player.value;
												}

												if (!error) {
													playerlist.push(player);
													setAvailableFunds(availableFunds - selectedPlayerTotalValue);

													//Updating playersout array, if the player out is selected back, remove it from the playerout array
													//console.log('checking if player is in the playerout array: ' + playersOut.length)
													var foundIdx = playersOut.findIndex(
														(outplayer) => outplayer.player_id === player.player_id
													);
													if (foundIdx !== -1) {
														playersOut.splice(foundIdx, 1);
														setPlayersOut(playersOut);
														console.log(
															'Removing player from players out: ' +
																player.firstname +
																' ' +
																playersOut.length
														);
													} else {
														console.log('Nothing to remove: ' + playersOut.length);
													}
												}
											}
										}
									});

									selectedPlayers.forEach((player) => {
										playerlist.push(player);
									});

									// Sort by city, case-insensitive, A-Z
									playerlist.sort(sort_by('position', false, (a) => a.toUpperCase()));
									setSelectedPlayers(playerlist);
									setSelectedRows([]); //clear selected rows
								} else {
									setErrorMessage('Insuffcient Funds for players selected.');
									setErrorDialogOpen(true);
								}
							}
						}}
						size='large'
					>
						<AddBoxIcon />
					</IconButton>
				</Tooltip>
			</div>
		)
	};

	const handleCloseSaveDialog = () => {
		setOpenSaveDialog(false);
	};

	const resetToCurrentSquad = (playersInArray) => {
		//playersInArray.forEach((playersIn) => refund = refund + playersIn.value);
		currentSquad.sort(sort_by('position', false, (a) => a.toUpperCase()));
		setSelectedPlayers(currentSquad);
		setAvailableFunds(gamer.available_funds); //refund the available funds
		var resetArray = [];
		setPlayersIn(resetArray);
		setPlayersOut(resetArray);
	};

	const handleSave = () => {
		console.log('Saving the team');

		//check if there are 15 players in selection
		if (playersOut.length === 0 && noOfTransfersAllowed !== 99) {
			console.log('No players out, nothing to do');
		} else if (selectedPlayers.length !== 15) {
			if (noOfTransfersAllowed !== 99) resetToCurrentSquad();
			setErrorMessage('You need 15 players in your squad');
			setErrorDialogOpen(true);
			return;
		} else {
			console.log(noOfTransfersAllowed);
			if (noOfTransfersAllowed === 99) {
				//new gamer
				console.log('New Player Setup');
				console.log('Checking the squad mix of players position');
				//check if we have the right mix of players (2 GKs, 5 Ds, 5 Ms, 3As)
				var goalkeepers = selectedPlayers.filter(function(player) {
					return player.position === 'Goalkeeper';
				});

				var defenders = selectedPlayers.filter(function(player) {
					return player.position === 'Defender';
				});

				var midfielders = selectedPlayers.filter(function(player) {
					return player.position === 'Midfielder';
				});

				var attackers = selectedPlayers.filter(function(player) {
					return player.position === 'Forward';
				});

				if (
					goalkeepers.length !== 2 ||
					defenders.length !== 5 ||
					midfielders.length !== 5 ||
					attackers.length !== 3
				) {
					/*console.log('Error in players position  ix: ' +goalkeepers.length +
							' d: ' +
							defenders.length +
							' m: ' +
							midfielders.length +
							' f: ' +
							attackers.length
					);*/
					setErrorMessage(
						'Your team requires 2 Goalkeepers, 5 Defenders, 5 Midfielders, 3 Forwards. Orginal squad restored.'
					);
					setErrorDialogOpen(true);
					return;
				} else {
					console.log('Correct mix of players position, checking u23 quota');
					var u23PlayerSelection = selectedPlayers.filter(function(item) {
						return item.u23 === true;
					});

					if (u23PlayerSelection.length < MIN_NO_OF_U23_PLAYERS) {
						console.log('Error: Not enough U23 players');
						setErrorMessage(
							'Your team requires ' + MIN_NO_OF_U23_PLAYERS + ' U23 players. Original squad restored'
						);
						setErrorDialogOpen(true);
						return;
					} else {
						console.log('New  Player - squad setup');
						var batch = db.batch();
						selectedPlayers.forEach((player) => {
							var docRef = db
								.collection('FantasyLeague')
								.doc(CURRENT_SEASON)
								.collection('gamers')
								.doc(user.uid)
								.collection('players')
								.doc(player.player_id);
							batch.set(docRef, player);
						});

						batch
							.commit()
							.then(function() {
								console.log('Batch writing squad players completed');
								setSaveButtonDialogText('Your team selection saved');
								setOpenSaveDialog(true);
								return;
							})
							.catch((error) => {
								console.error('Error writing document: ', error);
								setSaveButtonDialogText('Unable to save your team selection');
								setOpenSaveDialog(true);
								return;
							});
					}
				}
			}

			var playersInArray = [];

			//checking if there are new players and extracting by comparing selected list and the current squad
			console.log('check for new players');

			var noOfTransfersUsed = 0;
			selectedPlayers.forEach(function(player, index) {
				//console.log('-- check if ' + player.firstname + ' in the ' + currentWeek + ' list');
				var playerFound = currentSquad.find((currentPlayer) => currentPlayer.player_id === player.player_id);
				if (playerFound === undefined) {
					//if the player is not found then it is new
					console.log('New Player found: ' + player.firstname);
					noOfTransfersUsed = noOfTransfersUsed + 1;
					playersInArray.push(player); //add the new players in the Players-In array
				} else {
					//console.log('No new Player Found');
				}
			});

			setPlayersIn(playersInArray);

			//console.log('Players Out and In: ' + playersOut.length + ' ' + playersInArray.length + ' ' + playersIn.length);
			//console.log('No of Transfer Quota: ' + noOfTransfersAllowed);

			console.log(
				'Checking no transfer quota: Used: ' + noOfTransfersUsed + ' Transfer Quota: ' + noOfTransfersAllowed
			);
			if (noOfTransfersUsed <= noOfTransfersAllowed) {
				console.log('Within Transfer Quota');
				console.log('Checking the squad mix of players position');
				//check if we have the right mix of players (2 GKs, 5 Ds, 5 Ms, 3As)
				var goalkeepers = selectedPlayers.filter(function(player) {
					return player.position === 'Goalkeeper';
				});

				var defenders = selectedPlayers.filter(function(player) {
					return player.position === 'Defender';
				});

				var midfielders = selectedPlayers.filter(function(player) {
					return player.position === 'Midfielder';
				});

				var attackers = selectedPlayers.filter(function(player) {
					return player.position === 'Forward';
				});

				if (
					goalkeepers.length !== 2 ||
					defenders.length !== 5 ||
					midfielders.length !== 5 ||
					attackers.length !== 3
				) {
					/*console.log('Error in players position  ix: ' +goalkeepers.length +
							' d: ' +
							defenders.length +
							' m: ' +
							midfielders.length +
							' f: ' +
							attackers.length
					);*/

					resetToCurrentSquad();
					setErrorMessage(
						'Your team requires 2 Goalkeepers, 5 Defenders, 5 Midfielders, 3 Forwards. Orginal squad restored.'
					);
					setErrorDialogOpen(true);
				} else {
					console.log('Correct mix of players position, checking u23 quota');
					var u23PlayerSelection = selectedPlayers.filter(function(item) {
						return item.u23 === true;
					});

					if (u23PlayerSelection.length < MIN_NO_OF_U23_PLAYERS) {
						console.log('Error: Not enough U23 players');
						resetToCurrentSquad();
						setErrorMessage(
							'Your team requires ' + MIN_NO_OF_U23_PLAYERS + ' U23 players. Original squad restored'
						);
						setErrorDialogOpen(true);
					} else {
						/** ALL GOOD AREA  */
						console.log('Updating Database');
						var transfersLeft;
						if (noOfTransfersAllowed === 99) {
							// For new users
							transfersLeft = 2;
						} else {
							//existing players will save what is available
							transfersLeft = noOfTransfersAllowed - noOfTransfersUsed;
							setNoOfTransfersAllowed(transfersLeft);
						}

						console.log('updating gamers profile');
						db
							.collection('FantasyLeague')
							.doc(CURRENT_SEASON)
							.collection('gamers')
							.doc(user.uid)
							.set(
								{
									uid             : user.uid,
									team_name       : teamName,
									date_updated    : Date.now(),
									available_funds : availableFunds,
									no_of_transfer  : transfersLeft
								},
								{ merge: true }
							)
							.then(() => {
								console.log('Updated gameer profile');
							})
							.catch((error) => {
								console.error('Error updateing gamer profile: ', error);
							});

						console.log('Removing Player Out From Current Squad');
						playersOut.forEach((playerOut) => {
							db
								.collection('FantasyLeague')
								.doc(CURRENT_SEASON)
								.collection('gamers')
								.doc(user.uid)
								.collection('players')
								.doc(playerOut.player_id)
								.delete()
								.then(() => {
									console.log(playerOut.firstname + ' successfully deleted!');
								})
								.catch((error) => {
									console.error('Error removing document: ', error);
								});
						});

						console.log('Adding Player Into Current Squad');
						playersInArray.forEach((playerIn) => {
							console.log(playerIn.firstname);
							db
								.collection('FantasyLeague')
								.doc(CURRENT_SEASON)
								.collection('gamers')
								.doc(user.uid)
								.collection('players')
								.doc(playerIn.player_id)
								.set(playerIn)
								.then(() => {
									console.log(playerIn.firstname + ' successfully created!');
									//now check the current team and update with the new player
								})
								.catch((error) => {
									console.error('Error removing document: ', error);
								});
						});

						console.log('Update Current Team');

						playersOut.forEach((playerOut) => {
							console.log('checking in current team :' + playerOut.firstname);
							var foundOutgoingPlayer = currentTeam.find(
								(player) => player.player_id === playerOut.player_id
							);
							if (foundOutgoingPlayer !== undefined) {
								console.log(
									'Found outgoing player position in team: ' +
										foundOutgoingPlayer.team_position +
										' ' +
										foundOutgoingPlayer.position
								);

								console.log(playersInArray);
								var foundIncomingPlayer = playersInArray.find(
									(player) => player.position === playerOut.position
								);

								var foundIncomingPlayerIndex = playersInArray.findIndex(
									(player) => player.player_id === foundIncomingPlayer.player_id
								);
								playersInArray.splice(foundIncomingPlayerIndex, 1);

								if (foundIncomingPlayer !== undefined) {
									console.log(
										foundIncomingPlayer.firstname +
											' successfully replaced player in team ' +
											foundOutgoingPlayer.firstname +
											' position: ' +
											foundOutgoingPlayer.team_position
									);

									db
										.collection('FantasyLeague')
										.doc(CURRENT_SEASON)
										.collection('gamers')
										.doc(user.uid)
										.collection('team')
										.doc(foundOutgoingPlayer.team_position)
										.set(foundIncomingPlayer, { merge: true })
										.then(() => {
											console.log(
												foundIncomingPlayer.firstname +
													' successfully replaced player in team ' +
													foundOutgoingPlayer.firstname +
													' position: ' +
													foundOutgoingPlayer.team_position
											);
											//now check the current team and update with the new player
											//playersInArray.splice(foundIncomingPlayerIdx, 1);
										})
										.catch((error) => {
											console.error('Error removing document: ', error);
										});
								} else {
									console.log('big error - should not happened');
								}

								//TODO: Potential race condition
							}
						});

						playersInArray = []; //reset all the arrays used
						setPlayersOut(playersInArray);
						setPlayersIn(playersInArray);
					}
				}
			} else if (noOfTransfersUsed >= noOfTransfersAllowed) {
				//calculate refunds
				resetToCurrentSquad();
				setErrorMessage(
					'You exceeded the ' + noOfTransfersAllowed + ' transfer quota limit. Original squad restored.'
				);
				setErrorDialogOpen(true);
			}
		}
	};

	const sort_by = (field, reverse, primer) => {
		const key = primer
			? function(x) {
					return primer(x[field]);
				}
			: function(x) {
					return x[field];
				};

		reverse = !reverse ? 1 : -1;

		return function(a, b) {
			return (a = key(a)), (b = key(b)), reverse * ((a > b) - (b > a));
		};
	};

	const handleRemovePlayer = (player_id, evt) => {
		//TODO: Removing a player will need check remove the player in the matchweek team

		var index = selectedPlayers.findIndex((player) => player.player_id === player_id);
		var player = selectedPlayers.find((player) => player.player_id === player_id);

		var tempPlayerOutArray = playersOut;
		tempPlayerOutArray.push(player);
		setPlayersOut(tempPlayerOutArray);
		setAvailableFunds(availableFunds + player.value);

		selectedPlayers.splice(index, 1);
		setSelectedPlayers(selectedPlayers.map((x) => x));
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		if (teamNameDialog !== '') setTeamName(teamNameDialog);
		setOpen(false);
	};

	const handleErrorDialogClose = () => {
		setErrorDialogOpen(false);
	};

	useEffect(() => {
		fetchGamerTeam();
		fetchPlayers();
		fetchSelectedPlayers();
		//fetchOrigSquad();
	}, []);

	useEffect(() => {
		const fetchTransferWindowStatus = async () => {
			const response = db.collection('FantasyLeague').doc(CURRENT_SEASON);

			response
				.get()
				.then((doc) => {
					if (doc.exists) {
						console.log(doc.data());
						setTransferWindowStatus(doc.data().transfer_window_status);
						setCurrentWeek(doc.data().current_week);
						fetchCurrentSquadAndTeam(doc.data().current_week);
					} else {
						// doc.data() will be undefined in this case
						console.log('No such document!');
					}
				})
				.catch((error) => {
					setNewPlayer(true);
					console.log('Error getting document:', error);
				});
		};

		fetchTransferWindowStatus();
	}, []);

	const fetchCurrentSquadAndTeam = (currentweek) => {
		//Getting previous week squad
		const squadResponse = db
			.collection('FantasyLeague')
			.doc(CURRENT_SEASON)
			.collection('gamers')
			.doc(user.uid)
			.collection('players');

		squadResponse
			.get()
			.then(function(querySnapshot) {
				setCurrentSquad(querySnapshot.docs.map((players) => players.data()));
			})
			.catch((err) => console.log(err));

		//Getting the previous week team
		const teamResponse = db
			.collection('FantasyLeague')
			.doc(CURRENT_SEASON)
			.collection('gamers')
			.doc(user.uid)
			.collection('team');

		var tempOrigTeamArray = [];
		teamResponse
			.get()
			.then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					// doc.data() is never undefined for query doc snapshots
					var player = doc.data();
					var temp = {
						...player,
						team_position : doc.id
					};
					tempOrigTeamArray.push(temp);
					//console.log(temp);
					if (tempOrigTeamArray.length === 15) setCurrentTeam(tempOrigTeamArray);
				});
			})
			.catch((err) => console.log(err));

		//console.log(currentTeam);
	};

	const fetchGamerTeam = async () => {
		const response = db.collection('FantasyLeague').doc(CURRENT_SEASON).collection('gamers').doc(user.uid);

		response
			.get()
			.then((doc) => {
				if (doc.exists) {
					setGamer(doc.data());
					setTeamName(doc.data().team_name);
					setAvailableFunds(doc.data().available_funds);
					setNoOfTransfersAllowed(doc.data().no_of_transfer);
					//setNoOfTransfersAllowed(99);
				} else {
					// doc.data() will be undefined in this case
					console.log('No such document!');
				}
				setNewPlayer(false);
			})
			.catch((error) => {
				setNewPlayer(true);
				console.log('Error getting document:', error);
			});
	};

	const fetchPlayers = async () => {
		const response = db.collectionGroup('2022Players').orderBy('position', 'asc');
		response
			.get()
			.then((querySnapshot) => {
				setPlayers(querySnapshot.docs.map((players) => players.data()));
			})
			.catch((err) => console.log(err));
	};

	const fetchSelectedPlayers = async () => {
		const response = db
			.collection('FantasyLeague')
			.doc(CURRENT_SEASON)
			.collection('gamers')
			.doc(user.uid)
			.collection('players')
			.orderBy('position_id', 'asc');
		response
			.get()
			.then((querySnapshot) => {
				setSelectedPlayers(querySnapshot.docs.map((players) => players.data()));
			})
			.catch((err) => console.log(err));
	};

	return (
		<StyledGrid container alignContent='center' spacing={1}>
			<Grid item xs={12}>
				<Card className={classes.root}>
					<CardActionArea>
						<CardMedia
							className={classes.media}
							image='https://firebasestorage.googleapis.com/v0/b/voiddeckfootie.appspot.com/o/fantasy_league%2Fspl_fl_image.jpeg?alt=media&token=e49d8ca0-2033-4ffc-bfee-c74e5d062bb3'
							title='Fantasy Football Transfer'
						/>
						<CardContent>
							<Typography align='center' gutterBottom variant='h6' component='h2'>
								SPL Fantasy Footbal Transfer
							</Typography>
						</CardContent>
					</CardActionArea>
				</Card>
			</Grid>

			<Grid item xs={12}>
				{teamName === '' ? (
					<div align='center'>
						<Button variant='outlined' color='primary' onClick={handleClickOpen}>
							Click To Create Your SPL Dream Team
						</Button>
						<Dialog open={open} onClose={handleClose} aria-labelledby='form-dialog-title'>
							<DialogTitle id='form-dialog-title'>Fantasy League</DialogTitle>
							<DialogContent>
								<DialogContentText>Enter Your Team Name</DialogContentText>
								<TextField
									autoFocus
									margin='dense'
									id='name'
									label='Team Name'
									type='email'
									onChange={(e) => setTeamNameDialog(e.target.value)}
									fullWidth
								/>
							</DialogContent>
							<DialogActions>
								<Button onClick={handleClose} color='primary'>
									Cancel
								</Button>
								<Button onClick={handleClose} color='primary'>
									Done
								</Button>
							</DialogActions>
						</Dialog>
					</div>
				) : (
					<div>
						<Card className={classes.root}>
							<CardActionArea>
								<CardContent>
									<Typography align='center' gutterBottom variant='h6' component='h2'>
										{teamName}
									</Typography>
									<Typography align='center' variant='subtitle1' style={{ color: 'red' }}>
										Available Funds: SGD {availableFunds} M
									</Typography>
									<Typography align='center' variant='subtitle1'>
										Squad Size: {selectedPlayers.length} / 15
									</Typography>
									<Typography align='center' variant='subtitle1'>
										No of Transfers Available: {noOfTransfersAllowed}
									</Typography>
								</CardContent>
							</CardActionArea>
						</Card>
						{/*
						playersOut.map((players) => (
							<Typography>{players.firstname}</Typography>
						)) */}
					</div>
				)}
			</Grid>
			<Grid item xs={12}>
				{selectedPlayers.length === 0 ? (
					<Card
						style={{
							width           : '100%',
							margin          : 'auto',
							padding         : '10px',
							backgroundColor : '#FDC5CF'
						}}
					>
						<Typography align='center' gutterBottom variant='h6' component='h6'>
							Start Building Your Team
						</Typography>
					</Card>
				) : (
					<div>
						{/*currentSquad.map((player) => <Typography>{JSON.stringify(player)}</Typography>)*/}
						<TableContainer component={Paper}>
							<Table className={classes.table} size='small' aria-label='a dense table'>
								<TableHead>
									<TableRow>
										<StyledTableCell
											align='left'
											classes={{
												head : classes.head,
												body : classes.body
											}}
										/>
										<StyledTableCell
											classes={{
												head : classes.head,
												body : classes.body
											}}
										>
											Name
										</StyledTableCell>
										<StyledTableCell
											classes={{
												head : classes.head,
												body : classes.body
											}}
										>
											Club
										</StyledTableCell>
										<StyledTableCell
											align='left'
											classes={{
												head : classes.head,
												body : classes.body
											}}
										>
											Position
										</StyledTableCell>
										<StyledTableCell
											align='left'
											classes={{
												head : classes.head,
												body : classes.body
											}}
										>
											$(M)
										</StyledTableCell>
										<StyledTableCell
											align='left'
											classes={{
												head : classes.head,
												body : classes.body
											}}
										>
											Pts
										</StyledTableCell>
										<StyledTableCell
											align='left'
											classes={{
												head : classes.head,
												body : classes.body
											}}
										/>
									</TableRow>
								</TableHead>
								<TableBody>
									{selectedPlayers.map((player, index) => (
										<TableRow key={index}>
											<StyledTableCell
												style={{ width: '10%' }}
												classes={{
													head : classes.head,
													body : classes.body
												}}
											>
												<IconButton
													id={'removeButton_' + player.player_id}
													color='primary'
													aria-label='upload picture'
													component='span'
													onClick={(e) => handleRemovePlayer(player.player_id, e)}
													size='large'
												>
													<IndeterminateCheckBoxIcon />
												</IconButton>
											</StyledTableCell>
											{player.u23 === 'y' ? (
												<StyledTableCell
													component='th'
													scope='row'
													classes={{
														head : classes.head,
														body : classes.body
													}}
												>
													{player.firstname} (u23)
												</StyledTableCell>
											) : (
												<StyledTableCell
													component='th'
													scope='row'
													classes={{
														head : classes.head,
														body : classes.body
													}}
												>
													{player.firstname}
												</StyledTableCell>
											)}
											<StyledTableCell
												classes={{
													head : classes.head,
													body : classes.body
												}}
											>
												{player.team_short_name}
											</StyledTableCell>
											<StyledTableCell
												align='left'
												classes={{
													head : classes.head,
													body : classes.body
												}}
											>
												{player.position}
											</StyledTableCell>
											<StyledTableCell
												align='left'
												classes={{
													head : classes.head,
													body : classes.body
												}}
											>
												{player.value}
											</StyledTableCell>
											<StyledTableCell
												align='left'
												classes={{
													head : classes.head,
													body : classes.body
												}}
											>
												{player.fl_points}
											</StyledTableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
						{transferWindowStatus === 'open' && noOfTransfersAllowed > 0 ? (
							<div>
								<Button
									onClick={handleSave}
									variant='contained'
									color='primary'
									size='small'
									className={classes.button}
									startIcon={<SaveIcon />}
									fullWidth
								>
									Save
								</Button>
							</div>
						) : (
							<div>
								<Button
									onClick={handleSave}
									disabled
									variant='contained'
									color='primary'
									size='small'
									className={classes.button}
									startIcon={<SaveIcon />}
									fullWidth
								>
									{noOfTransfersAllowed === 0 ? 'Transfer Quota Used' : 'Transfer Window Closed'}
								</Button>
							</div>
						)}
						<Dialog
							open={openSaveDialog}
							onClose={handleCloseSaveDialog}
							aria-labelledby='alert-dialog-title'
							aria-describedby='alert-dialog-description'
						>
							<DialogTitle id='alert-dialog-title'>{teamName + 'Squad'}</DialogTitle>
							<DialogContent>
								<DialogContentText id='alert-dialog-description'>
									{saveButtonDialogText}
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={handleCloseSaveDialog} color='primary' autoFocus>
									Close
								</Button>
							</DialogActions>
						</Dialog>
					</div>
				)}
			</Grid>
			<Grid item xs={12}>
				<Card>
					<CardHeader title='Selection Summary' />
					<CardContent>
						<Typography variant='subtitle1'>Available Funds: $ {availableFunds} M</Typography>
						{availableFunds - selectedPlayersCost >= 0 ? (
							<Typography variant='subtitle1'>
								Total selected player(s) value: $ {selectedPlayersCost} M
							</Typography>
						) : (
							<Typography variant='subtitle1' style={{ color: 'red' }}>
								Total selected player(s) value: $ {selectedPlayersCost} M (Exceed Available Funds)
							</Typography>
						)}
					</CardContent>
				</Card>
			</Grid>
			<Grid item xs={12}>
				<ThemeProvider theme={createTheme()}>
					<MUIDataTable title={'Players Search'} data={players} columns={columns} options={options} />
				</ThemeProvider>
				<Dialog
					open={openErrorDialog}
					onClose={handleErrorDialogClose}
					aria-labelledby='alert-dialog-title'
					aria-describedby='alert-dialog-description'
				>
					<DialogTitle id='alert-dialog-title'>Team Selection Violation</DialogTitle>
					<DialogContent>
						<DialogContentText id='alert-dialog-description'>{errorMessage}</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleErrorDialogClose} color='primary' autoFocus>
							Close
						</Button>
					</DialogActions>
				</Dialog>
			</Grid>
		</StyledGrid>
	);
}

import FantasyLeagueOverview from '../component/FantasyLeagueOverview';
import React, { Component } from 'react';

class fantasyleagueoverview extends Component {
	render() {
		return (
            <FantasyLeagueOverview></FantasyLeagueOverview>
		)
	}
}

export default fantasyleagueoverview;

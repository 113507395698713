import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Avatar, Button } from '@mui/material';
import CardHeader from '@mui/material/CardHeader';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { withRouter } from 'react-router-dom';
import { useSession } from '../App';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import FormHelperText from '@mui/material/FormHelperText';
import { yellow, green, red } from '@mui/material/colors';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@mui/material/Alert';
import Link from '@mui/material/Link';

import firebase from '../firebase';
import { messaging } from '../init-fcm';
import AppBarColour from './Navbar';

import PredictionDialog from './PredictionDialog';

const PREFIX = 'ColourChange';

const classes = {
	head              : `${PREFIX}-head`,
	body              : `${PREFIX}-body`,
	root              : `${PREFIX}-root`,
	root2             : `${PREFIX}-root2`,
	table             : `${PREFIX}-table`,
	paper             : `${PREFIX}-paper`,
	media             : `${PREFIX}-media`,
	mediaPlayerRating : `${PREFIX}-mediaPlayerRating`,
	yellow            : `${PREFIX}-yellow`,
	green             : `${PREFIX}-green`,
	red               : `${PREFIX}-red`,
	card              : `${PREFIX}-card`
};

const Root = styled('div')(({ theme }) => ({
	[`& .${classes.root2}`]: {
		flexGrow : 1
	},

	[`& .${classes.table}`]: {
		minWidth : 700
	},

	[`& .${classes.paper}`]: {
		padding   : theme.spacing(2),
		textAlign : 'center',
		color     : theme.palette.text.secondary
	},

	[`& .${classes.media}`]: {
		height : 180
	},

	[`& .${classes.mediaPlayerRating}`]: {
		width  : 70,
		height : 70
	},

	[`& .${classes.yellow}`]: {
		color           : theme.palette.getContrastText(yellow[500]),
		backgroundColor : yellow[500]
	},

	[`& .${classes.green}`]: {
		color           : theme.palette.getContrastText(green[500]),
		backgroundColor : green[500]
	},

	[`& .${classes.red}`]: {
		color           : theme.palette.getContrastText(red[500]),
		backgroundColor : red[500]
	},

	[`& .${classes.card}`]: {}
}));

const internationalWeek = false;
const prizeSelection = true; //toggles prize notification for giveaways

function Alert(props) {
	return <MuiAlert elevation={6} variant='filled' {...props} />;
}

export let ColourChange = '#d10606';

function Dashboard(props) {
	const user = useSession();

	const [ myMatchdayPredictions, setMyMatchdayPredictions ] = useState([]);
	const [ matchdayMatch, setMatchdayMatch ] = useState([]);
	const [ myProfile, setMyProfile ] = useState([]);
	const [ myPredictionRanking, setMyPredictionRanking ] = useState([]);
	const [ myPredictionPoints, setMyPredictionPoints ] = useState([]);
	const [ myFantasyLeagueRanking, setMyFantasyLeagueRanking ] = useState([]);
	const [ myFantasyLeaguePoints, setMyFantasyLeaguePoints ] = useState([]);

	//prediction score snackbar
	const [ open, setOpen ] = React.useState(false); //snackbar
	const [ alertStanding, setAlertStanding ] = useState();

	const [ banner, setBanner ] = useState('Singapore Premier League');
	const [ subBanner, setSubBanner ] = useState('Support Local Football');
	const [ navBarColour, setNavBarColour ] = useState('#0d0c0c');
	const [ cheer, setCheer ] = useState('Do Us Proud');
	const [ currentClub, setCurrentClub ] = useState(10);
	const [ matchdayWeek, setMatchdayWeek ] = useState(1);

	const [ sgfcPredictionsSummary, setSgfcPredictionsSummary ] = useState([]);
	const [ anfcPredictionsSummary, setAnfcPredictionsSummary ] = useState([]);
	const [ bkfcPredictionsSummary, setBkfcPredictionsSummary ] = useState([]);
	const [ gifcPredictionsSummary, setGifcPredictionsSummary ] = useState([]);
	const [ lcsfPredictionsSummary, setLcsfPredictionsSummary ] = useState([]);
	const [ hufcPredictionsSummary, setHufcPredictionsSummary ] = useState([]);
	const [ trfcPredictionsSummary, setTrfcPredictionsSummary ] = useState([]);
	const [ tpfcPredictionsSummary, setTpfcPredictionsSummary ] = useState([]);
	const [ ylfcPredictionsSummary, setYlfcPredictionsSummary ] = useState([]);

	const [ bannerPictureURL, setBannerPictureURL ] = useState(
		'/images/Spl-neutral-banner.jpeg' //https://www.spl.sg/
	);

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpen(false);
	};

	const handleClubChange = (event) => {
		const currentClub = event.target.value;
		if (currentClub === 10) {
			setUserAppSkin(currentClub);
			setCurrentClub(currentClub);
			updateUserProfile(currentClub, 'Neutral');
			user.club = 'Neutral';
		}
		if (currentClub === 20) {
			//setClub("Geylang I'tnl");
			setUserAppSkin(currentClub);
			setCurrentClub(currentClub);
			updateUserProfile(currentClub, 'Geylang International');
			user.club = 'GIFC';
		} else if (currentClub === 30) {
			setUserAppSkin(currentClub);
			setCurrentClub(30);
			updateUserProfile(30, 'Tampines Rovers');
			user.club = 'TRFC';
		} else if (currentClub === 40) {
			setUserAppSkin(currentClub);
			setCurrentClub(40);
			updateUserProfile(40, 'Albirex Niigata');
			user.club = 'ANFC';
		} else if (currentClub === 50) {
			setUserAppSkin(currentClub);
			setCurrentClub(50);
			updateUserProfile(50, 'Balestier Khalsa');
			user.club = 'BKFC';
		} else if (currentClub === 60) {
			setUserAppSkin(currentClub);
			setCurrentClub(60);
			updateUserProfile(60, 'Hougang United');
			user.club = 'HGFC';
		} else if (currentClub === 70) {
			setUserAppSkin(currentClub);
			setCurrentClub(70);
			updateUserProfile(70, 'Lion City Sailors');
			user.club = 'LCS';
		} else if (currentClub === 80) {
			setUserAppSkin(currentClub);
			setCurrentClub(80);
			updateUserProfile(80, 'Tanjong Pagar United');
			user.club = 'TPUFC';
		} else if (currentClub === 90) {
			setUserAppSkin(currentClub);
			setCurrentClub(90);
			updateUserProfile(90, 'Young Lions');
			user.club = 'YGLIONS';
		}
	};

	useEffect(() => {
		const db = firebase.firestore();
		db
			.collection('Users')
			.doc(user.uid)
			.get()
			.then((doc) => {
				if (!doc || doc.data() === undefined) {
					let setDoc = db
						.collection('Users')
						.doc(user.uid)
						.set(
							{
								displayName      : user.displayName,
								prediction_score : 0,
								club_index       : 10,
								club_name        : 'Neutral'
							},
							{ merge: true }
						)
						.then(console.log('User: ' + user.displayName + ' is created'))
						.catch((err) => console.log(err));
				} else {
					//getNotificationToken();
					setCurrentClub(doc.data().club_index);
					setUserAppSkin(doc.data().club_index);
					setMyProfile(doc.data());

					console.log(doc.data())

					//console.log(doc.data().prediction_score_2022_by_rounds);
					//if (doc.data().prediction_ranking_2022_by_rounds === undefined)
					//	setMyPredictionPoints({ round_1: -1 });
					//else 
					setMyPredictionPoints(doc.data().prediction_score_2022);

					//if (doc.data().prediction_ranking_2022_by_rounds === undefined)
					//	setMyPredictionRanking({ round_1: -1 });
					//else 
					setMyPredictionRanking(doc.data().rank);

					/*
					if (doc.data().fantasyleague_points_2022_by_rounds === undefined)
						setMyFantasyLeaguePoints({ round_1: -1 });
					else setMyFantasyLeaguePoints(doc.data().fantasyleague_ranking_2022_by_rounds);

					if (doc.data().fantasyleague_ranking_2022_by_rounds === undefined)
						setMyFantasyLeagueRanking({ round_1: -1 });
					else setMyFantasyLeagueRanking(doc.data().fantasyleague_points_2022_by_rounds);
					*/
				}
			})
			.catch((err) => console.log(err));

			db
			.collection('FantasyLeague/2022/gamers')
			.doc(user.uid)
			.get()
			.then((doc) => {
				if (doc || doc.data() === undefined) {

					console.log(doc.data().current_ranking);
						
					if (doc.data().total_fl_points === undefined)
						setMyFantasyLeaguePoints(-1);
					else setMyFantasyLeaguePoints(doc.data().total_fl_points);

					if (doc.data().current_ranking === undefined)
						setMyFantasyLeagueRanking(-1);
					else setMyFantasyLeagueRanking(doc.data().current_ranking);

					
				} else {
					//getNotificationToken();
					setCurrentClub(doc.data().club_index);
					setUserAppSkin(doc.data().club_index);
					setMyProfile(doc.data());

					console.log(doc.data().prediction_score_2022_by_rounds);
					if (doc.data().prediction_ranking_2022_by_rounds === undefined)
						setMyPredictionPoints({ round_1: -1 });
					else setMyPredictionPoints(doc.data().prediction_score_2022_by_rounds);

					if (doc.data().prediction_ranking_2022_by_rounds === undefined)
						setMyPredictionRanking({ round_1: -1 });
					else setMyPredictionRanking(doc.data().prediction_ranking_2022_by_rounds);

					/*
					if (doc.data().fantasyleague_points_2022_by_rounds === undefined)
						setMyFantasyLeaguePoints({ round_1: -1 });
					else setMyFantasyLeaguePoints(doc.data().fantasyleague_ranking_2022_by_rounds);

					if (doc.data().fantasyleague_ranking_2022_by_rounds === undefined)
						setMyFantasyLeagueRanking({ round_1: -1 });
					else setMyFantasyLeagueRanking(doc.data().fantasyleague_points_2022_by_rounds);
					*/
				}
			})
			.catch((err) => console.log(err));



		const fetchMyMatchdayPredictions = async () => {
			const db = firebase.firestore();

			let data2 = db
				.collectionGroup('2022_Match_Predictions')
				.where('uid', '==', user.uid)
				.where('prediction_points', '==', -1)
				.get()
				.then(function(querySnapshot2) {
					setMyMatchdayPredictions(querySnapshot2.docs.map((prediction) => prediction.data()));
				});
		};
		fetchMyMatchdayPredictions();

		const fetchMatchdayMatches = async () => {
			if (internationalWeek) {
				const db = firebase.firestore();
				const data = await db
					.collection('2021Matches')
					.where('match_status', '==', 'matchday')
					.where('match_competition_status', '==', 'WCQ')
					.orderBy('match_date')
					.get()
					.then(function(querySnapshot) {
						if (!querySnapshot.empty) {
							var matches = [ ...querySnapshot.docs ];
							console.log(matches[0].docId);
							setMatchdayWeek(matches[0].data().match_week);
							setMatchdayMatch(querySnapshot.docs.map((matchdayMatch) => matchdayMatch.data()));
							console.log(matches[0]);
						}
						console.log(matchdayMatch.length);
					})
					.catch((error) => console.log(error));
			} else {
				const db = firebase.firestore();
				const data = await db
					.collection('2022Matches')
					.where('match_status', '==', 'matchday')
					.get()
					.then(function(querySnapshot) {
						if (!querySnapshot.empty) {
							var matches = [ ...querySnapshot.docs ];
							setMatchdayWeek(matches[0].data().match_week);
							setMatchdayMatch(querySnapshot.docs.map((matchdayMatch) => matchdayMatch.data()));
						}
					})
					.catch((error) => console.log(error));
			}
		};
		fetchMatchdayMatches();
	}, []);

	const Style = {
		height : 400
	};

	function setUserAppSkin(currentClub) {
		if (internationalWeek) {
			setBannerPictureURL(
				'https://scontent.fsin8-1.fna.fbcdn.net/v/t1.6435-9/70748625_937947473229376_6135697172764557312_n.jpg?_nc_cat=108&ccb=1-3&_nc_sid=8bfeb9&_nc_ohc=PRnuMV1OVBoAX8NJCR2&_nc_ht=scontent.fsin8-1.fna&oh=0452682f54daf4b66f9daa6d53f3e5b6&oe=60D8297B'
			);
			setBanner('World Cup 2022 Qualifiers');
			setSubBanner("Ohhhh Singapura - We're behind you - MAJULAH");
			setCheer('Satu Singapura');
		} else {
			if (currentClub === 10) {
				setBannerPictureURL(
					'/images/Spl-neutral-banner.jpeg' //https://www.spl.sg/
				);
				setBanner('Singapore Premier League');
				setSubBanner('Support Local Football');
				setCheer('Do Us Proud');
				ColourChange = '#d10606';
			}
			if (currentClub === 20) {
				//setClub("Geylang I'tnl");
				setBannerPictureURL(
					'/images/generic/geylang_dashboard.jpeg' //https://gifc.org.sg/clubhouse/
				);
				setBanner('Geylang Eagles In My Heart');
				setSubBanner('Eagles All The Way');
				setCheer('Ohh...Common Eagle...We Behind You Majulah');
				ColourChange = '#05731c';
			} else if (currentClub === 30) {
				setBannerPictureURL(
					'/images/Tampines-rovers-banner.jpeg' //https://business.facebook.com/TampinesRoversFC/photos/a.257733607644792/3917419825009467/?type=1&theater
				);
				setBanner('Win, Lose or Draw our love for Tampines Rovers will never die');
				setSubBanner('#ForzaRovers');
				setCheer("C'Mon Rovers");
				ColourChange = '#d4ca1c';
			} else if (currentClub === 40) {
				setBannerPictureURL(
					'/images/Albirex-niigata-banner.jpeg' //https://www.albirex.com.sg/en/news/42102/
				);
				setBanner('Joy, Bridge, Dream');
				setSubBanner('The Reason');
				setCheer('Flying ahead to become King');
				ColourChange = '#e39105';
			} else if (currentClub === 50) {
				setBannerPictureURL(
					'/images/Balestier-khalsa-banner.jpeg' //https://www.facebook.com/balestierkhalsa.fc/photos/a.4145377415508567/4145379812174994
				);
				setBanner('We are Balestier Khalsa');
				setSubBanner('Balestier Khalsa till the end');
				setCheer("Let's go BK, let's go!");
				ColourChange = '#b5b5b5';
			} else if (currentClub === 60) {
				setBannerPictureURL(
					'/images/Hougang-united-banner.jpeg' //https://www.facebook.com/hougangunited/photos/3980654361997973
				);
				setBanner('United for Hougang United');
				setSubBanner('Once we go black, we never go back');
				setCheer('Hougang Only One Love');
				ColourChange = '#db6a00';
			} else if (currentClub === 70) {
				setBannerPictureURL(
					'/images/Lion-city-sailors-banner.jpeg' //https://www.lioncitysailorsfc.sg/the-club/
				);
				setBanner('The Sailors');
				setSubBanner('Sailing into uncharted waters');
				setCheer('We are The Crew');
				ColourChange = '#1105b0';
			} else if (currentClub === 80) {
				setBannerPictureURL(
					'/images/Tanjong-pagar-united-banner.jpeg' //https://www.facebook.com/tpufc/photos/3827437340680641
				);
				setBanner('Tanjong Pagar United');
				setSubBanner('Battezvousjusqualafin');
				setCheer('Berjuang sampai mati');
				ColourChange = '#a60808';
			} else if (currentClub === 90) {
				setBannerPictureURL(
					'/images/Young-lions-banner.jpeg' //https://www.facebook.com/yglions/photos/4281741265170311
				);
				setBanner('Garena Young Lions');
				setSubBanner('RAWR like a lion');
				setCheer('#COYL');
				ColourChange = '#d10606';
			}
		}
	}

	function updateUserProfile(clubIndex, clubName) {
		const db = firebase.firestore();
		db
			.collection('Users')
			.doc(user.uid)
			.set(
				{
					club_index : clubIndex,
					club_name  : clubName
				},
				{ merge: true }
			)
			.then(function(docRef) {
				console.log('-- user updated --');
			})
			.catch(function(error) {
				console.error('-- Error adding document: ', error, ' -- ');
			});
	}

	function PredictionRoundsPrizeMoney(props) {
		const ranking = props.ranking;
		const rounds = props.rounds;
		const points = props.points;

		if (ranking === 1) {
			return (
				<div>
				<Typography style={{ textAlign: 'center' }} variant='h6'>
					Your Current Round {rounds} Prize: $30
				</Typography>
				<Typography style={{ textAlign: 'center' }} variant='subtitle1'>
						(Top 3 Predictors Get The Prize)
					</Typography>
				<Typography style={{ textAlign: 'center' }} variant='h6'>
				Points: {points}
			</Typography>
			</div>
			);
		} else if (ranking === 2) {
			return (
				<div>
				<Typography style={{ textAlign: 'center' }} variant='h6'>
					Your Current Round {rounds} Prize: $20
				</Typography>
				<Typography style={{ textAlign: 'center' }} variant='subtitle1'>
						(Top 3 Predictors Get The Prize)
					</Typography>
				<Typography style={{ textAlign: 'center' }} variant='h6'>
				Points: {points}
			</Typography>
			</div>
			);
		} else if (ranking === 3) {
			return (
				<div>
				<Typography style={{ textAlign: 'center' }} variant='h6'>
					Your Current Round {rounds} Prize: $10
				</Typography>
				<Typography style={{ textAlign: 'center' }} variant='subtitle1'>
						(Top 3 Predictors Get The Prize)
					</Typography>
				<Typography style={{ textAlign: 'center' }} variant='h6'>
				Points: {points}
			</Typography>
			</div>
			);
		} else if (ranking === -1) {
			return (
				<div>
					<Paper variant='outlined'>
						<img src='../images/generic/spl_trophy.jpeg' alt='predictions' />
					</Paper>

					<Link href='/mypredictions'>Enter Match Score Predictions</Link>
				</div>
			);
		} else {
			return (
				<div>
					<Typography style={{ textAlign: 'center' }} variant='h6'>
						Your Current Round {rounds} Prize: No Prize 
					</Typography>
					<Typography style={{ textAlign: 'center' }} variant='subtitle1'>
						(Top 3 Predictors Get The Prize)
					</Typography>
					<Typography style={{ textAlign: 'center' }} variant='h6'>
						Points: {points}
					</Typography>
				</div>
			);
		}
	}

	function FantasyLeagueRoundsPrizeMoney(props) {
		const ranking = props.ranking;
		const rounds = props.rounds;
		const points = props.points;

		if (ranking === 1) {
			return (
				<div>
				<Typography style={{ textAlign: 'center' }} variant='h6'>
					Your Current Round {rounds} Prize: $30
				</Typography>
				<Typography style={{ textAlign: 'center' }} variant='subtitle1'>
						(Top 3 Predictors Get The Prize)
					</Typography>
				<Typography style={{ textAlign: 'center' }} variant='h6'>
				Points: {points}
			</Typography>
			</div>
			);
		} else if (ranking === 2) {
			return (
				<div>
				<Typography style={{ textAlign: 'center' }} variant='h6'>
					Your Current Round {rounds} Prize: $30
				</Typography>
				<Typography style={{ textAlign: 'center' }} variant='subtitle1'>
						(Top 3 Predictors Get The Prize)
					</Typography>
				<Typography style={{ textAlign: 'center' }} variant='h6'>
				Points: {points}
			</Typography>
			</div>
			);
		}
		else if (ranking === 3) {
			return (
				<div>
				<Typography style={{ textAlign: 'center' }} variant='h6'>
					Your Current Round {rounds} Prize: $30
				</Typography>
				<Typography style={{ textAlign: 'center' }} variant='subtitle1'>
						(Top 3 Predictors Get The Prize)
					</Typography>
				<Typography style={{ textAlign: 'center' }} variant='h6'>
				Points: {points}
			</Typography>
			</div>
			);
		}
		else if (ranking === -1) {
			return (
				<div>
					<Paper variant='outlined'>
						<img src='../images/generic/spl_fm.jpeg' />
					</Paper>

					<Link href='/fantasyleague/transfer'>Build My Squad</Link>
				</div>
			);
		} else {
			return (
				<div>
				<Typography style={{ textAlign: 'center' }} variant='h6'>
						Current Round {rounds} Prize: No Prize 
					</Typography>
					<Typography style={{ textAlign: 'center' }} variant='subtitle1'>
						(Top 3 Predictors Get The Prize)
					</Typography>
					<Typography style={{ textAlign: 'center' }} variant='h6'>
						Points: {points}
					</Typography>
					</div>
			);
		}
	}

	function ShowMyPrediction(props) {
		//console.log(props.match_id);
		//console.log(myMatchdayPredictions);
		const match = props.match;

		const myPrediction = myMatchdayPredictions.find((prediction) => prediction.match_id === match.match_id);
		//console.log(myPrediction);
		if (myPrediction === undefined) {
			return (
				<TableCell>
					My Prediction: Not Made <PredictionDialog newPrediction='true' user={user} match={match} />
				</TableCell>
			);
		} else {
			/*
			console.log(
				'My prediction for match ' +
					myPrediction.match_id +
					' : ' +
					myPrediction.predicted_home_score +
					' - ' +
					myPrediction.predicted_away_score
			);
			*/
			return (
				<TableCell>
					{'My Prediction: ' +
						myPrediction.predicted_home_score +
						' - ' +
						myPrediction.predicted_away_score +
						' '}
					<Link href='/mypredictions'>Update</Link>
				</TableCell>
			);
		}
	}

	const handleNotificationSnackbarClick = () => {
		//setShowNotification(true);
	};

	const handleNotificationSnankbarClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		//setShowNotification(false);
	};

	// Handle incoming messages. Called when:
	// - a message is received while the app has focus
	// - the user clicks on an app notification created by a service worker
	//   `messaging.setBackgroundMessageHandler` handler.
	/*
	messaging.onMessage((payload) => {
		console.log('Message received. ', payload);
		setNotificationMessage(JSON.stringify(payload.notification.body));
		setShowNotification(true);

		// ...
	});
	*/

	/*
	function getNotificationToken() {
		messaging.getToken({vapidKey: 'BHESmk9D-g3gWYl9yVGj5FLK6Tvwt4ACfcFwUyDNNx8tmffqvmlDoHVft8vZpEHdOGmGMjs9hSY5jIQuRndliqQ'}).then((currentToken) => {
			if (currentToken) {
				  console.log(user.uid);
				  const db = firebase.firestore();
				  db
					.collection('Users')
					.doc(user.uid)
					.get()
					.then((doc) => {
						//console.log(doc.data())
						var notificationTokens = doc.data().notification_tokens;
						//console.log(notificationTokens.length);
						if (notificationTokens === undefined || notificationTokens.length < 1)
						{
							console.log('User has no notification tokens')
							let setDoc = db
									.collection('Users')
									.doc(user.uid)
									.set(
										{
											notification_tokens: [{currentToken}]
										},
										{ merge: true }
									)
									.then(console.log('User notification is updated'))
									.catch((err) => console.log(err));
						} else {
							//console.log('User has notifiction tokens: ' + JSON.stringify(notificationTokens));
							var found = notificationTokens.find(item => {
								//console.log(item.currentToken + ' --> ' + currentToken);
								return item.currentToken === currentToken;
							})

							console.log('found: ' + found )

							if (found !== undefined) {
								console.log('This notification already regirstered with user' + currentToken)
							} else{
								console.log('This notification token is not found')
								
								let setDoc = db
								.collection('Users')
								.doc(user.uid)
								.set(
									{
										notification_tokens: [...notificationTokens, {currentToken}]
									},
									{ merge: true }
								)
								.then(console.log('User notification is updated'))
								.catch((err) => console.log(err));
								
								
							}
				
						}
						
						
					})
					.catch((err) => console.log(err))
					
			  return currentToken;
			  //sendTokenToServer(currentToken);
			  //updateUIForPushEnabled(currentToken);
			} else {
			  // Show permission request.
			  console.log('No registration token available. Request permission to generate one.');
			  // Show permission UI.
			  //updateUIForPushPermissionRequired();
			  //setTokenSentToServer(false);
			}
		  }).catch((err) => {
			console.log('An error occurred while retrieving token. ', err);
			//showToken('Error retrieving registration token. ', err);
			//setTokenSentToServer(false);
		  });
	}
	*/

	return (
		<Root className={classes.root}>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<Card className={classes.card}>
						<CardActionArea>
							<CardMedia component='img' image={bannerPictureURL} title='Club Banner' />
						</CardActionArea>
						<CardContent>
							<Typography alignitem='center' align='center' gutterBottom variant='h5' component='h2'>
								{banner}
							</Typography>
							<Typography
								alignitem='center'
								align='center'
								gutterBottom
								variant='body2'
								color='textSecondary'
								component='p'
							>
								{subBanner}
							</Typography>
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={12}>
					<Card>
						<CardActionArea>
							<CardHeader
								avatar={<Avatar src={user.photoURL} />}
								title={user.displayName}
								subheader={cheer}
							/>
						</CardActionArea>
						<CardContent>
							<FormControl className={classes.formControl}>
								<Select
									labelId='myclub'
									id='myclub-select'
									value={currentClub}
									onChange={handleClubChange}
								>
									<MenuItem value={10}>Neutral</MenuItem>
									<MenuItem value={20}>Geylang International</MenuItem>
									<MenuItem value={30}>Tampines Rovers</MenuItem>
									<MenuItem value={40}>Albirex Niigata</MenuItem>
									<MenuItem value={50}>Balestier Khalsa</MenuItem>
									<MenuItem value={60}>Hougang United</MenuItem>
									<MenuItem value={70}>Lion City Sailors</MenuItem>
									<MenuItem value={80}>Tanjong Pagar</MenuItem>
									<MenuItem value={90}>Young Lions</MenuItem>
								</Select>
								<FormHelperText>Select your club or remain a neutral</FormHelperText>
							</FormControl>
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={12}>
					<Grid container spacing={1}>
						<Grid item xs={12} sm={12}>
							<Card className={classes.cardHeader}>
								<CardActionArea>
									<CardHeader
										sx={{
											height     : 50,
											textAlign  : 'center',
											background : 'black',
											color      : 'white'
										}}
										title='My Positions'
										titleTypographyProps={{ variant: 'h6' }}
									/>
								</CardActionArea>
							</Card>
						</Grid>

						<Grid item xs={12} sm={6}>
							<Card className={classes.card}>
								<CardActionArea>
									<CardHeader style={{ textAlign: 'center' }} title='Prediction Ranking' />
								</CardActionArea>
								<CardContent>
									<Typography style={{ textAlign: 'center' }} variant='h5'>
										{myPredictionRanking}
									</Typography>
									<PredictionRoundsPrizeMoney
										ranking={myPredictionRanking}
										rounds='1'
										points={myPredictionPoints}
									/>
								</CardContent>
							</Card>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Card className={classes.card}>
								<CardActionArea>
									<CardHeader style={{ textAlign: 'center' }} title='Fantasy League Ranking' />
								</CardActionArea>
								<CardContent>
									{myFantasyLeagueRanking === -1 ? (
										<Typography style={{ textAlign: 'center' }} variant='h6'>
											No Ranking Yet
										</Typography>
									) : (
										<Typography style={{ textAlign: 'center' }} variant='h5'>
											{myFantasyLeagueRanking}
										</Typography>
									)}
									<FantasyLeagueRoundsPrizeMoney
										ranking={myFantasyLeagueRanking}
										rounds='1'
										points={myFantasyLeaguePoints}
									/>
								</CardContent>
							</Card>
						</Grid>
						<Grid item xs={12} sm={12}>
							<Card className={classes.cardHeader}>
								<CardActionArea>
									<CardHeader
										sx={{
											height     : 50,
											textAlign  : 'center',
											background : 'black',
											color      : 'white'
										}}
										title={'Week ' + matchdayWeek + ' Games'}
										titleTypographyProps={{ variant: 'h6' }}
									/>
								</CardActionArea>
							</Card>
						</Grid>
						<Grid item xs={12} sm={6}>
							{matchdayMatch.map((match, index) => (
								<Card key={index}>
									<CardActionArea>
										<CardHeader
											avatar={
												<Avatar
													className={
														match.hometeam_id.indexOf('GIFC') > 0 ? (
															classes.green
														) : (
															classes.red
														)
													}
												>
													{match.match_avatar}
												</Avatar>
											}
											title={match.match_competition_status}
											subheader={match.match_title}
										/>
										<CardContent>
											<TableContainer component={Paper}>
												<Table className={classes.paperContainer} aria-label='simple table'>
													<TableHead>
														<TableRow>
															<TableCell>
																Home <Avatar src={match.hometeam_logo_url} />
															</TableCell>
															<TableCell>
																Away
																<Avatar src={match.awayteam_logo_url} />
															</TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														<TableRow>
															<TableCell>
																Match Date:{' '}
																{new Date(match.match_date.seconds * 1000).toLocaleDateString()}
															</TableCell>
														</TableRow>
														<TableRow>
															<TableCell>Venue: {match.match_venue}</TableCell>
														</TableRow>
														<TableRow>
															<ShowMyPrediction match={match} />
														</TableRow>
														<TableRow>
															<TableCell colSpan={3}>
																<Typography
																	sx={{ textDecoration: 'underline' }}
																	gutterBottom
																	variant='subtitle1'
																>
																	Predictions Summary
																</Typography>
																<Typography gutterBottom variant='subtitle2'>
																	Total Predictions Made: {match.total_predictions}
																</Typography>
																<Typography gutterBottom variant='subtitle2'>
																	Home Win: {match.hometeam_predictions_win}
																</Typography>
																<Typography gutterBottom variant='subtitle2'>
																	Home Lose: {match.awayteam_predictions_win}
																</Typography>
																<Typography gutterBottom variant='subtitle2'>
																	Home Draw: {match.hometeam_predictions_draw}
																</Typography>
															</TableCell>
														</TableRow>
													</TableBody>
												</Table>
											</TableContainer>
										</CardContent>
									</CardActionArea>
								</Card>
							))}
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			<div>
				<AppBarColour curr={navBarColour} />
			</div>
		</Root>
	);
}

export default withRouter(Dashboard);

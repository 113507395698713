import React, { Component } from 'react';
import PredictionTopUsers from '../component/PredictionTopUsers';

export class predictiontopusers extends Component {
	render() {
		return (
			<div>
                <h1 align='center'>
                    Top 10 Gamers for Match Predictions
                </h1>
                
				<PredictionTopUsers />
			</div>
		);
	}
}

export default predictiontopusers;
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import { useTheme } from '@mui/styles';
import { Link } from 'react-router-dom';

import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import HomeIcon from '@mui/icons-material/Home';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import InfoIcon from '@mui/icons-material/Info';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import QueueMusic from '@mui/icons-material/QueueMusic';
import IconExpandLess from '@mui/icons-material/ExpandLess';
import IconExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';

//usersession
import { useSession, logoutFromApp } from '../App';
import { ColourChange } from './Dashboard';
import { createTheme } from '@mui/material';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';

const PREFIX = 'Navbar';

const classes = {
    root: `${PREFIX}-root`,
    appBar: `${PREFIX}-appBar`,
    appBarShift: `${PREFIX}-appBarShift`,
    menuButton: `${PREFIX}-menuButton`,
    hide: `${PREFIX}-hide`,
    drawer: `${PREFIX}-drawer`,
    drawerPaper: `${PREFIX}-drawerPaper`,
    drawerHeader: `${PREFIX}-drawerHeader`,
    content: `${PREFIX}-content`,
    contentShift: `${PREFIX}-contentShift`
};

const StyledStyledEngineProvider = styled(StyledEngineProvider)((
    {
        theme
    }
) => ({
    [`& .${classes.root}`]: {
		display: 'flex'
	},

    [`& .${classes.appBar}`]: {
		transition: theme.transitions.create([ 'margin', 'width' ], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		})
	},

    [`& .${classes.appBarShift}`]: {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: drawerWidth,
		transition: theme.transitions.create([ 'margin', 'width' ], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen
		})
	},

    [`& .${classes.menuButton}`]: {
		marginRight: theme.spacing(2)
	},

    [`& .${classes.hide}`]: {
		display: 'none'
	},

    [`& .${classes.drawer}`]: {
		width: drawerWidth,
		flexShrink: 0
	},

    [`& .${classes.drawerPaper}`]: {
		width: drawerWidth
	},

    [`& .${classes.drawerHeader}`]: {
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing(0, 1),
		...theme.mixins.toolbar,
		justifyContent: 'flex-end'
	},

    [`& .${classes.content}`]: {
		flexGrow: 1,
		padding: theme.spacing(3),
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		marginLeft: -drawerWidth
	},

    [`& .${classes.contentShift}`]: {
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen
		}),
		marginLeft: 0
	}
}));

const drawerWidth = 240;

export default function Navbar() {

	const theme = createTheme();
	const [ open, setOpen ] = React.useState(false);
	const [ openFLMenu, setOpenFLMenu ] = React.useState(false);
	const NavAppBarColour = ColourChange;

	const user = useSession();
	const [ authorized, setAuthorized ] = useState(false);

	const userContext = useSession('usercontext');

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	const handleLogout = () => {
		logoutFromApp();
	};

	function handleFLMenuClick() {
		setOpen(!openFLMenu);
	}

	useEffect(() => {
		console.log('Checking user: ' + user.uid);
		if (user.uid === 'glKicD84lLejtJ5M9qOLaczt5ny1' || user.uid === 'YP1dpgESghPw7WiAoIL1Sd0HARw1' || user.uid === 'MmkWUYFwTwT7DKWfnsgXoKoYgxo2') {
			console.log('Authorized User');
			setAuthorized(true);
		} else {
			console.log('Unauthorized User');
			setAuthorized(false);
		}
	}, []);

	return (
        <StyledStyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
            <div className={classes.root}>
                <CssBaseline />
                <AppBar
                    position='fixed' style={{ background: NavAppBarColour}}
                    className={clsx(classes.appBar, {
                        [classes.appBarShift]: open
                    })}
                >
                    <Toolbar>
                        <IconButton
                            color='inherit'
                            aria-label='open drawer'
                            onClick={handleDrawerOpen}
                            edge='start'
                            className={clsx(classes.menuButton, open && classes.hide)}
                            size="large">
                            <MenuIcon />
                        </IconButton>
                        <Typography gutterBottom variant='h6' noWrap>
                            Voiddeck Football
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Drawer
                    className={classes.drawer}
                    variant='persistent'
                    anchor='left'
                    open={open}
                    classes={{
                        paper: classes.drawerPaper
                    }}
                >
                    <div className={classes.drawerHeader}>
                        <IconButton onClick={handleDrawerClose} size="large">
                            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </IconButton>
                    </div>
                    <Divider />
                    { authorized ? (
                    <List>
                        <Typography variant='h6' align='center'>
                            Admin Directory
                        </Typography>
                        <Link
                            to='/admin'
                            style={{ textDecoration: 'none' }}
                            key='Admin'
                            onClick={(e) => setOpen(false)}
                        >
                            <ListItem button key='Admin'>
                                <ListItemIcon>
                                    <HomeIcon />
                                </ListItemIcon>
                                <ListItemText>Admin Dashboard</ListItemText>
                            </ListItem>
                        </Link>
                        <Link
                            to='/predictiontopusers'
                            style={{ textDecoration: 'none' }}
                            key='PredictionTopUsers'
                            onClick={(e) => setOpen(false)}
                        >
                            <ListItem button className={classes.menuItem}>
                                <ListItemText inset primary='Top Users' />
                            </ListItem>
                        </Link>
                        <Link
                            to='/admin/users'
                            style={{ textDecoration: 'none' }}
                            key='UserManagement'
                            onClick={(e) => setOpen(false)}
                        >
                            <ListItem button className={classes.menuItem}>
                                <ListItemText inset primary='User Management' />
                            </ListItem>
                        </Link>
                        <Link
                            to='/admin/fantasyleague'
                            style={{ textDecoration: 'none' }}
                            key='FantasyLeagueManagement'
                            onClick={(e) => setOpen(false)}
                        >
                            <ListItem button className={classes.menuItem}>
                                <ListItemText inset primary='Fantasy League Management' />
                            </ListItem>
                        </Link>
                        <Link
                            to='/admin/adminfantasyleaguestats'
                            style={{ textDecoration: 'none' }}
                            key='AdminFantasyLeagueStats'
                            onClick={(e) => setOpen(false)}
                        >
                            <ListItem button className={classes.menuItem}>
                                <ListItemText inset primary='Fantasy League Stats' />
                            </ListItem>
                        </Link>
                        <Link
                            to='/admin/fantasyleaguediagnostic'
                            style={{ textDecoration: 'none' }}
                            key='FantasyLeagueDiagnosticManagement'
                            onClick={(e) => setOpen(false)}
                        >
                            <ListItem button className={classes.menuItem}>
                                <ListItemText inset primary='Fantasy League Diagnostic' />
                            </ListItem>
                        </Link>
                        <Link
                            to='/admin/creatematchschedule'
                            style={{ textDecoration: 'none' }}
                            key='CreateMatchSchedule'
                            onClick={(e) => setOpen(false)}
                        >
                            <ListItem button className={classes.menuItem}>
                                <ListItemText inset primary='Create Match Schedule' />
                            </ListItem>
                        </Link>
                        <Link
                            to='/admin/createplayers'
                            style={{ textDecoration: 'none' }}
                            key='CreatePlayers'
                            onClick={(e) => setOpen(false)}
                        >
                            <ListItem button className={classes.menuItem}>
                                <ListItemText inset primary='Create Players' />
                            </ListItem>
                        </Link>
                        <Link
                            to='/admin/matchadmin'
                            style={{ textDecoration: 'none' }}
                            key='MatchAdmin'
                            onClick={(e) => setOpen(false)}
                        >
                            <ListItem button className={classes.menuItem}>
                                <ListItemText inset primary='Match Admin' />
                            </ListItem>
                        </Link>
                        <Divider />
                    </List>
                    ) : (
                        <Divider />
                    )}
                    <List>
                        <Link
                            to='/dashboard'
                            style={{ textDecoration: 'none' }}
                            key='Dashboard'
                            onClick={(e) => setOpen(false)}
                        >
                            <ListItem button key='Dashboard'>
                                <ListItemIcon>
                                    <HomeIcon />
                                </ListItemIcon>
                                <ListItemText>Dashboard</ListItemText>
                            </ListItem>
                        </Link>
                        <Link
                            to='/fantasyoverview'
                            style={{ textDecoration: 'none' }}
                            key='FantasyLeague'
                            onClick={(e) => setOpen(false)}
                        >
                            <ListItem button key='FantasyLeague' onClick={handleFLMenuClick}>
                                <ListItemIcon>
                                    <EmojiEventsIcon />
                                </ListItemIcon>
                                <ListItemText>Fantasy League</ListItemText>
                            </ListItem>
                        </Link>
                        <Link
                            to='/fantasyleague/overview'
                            style={{ textDecoration: 'none' }}
                            key='fantasyleagueoverview'
                            onClick={(e) => setOpen(false)}
                        >
                            <List component='div' disablePadding>
                                <ListItem button className={classes.menuItem} to='/fantasyleague/overview'>
                                    <ListItemText inset primary='Overview' />
                                </ListItem>
                            </List>
                        </Link>
                        <Link
                            to='/fantasyleague/totw'
                            style={{ textDecoration: 'none' }}
                            key='fantasyleaguetotw'
                            onClick={(e) => setOpen(false)}
                        >
                            <List>
                                <ListItem button className={classes.menuItem}>
                                    <ListItemText inset primary='ToTW and Stats' />
                                </ListItem>
                            </List>
                        </Link>
                        <Link
                            to='/fantasyleague/flml'
                            style={{ textDecoration: 'none' }}
                            key='fantasyleagueminileague'
                            onClick={(e) => setOpen(false)}
                        >
                            <List>
                                <ListItem button className={classes.menuItem}>
                                    <ListItemText inset primary='My Mini-Leagues' />
                                </ListItem>
                            </List>
                        </Link>
                        <Link
                            to='/fantasyleague/team'
                            style={{ textDecoration: 'none' }}
                            key='fantasyleagueteam'
                            onClick={(e) => setOpen(false)}
                        >
                            <List>
                                <ListItem button className={classes.menuItem}>
                                    <ListItemText inset primary='Team' />
                                </ListItem>
                            </List>
                        </Link>
                        <Link
                            to='/fantasyleague/transfer'
                            style={{ textDecoration: 'none' }}
                            key='fantasyleaguetransfer'
                            onClick={(e) => setOpen(false)}
                        >
                            <List>
                                <ListItem button className={classes.menuItem} to='/fantasyleague/transfer'>
                                    <ListItemText inset primary='Transfer' />
                                </ListItem>
                            </List>
                        </Link>
                        <Link
                            to='/mypredictions'
                            style={{ textDecoration: 'none' }}
                            key='MyPredictions'
                            onClick={(e) => setOpen(false)}
                        >
                            <ListItem button key='MyPredictions'>
                                <ListItemIcon>
                                    <SportsSoccerIcon />
                                </ListItemIcon>
                                <ListItemText>My Predictions</ListItemText>
                            </ListItem>
                        </Link>
                        <Link to='/rating' style={{ textDecoration: 'none' }} key='Rating' onClick={(e) => setOpen(false)}>
                            <ListItem button key='Rating'>
                                <ListItemIcon>
                                    <TrendingUpIcon />
                                </ListItemIcon>
                                <ListItemText>Players Rating</ListItemText>
                            </ListItem>
                        </Link>
                        <Link to='/chants' style={{ textDecoration: 'none' }} key='Chants' onClick={(e) => setOpen(false)}>
                            <ListItem button key='Chants'>
                                <ListItemIcon>
                                    <QueueMusic />
                                </ListItemIcon>
                                <ListItemText>Team Chants</ListItemText>
                            </ListItem>
                        </Link>
                        <Link to='/about' style={{ textDecoration: 'none' }} key='About' onClick={(e) => setOpen(false)}>
                            <ListItem button key='About'>
                                <ListItemIcon>
                                    <InfoIcon />
                                </ListItemIcon>
                                <ListItemText>About</ListItemText>
                            </ListItem>
                        </Link>
                    </List>
                    <Divider />
                    <Link to='/' style={{ textDecoration: 'none' }} key='Logout' onClick={handleLogout}>
                        <ListItem button key='Logout'>
                            <ListItemIcon>
                                <ExitToAppIcon />
                            </ListItemIcon>
                            <ListItemText>Logout</ListItemText>
                        </ListItem>
                    </Link>
                    <List />
                </Drawer>
            </div>
            </ThemeProvider>
        </StyledStyledEngineProvider>
    );
}

import React, { Component } from 'react';
import AdminFantasyLeagueDiagnosticsDashboard from '../../component/AdminFantasyLeagueDiagnosticsDashboard';

export class adminfantasyleaguediagnosticsdashboard extends Component {
	render() {
		return (
			<div>
				<AdminFantasyLeagueDiagnosticsDashboard />
			</div>
		);
	}
}

export default adminfantasyleaguediagnosticsdashboard;
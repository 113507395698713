import React, { useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';
import { useSession } from '../App';
import { Typography, Card, TextField, CardContent, CardActionArea, CardHeader } from '@mui/material';
import Grid from '@mui/material/Grid';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import SaveIcon from '@mui/icons-material/Save';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';

//Firebase
import { db } from '../firebase';
import { getMatchesByWeek, getMatchHomePlayers } from '../firestore/matchhandler';
import { updatePlayersStats, updateFantasyTeamsSquadAndTeamPlayers } from '../firestore/playershandler';

//Table
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import firebase from '../firebase';

const PREFIX = 'AdminFantasyLeagueDashboard';

const classes = {
	root                 : `${PREFIX}-root`,
	paper                : `${PREFIX}-paper`,
	button               : `${PREFIX}-button`,
	formControl          : `${PREFIX}-formControl`,
	grouplabel           : `${PREFIX}-grouplabel`,
	large                : `${PREFIX}-large`,
	selectEmpty          : `${PREFIX}-selectEmpty`,
	formControlSelection : `${PREFIX}-formControlSelection`,
	table                : `${PREFIX}-table`
};

const StyledGrid = styled(Grid)(({ theme }) => ({
	[`&.${classes.root}`]: {
		flexgrow : 1,
		margin   : 'auto'
	},

	[`& .${classes.paper}`]: {
		width    : 130,
		height   : 230,
		overflow : 'auto'
	},

	[`& .${classes.button}`]: {
		margin : theme.spacing(1)
	},

	[`& .${classes.formControl}`]: {
		margin   : theme.spacing(3),
		minWidth : 150,
		maxWidth : 250
	},

	[`& .${classes.grouplabel}`]: {
		fontsize : 10
	},

	[`& .${classes.large}`]: {
		width  : theme.spacing(7),
		height : theme.spacing(7)
	},

	[`& .${classes.selectEmpty}`]: {
		marginTop : theme.spacing(2)
	},

	[`& .${classes.formControlSelection}`]: {
		margin   : theme.spacing(1),
		minWidth : 150
	},

	[`& .${classes.table}`]: {
		minWidth : 650
	}
}));

//const { GoogleSpreadsheet } = require('google-spreadsheet');

const matchWeek = [
	'1',
	'2',
	'3',
	'4',
	'5',
	'6',
	'7',
	'7.1',
	'7.2',
	'8',
	'9',
	'10',
	'11',
	'12',
	'12.1',
	'13',
	'13.1',
	'14',
	'15',
	'15.1',
	'16',
	'17',
	'18',
	'18.1',
	'19',
	'19.1',
	'20',
	'21',
	'21.1',
	'22',
	'23',
	'24',
	'25',
	'26',
	'27',
	'28'
];

export default function AdminFantasyLeagueDashboard() {
	const user = useSession();
	const [ authorized, setAuthorized ] = useState(false);

	const [ matches, setMatches ] = React.useState([]);

	//Array to keep all the team players
	const [ gifcPlayers, setGifcPlayers ] = useState([]);
	const [ trfcPlayers, setTrfcPlayers ] = useState([]);
	const [ lcsfPlayers, setLcsfPlayers ] = useState([]);
	const [ bkfcPlayers, setBkfcPlayers ] = useState([]);
	const [ anfcPlayers, setAnfcPlayers ] = useState([]);
	const [ tpfcPlayers, setTpfcPlayers ] = useState([]);
	const [ ylfcPlayers, setYlfcPlayers ] = useState([]);
	const [ hufcPlayers, setHufcPlayers ] = useState([]);

	const [ flPlayers, setFlPlayers ] = useState([]);
	const [ players, setPlayers ] = useState([]);

	//New
	const [ week, setWeek ] = useState('21');
	const [ weekCalculation, setWeekCalculation ] = useState('');

	const handleWeekChange = (event) => {
		setWeek(event.target.value);
	};

	const handleWeekCalculationChange = (event) => {
		var localWeek =
			event.target.value.indexOf('.') === -1 ? parseInt(event.target.value) : parseFloat(event.target.value);
		console.log(localWeek);
		setWeekCalculation(localWeek);

		db
			.collection('2022Matches')
			.where('match_week', '==', localWeek)
			.get()
			.then((querySnapshot) => {
				setMatches(querySnapshot.docs.map((match) => match.data()));

				querySnapshot.forEach((doc) => {
					// doc.data() is never undefined for query doc snapshots
					console.log(doc.id, ' => ', doc.data());
					//matches.push(doc.data());
					setMatches(...matches, doc.data());
					setMatches(querySnapshot.docs.map((match) => match.data()));
				});
			})
			.catch((error) => console.log(error));

		const data2 = db
			.collection('FantasyLeague/2022/fl_players_week_' + localWeek)
			.get()
			.then(function(querySnapshot) {
				setFlPlayers(querySnapshot.docs.map((player) => player.data()));
			});
	};

	/**
	 * Set up the upcoming week. Will make a copy of the team/players as squad_week_XX and team_week_XX
	 * Set the transfers back  2
	 * @param {} event 
	 */
	function handleStartSetup(event) {
		console.log('Start setting up Fantasy League for Week ' + week);

		//Extract All the Games
		const db = firebase.firestore();

		db
			.collection('/FantasyLeague/2022/gamers')
			.get()
			.then(
				//look at the predicted score vs real score
				function(querySnapshot) {
					querySnapshot.forEach(function(doc) {
						var gamer = doc.data();

						var captain = gamer.captain;
						var viceCaptain = gamer.vice_captain;
						console.log(gamer.team_name);

						var batch = db.batch();
						gamer.no_of_transfer = 2;

						// Update 2 new transfers for the gamer
						var ref = db
							.collection('/FantasyLeague/2022/gamers/')
							.doc(doc.id)
							.set(gamer, { merge: true })
							.then(() => {
								console.log('Updated the transfers successfully to 2 new transfers');
							})
							.catch((error) => console.log(error));

						// Duplicate the squad for the week
						var squadBatch = db.batch();
						db
							.collection('/FantasyLeague/2022/gamers')
							.doc(doc.id)
							.collection('players')
							.get()
							.then(function(querySnapshot) {
								querySnapshot.forEach(function(doc1) {
									//console.log(doc1.data());

									//duplicate the squad to current week + 1
									var ref = db
										.collection('/FantasyLeague/2022/gamers/' + doc.id + '/squad_week_' + week)
										.doc(doc1.id);
									squadBatch.set(ref, doc1.data());
								});

								squadBatch
									.commit()
									.then(function() {
										console.log('Batch writing squad squad_week_' + week);
									})
									.catch((error) => {
										console.error('Error writing totw: ', error);
									});
							});

						//Duplicate the totw for the week
						var totwBatch = db.batch();
						db
							.collection('/FantasyLeague/2022/gamers')
							.doc(doc.id)
							.collection('team')
							.get()
							.then(function(querySnapshot) {
								querySnapshot.forEach(function(doc1) {
									//console.log(doc1.data());
									var player = doc1.data();
									if (player.player_id === captain) {
										console.log('Setting player as captain: ' + player.player_id);
										player = { ...player, captain: true };
									} else if (player.player_id === viceCaptain) {
										console.log('Setting player as vice captain: ' + player.player_id);
										player = { ...player, vice_captain: true };
									}
									var ref = db
										.collection('/FantasyLeague/2022/gamers/' + doc.id + '/team_week_' + week)
										.doc(doc1.id);

									totwBatch.set(ref, player);
								});

								totwBatch
									.commit()
									.then(function() {
										console.log('Batch writing totw team_week_' + week);
									})
									.catch((error) => {
										console.error('Error writing totw: ', error);
									});
							});
					});
				}
			)
			.catch((err) => console.log(err));
	}

	//Save all the players with FL points to firestore
	function handleSaveCalculations(event) {
		//console.log('Start to Save Calculation for week ' + weekCalculation);

		var batch = db.batch();

		anfcPlayers.forEach((player) => {
			//console.log('adding player ' + JSON.stringify(player));
			if (player.player_id.length !== 0 && player.match_playing_status !== 'reserve') {
				var flRef = db
					.collection('FantasyLeague/2022/fl_players_week_' + weekCalculation)
					.doc(player.player_id);
				batch.set(flRef, player);
			} else {
				console.log('Did not do 3 substitutions');
			}
		});

		bkfcPlayers.forEach((player) => {
			//console.log('adding player ' + player.fullname + ' -> ' + player.player_id);

			if (player.player_id.length !== 0) {
				var flRef = db
					.collection('FantasyLeague/2022/fl_players_week_' + weekCalculation)
					.doc(player.player_id);
				batch.set(flRef, player);
			} else {
				console.log('Did not do 3 substitutions');
			}
		});

		gifcPlayers.forEach((player) => {
			//console.log('adding player ' + player.fullname);
			if (player.player_id.length !== 0) {
				var flRef = db
					.collection('FantasyLeague/2022/fl_players_week_' + weekCalculation)
					.doc(player.player_id);
				batch.set(flRef, player);
			} else {
				console.log('Did not do 3 substitutions');
			}
		});

		hufcPlayers.forEach((player) => {
			if (player.player_id.length !== 0) {
				var flRef = db
					.collection('FantasyLeague/2022/fl_players_week_' + weekCalculation)
					.doc(player.player_id);
				batch.set(flRef, player);
			} else {
				console.log('Did not do 3 substitutions');
			}
		});

		lcsfPlayers.forEach((player) => {
			//console.log('adding player ' + player.fullname);
			if (player.player_id.length !== 0) {
				var flRef = db
					.collection('FantasyLeague/2022/fl_players_week_' + weekCalculation)
					.doc(player.player_id);
				batch.set(flRef, player);
			} else {
				console.log('Did not do 3 substitutions');
			}
		});

		tpfcPlayers.forEach((player) => {
			//console.log('adding player ' + player.fullname);
			if (player.player_id.length !== 0 && player.match_playing_status !== 'reserve') {
				var flRef = db
					.collection('FantasyLeague/2022/fl_players_week_' + weekCalculation)
					.doc(player.player_id);
				batch.set(flRef, player);
			} else {
				console.log('Did not do 3 substitutions');
			}
		});

		trfcPlayers.forEach((player) => {
			//console.log('adding player ' + player.fullname);
			if (player.player_id.length !== 0) {
				var flRef = db
					.collection('FantasyLeague/2022/fl_players_week_' + weekCalculation)
					.doc(player.player_id);
				batch.set(flRef, player);
			} else {
				console.log('Did not do 3 substitutions');
			}
		});

		ylfcPlayers.forEach((player) => {
			//console.log('adding player ' + player.fullname);
			if (player.player_id.length !== 0) {
				var flRef = db
					.collection('FantasyLeague/2022/fl_players_week_' + weekCalculation)
					.doc(player.player_id);
				batch.set(flRef, player);
			} else {
				console.log('Did not do 3 substitutions');
			}
		});

		batch
			.commit()
			.then(function() {
				console.log('Batch writing squad players completed to fl_players_week_' + weekCalculation);
			})
			.catch((error) => {
				console.error('Error writing document: ', error);
			});
	}

	useEffect(() => {
		console.log('Checking user: ' + user.uid);
		if (
			user.uid === 'glKicD84lLejtJ5M9qOLaczt5ny1' ||
			user.uid === 'YP1dpgESghPw7WiAoIL1Sd0HARw1' ||
			user.uid === 'MmkWUYFwTwT7DKWfnsgXoKoYgxo2'
		) {
			console.log('Authorized User');
			setAuthorized(true);
		} else {
			console.log('Unauthorized User');
			setAuthorized(false);
		}

		//Get all the 2022 Players
		var playersRef = db.collectionGroup('2022Players');
		playersRef.get().then((querySnapshot) => {
			setPlayers(querySnapshot.docs.map((player) => player.data()));
		});
	}, []);

	/**
	 * Accessing the google sheets based on the matches for the selected week
	 * 
	 * @param {} event 
	 */
	function handleAccessMatchSheets(event) {
		console.log('Accessing match sheets for Week: ' + weekCalculation);

		//const creds = require('../google-keys/voiddeckfootie-8d10a6208b54.json'); // the file saved above
		//const doc = new GoogleSpreadsheet('1zcKTkgYKgJz0k1etXxZ3s9fswswFL5r9-Mt7r27Tw7M');
		//const auth = await doc.useServiceAccountAuth(creds);
		//const loadInfo = await doc.loadInfo();

		matches.forEach((match) => {
			var homeTeam = match.hometeam_id;
			var awayTeam = match.awayteam_id;

			var homeTeamGoals = match.hometeam_score;
			var awayTeamGoals = match.awayteam_score;
			var homeTeamShotsAttempt = match.hometeam_shots;
			var awayTeamShotsAttempt = match.awayteam_shots;
			/*
			console.log(
				'Results Home Team: ' +
					homeTeam +
					': ' +
					homeTeamGoals +
					' Away Team: ' +
					awayTeam +
					': ' +
					awayTeamGoals
			);
			*/

			var homeTeamShotSaves = awayTeamShotsAttempt - awayTeamGoals; //assuming that all the attempt on target but not resulted in goad is saved by keeper
			var awayTeamShotSaves = homeTeamShotsAttempt - homeTeamGoals;

			var homeTeamShotSavesPoints = Math.floor(homeTeamShotSaves / 3);
			var awayTeamShotSavesPoints = Math.floor(awayTeamShotSaves / 3);

			//console.log('Home team GK Points: ' + homeTeamShotSavesPoints + ' Away team GK points: ' + awayTeamShotSavesPoints);

			var homeTeamArray = [];
			var awayTeamArray = [];

			//console.log(match.match_id)

			db
				.collection('2022Matches/' + match.match_id + '/HOME_TEAM_PLAYERS')
				.get()
				.then((querySnapshot) => {
					querySnapshot.forEach((doc) => {
						//console.log(doc.id, ' => ', doc.data());
						var player = doc.data();
						let data = {
							player_id           : player.player_id,
							position            : player.position,
							teamId              : player.team_id,
							fullname            : player.fullname,
							firsteleven         : player.match_playing_status,
							minutes             : player.match_end_time - player.match_start_time,
							goals               : player.match_goals,
							assists             : player.match_assists,
							yc                  : player.match_yellow_cards,
							rc                  : player.match_red_cards,
							og                  : player.match_own_goals,
							shotssaves          : player.position === 'Goalkeeper' ? homeTeamShotSaves : 0,
							penaltySave         : player.match_penalty_saves,
							penaltyMiss         : player.match_penalty_misses,
							goalsconcede        : match.awayteam_score,
							value               : player.value,
							fl_playingtime_pts  : 0,
							fl_goalsscored_pts  : 0,
							fl_assists_pts      : 0,
							fl_cleansheet_pts   : 0,
							fl_shotsaves_pts    : player.position === 'Goalkeeper' ? homeTeamShotSavesPoints : 0,
							fl_penaltysaves_pts : 0,
							fl_penaltymiss_pts  : 0,
							fl_motm_pts         : 0,
							fl_goalsconcede_pts : 0,
							fl_yellowcard_pts   : 0,
							fl_redcard_pts      : 0,
							fl_owgoal_pts       : 0,
							fl_points           : 0
						};
						//only calculating those in first_eleven and subtitute players
						if (player.match_playing_status !== 'reserve') homeTeamArray.push(calculatepoints(data));
					});
					
					if (homeTeam === 'GIFC') setGifcPlayers(homeTeamArray);
					else if (awayTeam === 'GIFC') setGifcPlayers(awayTeamArray);

					if (homeTeam === 'ANFC') setAnfcPlayers(homeTeamArray);
					else if (awayTeam === 'ANFC') setAnfcPlayers(awayTeamArray);

					if (homeTeam === 'BKFC') setBkfcPlayers(homeTeamArray);
					else if (awayTeam === 'BKFC') setBkfcPlayers(awayTeamArray);

					if (homeTeam === 'HUFC') setHufcPlayers(homeTeamArray);
					else if (awayTeam === 'HUFC') setHufcPlayers(awayTeamArray);

					if (homeTeam === 'LCSF') setLcsfPlayers(homeTeamArray);
					else if (awayTeam === 'LCSF') setLcsfPlayers(awayTeamArray);

					if (homeTeam === 'TPFC') setTpfcPlayers(homeTeamArray);
					else if (awayTeam === 'TPFC') setTpfcPlayers(awayTeamArray);

					if (homeTeam === 'TRFC') setTrfcPlayers(homeTeamArray);
					else if (awayTeam === 'TRFC') setTrfcPlayers(awayTeamArray);

					if (homeTeam === 'YLFC') setYlfcPlayers(homeTeamArray);
					else if (awayTeam === 'YLFC') setYlfcPlayers(awayTeamArray);
				})
				.catch((error) => console.log(error));

			db
				.collection('2022Matches/' + match.match_id + '/AWAY_TEAM_PLAYERS')
				.get()
				.then((querySnapshot) => {
					querySnapshot.forEach((doc) => {
						//console.log(doc.id, ' => ', doc.data());
						var player = doc.data();
						let data = {
							player_id           : player.player_id,
							position            : player.position,
							teamId              : player.team_id,
							fullname            : player.fullname,
							firsteleven         : player.match_playing_status,
							minutes             : player.match_end_time - player.match_start_time,
							goals               : player.match_goals,
							assists             : player.match_assists,
							yc                  : player.match_yellow_cards,
							rc                  : player.match_red_cards,
							og                  : player.match_own_goals,
							shotssaves          : player.position === 'Goalkeeper' ? awayTeamShotSaves : 0,
							penaltySave         : player.match_penalty_saves,
							penaltyMiss         : player.match_penalty_misses,
							goalsconcede        : match.hometeam_score,
							value               : player.value,
							fl_playingtime_pts  : 0,
							fl_goalsscored_pts  : 0,
							fl_assists_pts      : 0,
							fl_cleansheet_pts   : 0,
							fl_shotsaves_pts    : player.position === 'Goalkeeper' ? awayTeamShotSavesPoints : 0,
							fl_penaltysaves_pts : 0,
							fl_penaltymiss_pts  : 0,
							fl_motm_pts         : 0,
							fl_goalsconcede_pts : 0,
							fl_yellowcard_pts   : 0,
							fl_redcard_pts      : 0,
							fl_owgoal_pts       : 0,
							fl_points           : 0
						};
						if (player.match_playing_status !== 'reserve') awayTeamArray.push(calculatepoints(data));
					});

					if (homeTeam === 'GIFC') setGifcPlayers(homeTeamArray);
					else if (awayTeam === 'GIFC') setGifcPlayers(awayTeamArray);

					if (homeTeam === 'ANFC') setAnfcPlayers(homeTeamArray);
					else if (awayTeam === 'ANFC') setAnfcPlayers(awayTeamArray);

					if (homeTeam === 'BKFC') setBkfcPlayers(homeTeamArray);
					else if (awayTeam === 'BKFC') setBkfcPlayers(awayTeamArray);

					if (homeTeam === 'HUFC') setHufcPlayers(homeTeamArray);
					else if (awayTeam === 'HUFC') {
						setHufcPlayers(awayTeamArray);
					}

					if (homeTeam === 'LCSF') setLcsfPlayers(homeTeamArray);
					else if (awayTeam === 'LCSF') setLcsfPlayers(awayTeamArray);

					if (homeTeam === 'TPFC') setTpfcPlayers(homeTeamArray);
					else if (awayTeam === 'TPFC') setTpfcPlayers(awayTeamArray);

					if (homeTeam === 'TRFC') setTrfcPlayers(homeTeamArray);
					else if (awayTeam === 'TRFC') setTrfcPlayers(awayTeamArray);

					if (homeTeam === 'YLFC') setYlfcPlayers(homeTeamArray);
					else if (awayTeam === 'YLFC') setYlfcPlayers(awayTeamArray);
				})
				.catch((error) => console.log(error));
		});
	}

	function calculatepoints(player) {
		if (player.match_playing_status !== 'reserve') {
			console.log('Start calculating for: ' + player.fullname + ' id: ' + player.player_id + ' Club: ' + player.teamId); 

			var realPlayer = players.find((tempPlayer) => tempPlayer.player_id.trim() === player.player_id.trim());
			if (realPlayer) {
				player.position = realPlayer.position;
				//console.log(realPlayer.position);
				//console.log('Position updated to: ' + player.position);
			} else {
				//console.log(' -----> Player not found ' + player.firstname);
			}

			//console.log(player);

			var pts = 0;

			if (player.minutes <= 45) {
				pts = pts + 1;
				player.fl_playingtime_pts = 1;
				//console.log('Adding 1 pt for playing 90 mins. Pts: ' + pts);
			} else if (player.minutes > 45) {
				pts = pts + 2;
				player.fl_playingtime_pts = 2;
				//console.log('Adding 2 pts for playing 90 mins. Pts: ' + pts);
			}

			if (player.yc === 1) {
				pts = pts - 1;
				player.fl_yellowcard_pts = -1;
				//console.log('Deduct 1 pts for yellow card. Pts: ' + pts);
			} else if (player.rc === 1) {
				pts = pts - 3;
				player.fl_redcard_pts = -3;
				//console.log('Deduct 3 pts for red card. Pts: ' + pts);
			}

			if (player.og > 0) {
				pts = pts - 2 * player.og;
				player.fl_owgoal_pts = -2 * player.og;
				//console.log('Deduct ' + 2 * player.og + ' 1 pts for yellow card. Pts: ' + pts);
			}

			if (player.goals > 0 && (player.position === 'Goalkeeper' || player.position === 'Defender')) {
				pts = pts + 6 * player.goals;
				player.fl_goalsscored_pts = 6 * player.goals;
				//console.log('Add ' + 6 * player.goals + ' for defender scoring ' + player.goals + '. Pts: ' + pts);
			}

			if (player.goals > 0 && player.position === 'Midfielder') {
				pts = pts + 5 * player.goals;
				player.fl_goalsscored_pts = 5 * player.goals;
				//console.log('Add ' + 5 * player.goals + ' for midfielder scoring ' + player.goals + '. Pts: ' + pts);
			}

			if (player.goals > 0 && player.position === 'Forward') {
				pts = pts + 4 * player.goals;
				player.fl_goalsscored_pts = 4 * player.goals;
				//console.log('Add ' + 4 * player.goals + ' for forward scoring ' + player.goals + '. Pts: ' + pts);
			}

			if (player.assists > 0) {
				pts = pts + 3 * player.assists;
				player.fl_assists_pts = 3 * player.assists;
				//console.log('Add ' + 3 * player.assists + ' for assists ' + player.assists + '. Pts: ' + pts);
			}

			if (player.position === 'Defender' && parseInt(player.goalsconcede) === 0) {
				pts = pts + 4;
				player.fl_cleansheet_pts = 4;
				//console.log('Add 4 pts for no goals conceded by goalkeeper/defender. Pts: ' + pts);
			}

			if (player.position === 'Goalkeeper' && parseInt(player.goalsconcede) === 0 && player.minutes >= 60) {
				pts = pts + 4;
				player.fl_cleansheet_pts = 4;
				console.log('Add 4 pts for no goals conceded by goalkeeper/defender. Pts: ' + pts);
			}

			if (player.position === 'Midfielder' && parseInt(player.goalsconcede) === 0) {
				pts = pts + 1;
				player.fl_cleansheet_pts = 1;
				//console.log('Add 1pts for no goals conceded by midfielder. Pts: ' + pts);
			}

			if (player.penaltyMiss > 0) {
				pts = pts - 2 * player.penaltyMiss;
				player.fl_penaltymiss_pts = -2 * player.penaltyMiss;
				//console.log('Dedect ' + 2 * player.miss + ' for penalty miss ' + player.penaltyMiss + '. Pts: ' + pts);
			}

			if (player.penaltySave > 0) {
				pts = pts + 5 * player.penaltySave;
				player.fl_penaltysaves_pts = 5 * player.penaltySave;
				//console.log('Add ' + 5 * player.penaltySave + ' for penalty save ' + player.penaltySave + '. Pts: ' + pts);
			}

			if (player.goalsconcede >= 0 && (player.position === 'Goalkeeper' || player.position === 'Defender')) {
				var concedePts = Math.floor(player.goalsconcede / 2);

				pts = pts - concedePts;
				player.fl_goalsconcede_pts = concedePts * -1;
				/*
				console.log(
					'Deduct ' +
						concedePts +
						' points for goals conceded for ' +
						player.position +
						' for ' +
						player.goalsconcede +
						' goals.'
				);
				*/
			}

			player.fl_points = pts + player.fl_shotsaves_pts;
			//console.log('Total Points: ' + pts);
			return player;
		} else return player;
	}

	const totwPointsCalculations = (leaderPlayer, flPlayer) => {
		//console.log('leader: ' + leaderPlayer.player_id);
		//console.log('flplayer: ' + flPlayer.player_id);
		if (flPlayer.player_id === leaderPlayer.player_id) {
			console.log('Double points for captain/vicecaptain ' + flPlayer.player_id);
			return flPlayer.fl_points * 2;
		} else {
			console.log('Regular points for : ' + flPlayer.player_id + '');
			return flPlayer.fl_points;
		}
	};

	/**
	 * This function if to prepare the data to be persisted with the updated statistics to the ToWT
	 * @param {Player from the ToTW} totwPlayer 
	 * @param {Fantasy League Player with all FL statistics} flPlayer 
	 * @param {FL Points - processed to give captains/vice cap multiplies} points 
	 */
	const totwFLUpdatesCommitsPreparation = (position, totwPlayer, flPlayer, points, batch, gamerId) => {
		//console.log('Creating batch commits');

		console.log('----------> ' + gamerId);
		if (typeof gamerId === 'object' && gamerId !== null) {
			console.log(' ------ Object FOUND !!!');
			console.dir(gamerId);
		}

		var fldata = { ...flPlayer, fl_appearance: true };
		delete fldata.firsteleven;
		delete fldata.fullname;
		delete fldata.player_id;
		delete fldata.position;
		delete fldata.teamId;
		delete fldata.position;
		fldata.fl_points = points === 0 ? 0 : points; //processed points hence need to overwrite TODO: could do this better instead of calling the method seperately

		var data = { ...totwPlayer, ...fldata };
		//console.log(data);
		console.log('/FantasyLeague/2022/gamers/' + gamerId + '/team_week_' + weekCalculation);

		var ref = db
			.collection('/FantasyLeague/2022/gamers/' + gamerId + '/team_week_' + weekCalculation)
			.doc(position);

		return batch.update(ref, data);
	};

	/**
	 * This const will calculate the point based on the gamer's totw selection
	 * 
	 * @param {A list of players from a gamer team of the week} towtPlayerList 
	 * @returns 
	 */
	const gamerTotwCalculation = (towtPlayersMap, gamerId, totalFlPoints, gamer) => {
		//console.log(flPlayers);
		//var noOfPlayersProcssed = 0;
		console.log(' ------------------ Gamer TOWT Calculation -----------------' + gamerId);

		return new Promise((resolve, reject) => {
			var at1 = towtPlayersMap.get('AT1');
			var at2 = towtPlayersMap.get('AT2');
			var cb1 = towtPlayersMap.get('CB1');
			var cb2 = towtPlayersMap.get('CB2');
			var cm1 = towtPlayersMap.get('CM1');
			var cm2 = towtPlayersMap.get('CM2');
			var gk = towtPlayersMap.get('GK');
			var lb = towtPlayersMap.get('LB');
			var lm = towtPlayersMap.get('LM');
			var rat = towtPlayersMap.get('RAT');
			var rb = towtPlayersMap.get('RB');
			var rdf = towtPlayersMap.get('RDF');
			var rgk = towtPlayersMap.get('RGK');
			var rm = towtPlayersMap.get('RM');
			var rmf = towtPlayersMap.get('RMF');

			if (
				at1 === undefined ||
				at2 === undefined ||
				cb1 === undefined ||
				cb2 === undefined ||
				cm1 === undefined ||
				cm2 === undefined ||
				gk === undefined ||
				lb === undefined ||
				lm === undefined ||
				rat === undefined ||
				rb === undefined ||
				rdf === undefined ||
				rgk === undefined ||
				rm === undefined ||
				rmf === undefined
			) {
				console.log('Error => Have undefined in first eleven !!!!!! -> ' + gamerId);
				//reject('Issue : undefined object');
			} else {
				var captainDoublePoints = false;
				var vicecaptainDoublePoints = false;
				var captainPlayed = undefined;
				var viceCaptainPlayed = undefined;
				var totwArray = [ ...towtPlayersMap.values() ];

				var batch = db.batch();
				var totwTotalPoints = 0;

				//find captain in towt
				console.log(totwArray);
				var captain = totwArray.find((totwPlayer) => totwPlayer.captain !== undefined);
				//checking if captain plays, if not found then double points will go VC if he plays.
				if (captain) {
					console.log('Captain in team + ' + captain.player_id);
					captainPlayed = flPlayers.find(
						(flplayer) => flplayer.player_id.trim() === captain.player_id.trim()
					);
					if (captainPlayed) {
						console.log('Found captain: ' + captain.firstname);
						captainDoublePoints = true;
					} else {
						console.log('Captain did not play ' + captain.firstname);
						var viceCaptain = totwArray.find((totwPlayer) => totwPlayer.vice_captain !== undefined);
						//checking if captain plays, if not found then double points will go VC if he plays.
						if (viceCaptain) {
							viceCaptainPlayed = flPlayers.find(
								(flplayer) => flplayer.player_id.trim() === viceCaptain.player_id.trim()
							);
							if (viceCaptainPlayed) {
								console.log('Found vice captain: ' + viceCaptain.firstname);
								vicecaptainDoublePoints = true;
							} else {
								console.log('Vice Captain did not play ' + viceCaptain.firstname);
							}
						}
					}
				} else {
					console.log('Captain not found !!');
					viceCaptain = totwArray.find((totwPlayer) => totwPlayer.vice_captain !== undefined);
					//checking if captain plays, if not found then double points will go VC if he plays.
					if (viceCaptain) {
						viceCaptainPlayed = flPlayers.find(
							(flplayer) => flplayer.player_id.trim() === viceCaptain.player_id.trim()
						);
						if (viceCaptainPlayed) {
							console.log('Found vice captain: ' + viceCaptain.firstname);
							vicecaptainDoublePoints = true;
						} else {
							console.log('Vice Captain did not play ' + viceCaptain.firstname);
						}
					} else {
						console.log('Vice captain not playing ');
					}
				}

				//calculate goalkeepers
				var foundGk = flPlayers.find((flplayer) => flplayer.player_id.trim() === gk.player_id.trim());
				if (foundGk) {
					console.log('Position: gk Player: ' + gk.firstname);
					if (captainDoublePoints) {
						console.log('Points: ' + totwPointsCalculations(captainPlayed, foundGk));
						totwTotalPoints = totwTotalPoints + totwPointsCalculations(captainPlayed, foundGk);
						batch = totwFLUpdatesCommitsPreparation(
							'GK',
							gk,
							foundGk,
							totwPointsCalculations(captainPlayed, foundGk),
							batch,
							gamerId
						);
					} else if (vicecaptainDoublePoints) {
						console.log('Points: ' + totwPointsCalculations(viceCaptainPlayed, foundGk));
						totwTotalPoints = totwTotalPoints + totwPointsCalculations(viceCaptainPlayed, foundGk);
						batch = totwFLUpdatesCommitsPreparation(
							'GK',
							gk,
							foundGk,
							totwPointsCalculations(viceCaptainPlayed, foundGk),
							batch,
							gamerId
						);
					} else {
						console.log('Points: ' + foundGk.fl_points);
						totwTotalPoints = totwTotalPoints + foundGk.fl_points;
						batch = totwFLUpdatesCommitsPreparation('GK', gk, foundGk, foundGk.fl_points, batch, gamerId);
					}
				} else {
					console.log('Using reserve kepper: ' + rgk.player_id);
					var foundRgk = flPlayers.find((flplayer) => flplayer.player_id.trim() === rgk.player_id.trim());
					if (foundRgk) {
						console.log('Position: rgk Player: ' + rgk.firstname);
						if (captainDoublePoints) {
							console.log('Points: ' + totwPointsCalculations(captainPlayed, foundRgk));
							totwTotalPoints = totwTotalPoints + totwPointsCalculations(captainPlayed, foundRgk);
							batch = totwFLUpdatesCommitsPreparation(
								'RGK',
								rgk,
								foundRgk,
								totwPointsCalculations(captainPlayed, foundRgk),
								batch,
								gamerId
							);
						} else if (vicecaptainDoublePoints) {
							//console.log('Points: ' + totwPointsCalculations(viceCaptainPlayed, foundRgk));
							totwTotalPoints = totwTotalPoints + totwPointsCalculations(viceCaptainPlayed, foundRgk);
							batch = totwFLUpdatesCommitsPreparation(
								'RGK',
								rgk,
								foundRgk,
								totwPointsCalculations(viceCaptainPlayed, foundRgk),
								batch,
								gamerId
							);
							console.log('gk vp ');
						} else {
							console.log('Points: ' + foundRgk.fl_points);
							totwTotalPoints = totwTotalPoints + foundRgk.fl_points;
							batch = totwFLUpdatesCommitsPreparation(
								'RGK',
								rgk,
								foundRgk,
								foundRgk.fl_points,
								batch,
								gamerId
							);
						}
					} else {
						console.log('Reserve keeper did not play. ' + rgk.firstname);
					}
				}
				console.log('Keeper calculation done: ');

				//calculate defenders
				var useReserveDefender = false;
				console.log(' lb -----> ' + lb.player_id);
				var foundLb = flPlayers.find((flplayer) => flplayer.player_id.trim() === lb.player_id.trim());
				if (foundLb) {
					console.log('Position: lb Player: ' + lb.firstname);
					if (captainDoublePoints) {
						console.log('Points: ' + totwPointsCalculations(captainPlayed, foundLb));
						totwTotalPoints = totwTotalPoints + totwPointsCalculations(captainPlayed, foundLb);
						batch = totwFLUpdatesCommitsPreparation(
							'LB',
							lb,
							foundLb,
							totwPointsCalculations(captainPlayed, foundLb),
							batch,
							gamerId
						);
					} else if (vicecaptainDoublePoints) {
						console.log('Points: ' + totwPointsCalculations(viceCaptainPlayed, foundLb));
						totwTotalPoints = totwTotalPoints + totwPointsCalculations(viceCaptainPlayed, foundLb);
						batch = totwFLUpdatesCommitsPreparation(
							'LB',
							lb,
							foundLb,
							totwPointsCalculations(viceCaptainPlayed, foundLb),
							batch,
							gamerId
						);
					} else {
						console.log('Points: ' + foundLb.fl_points);
						totwTotalPoints = totwTotalPoints + foundLb.fl_points;
						batch = totwFLUpdatesCommitsPreparation('LB', lb, foundLb, foundLb.fl_points, batch, gamerId);
					}
				} else {
					console.log('LB did not play ' + lb.player_id);
					useReserveDefender = true;
				}

				var foundRb = flPlayers.find((flplayer) => flplayer.player_id.trim() === rb.player_id.trim());
				if (foundRb) {
					console.log('Position: rb Player: ' + rb.firstname);
					if (captainDoublePoints) {
						console.log(
							'Points: ' + totwPointsCalculations(captainPlayed, foundRb) + ' -----------> ' + gamerId
						);
						totwTotalPoints = totwTotalPoints + totwPointsCalculations(captainPlayed, foundRb);
						batch = totwFLUpdatesCommitsPreparation(
							'RB',
							rb,
							foundRb,
							totwPointsCalculations(captainPlayed, foundRb),
							batch,
							gamerId
						);
					} else if (vicecaptainDoublePoints) {
						console.log('Points: ' + totwPointsCalculations(viceCaptainPlayed, foundRb));
						totwTotalPoints = totwTotalPoints + totwPointsCalculations(viceCaptainPlayed, foundRb);
						batch = totwFLUpdatesCommitsPreparation(
							'RB',
							rb,
							foundRb,
							totwPointsCalculations(viceCaptainPlayed, foundRb),
							batch,
							gamerId
						);
					} else {
						console.log('Points: ' + foundRb.fl_points + ' -----------> ' + gamerId);
						totwTotalPoints = totwTotalPoints + foundRb.fl_points;
						console.log(' < ----------------- >');
						console.log(rb);
						console.log(foundRb);
						console.log(foundRb.fl_points);
						console.log(gamerId);
						console.log(' < ----------------- >');

						batch = totwFLUpdatesCommitsPreparation('RB', rb, foundRb, foundRb.fl_points, batch, gamerId);
					}
				} else {
					console.log('RB did not play ' + rb.player_id);
					useReserveDefender = true;
				}

				var foundCb1 = flPlayers.find((flplayer) => flplayer.player_id.trim() === cb1.player_id.trim());
				if (foundCb1) {
					console.log('Position: cb1 Player: ' + cb1.firstname);
					if (captainDoublePoints) {
						console.log('Points: ' + totwPointsCalculations(captainPlayed, foundCb1));
						totwTotalPoints = totwTotalPoints + totwPointsCalculations(captainPlayed, foundCb1);
						batch = totwFLUpdatesCommitsPreparation(
							'CB1',
							cb1,
							foundCb1,
							totwPointsCalculations(captainPlayed, foundCb1),
							batch,
							gamerId
						);
					} else if (vicecaptainDoublePoints) {
						console.log('Points: ' + totwPointsCalculations(viceCaptainPlayed, foundCb1));
						totwTotalPoints = totwTotalPoints + totwPointsCalculations(viceCaptainPlayed, foundCb1);
						batch = totwFLUpdatesCommitsPreparation(
							'CB1',
							cb1,
							foundCb1,
							totwPointsCalculations(viceCaptainPlayed, foundCb1),
							batch,
							gamerId
						);
					} else {
						console.log('Points: ' + foundCb1.fl_points);
						totwTotalPoints = totwTotalPoints + foundCb1.fl_points;
						batch = totwFLUpdatesCommitsPreparation(
							'CB1',
							cb1,
							foundCb1,
							foundCb1.fl_points,
							batch,
							gamerId
						);
					}
				} else {
					console.log('CB1 did not play ' + cb1.player_id);
					useReserveDefender = true;
				}

				var foundCb2 = flPlayers.find((flplayer) => flplayer.player_id.trim() === cb2.player_id.trim());
				if (foundCb2) {
					console.log('Position: cb2 Player: ' + cb2.firstname);
					if (captainDoublePoints) {
						console.log('Points: ' + totwPointsCalculations(captainPlayed, foundCb2));
						totwTotalPoints = totwTotalPoints + totwPointsCalculations(captainPlayed, foundCb2);
						batch = totwFLUpdatesCommitsPreparation(
							'CB2',
							cb2,
							foundCb2,
							totwPointsCalculations(captainPlayed, foundCb2),
							batch,
							gamerId
						);
					} else if (vicecaptainDoublePoints) {
						console.log('Points: ' + totwPointsCalculations(viceCaptainPlayed, foundCb2));
						totwTotalPoints = totwTotalPoints + totwPointsCalculations(viceCaptainPlayed, foundCb2);
						batch = totwFLUpdatesCommitsPreparation(
							'CB2',
							cb2,
							foundCb2,
							totwPointsCalculations(viceCaptainPlayed, foundCb2),
							batch,
							gamerId
						);
					} else {
						console.log('Points: ' + foundCb2.fl_points);
						totwTotalPoints = totwTotalPoints + foundCb2.fl_points;
						batch = totwFLUpdatesCommitsPreparation(
							'CB2',
							cb2,
							foundCb2,
							foundCb2.fl_points,
							batch,
							gamerId
						);
					}
				} else {
					console.log('CB2 did not play ' + cb2.player_id);
					useReserveDefender = true;
				}

				if (useReserveDefender) {
					console.log('Using reserve Defender: ' + rdf.player_id);
					var foundRdf = flPlayers.find((flplayer) => flplayer.player_id.trim() === rdf.player_id.trim());
					if (foundRdf) {
						console.log('Position: rdf Player: ' + rdf.firstname);
						if (captainDoublePoints) {
							console.log('Points: ' + totwPointsCalculations(captainPlayed, foundRdf));
							totwTotalPoints = totwTotalPoints + totwPointsCalculations(captainPlayed, foundRdf);
							batch = totwFLUpdatesCommitsPreparation(
								'RDF',
								rdf,
								foundRdf,
								totwPointsCalculations(captainPlayed, foundRdf),
								batch,
								gamerId
							);
						} else if (vicecaptainDoublePoints) {
							console.log('Points: ' + totwPointsCalculations(viceCaptainPlayed, foundRdf));
							totwTotalPoints = totwTotalPoints + totwPointsCalculations(viceCaptainPlayed, foundRdf);
							batch = totwFLUpdatesCommitsPreparation(
								'RDF',
								rdf,
								foundRdf,
								totwPointsCalculations(viceCaptainPlayed, foundRdf),
								batch,
								gamerId
							);
						} else {
							console.log('Points: ' + foundRdf.fl_points);
							totwTotalPoints = totwTotalPoints + foundRdf.fl_points;
							batch = totwFLUpdatesCommitsPreparation(
								'RDF',
								rdf,
								foundRdf,
								foundRdf.fl_points,
								batch,
								gamerId
							);
						}
					} else {
						console.log('Reserve defence did not play. ' + rdf.player_id);
					}
				}

				//calculate midfielders
				var useReserveMidfielders = false;
				var foundRm = flPlayers.find((flplayer) => flplayer.player_id.trim() === rm.player_id.trim());
				if (foundRm) {
					console.log('Position: rm Player: ' + rm.firstname);
					if (captainDoublePoints) {
						console.log('Points: ' + totwPointsCalculations(captainPlayed, foundRm));
						totwTotalPoints = totwTotalPoints + totwPointsCalculations(captainPlayed, foundRm);
						batch = totwFLUpdatesCommitsPreparation(
							'RM',
							rm,
							foundRm,
							totwPointsCalculations(captainPlayed, foundRm),
							batch,
							gamerId
						);
					} else if (vicecaptainDoublePoints) {
						console.log('Points: ' + totwPointsCalculations(viceCaptainPlayed, foundRm));
						totwTotalPoints = totwTotalPoints + totwPointsCalculations(viceCaptainPlayed, foundRm);
						batch = totwFLUpdatesCommitsPreparation(
							'RM',
							rm,
							foundRm,
							totwPointsCalculations(viceCaptainPlayed, foundRm),
							batch,
							gamerId
						);
					} else {
						console.log('Points: ' + foundRm.fl_points);
						totwTotalPoints = totwTotalPoints + foundRm.fl_points;
						batch = totwFLUpdatesCommitsPreparation('RM', rm, foundRm, foundRm.fl_points, batch, gamerId);
					}
				} else {
					console.log('RM did not play ' + rm.player_id);
					useReserveMidfielders = true;
				}

				var foundLm = flPlayers.find((flplayer) => flplayer.player_id.trim() === lm.player_id.trim());
				if (foundLm) {
					console.log('Position: lm Player: ' + lm.firstname);
					if (captainDoublePoints) {
						console.log('Points: ' + totwPointsCalculations(captainPlayed, foundLm));
						totwTotalPoints = totwTotalPoints + totwPointsCalculations(captainPlayed, foundLm);
						batch = totwFLUpdatesCommitsPreparation(
							'LM',
							lm,
							foundLm,
							totwPointsCalculations(captainPlayed, foundLm),
							batch,
							gamerId
						);
					} else if (vicecaptainDoublePoints) {
						console.log('Points: ' + totwPointsCalculations(viceCaptainPlayed, foundLm));
						totwTotalPoints = totwTotalPoints + totwPointsCalculations(viceCaptainPlayed, foundLm);
						batch = totwFLUpdatesCommitsPreparation(
							'LM',
							lm,
							foundLm,
							totwPointsCalculations(viceCaptainPlayed, foundLm),
							batch,
							gamerId
						);
					} else {
						console.log('Points: ' + foundLm.fl_points);
						totwTotalPoints = totwTotalPoints + foundLm.fl_points;
						batch = totwFLUpdatesCommitsPreparation('LM', lm, foundLm, foundLm.fl_points, batch, gamerId);
					}
				} else {
					console.log('LM did not play ' + lm.player_id);
					useReserveMidfielders = true;
				}

				var foundCm1 = flPlayers.find((flplayer) => flplayer.player_id.trim() === cm1.player_id.trim());
				if (foundCm1) {
					console.log('Position: cm1 Player: ' + cm1.firstname);
					if (captainDoublePoints) {
						console.log('Points: ' + totwPointsCalculations(captainPlayed, foundCm1));
						totwTotalPoints = totwTotalPoints + totwPointsCalculations(captainPlayed, foundCm1);
						batch = totwFLUpdatesCommitsPreparation(
							'CM1',
							cm1,
							foundCm1,
							totwPointsCalculations(captainPlayed, foundCm1),
							batch,
							gamerId
						);
					} else if (vicecaptainDoublePoints) {
						console.log('Points: ' + totwPointsCalculations(viceCaptainPlayed, foundCm1));
						totwTotalPoints = totwTotalPoints + totwPointsCalculations(viceCaptainPlayed, foundCm1);
						batch = totwFLUpdatesCommitsPreparation(
							'CM1',
							cm1,
							foundCm1,
							totwPointsCalculations(viceCaptainPlayed, foundCm1),
							batch,
							gamerId
						);
					} else {
						console.log('Points: ' + foundCm1.fl_points);
						totwTotalPoints = totwTotalPoints + foundCm1.fl_points;
						batch = totwFLUpdatesCommitsPreparation(
							'CM1',
							cm1,
							foundCm1,
							foundCm1.fl_points,
							batch,
							gamerId
						);
					}
				} else {
					console.log('CM1 did not play ' + cm1.player_id);
					useReserveMidfielders = true;
				}

				var foundCm2 = flPlayers.find((flplayer) => flplayer.player_id.trim() === cm2.player_id.trim());
				if (foundCm2) {
					console.log('Position: cm2 Player: ' + cm2.firstname);
					if (captainDoublePoints) {
						console.log('Points: ' + totwPointsCalculations(captainPlayed, foundCm2));
						totwTotalPoints = totwTotalPoints + totwPointsCalculations(captainPlayed, foundCm2);
						batch = totwFLUpdatesCommitsPreparation(
							'CM2',
							cm2,
							foundCm2,
							totwPointsCalculations(captainPlayed, foundCm2),
							batch,
							gamerId
						);
					} else if (vicecaptainDoublePoints) {
						console.log('Points: ' + totwPointsCalculations(viceCaptainPlayed, foundCm2));
						totwTotalPoints = totwTotalPoints + totwPointsCalculations(viceCaptainPlayed, foundCm2);
						batch = totwFLUpdatesCommitsPreparation(
							'CM2',
							cm2,
							foundCm2,
							totwPointsCalculations(viceCaptainPlayed, foundCm2),
							batch,
							gamerId
						);
					} else {
						console.log('Points: ' + foundCm2.fl_points);
						totwTotalPoints = totwTotalPoints + foundCm2.fl_points;
						batch = totwFLUpdatesCommitsPreparation(
							'CM2',
							cm2,
							foundCm2,
							foundCm2.fl_points,
							batch,
							gamerId
						);
					}
				} else {
					console.log('CM2 did not play ' + cm2.player_id);
					useReserveMidfielders = true;
				}

				if (useReserveMidfielders) {
					console.log('Using Reserve Midfielder ' + rmf.player_id);
					var foundRmf = flPlayers.find((flplayer) => flplayer.player_id.trim() === rmf.player_id.trim());
					if (foundRmf) {
						console.log('Position: rmf Player: ' + rmf.firstname);
						if (captainDoublePoints) {
							console.log('Points: ' + totwPointsCalculations(captainPlayed, foundRmf));
							totwTotalPoints = totwTotalPoints + totwPointsCalculations(captainPlayed, foundRmf);
							batch = totwFLUpdatesCommitsPreparation(
								'RMF',
								rmf,
								foundRmf,
								totwPointsCalculations(captainPlayed, foundRmf),
								batch,
								gamerId
							);
						} else if (vicecaptainDoublePoints) {
							console.log('Points: ' + totwPointsCalculations(viceCaptainPlayed, foundRmf));
							totwTotalPoints = totwTotalPoints + totwPointsCalculations(viceCaptainPlayed, foundRmf);
							batch = totwFLUpdatesCommitsPreparation(
								'RMF',
								rmf,
								foundRmf,
								totwPointsCalculations(viceCaptainPlayed, foundRmf),
								batch,
								gamerId
							);
						} else {
							console.log('Points: ' + foundRmf.fl_points);
							totwTotalPoints = totwTotalPoints + foundRmf.fl_points;
							batch = totwFLUpdatesCommitsPreparation(
								'RMF',
								rmf,
								foundRmf,
								foundRmf.fl_points,
								batch,
								gamerId
							);
						}
					} else {
						console.log('Reserve mid did not play: ' + rmf.player_id);
					}
				}

				//calculate attackers
				var useReserveAttacker = false;
				var foundAt1 = flPlayers.find((flplayer) => flplayer.player_id.trim() === at1.player_id.trim());
				if (foundAt1) {
					console.log('Position: at1 Player: ' + at1.firstname);
					if (captainDoublePoints) {
						console.log('Points: ' + totwPointsCalculations(captainPlayed, foundAt1));
						totwTotalPoints = totwTotalPoints + totwPointsCalculations(captainPlayed, foundAt1);
						batch = totwFLUpdatesCommitsPreparation(
							'AT1',
							at1,
							foundAt1,
							totwPointsCalculations(captainPlayed, foundAt1),
							batch,
							gamerId
						);
					} else if (vicecaptainDoublePoints) {
						console.log('Points: ' + totwPointsCalculations(viceCaptainPlayed, foundAt1));
						totwTotalPoints = totwTotalPoints + totwPointsCalculations(viceCaptainPlayed, foundAt1);
						batch = totwFLUpdatesCommitsPreparation(
							'AT1',
							at1,
							foundAt1,
							totwPointsCalculations(viceCaptainPlayed, foundAt1),
							batch,
							gamerId
						);
					} else {
						console.log('Points: ' + foundAt1.fl_points);
						totwTotalPoints = totwTotalPoints + foundAt1.fl_points;
						batch = totwFLUpdatesCommitsPreparation(
							'AT1',
							at1,
							foundAt1,
							foundAt1.fl_points,
							batch,
							gamerId
						);
					}
				} else {
					console.log('AT1 did not play ' + at1.player_id);
					useReserveAttacker = true;
				}

				var foundAt2 = flPlayers.find((flplayer) => flplayer.player_id.trim() === at2.player_id.trim());
				if (foundAt2) {
					console.log('Position: at2 Player: ' + at2.firstname);
					if (captainDoublePoints) {
						console.log('Points: ' + totwPointsCalculations(captainPlayed, foundAt2));
						totwTotalPoints = totwTotalPoints + totwPointsCalculations(captainPlayed, foundAt2);
						batch = totwFLUpdatesCommitsPreparation(
							'AT2',
							at2,
							foundAt2,
							totwPointsCalculations(captainPlayed, foundAt2),
							batch,
							gamerId
						);
					} else if (vicecaptainDoublePoints) {
						console.log('Points: ' + totwPointsCalculations(viceCaptainPlayed, foundAt2));
						totwTotalPoints = totwTotalPoints + totwPointsCalculations(viceCaptainPlayed, foundAt2);
						batch = totwFLUpdatesCommitsPreparation(
							'AT2',
							at2,
							foundAt2,
							totwPointsCalculations(viceCaptainPlayed, foundAt2),
							batch,
							gamerId
						);
					} else {
						console.log('Points: ' + foundAt2.fl_points);
						totwTotalPoints = totwTotalPoints + foundAt2.fl_points;
						batch = totwFLUpdatesCommitsPreparation(
							'AT2',
							at2,
							foundAt2,
							foundAt2.fl_points,
							batch,
							gamerId
						);
					}
				} else {
					console.log('AT2 did not play ' + at2.player_id);
					useReserveAttacker = true;
				}

				if (useReserveAttacker) {
					console.log('Using Reserve Attacker ' + rat.player_id);
					var foundRat = flPlayers.find((flplayer) => flplayer.player_id.trim() === rat.player_id.trim());
					if (foundRat) {
						console.log('Position: rat Player: ' + rat.firstname);
						if (captainDoublePoints) {
							console.log('Points: ' + totwPointsCalculations(captainPlayed, foundRat));
							totwTotalPoints = totwTotalPoints + totwPointsCalculations(captainPlayed, foundRat);
							batch = totwFLUpdatesCommitsPreparation(
								'RAT',
								rat,
								foundRat,
								totwPointsCalculations(captainPlayed, foundRat),
								batch,
								gamerId
							);
						} else if (vicecaptainDoublePoints) {
							console.log('Points: ' + totwPointsCalculations(viceCaptainPlayed, foundRat));
							totwTotalPoints = totwTotalPoints + totwPointsCalculations(viceCaptainPlayed, foundRat);
							batch = totwFLUpdatesCommitsPreparation(
								'RAT',
								rat,
								foundRat,
								totwPointsCalculations(viceCaptainPlayed, foundRat),
								batch,
								gamerId
							);
						} else {
							//console.log('Points: ' + foundRat.fl_points);
							totwTotalPoints = totwTotalPoints + foundRat.fl_points;
							batch = totwFLUpdatesCommitsPreparation(
								'RAT',
								rat,
								foundRat,
								foundRat.fl_points,
								batch,
								gamerId
							);
						}
					} else {
						console.log('Reserve did not play: ' + rat.player_id);
					}
				}

				console.log('Total Team Points: ' + totwTotalPoints);
				var gamerRef = db.collection('/FantasyLeague/2022/gamers').doc(gamerId);
				var totalPoints = 0;

				var tempWeeklyPoints = gamer.weekly_points;
				if (tempWeeklyPoints === undefined) {
					console.log('xxx --> ' + gamer.uid + ' Total Points: ' + totalPoints);
				} else {
					for (var week of Object.keys(gamer.weekly_points)) {
						totalPoints = totalPoints + gamer.weekly_points[week];
						//console.log(week + ' -> ' + gamer.weekly_points[week]);
					}
					//console.log(' Gamers total points : ' + totalPoints);
				}

				batch.update(gamerRef, {
					weekly_points   : { ...tempWeeklyPoints, ['week_' + weekCalculation]: totwTotalPoints },
					total_fl_points : totalPoints
				});

				batch
					.commit()
					.then(function() {
						console.log('Batch writing squad players completed');
						resolve('success ' + gamerId);
					})
					.catch((error) => {
						console.error('Error writing document: ', error);
						reject(error + ' ' + gamerId);
					});
			}
		});
	};

	/**
	 * Button: Update Overall Gamers Rankings
	 */
	const gamersOverallRankings = () => {
		var batch = db.batch();

		var gamerMap = new Map();
		var gamerDataMap = new Map();
		const gamersRef = db.collection('/FantasyLeague/2022/gamers');
		gamersRef
			.get()
			.then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					gamerMap.set(
						doc.ref.path,
						doc.data().total_fl_points === undefined ? 0 : doc.data().total_fl_points
					);
					gamerDataMap.set(
						doc.data().team_name,
						doc.data().total_fl_points === undefined ? 0 : doc.data().total_fl_points
					);
				});

				//sort the map
				const mapSort1 = new Map([ ...gamerMap.entries() ].sort((a, b) => b[1] - a[1]));
				//console.log(mapSort1);

				var ranking = 0;
				mapSort1.forEach((value, key, map) => {
					//console.log(key + ' --> ' + value);
					ranking = ranking + 1;
					var gamerRef = db.doc(key);
					//console.log('Team Name: ' + key + ' Pts: ' + value + ' Ranking: ' + ranking);

					batch.update(gamerRef, {
						current_ranking : ranking
					});
				});

				console.log(mapSort1);

				batch
					.commit()
					.then(function() {
						console.log('Batch writing squad players completed');
					})
					.catch((error) => {
						console.error('Error writing document: ', error);
					});
			})
			.catch((error) => {
				console.log('Error getting documents: ', error);
			});
	};

	/** 
	 * Calculate weekly ranking 
	 */
	const gamersRankingsCalculation = () => {
		var batch = db.batch();

		var gamerMap = new Map();
		var rankMap = new Map();
		const gamersRef = db.collection('/FantasyLeague/2022/gamers');
		gamersRef
			.get()
			.then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					var path = doc.ref.path;
					var temp = 'week_' + weekCalculation;
					var weeklyPoints = doc.data().weekly_points === undefined ? 0 : doc.data().weekly_points;
					var points = weeklyPoints['week_' + weekCalculation];
					console.log(path + ' --> ' + points);
					gamerMap.set(path, points === undefined ? 0 : points);
					if (doc.data().weekly_ranking !== undefined) rankMap.set(path, doc.data().weekly_ranking);
				});

				//sort the map
				var ranking = 0;
				const mapSort1 = new Map([ ...gamerMap.entries() ].sort((a, b) => b[1] - a[1]));
				mapSort1.forEach((value, key, map) => {
					//console.log(key + ' --> ' + value);
					ranking = ranking + 1;
					var gamerRef = db.doc(key);

					//console.log(rankMap.get(key));
					var tempWeeklyRanking = rankMap.get(key);
					//console.log(key + ' --> ' + ranking);

					batch.update(gamerRef, {
						weekly_ranking  : { ...tempWeeklyRanking, ['week_' + weekCalculation]: ranking },
						current_ranking : ranking
					});
				});

				console.log(mapSort1);
				batch
					.commit()
					.then(function() {
						console.log('Batch writing squad players completed');
					})
					.catch((error) => {
						console.error('Error writing document: ', error);
					});
			})
			.catch((error) => {
				console.log('Error getting documents: ', error);
			});
	};

	function handleUpdateGamersTeams(event) {
		console.log('Starting to update games for week ' + weekCalculation + ' ' + typeof(weekCalculation));

		const promises = [];

		const gamersRef = db.collection('/FantasyLeague/2022/gamers');
		gamersRef
			.get()
			.then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					//console.log(doc.id, " => ", doc.data());
					var tempDocId = doc.id;
					var towtMap = new Map();
					const towtRef = db.collection(
						'/FantasyLeague/2022/gamers/' + doc.id + '/team_week_' + weekCalculation.toString()
					);
					towtRef
						.get()
						.then((querySnapshot) => {
							querySnapshot.forEach((doc2) => {
								//console.log(doc2.id, " => ", doc2.data());
								towtMap.set(doc2.id, doc2.data());
							});
							//console.log(towtMap.size, ' --> ' + tempDocId)
							promises.push(
								gamerTotwCalculation(
									towtMap,
									tempDocId,
									doc.data().total_fl_points === undefined ? 0 : doc.data().total_fl_points,
									doc.data()
								)
							);
						})
						.catch((error) => {
							console.log('Error getting documents: ', error);
						});
				});

				Promise.all(promises)
					.then((response) => {
						console.log(response);
					})
					.catch((error) => console.log(error));
			})
			.catch((error) => {
				console.log('Error getting documents: ', error);
			});
	}

	/**
	 * Update the players with the FL Points.
	 * @param () event 
	 */
	function handleUpdatePlayersWeekPoints(event) {
		console.log('Update Players Weekly Points. Week: ' + weekCalculation);

		var flPlayers = [];
		var batch = firebase.firestore().batch();

		var flPlayersRef = db.collection('/FantasyLeague/2022/fl_players_week_' + weekCalculation);
		flPlayersRef.get().then((querySnapshot) => {
			querySnapshot.forEach((doc) => {
				//console.log(doc.id, ' => ', doc.data());
				flPlayers.push(doc.data());
			});
		});

		//Get all the 2022 Players
		var playersRef = db.collectionGroup('2022Players');
		playersRef.get().then((querySnapshot) => {
			querySnapshot.forEach((doc) => {
				//console.log(doc.id, ' => ', doc.data());
				var player = doc.data();
				var path = db.doc(doc.ref.path);
				//console.log(player.player_id + ' ' +  doc.ref.path)

				//check if they are in the fl for the week
				if (player.player_id !== undefined) {
					var foundPlayer = flPlayers.find((flP) => flP.player_id.trim() === player.player_id.trim());
					if (foundPlayer) {
						/*
						console.log('Player Found: ' + JSON.stringify(foundPlayer));
						console.log('Player Found: ' + foundPlayer.fl_points);
						console.log('Player Found: ' + foundPlayer.goals);
						console.log('Player Found: ' + foundPlayer.assists);
						console.log('Player Found: ' + foundPlayer.minutes);
						console.log('Player Found: ' + foundPlayer.yc);
						console.log('Player Found: ' + foundPlayer.rc);
						console.log('Player Found: ' + foundPlayer.goalsconcede);
						*/

						//Update overall Statistics including Fantasy League
						var pointsArray = player.fl_weekly_points;
						if (pointsArray !== undefined) {
							var totalpoints = 0;
							var totalassists = 0;
							var totalconceded = 0;
							var totalgoals = 0;
							var totalminutesplayed = 0;
							var totalredcards = 0;
							var totalyellowcards = 0;
							var totalcleansheets = 0;

							for (var i = 1; i < weekCalculation; i++) {
								if (player.fl_weekly_points['week_' + i] !== undefined) {
									console.log('Week ' + i + ' ' + player.fl_weekly_points['week_' + i]);

									totalpoints = totalpoints + player.fl_weekly_points['week_' + i];
									totalassists = totalassists + player.weekly_assists['week_' + i];
									totalconceded = totalconceded + player.weekly_conceded['week_' + i];
									totalgoals = totalgoals + player.weekly_goals['week_' + i];
									totalminutesplayed = totalminutesplayed + player.weekly_minutes_played['week_' + i];
									totalredcards = totalredcards + player.weekly_red_cards['week_' + i];
									totalyellowcards = totalyellowcards + player.weekly_yellow_cards['week_' + i];
									if (
										player.position === 'Goalkeeper' &&
										player.weekly_conceded['week_' + i] === 0 &&
										player.weekly_selection['week_' + i] === true
									) {
										totalcleansheets = totalcleansheets + 1;
										console.log('total clean sheets !!!' + player.player_id);
									}
								}
							}

							totalpoints = totalpoints + foundPlayer.fl_points;
							totalassists = totalassists + foundPlayer.assists;
							totalconceded = totalconceded + foundPlayer.goalsconcede;
							totalgoals = totalgoals + foundPlayer.goals;
							totalminutesplayed = totalminutesplayed + foundPlayer.minutes;
							totalredcards = totalredcards + foundPlayer.rc;
							totalyellowcards = totalyellowcards + foundPlayer.yc;
						} else {
							console.log('Adding week ' + weekCalculation + ' points: ' + foundPlayer.fl_points);

							totalpoints = totalpoints + foundPlayer.fl_points;
							totalassists = totalassists + foundPlayer.assists;
							totalconceded = totalconceded + foundPlayer.goalsconcede;
							totalgoals = totalgoals + foundPlayer.goals;
							totalminutesplayed = totalminutesplayed + foundPlayer.minutes;
							totalredcards = totalredcards + foundPlayer.rc;
							totalyellowcards = totalyellowcards + foundPlayer.yc;
						}

						console.log('Player: ' + player.firstname + player.lastname + ' total points: ' + totalpoints);
						/*
						console.log('total assists: ' + totalassists);
						console.log('total conceded: ' + totalconceded);
						console.log('total goals: ' + totalgoals);
						console.log('total mins playes: ' + totalminutesplayed);
						console.log('total red cards: ' + totalredcards);
						console.log('total yellow cards: ' + totalyellowcards);
						console.log('total clean sheets: ' + totalcleansheets);
						*/

						player = {
							...player,
							fl_points             : totalpoints === undefined ? 0 : totalpoints,
							total_assists         : totalassists === undefined ? 0 : totalassists,
							total_goals           : totalgoals === undefined ? 0 : totalgoals,
							total_red_cards       : totalredcards === undefined ? 0 : totalredcards,
							total_conceceded      : totalconceded === undefined ? 0 : totalconceded,
							total_yellow_cards    : totalyellowcards === undefined ? 0 : totalyellowcards,
							total_minutes_played  : totalminutesplayed === undefined ? 0 : totalminutesplayed,
							total_clean_sheets    : totalcleansheets === undefined ? 0 : totalcleansheets,
							fl_weekly_points      : {
								...player.fl_weekly_points,
								['week_' + weekCalculation]: foundPlayer.fl_points
							},
							weekly_selection      : { ...player.weekly_selection, ['week_' + weekCalculation]: true },
							weekly_goals          : {
								...player.weekly_goals,
								['week_' + weekCalculation]: foundPlayer.goals
							},
							weekly_assists        : {
								...player.weekly_assists,
								['week_' + weekCalculation]: foundPlayer.assists
							},
							weekly_minutes_played : {
								...player.weekly_minutes_played,
								['week_' + weekCalculation]: foundPlayer.minutes
							},
							weekly_yellow_cards   : {
								...player.weekly_yellow_cards,
								['week_' + weekCalculation]: foundPlayer.yc
							},
							weekly_red_cards      : {
								...player.weekly_red_cards,
								['week_' + weekCalculation]: foundPlayer.rc
							},
							weekly_conceded       : {
								...player.weekly_conceded,
								['week_' + weekCalculation]: foundPlayer.goalsconcede
							}
						};
						batch.update(path, player);
					} else {
						//console.log(doc.data());
						player = {
							...player,
							fl_weekly_points      : { ...player.fl_weekly_points, ['week_' + weekCalculation]: 0 },
							weekly_selection      : { ...player.weekly_selection, ['week_' + weekCalculation]: false },
							weekly_goals          : { ...player.weekly_goals, ['week_' + weekCalculation]: 0 },
							weekly_assists        : { ...player.weekly_assists, ['week_' + weekCalculation]: 0 },
							weekly_minutes_played : { ...player.weekly_minutes_played, ['week_' + weekCalculation]: 0 },
							weekly_yellow_cards   : { ...player.weekly_yellow_cards, ['week_' + weekCalculation]: 0 },
							weekly_red_cards      : { ...player.weekly_red_cards, ['week_' + weekCalculation]: 0 },
							weekly_conceded       : { ...player.weekly_conceded, ['week_' + weekCalculation]: 0 }
						};
						batch.update(path, player);
					}
					//console.log('Not found addition' + JSON.stringify(player));
					//console.log(path);
				}
				//console.log('Augmented Player: ' + JSON.stringify(player));
				//console.log(path);
			});

			batch
				.commit()
				.then(function() {
					console.log('Batch writing squad squad_week_' + weekCalculation);
				})
				.catch((error) => {
					console.error('Error writing totw: ', error);
				});
		});
	}

	return (
		<StyledGrid container spacing={3} className={classes.root}>
			{authorized ? (
				<div>
					<Grid item xs={12}>
						<Typography variant='h5' align='center'>
							Fantasy League Admin Dashboard 2022
						</Typography>
					</Grid>
					<Grid>
						<Card>
							<CardHeader
								sx={{
									height     : 50,
									textAlign  : 'center',
									background : 'black',
									color      : 'white'
								}}
								title='Weekly Setup'
								titleTypographyProps={{ variant: 'h6' }}
							/>
							<CardContent>
								<Typography variant='caption' align='center'>
									This will create a snapshot for the selected week, reset transfers to 2 for each
									gamers
								</Typography>
								<Box sx={{ maxWidth: 120 }}>
									<FormControl variant='standard' sx={{ m: 1, minWidth: 120 }}>
										<InputLabel id='demo-simple-select-label'>Select Game Week</InputLabel>
										<Select
											align='left'
											labelId='demo-simple-select-label'
											id='demo-simple-select'
											value={week}
											onChange={handleWeekChange}
										>
											{matchWeek.map((weekno, index) => (
												<MenuItem key={index} value={weekno}>
													{weekno}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Box>

								<Button variant='contained' color='primary' onClick={(e) => handleStartSetup(e)}>
									Start Setup
								</Button>
							</CardContent>
							<CardActionArea />
						</Card>
					</Grid>

					<Grid item xs={12}>
						<Card>
							<CardHeader
								sx={{
									height     : 50,
									textAlign  : 'center',
									background : 'black',
									color      : 'white'
								}}
								title='Fantasy League Week Calculation'
								titleTypographyProps={{ variant: 'h6' }}
							/>
							<CardContent>
								<FormControl variant='standard' sx={{ m: 1, minWidth: 120 }}>
									<InputLabel id='demo-simple-select-label'>Select Game Week</InputLabel>
									<Select
										align='left'
										labelId='demo-simple-select-label'
										id='demo-simple-select'
										value={weekCalculation}
										onChange={(e) => handleWeekCalculationChange(e)}
									>
										{matchWeek.map((weekno, index) => (
											<MenuItem key={index} value={weekno}>
												{weekno}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</CardContent>
						</Card>
					</Grid>

					<Grid item xs={12}>
						<Box m={8} />
						<Typography variant='h5' align='left'>
							Calculation for Week
						</Typography>
						<Box m={2} />
					</Grid>
					<Grid item xs={6}>
						<Button
							variant='contained'
							color='primary'
							onClick={(e) => handleAccessMatchSheets(e)}
							fullwidth='true'
						>
							Access Match Sheets
						</Button>
					</Grid>
					<Box m={2} />
					<Grid item xs={6}>
						<Button
							variant='contained'
							color='primary'
							onClick={(e) => handleUpdateGamersTeams(e)}
							fullwidth='true'
						>
							Update Gamers Teams
						</Button>
					</Grid>
					<Box m={2} />
					<Grid item xs={6}>
						<Button
							variant='contained'
							color='primary'
							onClick={(e) => gamersRankingsCalculation(e)}
							fullwidth='true'
						>
							Update Gamers Weekly Rankings
						</Button>
					</Grid>
					<Grid item xs={12}>
						<Box m={2} />

						<Button variant='contained' color='primary' onClick={(e) => gamersOverallRankings(e)}>
							Update Overall Gamers Ranking
						</Button>
					</Grid>

					<Grid item xs={12}>
						<Box m={2} />
						<Button
							variant='contained'
							color='primary'
							onClick={(e) => updatePlayersStats(weekCalculation)}
						>
							Update Players with FL Points (2022 Players)
						</Button>
					</Grid>
					<Grid item xs={12}>
						<Box m={2} />

						<Button
							variant='contained'
							color='primary'
							onClick={(e) => updateFantasyTeamsSquadAndTeamPlayers(e)}
						>
							Update FL Teams and Squad Players
						</Button>
					</Grid>
					<Grid item xs={12}>
						<Typography variant='h5'>Albirex Nigata Players</Typography>
						<TableContainer component={Paper}>
							<Table className={classes.table} size='small' aria-label='a dense table'>
								<TableHead>
									<TableRow>
										<TableCell>Players</TableCell>
										<TableCell align='right'>Clubs</TableCell>
										<TableCell align='right'>FE</TableCell>
										<TableCell align='right'>M</TableCell>
										<TableCell align='right'>GS</TableCell>
										<TableCell align='right'>A</TableCell>
										<TableCell align='right'>CS</TableCell>
										<TableCell alighn='right'>SS</TableCell>
										<TableCell align='right'>PS</TableCell>
										<TableCell align='right'>PM</TableCell>
										<TableCell align='right'>MM</TableCell>
										<TableCell align='right'>C</TableCell>
										<TableCell align='right'>YC</TableCell>
										<TableCell align='right'>RC</TableCell>
										<TableCell align='right'>OG</TableCell>
										<TableCell align='right'>Pts</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{anfcPlayers.map((row) => (
										<TableRow key={row.fullname}>
											<TableCell align='right'>{row.fullname} </TableCell>
											<TableCell align='right'>{row.teamId}</TableCell>
											<TableCell align='right'>{row.firsteleven}</TableCell>
											<TableCell align='right'>
												{row.minutes} ({row.fl_playingtime_pts})
											</TableCell>
											<TableCell align='right'>
												{row.goals} ({row.fl_goalsscored_pts})
											</TableCell>
											<TableCell align='right'>
												{row.assists} ({row.fl_assists_pts})
											</TableCell>
											<TableCell align='right'>
												{row.goalsconcede} ({row.fl_cleansheet_pts})
											</TableCell>
											<TableCell align='right'>
												{row.shotssaves} ({row.fl_shotsaves_pts})
											</TableCell>
											<TableCell align='right'>
												{row.penaltySave} ({row.fl_penaltymiss_pts})
											</TableCell>
											<TableCell align='right'>
												{row.penaltyMiss} ({row.fl_penaltymiss_pts})
											</TableCell>
											<TableCell align='right'>
												{row.motm} ({row.fl_motm_pts})
											</TableCell>
											<TableCell align='right'>
												{row.goalsconcede} ({row.fl_goalsconcede_pts})
											</TableCell>
											<TableCell align='right'>
												{row.yc} ({row.fl_yellowcard_pts})
											</TableCell>
											<TableCell align='right'>
												{row.rc} ({row.fl_redcard_pts})
											</TableCell>
											<TableCell align='right'>
												{row.og} ({row.fl_owgoal_pts})
											</TableCell>
											<TableCell align='right'>{row.fl_points}</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
					<Grid item xs={12}>
						<Typography variant='h5'>Balestier Khalsa Players</Typography>
						<TableContainer component={Paper}>
							<Table className={classes.table} size='small' aria-label='a dense table'>
								<TableHead>
									<TableRow>
										<TableCell>Players</TableCell>
										<TableCell align='right'>Clubs</TableCell>
										<TableCell align='right'>FE</TableCell>
										<TableCell align='right'>M</TableCell>
										<TableCell align='right'>GS</TableCell>
										<TableCell align='right'>A</TableCell>
										<TableCell align='right'>CS</TableCell>
										<TableCell alighn='right'>SS</TableCell>
										<TableCell align='right'>PS</TableCell>
										<TableCell align='right'>PM</TableCell>
										<TableCell align='right'>MM</TableCell>
										<TableCell align='right'>C</TableCell>
										<TableCell align='right'>YC</TableCell>
										<TableCell align='right'>RC</TableCell>
										<TableCell align='right'>OG</TableCell>
										<TableCell align='right'>Pts</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{bkfcPlayers.map((row) => (
										<TableRow key={row.fullname}>
											<TableCell align='right'>{row.fullname} </TableCell>
											<TableCell align='right'>{row.teamId}</TableCell>
											<TableCell align='right'>{row.firsteleven}</TableCell>
											<TableCell align='right'>
												{row.minutes} ({row.fl_playingtime_pts})
											</TableCell>
											<TableCell align='right'>
												{row.goals} ({row.fl_goalsscored_pts})
											</TableCell>
											<TableCell align='right'>
												{row.assists} ({row.fl_assists_pts})
											</TableCell>
											<TableCell align='right'>
												{row.goalsconcede} ({row.fl_cleansheet_pts})
											</TableCell>
											<TableCell align='right'>
												{row.shotssaves} ({row.fl_shotsaves_pts})
											</TableCell>
											<TableCell align='right'>
												{row.penaltySave} ({row.fl_penaltymiss_pts})
											</TableCell>
											<TableCell align='right'>
												{row.penaltyMiss} ({row.fl_penaltymiss_pts})
											</TableCell>
											<TableCell align='right'>
												{row.motm} ({row.fl_motm_pts})
											</TableCell>
											<TableCell align='right'>
												{row.goalsconcede} ({row.fl_goalsconcede_pts})
											</TableCell>
											<TableCell align='right'>
												{row.yc} ({row.fl_yellowcard_pts})
											</TableCell>
											<TableCell align='right'>
												{row.rc} ({row.fl_redcard_pts})
											</TableCell>
											<TableCell align='right'>
												{row.og} ({row.fl_owgoal_pts})
											</TableCell>
											<TableCell align='right'>{row.fl_points}</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
					<Grid item xs={12}>
						<Typography variant='h5'>Geylang International Players</Typography>
						<TableContainer component={Paper}>
							<Table className={classes.table} size='small' aria-label='a dense table'>
								<TableHead>
									<TableRow>
										<TableCell>Players</TableCell>
										<TableCell align='right'>Clubs</TableCell>
										<TableCell align='right'>FE</TableCell>
										<TableCell align='right'>M</TableCell>
										<TableCell align='right'>GS</TableCell>
										<TableCell align='right'>A</TableCell>
										<TableCell align='right'>CS</TableCell>
										<TableCell alighn='right'>SS</TableCell>
										<TableCell align='right'>PS</TableCell>
										<TableCell align='right'>PM</TableCell>
										<TableCell align='right'>MM</TableCell>
										<TableCell align='right'>C</TableCell>
										<TableCell align='right'>YC</TableCell>
										<TableCell align='right'>RC</TableCell>
										<TableCell align='right'>OG</TableCell>
										<TableCell align='right'>Pts</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{gifcPlayers.map((row) => (
										<TableRow key={row.fullname}>
											<TableCell align='right'>{row.fullname} </TableCell>
											<TableCell align='right'>{row.teamId}</TableCell>
											<TableCell align='right'>{row.firsteleven}</TableCell>
											<TableCell align='right'>
												{row.minutes} ({row.fl_playingtime_pts})
											</TableCell>
											<TableCell align='right'>
												{row.goals} ({row.fl_goalsscored_pts})
											</TableCell>
											<TableCell align='right'>
												{row.assists} ({row.fl_assists_pts})
											</TableCell>
											<TableCell align='right'>
												{row.goalsconcede} ({row.fl_cleansheet_pts})
											</TableCell>
											<TableCell align='right'>
												{row.shotssaves} ({row.fl_shotsaves_pts})
											</TableCell>
											<TableCell align='right'>
												{row.penaltySave} ({row.fl_penaltymiss_pts})
											</TableCell>
											<TableCell align='right'>
												{row.penaltyMiss} ({row.fl_penaltymiss_pts})
											</TableCell>
											<TableCell align='right'>
												{row.motm} ({row.fl_motm_pts})
											</TableCell>
											<TableCell align='right'>
												{row.goalsconcede} ({row.fl_goalsconcede_pts})
											</TableCell>
											<TableCell align='right'>
												{row.yc} ({row.fl_yellowcard_pts})
											</TableCell>
											<TableCell align='right'>
												{row.rc} ({row.fl_redcard_pts})
											</TableCell>
											<TableCell align='right'>
												{row.og} ({row.fl_owgoal_pts})
											</TableCell>
											<TableCell align='right'>{row.fl_points}</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
					<Grid item xs={12}>
						<Typography variant='h5'>Hougang Utd Players</Typography>
						<TableContainer component={Paper}>
							<Table className={classes.table} size='small' aria-label='a dense table'>
								<TableHead>
									<TableRow>
										<TableCell>Players</TableCell>
										<TableCell align='right'>Club</TableCell>
										<TableCell align='right'>FE</TableCell>
										<TableCell align='right'>M</TableCell>
										<TableCell align='right'>GS</TableCell>
										<TableCell align='right'>A</TableCell>
										<TableCell align='right'>CS</TableCell>
										<TableCell alighn='right'>SS</TableCell>
										<TableCell align='right'>PS</TableCell>
										<TableCell align='right'>PM</TableCell>
										<TableCell align='right'>MM</TableCell>
										<TableCell align='right'>C</TableCell>
										<TableCell align='right'>YC</TableCell>
										<TableCell align='right'>RC</TableCell>
										<TableCell align='right'>OG</TableCell>
										<TableCell align='right'>Pts</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{hufcPlayers.map((row) => (
										<TableRow key={row.fullname}>
											<TableCell align='right'>{row.fullname} </TableCell>
											<TableCell align='right'>{row.teamId}</TableCell>
											<TableCell align='right'>{row.firsteleven}</TableCell>
											<TableCell align='right'>
												{row.minutes} ({row.fl_playingtime_pts})
											</TableCell>
											<TableCell align='right'>
												{row.goals} ({row.fl_goalsscored_pts})
											</TableCell>
											<TableCell align='right'>
												{row.assists} ({row.fl_assists_pts})
											</TableCell>
											<TableCell align='right'>
												{row.goalsconcede} ({row.fl_cleansheet_pts})
											</TableCell>
											<TableCell align='right'>
												{row.shotssaves} ({row.fl_shotsaves_pts})
											</TableCell>
											<TableCell align='right'>
												{row.penaltySave} ({row.fl_penaltymiss_pts})
											</TableCell>
											<TableCell align='right'>
												{row.penaltyMiss} ({row.fl_penaltymiss_pts})
											</TableCell>
											<TableCell align='right'>
												{row.motm} ({row.fl_motm_pts})
											</TableCell>
											<TableCell align='right'>
												{row.goalsconcede} ({row.fl_goalsconcede_pts})
											</TableCell>
											<TableCell align='right'>
												{row.yc} ({row.fl_yellowcard_pts})
											</TableCell>
											<TableCell align='right'>
												{row.rc} ({row.fl_redcard_pts})
											</TableCell>
											<TableCell align='right'>
												{row.og} ({row.fl_owgoal_pts})
											</TableCell>
											<TableCell align='right'>{row.fl_points}</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
					<Grid item xs={12}>
						<Typography variant='h5'>Lion City Sailors Players</Typography>
						<TableContainer component={Paper}>
							<Table className={classes.table} size='small' aria-label='a dense table'>
								<TableHead>
									<TableRow>
										<TableCell>Players</TableCell>
										<TableCell align='right'>Clubs</TableCell>
										<TableCell align='right'>FE</TableCell>
										<TableCell align='right'>M</TableCell>
										<TableCell align='right'>GS</TableCell>
										<TableCell align='right'>A</TableCell>
										<TableCell align='right'>CS</TableCell>
										<TableCell alighn='right'>SS</TableCell>
										<TableCell align='right'>PS</TableCell>
										<TableCell align='right'>PM</TableCell>
										<TableCell align='right'>MM</TableCell>
										<TableCell align='right'>C</TableCell>
										<TableCell align='right'>YC</TableCell>
										<TableCell align='right'>RC</TableCell>
										<TableCell align='right'>OG</TableCell>
										<TableCell align='right'>Pts</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{lcsfPlayers.map((row) => (
										<TableRow key={row.fullname}>
											<TableCell align='right'>
												{row.fullname} ({row.position})
											</TableCell>
											<TableCell align='right'>{row.teamId}</TableCell>
											<TableCell align='right'>{row.firsteleven}</TableCell>
											<TableCell align='right'>
												{row.minutes} ({row.fl_playingtime_pts})
											</TableCell>
											<TableCell align='right'>
												{row.goals} ({row.fl_goalsscored_pts})
											</TableCell>
											<TableCell align='right'>
												{row.assists} ({row.fl_assists_pts})
											</TableCell>
											<TableCell align='right'>
												{row.goalsconcede} ({row.fl_cleansheet_pts})
											</TableCell>
											<TableCell align='right'>
												{row.shotssaves} ({row.fl_shotsaves_pts})
											</TableCell>
											<TableCell align='right'>
												{row.penaltySave} ({row.fl_penaltymiss_pts})
											</TableCell>
											<TableCell align='right'>
												{row.penaltyMiss} ({row.fl_penaltymiss_pts})
											</TableCell>
											<TableCell align='right'>
												{row.motm} ({row.fl_motm_pts})
											</TableCell>
											<TableCell align='right'>
												{row.goalsconcede} ({row.fl_goalsconcede_pts})
											</TableCell>
											<TableCell align='right'>
												{row.yc} ({row.fl_yellowcard_pts})
											</TableCell>
											<TableCell align='right'>
												{row.rc} ({row.fl_redcard_pts})
											</TableCell>
											<TableCell align='right'>
												{row.og} ({row.fl_owgoal_pts})
											</TableCell>
											<TableCell align='right'>{row.fl_points}</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
					<Grid item xs={12}>
						<Typography variant='h5'>Tanjong Pagar Players</Typography>
						<TableContainer component={Paper}>
							<Table className={classes.table} size='small' aria-label='a dense table'>
								<TableHead>
									<TableRow>
										<TableCell>Players</TableCell>
										<TableCell align='right'>Clubs</TableCell>
										<TableCell align='right'>FE</TableCell>
										<TableCell align='right'>M</TableCell>
										<TableCell align='right'>GS</TableCell>
										<TableCell align='right'>A</TableCell>
										<TableCell align='right'>CS</TableCell>
										<TableCell alighn='right'>SS</TableCell>
										<TableCell align='right'>PS</TableCell>
										<TableCell align='right'>PM</TableCell>
										<TableCell align='right'>MM</TableCell>
										<TableCell align='right'>C</TableCell>
										<TableCell align='right'>YC</TableCell>
										<TableCell align='right'>RC</TableCell>
										<TableCell align='right'>OG</TableCell>
										<TableCell align='right'>Pts</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{tpfcPlayers.map((row) => (
										<TableRow key={row.fullname}>
											<TableCell align='right'>{row.fullname} </TableCell>
											<TableCell align='right'>{row.teamId}</TableCell>
											<TableCell align='right'>{row.firsteleven}</TableCell>
											<TableCell align='right'>
												{row.minutes} ({row.fl_playingtime_pts})
											</TableCell>
											<TableCell align='right'>
												{row.goals} ({row.fl_goalsscored_pts})
											</TableCell>
											<TableCell align='right'>
												{row.assists} ({row.fl_assists_pts})
											</TableCell>
											<TableCell align='right'>
												{row.goalsconcede} ({row.fl_cleansheet_pts})
											</TableCell>
											<TableCell align='right'>
												{row.shotssaves} ({row.fl_shotsaves_pts})
											</TableCell>
											<TableCell align='right'>
												{row.penaltySave} ({row.fl_penaltymiss_pts})
											</TableCell>
											<TableCell align='right'>
												{row.penaltyMiss} ({row.fl_penaltymiss_pts})
											</TableCell>
											<TableCell align='right'>
												{row.motm} ({row.fl_motm_pts})
											</TableCell>
											<TableCell align='right'>
												{row.goalsconcede} ({row.fl_goalsconcede_pts})
											</TableCell>
											<TableCell align='right'>
												{row.yc} ({row.fl_yellowcard_pts})
											</TableCell>
											<TableCell align='right'>
												{row.rc} ({row.fl_redcard_pts})
											</TableCell>
											<TableCell align='right'>
												{row.og} ({row.fl_owgoal_pts})
											</TableCell>
											<TableCell align='right'>{row.fl_points}</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
					<Grid item xs={12}>
						<Typography variant='h5'>Tampines Rovers Players</Typography>
						<TableContainer component={Paper}>
							<Table className={classes.table} size='small' aria-label='a dense table'>
								<TableHead>
									<TableRow>
										<TableCell>Players</TableCell>
										<TableCell align='right'>Clubs</TableCell>
										<TableCell align='right'>FE</TableCell>
										<TableCell align='right'>M</TableCell>
										<TableCell align='right'>GS</TableCell>
										<TableCell align='right'>A</TableCell>
										<TableCell align='right'>CS</TableCell>
										<TableCell alighn='right'>SS</TableCell>
										<TableCell align='right'>PS</TableCell>
										<TableCell align='right'>PM</TableCell>
										<TableCell align='right'>MM</TableCell>
										<TableCell align='right'>C</TableCell>
										<TableCell align='right'>YC</TableCell>
										<TableCell align='right'>RC</TableCell>
										<TableCell align='right'>OG</TableCell>
										<TableCell align='right'>Pts</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{trfcPlayers.map((row) => (
										<TableRow key={row.fullname}>
											<TableCell align='right'>{row.fullname} </TableCell>
											<TableCell align='right'>{row.teamId}</TableCell>
											<TableCell align='right'>{row.firsteleven}</TableCell>
											<TableCell align='right'>
												{row.minutes} ({row.fl_playingtime_pts})
											</TableCell>
											<TableCell align='right'>
												{row.goals} ({row.fl_goalsscored_pts})
											</TableCell>
											<TableCell align='right'>
												{row.assists} ({row.fl_assists_pts})
											</TableCell>
											<TableCell align='right'>
												{row.goalsconcede} ({row.fl_cleansheet_pts})
											</TableCell>
											<TableCell align='right'>
												{row.shotssaves} ({row.fl_shotsaves_pts})
											</TableCell>
											<TableCell align='right'>
												{row.penaltySave} ({row.fl_penaltymiss_pts})
											</TableCell>
											<TableCell align='right'>
												{row.penaltyMiss} ({row.fl_penaltymiss_pts})
											</TableCell>
											<TableCell align='right'>
												{row.motm} ({row.fl_motm_pts})
											</TableCell>
											<TableCell align='right'>
												{row.goalsconcede} ({row.fl_goalsconcede_pts})
											</TableCell>
											<TableCell align='right'>
												{row.yc} ({row.fl_yellowcard_pts})
											</TableCell>
											<TableCell align='right'>
												{row.rc} ({row.fl_redcard_pts})
											</TableCell>
											<TableCell align='right'>
												{row.og} ({row.fl_owgoal_pts})
											</TableCell>
											<TableCell align='right'>{row.fl_points}</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
					<Grid item xs={12}>
						<Typography variant='h5'>Young Lions Players</Typography>
						<TableContainer component={Paper}>
							<Table className={classes.table} size='small' aria-label='a dense table'>
								<TableHead>
									<TableRow>
										<TableCell>Players</TableCell>
										<TableCell align='right'>Clubs</TableCell>
										<TableCell align='right'>FE</TableCell>
										<TableCell align='right'>M</TableCell>
										<TableCell align='right'>GS</TableCell>
										<TableCell align='right'>A</TableCell>
										<TableCell align='right'>CS</TableCell>
										<TableCell alighn='right'>SS</TableCell>
										<TableCell align='right'>PS</TableCell>
										<TableCell align='right'>PM</TableCell>
										<TableCell align='right'>MM</TableCell>
										<TableCell align='right'>C</TableCell>
										<TableCell align='right'>YC</TableCell>
										<TableCell align='right'>RC</TableCell>
										<TableCell align='right'>OG</TableCell>
										<TableCell align='right'>Pts</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{ylfcPlayers.map((row) => (
										<TableRow key={row.fullname}>
											<TableCell align='right'>{row.fullname} </TableCell>
											<TableCell align='right'>{row.teamId}</TableCell>
											<TableCell align='right'>{row.firsteleven}</TableCell>
											<TableCell align='right'>
												{row.minutes} ({row.fl_playingtime_pts})
											</TableCell>
											<TableCell align='right'>
												{row.goals} ({row.fl_goalsscored_pts})
											</TableCell>
											<TableCell align='right'>
												{row.assists} ({row.fl_assists_pts})
											</TableCell>
											<TableCell align='right'>
												{row.goalsconcede} ({row.fl_cleansheet_pts})
											</TableCell>
											<TableCell align='right'>
												{row.shotssaves} ({row.fl_shotsaves_pts})
											</TableCell>
											<TableCell align='right'>
												{row.penaltySave} ({row.fl_penaltymiss_pts})
											</TableCell>
											<TableCell align='right'>
												{row.penaltyMiss} ({row.fl_penaltymiss_pts})
											</TableCell>
											<TableCell align='right'>
												{row.motm} ({row.fl_motm_pts})
											</TableCell>
											<TableCell align='right'>
												{row.goalsconcede} ({row.fl_goalsconcede_pts})
											</TableCell>
											<TableCell align='right'>
												{row.yc} ({row.fl_yellowcard_pts})
											</TableCell>
											<TableCell align='right'>
												{row.rc} ({row.fl_redcard_pts})
											</TableCell>
											<TableCell align='right'>
												{row.og} ({row.fl_owgoal_pts})
											</TableCell>
											<TableCell align='right'>{row.fl_points}</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
					<Grid item xs={12}>
						<Box m={2} />

						<Button variant='contained' color='primary' onClick={(e) => handleSaveCalculations(e)}>
							Save Calculations
						</Button>
					</Grid>
				</div>
			) : (
				<div />
			)}
		</StyledGrid>
	);
}

//const firebase = require('firebase/app');
//require('firebase/auth');
//require('firebase/functions');
//require('firebase/firestore');
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';

export const firebaseconfig = {
	apiKey            : 'AIzaSyCMIsC4iONCv3rfLo4AKya5BdjWcXJWi3E',
	authDomain        : 'voiddeckfootie.firebaseapp.com',
	databaseURL       : 'https://voiddeckfootie.firebaseio.com',
	projectId         : 'voiddeckfootie',
	storageBucket     : 'voiddeckfootie.appspot.com',
	messagingSenderId : '286409626058',
	appId             : '1:286409626058:web:a144efa0f868e3de1fff33',
	measurementId     : 'G-M8KT2SCEPZ'
};

//firebase.initializeApp(firebaseConfigDevelopment);
firebase.initializeApp(firebaseconfig);


/*
if (window.location.hostname === 'localhost') {
	console.log("testing locally -- hitting local functions and firestore emulators");
	firebase.functions().useFunctionsEmulator('http://localhost:5001');
	firebase.firestore().settings({
	  host: 'localhost:8080',
	  ssl: false
	});
  }
*/

export const provider = new firebase.auth.GoogleAuthProvider();
export const auth = firebase.auth();
export const functions = firebase.functions();
export const db = firebase.firestore();
export const fieldval = firebase.firestore.FieldValue;
export default firebase;

import React, { useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';
import { useSession } from '../App';
import { Typography, Card, TextField, CardContent, CardActions } from '@mui/material';
import Grid from '@mui/material/Grid';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import SaveIcon from '@mui/icons-material/Save';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';

//Firebase
import { db } from '../firebase';

//Table
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import firebase from '../firebase';
import { DomainDisabled, LocalConvenienceStoreOutlined } from '@mui/icons-material';

const PREFIX = 'AdminFantasyLeagueDiagnosticsDashboard';

const classes = {
	root                 : `${PREFIX}-root`,
	paper                : `${PREFIX}-paper`,
	button               : `${PREFIX}-button`,
	formControl          : `${PREFIX}-formControl`,
	grouplabel           : `${PREFIX}-grouplabel`,
	large                : `${PREFIX}-large`,
	selectEmpty          : `${PREFIX}-selectEmpty`,
	formControlSelection : `${PREFIX}-formControlSelection`,
	table                : `${PREFIX}-table`
};

const StyledGrid = styled(Grid)(({ theme }) => ({
	[`&.${classes.root}`]: {
		flexgrow : 1,
		margin   : 'auto'
	},

	[`& .${classes.paper}`]: {
		width    : 130,
		height   : 230,
		overflow : 'auto'
	},

	[`& .${classes.button}`]: {
		margin : theme.spacing(1)
	},

	[`& .${classes.formControl}`]: {
		margin   : theme.spacing(3),
		minWidth : 150,
		maxWidth : 250
	},

	[`& .${classes.grouplabel}`]: {
		fontsize : 10
	},

	[`& .${classes.large}`]: {
		width  : theme.spacing(7),
		height : theme.spacing(7)
	},

	[`& .${classes.selectEmpty}`]: {
		marginTop : theme.spacing(2)
	},

	[`& .${classes.formControlSelection}`]: {
		margin   : theme.spacing(1),
		minWidth : 150
	},

	[`& .${classes.table}`]: {
		minWidth : 650
	}
}));

//const { GoogleSpreadsheet } = require('google-spreadsheet');

const matchWeek = [ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22 ];

export default function AdminFantasyLeagueDashboard() {
	const user = useSession();
	const [ authorized, setAuthorized ] = useState(false);

	const [ matches, setMatches ] = React.useState([]);

	const [ flPlayers, setFlPlayers ] = useState([]);
	const [ players, setPlayers ] = useState([]);
	const [ gamerPlayers, setGamerPlayers ] = useState([]);
	const [ gamers, setGamers ] = useState([]);

	//New
	const [ week, setWeek ] = useState(1);
	const [ weekCalculation, setWeekCalculation ] = useState(0);

	useEffect(() => {
		console.log('Checking user: ' + user.uid);
		if (
			user.uid === 'glKicD84lLejtJ5M9qOLaczt5ny1' ||
			user.uid === 'YP1dpgESghPw7WiAoIL1Sd0HARw1' ||
			user.uid === 'MmkWUYFwTwT7DKWfnsgXoKoYgxo2'
		) {
			console.log('Authorized User');
			setAuthorized(true);
		} else {
			console.log('Unauthorized User');
			setAuthorized(false);
		}

		//Get all the 2021 Players
		/*
		var playersRef = db.collectionGroup('2021Players');
		playersRef.get().then((querySnapshot) => {
			setPlayers(querySnapshot.docs.map((player) => player.data()));
			querySnapshot.forEach((doc) => {
				//console.log(doc.id, ' => ', doc.data());
				if (doc.data().player_id === undefined) console.log(doc.data());
			});
		});

		var gamersRef = db.collection('/FantasyLeague/2021/gamers').orderBy('total_fl_points', 'desc');
		gamersRef.get().then((querySnapshot) => {
			setGamers(querySnapshot.docs.map((gamer) => gamer.data()));
			//querySnapshot.forEach((doc) => {
			//console.log(doc.id, ' => ', doc.data());
			//	console.log(doc.data());
			//});
		});
		
		

		var playersRef = db.collectionGroup('team');
		playersRef.get().then((querySnapshot) => {
			setPlayers(querySnapshot.docs.map((player) => player.data()));
			querySnapshot.forEach((doc) => {
				//console.log(doc.id, ' => ', doc.data());
				if (doc.data().player_id === '7eba34dc6420a52ec1e835939d0802156ee683e9_Tajeli_Salamat_3') {
					console.log(doc.ref.path)
				}
			});
		});
		*/
		
	}, []);

	function checkBudgetLimit(event) {
		console.log('Checking Budget Limits');
		//console.log('Gamers: ' + gamers.length);
		gamers.forEach((gamer) => {
			//console.log(gamer.team_name);
			db
				.collection('FantasyLeague/2021/gamers/' + gamer.uid + '/players')
				.get()
				.then((querySnapshot) => {
					var playersCost = 0;
					querySnapshot.forEach((doc) => {
						// doc.data() is never undefined for query doc snapshots
						//console.log(doc.id, ' => ', doc.data());
						playersCost = playersCost + doc.data().value;
					});
					if (playersCost > 100) console.log('Team: ' + gamer.team_name + ' Players Cost: ' + playersCost);
				})
				.catch((error) => {
					console.log('Error getting documents: ', error);
				});
		});
	}

	function checkPlayersTeam(event) {
		console.log('Checking Players Team');

		var players = db
			.collectionGroup('team')
			.where('player_id', '==', '514997d7bf8c86b1f63f45f56d35e1d4c0ef437b_Ilhan_Fandi_17');
		players.get().then((querySnapshot) => {
			querySnapshot.forEach((doc) => {
				console.log(doc.ref.path, ' => ', doc.data());
			});
		});

		//console.log('Gamers: ' + gamers.length);
		/*
		gamers.forEach((gamer) => {
			//console.log(gamer.team_name);
			db
				.collection('FantasyLeague/2021/gamers/' + gamer.uid + '/players')
				.get()
				.then((querySnapshot) => {
					var playersCost = 0;
					querySnapshot.forEach((doc) => {
						// doc.data() is never undefined for query doc snapshots
						//console.log(doc.id, ' => ', doc.data());
						playersCost = playersCost + doc.data().value;
					});
					if (playersCost > 100)
					console.log('Team: ' + gamer.team_name + ' Players Cost: ' + playersCost);
				})
				.catch((error) => {
					console.log('Error getting documents: ', error);
				});
		});
		*/
	}

	function minileagueUpdate(event) {
		console.log('Mini League Update');
		//console.log('Gamers: ' + gamers.length);
		var miniLeagueGamers = db.collectionGroup('MiniLeague_Members').get().then((querySnapshot) => {
			querySnapshot.forEach((doc) => {
				//console.log(doc.id, ' => ', doc.data());
				var miniLeagueGamerDocPath = doc.ref.path.substring(0, doc.ref.path.length - 29);
				var miniLeagueGamerDocId = doc.ref.path.substring(
					miniLeagueGamerDocPath.length + 1,
					miniLeagueGamerDocPath.length + 29
				);
				console.log('------ ');
				//console.log(doc.ref.path);
				//console.log(doc.ref.path.substring(0, doc.ref.path.length - 29));
				//console.log(doc.ref.path.substring(miniLeagueGamerDocPath.length + 1, miniLeagueGamerDocPath.length + 29));

				var minileagueGamer = doc.data();
				var docRef = db.collection('/FantasyLeague/2022/gamers').doc(doc.id);
				docRef
					.get()
					.then((doc2) => {
						if (doc2.exists) {
							var gamer = doc2.data();
							//console.log(gamer.total_fl_points);
							let data = { ...minileagueGamer, total_fl_points: gamer.total_fl_points };
							//console.log(miniLeagueGamerDocPath + ' --> ' + miniLeagueGamerDocId);
							//console.log(data);

							db
								.collection(miniLeagueGamerDocPath)
								.doc(miniLeagueGamerDocId)
								.set(data, { merge: true })
								.then(() => {
									console.log('Document successfully written!');
								})
								.catch((error) => {
									console.error('Error writing document: ', error);
								});
						} else {
							// doc.data() will be undefined in this case
							console.log('No such document!');
						}
					})
					.catch((error) => {
						console.log('Error getting document:', error);
					});
			});
		});
	}

	function addPlayerToMatch(event) {
		console.log('Add Player to Match');
		db
			.collection('2022Teams/BKFC/2022Players')
			.doc('9558bc5305db34f0cc1ace616348c001cc0c692e_	Muhammad-Syukri_Noorhaizam_23')
			.get()
			.then((doc) => {
				if (doc.exists) {
					console.log('Document data:', doc.data());

					let data = {
						...doc.data(),
						match_id             : '2022Matches/2022_ROUND_4_WEEK_24_TPFC_BKFC_SPL',
						match_title          : 'Tanjong Pagar vs Balestier Khalsa',
						match_playing_status : 'substitute'
					};
					db
						.collection('2022Matches/2022_ROUND_4_WEEK_24_TPFC_BKFC_SPL/AWAY_TEAM_PLAYERS')
						.doc('9558bc5305db34f0cc1ace616348c001cc0c692e_	Muhammad-Syukri_Noorhaizam_23')
						.set(data, { merge: true })
						.then(() => {
							console.log('Document successfully written!');
						})
						.catch((error) => {
							console.error('Error writing document: ', error);
						});
				} else {
					// doc.data() will be undefined in this case
					console.log('No such document!');
				}
			})
			.catch((error) => {
				console.log('Error getting document:', error);
			});
			/*
			db
			.collection('2022Teams/YLFC/2022Players')
			.doc('9c6ba2cf812521be08f6608101714221a127d1a0_Nor-Hakim_Redzuan_20')
			.get()
			.then((doc) => {
				if (doc.exists) {
					console.log('Document data:', doc.data());

					let data = {
						...doc.data(),
						match_id             : '2022Matches/2022_ROUND_2_WEEK_12_TPFC_YLFC_SPL',
						match_title          : 'Tanjong Pagar vs Young Lions',
						match_playing_status : 'subtitute'
					};
					db
						.collection('/2022Matches/2022_ROUND_2_WEEK_12_TPFC_YLFC_SPL/AWAY_TEAM_PLAYERS')
						.doc('9c6ba2cf812521be08f6608101714221a127d1a0_Nor-Hakim_Redzuan_20')
						.set(data, { merge: true })
						.then(() => {
							console.log('Document successfully written!');
						})
						.catch((error) => {
							console.error('Error writing document: ', error);
						});
				} else {
					// doc.data() will be undefined in this case
					console.log('No such document!');
				}
			})
			.catch((error) => {
				console.log('Error getting document:', error);
			});
			*/
	}

	function copyMatch(source, dest) {
		console.log(source + ' -> ' + dest);

		const docRef = db.doc(source);

		docRef
			.get()
			.then((doc) => {
				if (doc.exists) {
					console.log('Document data:', doc.data());
					db
						.collection('/2022Matches/')
						.doc('2022_ROUND_1_WEEK_7.2_TRFC_YLFC_SPL')
						.set(doc.data())
						.then(() => {
							console.log('Document successfully written!');
						})
						.catch((error) => {
							console.error('Error writing document: ', error);
						});
				} else {
					// doc.data() will be undefined in this case
					console.log('No such document!');
				}
			})
			.catch((error) => {
				console.log('Error getting document:', error);
			});

		db
			.collection('/2022Matches/2022_ROUND_1_WEEK_7.1_TRFC_YLFC_SPL/2022_Match_Predictions')
			.get()
			.then((querySnapshot) => {
				querySnapshot.forEach((doc2) => {
					// doc.data() is never undefined for query doc snapshots
					console.log(doc2.id, ' => ', doc2.data());
					db
						.collection('/2022Matches/2022_ROUND_1_WEEK_7.2_TRFC_YLFC_SPL/2022_Match_Predictions')
						.doc(doc2.id)
						.set(doc2.data())
						.then(() => {
							console.log('Document successfully written!');
						})
						.catch((error) => {
							console.error('Error writing document: ', error);
						});
				});
			});

		/*
		if ( docData ) {
		  db
			.doc( dest )
			.set( docData )
			.catch( ( error ) => {
			  console.error( 'Error creating document', `${dest}`, error.message );
			 
			} );
		}
		*/
		/*
	  
		const subcollections =  docRef.listCollections();
		for  ( const subcollectionRef of subcollections ) {
		  const subPath = `${source}/${subcollectionRef.id}`
		  console.log( 'parsing: ', subPath )
		  try {
			const snapshot =  subcollectionRef.get()
			const docs = snapshot.docs;
			for  ( const doc of docs ) {
			  console.log( `coping: ${subPath}/${doc.id} -> ${dest}/${subcollectionRef.id}/${doc.id}` )
				copyMatch( `${subPath}/${doc.id}`, `${dest}/${subcollectionRef.id}/${doc.id}` );
			}
		  } catch ( e ) {
			console.log(e);
		  }
		}
		*/
	}

	function updatePlayersStats() {
		console.log('Update Player stats');
		var noOfUpdates = 0;
		var playersUpdateBatch = db.batch();
		var playersRef = db.collectionGroup('2022Players');
		playersRef.get().then((querySnapshot) => {
			querySnapshot.forEach((doc) => {
				var players = doc.data();
				var path = db.doc(doc.ref.path);

				players.fl_points = calculate(players.fl_weekly_points);
				players.total_assists = calculate(players.weekly_assists);
				players.total_clean_sheets = calculate(players.weekly_clean_sheets);
				players.total_conceded = calculate(players.weekly_conceded);
				players.total_goals = calculate(players.weekly_goals);
				players.total_minutes_played = calculate(players.weekly_minutes_played);
				players.total_own_goals = calculate(players.weekly_own_goals);
				players.total_penalty_missed = calculate(players.weekly_penalty_missed);
				players.total_penalty_saved = calculate(players.weekly_penalty_saved);
				players.total_red_cards = calculate(players.weekly_red_cards);
				players.total_yellow_cards = calculate(players.weekly_yellow_cards);

				//console.log(players);
				noOfUpdates = noOfUpdates + 1
				playersUpdateBatch.set(path, players);
			});

			playersUpdateBatch
				.commit()
				.then(function() {
					console.log('Update of players completed: ' + noOfUpdates);
				})
				.catch((error) => {
					console.error('Error writing totw: ', error);
				});
		});
	}

	function calculate(weeklyStats) {
		var totalPoints = 0;
		for (var idx of Object.keys(weeklyStats)) {
			//console.log(idx, weeklyStats[idx]); // key, value
			totalPoints = totalPoints + weeklyStats[idx];
		}
		return totalPoints;
	}

	function transferPlayers(players) {
		console.log(players);

		players.forEach((player) => {
			const docRef = db.doc(player);
			docRef
				.get()
				.then((doc) => {
					if (doc.exists) {
						console.log('Document data:', doc.data());
						db
							.collection('/2022Teams/GIFC/2022Players')
							.doc(doc.data().player_id)
							.set(doc.data())
							.then(() => {
								console.log('Document successfully written!');
							})
							.catch((error) => {
								console.error('Error writing document: ', error);
							});
					} else {
						// doc.data() will be undefined in this case
						console.log('No such document!');
					}
				})
				.catch((error) => {
					console.log('Error getting document:', error);
				});
		});

		//const docRef = db.doc(players);
		/*

		docRef
			.get()
			.then((doc) => {
				if (doc.exists) {
					console.log('Document data:', doc.data());
					db
						.collection('/2022Matches/')
						.doc('2022_ROUND_1_WEEK_7.2_TRFC_YLFC_SPL')
						.set(doc.data())
						.then(() => {
							console.log('Document successfully written!');
						})
						.catch((error) => {
							console.error('Error writing document: ', error);
						});
				} else {
					// doc.data() will be undefined in this case
					console.log('No such document!');
				}
			})
			.catch((error) => {
				console.log('Error getting document:', error);
			});

		db
			.collection('/2022Matches/2022_ROUND_1_WEEK_7.1_TRFC_YLFC_SPL/2022_Match_Predictions')
			.get()
			.then((querySnapshot) => {
				querySnapshot.forEach((doc2) => {
					// doc.data() is never undefined for query doc snapshots
					console.log(doc2.id, ' => ', doc2.data());
					db
						.collection('/2022Matches/2022_ROUND_1_WEEK_7.2_TRFC_YLFC_SPL/2022_Match_Predictions')
						.doc(doc2.id)
						.set(doc2.data())
						.then(() => {
							console.log('Document successfully written!');
						})
						.catch((error) => {
							console.error('Error writing document: ', error);
						});
				});
			});

		
		if ( docData ) {
		  db
			.doc( dest )
			.set( docData )
			.catch( ( error ) => {
			  console.error( 'Error creating document', `${dest}`, error.message );
			 
			} );
		}
		*/
		/*
	  
		const subcollections =  docRef.listCollections();
		for  ( const subcollectionRef of subcollections ) {
		  const subPath = `${source}/${subcollectionRef.id}`
		  console.log( 'parsing: ', subPath )
		  try {
			const snapshot =  subcollectionRef.get()
			const docs = snapshot.docs;
			for  ( const doc of docs ) {
			  console.log( `coping: ${subPath}/${doc.id} -> ${dest}/${subcollectionRef.id}/${doc.id}` )
				copyMatch( `${subPath}/${doc.id}`, `${dest}/${subcollectionRef.id}/${doc.id}` );
			}
		  } catch ( e ) {
			console.log(e);
		  }
		}
		*/
	}

	function gamersTotalPoints() {
		console.log('Fix Gamers Total Points');

		var batch = db.batch();

		db
			.collection('FantasyLeague/2022/gamers')
			.get()
			.then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					// doc.data() is never undefined for query doc snapshots
					//console.log(doc.id, ' => ', doc.data());
					var gamer = doc.data();
					var totalPoints = 0;
					if (gamer.weekly_points !== undefined) {
						for (var week of Object.keys(gamer.weekly_points)) {
							totalPoints = totalPoints + gamer.weekly_points[week];
						}

						var gamersRef = db.collection("FantasyLeague/2022/gamers").doc(doc.id);
						batch.set(gamersRef, {total_fl_points: totalPoints}, { merge: true });

						console.log(totalPoints);
						if (gamer.total_fl_points !== totalPoints)
							console.log(gamer.uid + ' points Not tallied. Current: ' + gamer.total_fl_points + ' recal: ' + totalPoints );
						else 
							console.log(gamer.uid + ' tallied. Current: ' + gamer.total_fl_points + ' recal: ' + totalPoints);
					}
					else {
						console.log(gamer.uid + ' does not have fl points');
					}
				
				});

				batch.commit().then(() => {
					console.log('Games points updated');
				});
			})
			.catch((error) => {
				console.log('Error getting documents: ', error);
			});
	}
	return (
		<StyledGrid container spacing={3} className={classes.root}>
			{authorized ? (
				<div>
					<Grid item xs={12}>
						<Typography variant='h5' align='center'>
							Fantasy League Admin Diagnostics Dashboard 2021
						</Typography>
					</Grid>
					<Grid>
						<Box m={16} />
						<Typography variant='h5' align='left'>
							Find a Player
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Box m={2} />

						<Button variant='contained' color='primary' onClick={(e) => updatePlayersStats(e)}>
							Update Players total Stats
						</Button>
					</Grid>

					<Grid item xs={12}>
						<Box m={2} />

						<Button variant='contained' color='primary' onClick={(e) => checkBudgetLimit(e)}>
							Check Budget Limit
						</Button>
					</Grid>

					<Grid item xs={12}>
						<Box m={2} />
						<Button variant='contained' color='primary' onClick={(e) => checkPlayersTeam(e)}>
							Check Player in teams
						</Button>
					</Grid>
					<Grid item xs={12}>
						<Box m={2} />
						<Button variant='contained' color='primary' onClick={(e) => minileagueUpdate(e)}>
							Mini-League Update
						</Button>
					</Grid>
					<Grid item xs={12}>
						<Box m={2} />
						<Button variant='contained' color='primary' onClick={(e) => addPlayerToMatch(e)}>
							Add Player to Match
						</Button>
					</Grid>
					<Grid item xs={12}>
						<Box m={2} />
						<Button
							variant='contained'
							color='primary'
							onClick={(e) =>
								copyMatch(
									'/2022Matches/2022_ROUND_1_WEEK_7.1_TRFC_YLFC_SPL',
									'/2022Matches/2022_ROUND_1_WEEK_7.2_TRFC_YLFC_SPL'
								)}
						>
							Copy Match{' '}
						</Button>
					</Grid>

					<Grid item xs={12}>
						<Box m={2} />
						<Button
							variant='contained'
							color='primary'
							onClick={(e) =>
								transferPlayers([
									'/2022Teams/LCSF/2022Players/7eba34dc6420a52ec1e835939d0802156ee683e9_Tajeli_Salamat_3'
								])}
						>
							Transfer Players
						</Button>
					</Grid>

					<Grid>
						<Box m={16} />
						<Typography variant='h5' align='left'>
							Summary No of FL Gamers: {gamers.length}
						</Typography>

						<Grid container spacing={1}>
							{gamers.map((row, index) => (
								<Grid item xs={3} key={index}>
									<Typography variant='caption'>
										{index + 1} {row.team_name} FL Pts: {row.total_fl_points}
									</Typography>
								</Grid>
							))}
						</Grid>
					</Grid>

					<Grid item xs={12}>
						<Box m={2} />
						<Button variant='contained' color='primary' onClick={(e) => gamersTotalPoints()}>
							Fix Gamers Total Points
						</Button>
					</Grid>
				</div>
			) : (
				<div />
			)}
		</StyledGrid>
	);
}

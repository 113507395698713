import React, { Component } from 'react'
import FantasyLeagueTeam from '../component/FantasyLeagueTeam'

class fantasyleagueteam extends Component {
    render() {
        return (
          <div>
            <FantasyLeagueTeam/>
          </div>
        )
    }
}

export default fantasyleagueteam;

import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { useEffect } from 'react';
import { useSession } from '../App';
import { Grid, Button, Avatar } from '@mui/material';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Rating from '@mui/material/Rating';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import firebase from '../firebase';

const PREFIX = 'MatchRatings';

const classes = {
    root: `${PREFIX}-root`,
    rootpastrating: `${PREFIX}-rootpastrating`,
    coverpastrating: `${PREFIX}-coverpastrating`,
    contentpastrating: `${PREFIX}-contentpastrating`,
    detailspastrating: `${PREFIX}-detailspastrating`,
    card: `${PREFIX}-card`,
    paperContainer: `${PREFIX}-paperContainer`,
    large: `${PREFIX}-large`,
    cover: `${PREFIX}-cover`,
    ratings: `${PREFIX}-ratings`,
    heading: `${PREFIX}-heading`,
    secondaryHeading: `${PREFIX}-secondaryHeading`,
    formControl: `${PREFIX}-formControl`,
    selectEmpty: `${PREFIX}-selectEmpty`,
    avatarlarge: `${PREFIX}-avatarlarge`,
    media: `${PREFIX}-media`,
    square: `${PREFIX}-square`,
    large2: `${PREFIX}-large2`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`&.${classes.root}`]: {
		flexGrow: 1
	},

    [`& .${classes.rootpastrating}`]: {
		display: 'flex'
	},

    [`& .${classes.coverpastrating}`]: {
		width: 100,
		height: 150
	},

    [`& .${classes.contentpastrating}`]: {
		flex: '1 0 auto'
	},

    [`& .${classes.detailspastrating}`]: {
		display: 'flex',
		flexDirection: 'column'
	},

    [`& .${classes.card}`]: {
		maxWidth: 600
	},

    [`& .${classes.paperContainer}`]: {
		height: 300
	},

    [`& .${classes.large}`]: {
		width: theme.spacing(7),
		height: theme.spacing(7)
	},

    [`& .${classes.cover}`]: {
		width: 100,
		height: 100
	},

    [`& .${classes.ratings}`]: {
		width: 200,
		display: 'flex',
		alignItems: 'center'
	},

    [`& .${classes.heading}`]: {
		fontSize: theme.typography.pxToRem(15),
		flexBasis: '33.33%',
		flexShrink: 0
	},

    [`& .${classes.secondaryHeading}`]: {
		fontSize: theme.typography.pxToRem(15),
		color: theme.palette.text.secondary
	},

    [`& .${classes.formControl}`]: {
		margin: theme.spacing(1),
		minWidth: 120
	},

    [`& .${classes.selectEmpty}`]: {
		marginTop: theme.spacing(2)
	},

    [`& .${classes.avatarlarge}`]: {
		width: theme.spacing(7),
		height: theme.spacing(7)
	},

    [`& .${classes.media}`]: {
		height: 200, // as an example I am modifying width and height
		width: 200
	},

    [`& .${classes.square}`]: {
		color: '#000000',
		backgroundColor: '#ffffff'
	},

    [`& .${classes.large2}`]: {
		width: theme.spacing(7),
		height: theme.spacing(7)
	}
}));

const labels = {
	1: 'Useless',
	2: 'Useless+',
	3: 'Poor',
	4: 'Poor+',
	5: 'Ok',
	6: 'Ok+',
	7: 'Good',
	8: 'Good+',
	9: 'Immerse',
	10: 'Immerse+'
};

function Alert(props) {
	return <MuiAlert elevation={6} variant='filled' {...props} />;
}

export default function MatchRatings() {
	const user = useSession();


	const [ matchHomePlayers, setMatchHomePlayers ] = useState([]);
	const [ matchAwayPlayers, setMatchAwayPlayers ] = useState([]);
	const [ openHomeTeamSnackbar, setOpenHomeTeamSnackbar ] = React.useState(false);
	const [ openAwayTeamSnackbar, setOpenAwayTeamSnackbar ] = React.useState(false);
	const [ openHomeTeamErrorSnackbar, setOpenHomeTeamErrorSnackbar ] = React.useState(false);
	const [ openAwayTeamErrorSnackbar, setOpenAwayTeamErrorSnackbar ] = React.useState(false);
	const [ expanded, setExpanded ] = React.useState('matchdaypanel');
	const [ completedMatches, setCompletedMatches ] = useState([]);
	const [ banner, setBanner ] = useState('Closed until match completion');
	const [ week, setWeek ] = useState(0);

	const [ bannerPictureURL, setBannerPictureURL ] = useState(
		'https://firebasestorage.googleapis.com/v0/b/voiddeckfootie.appspot.com/o/voiddeckfootie_banner%2Fneutral%2Fplayer_ratings_neutral.jpg?alt=media&token=d92d80da-69e8-4b14-b167-67894842ba72'
	);

	useEffect(() => {
		const setPageBanner = () => {
			console.log('User Club: ' + user.club);
			if (user.club === 'Neutral') {
				setBannerPictureURL(
					'https://firebasestorage.googleapis.com/v0/b/voiddeckfootie.appspot.com/o/voiddeckfootie_banner%2Fneutral%2Fplayer_ratings_neutral.jpg?alt=media&token=d92d80da-69e8-4b14-b167-67894842ba72'
				);
			}
			if (user.club === 'GIFC') {
				setBannerPictureURL(
					'https://firebasestorage.googleapis.com/v0/b/voiddeckfootie.appspot.com/o/voiddeckfootie_banner%2Fgifc.banner.ratings.banner_rating.jpg?alt=media&token=e9d001e6-e051-4687-9382-0d4d574aa93c'
				);
			} else if (user.club === 'TRFC') {
				setBannerPictureURL(
					'https://firebasestorage.googleapis.com/v0/b/voiddeckfootie.appspot.com/o/voiddeckfootie_banner%2Ftrfc%2Ftampines_player_ratings.jpeg?alt=media&token=93783766-f53e-4ae4-9e73-4df92aa12a19'
				);
			}
		};
		setPageBanner();

		const getGamesWithOpenPlayersRating = async () => {
			const db = firebase.firestore();
			const data = await db
				.collection('2021Matches')
				.where('players_rating_status', '==', 'open')
				.get()
				.then(function(querySnapshot) {
					console.log('No of open ratings: ' + querySnapshot.size);
					if (querySnapshot.size > 0) {
						setCompletedMatches(querySnapshot.docs.map((match) => match.data()));
						querySnapshot.docs.map((match) => {
							setBanner('Week ' + match.get('match_week'));
							setWeek(match.get('match_week'));
						});
					} else {
						console.log('-- No Player Ratings Poll Opened --');
						setBanner('Ratings closed until match completion');
					}
				})
				.catch(function(error) {
					console.log('Error getting documents: ', error);
				});
		};
		getGamesWithOpenPlayersRating();
	}, []);

	const handleHomeTeamSnackbarClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenHomeTeamSnackbar(false);
	};

	const handleAwayTeamSnackbarClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenAwayTeamSnackbar(false);
	};

	const handleHomeTeamErrorSnackbarClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpenHomeTeamErrorSnackbar(false);
	};

	const handleAwayTeamErrorSnackbarClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpenAwayTeamErrorSnackbar(false);
	};

	const handleAccordianChange = (panel) => (event, isExpanded) => {
		console.log('expaned ' + isExpanded + ' ' + panel);

		setExpanded(isExpanded ? panel : false);

		console.log('Week: ' + week + ' -> ' + panel.indexOf(week) + ' -> week length: ' + week.toString().length);
		var homeTeam = '';
		
		if (week.toString().length === 3) {
			homeTeam = panel.substring(22, 26);
		} else if (week.toString().length === 2) {
			homeTeam = panel.substring(21, 25);
		} else {
			homeTeam = panel.substring(20, 24);
		}

		console.log('get home team players: ' + homeTeam);
		var homePlayerArray = [];
		var awayPlayerArray = [];

		const db = firebase.firestore();
		const dataHomeTeam = db
			.collection('2021Matches/' + panel + '/' + homeTeam + '_PLAYERS')
			.get()
			.then(function(querySnapshot) {
				querySnapshot.forEach(function(doc) {
					let data = { ...doc.data() };
					homePlayerArray.push(data);
				});
				setMatchHomePlayers(homePlayerArray);
				//console.log(homePlayerArray);
			})
			.catch((err) => console.log(err));

		//var awayTeam = "";
		//if (panel.indexOf('YL') === -1)
		//	awayTeam = panel.substring(18, 22);
		//else
		var awayTeam = '';
		if (week.toString().length === 3) {
			awayTeam = panel.substring(27, 31);
		} else if (week.toString().length === 2) {
			awayTeam = panel.substring(26, 30);
		}else {
			awayTeam = panel.substring(25, 29);
		}

		console.log(panel);
		console.log('get away team players:' + awayTeam);
		const dataAwayTeam = db
			.collection('2021Matches/' + panel + '/' + awayTeam + '_PLAYERS')
			.get()
			.then(function(querySnapshot) {
				querySnapshot.forEach(function(doc) {
					let data = { ...doc.data() };
					awayPlayerArray.push(data);
				});
				setMatchAwayPlayers(awayPlayerArray);
				//console.log(awayPlayerArray);
			})
			.catch((err) => console.log(err));
	};

	//TODO
	const handleAwayPlayerRatingSubmit = (match_id) => {
		//console.log('ratings: ' + homePlayerRatings.length);
		var awayTeam = '';
		if (week.toString().length === 3) {
			awayTeam = match_id.substring(27, 31);
		} else if (week.toString().length === 2) {
			awayTeam = match_id.substring(26, 30);
		} else {
			awayTeam = match_id.substring(25, 29);
		}
		console.log('Away Team Submit: ' + awayTeam);
		console.log('Match id: ' + match_id);

		var noOfUnRatedPlayers = 0;
		matchAwayPlayers.forEach((player, index) => {
			if (player.rating == undefined) {
				noOfUnRatedPlayers = noOfUnRatedPlayers + 1;
			}
		});

		if (noOfUnRatedPlayers > 0) {
			console.log('Not all away players are rated');
			setOpenAwayTeamErrorSnackbar(true);
		} else {
			console.log('Submitting Away Player Ratings');

			const db = firebase.firestore();
			matchAwayPlayers.map((player) => {
				const data = {
					user_id: user.uid,
					player_id: player.id,
					player_rating: player.rating,
					player_firstname: player.firstname
				};

				const addRatings = db
					.collection('2021Matches')
					.doc(match_id)
					.collection(awayTeam + '_PLAYERS_USER_RATINGS')
					.doc(player.id + '_' + user.uid + '_rating')
					.set(data, { merge: true })
					.then(console.log(player.id + ' - ' + player.rating + ' - ' + user.uid + 'added.'))
					.catch((err) => console.log(err));
			});
			setOpenAwayTeamSnackbar(true);
		}
	};

	const handleHomePlayerRatingSubmit = (match_id) => {
		//console.log('ratings: ' + homePlayerRatings.length);
		
		var homeTeam = '';
		if (week.length === 3) {
			homeTeam = match_id.substring(22, 26);
		} else if (week.toString().length === 2) {
			homeTeam = match_id.substring(21, 25);
		} else {
			homeTeam = match_id.substring(20, 24);
		}

		console.log(homeTeam);
		console.log('Match id: ' + match_id);

		var noOfUnRatedPlayers = 0;
		matchHomePlayers.forEach((player, index) => {
			if (player.rating == undefined) {
				noOfUnRatedPlayers = noOfUnRatedPlayers + 1;
			}
		});
		if (noOfUnRatedPlayers > 0) {
			console.log('Not all homeplayers are rated');
			setOpenHomeTeamErrorSnackbar(true);
		} else {
			console.log('Submitting Home Player Ratings');

			const db = firebase.firestore();
			matchHomePlayers.map((player) => {
				const data = {
					user_id: user.uid,
					player_id: player.id,
					player_rating: player.rating,
					player_firstname: player.firstname
				};

				const addRatings = db
					.collection('2021Matches')
					.doc(match_id)
					.collection(homeTeam + '_PLAYERS_USER_RATINGS')
					.doc(player.id + '_' + user.uid + '_rating')
					.set(data, { merge: true })
					.then(console.log(player.id + ' - ' + player.rating + ' - ' + user.uid + 'added.'))
					.catch((err) => console.log(err));
			});
			setOpenHomeTeamSnackbar(true);
		}
	};

	const handleAwayTeamRatingValueChange = (event, newValue) => {
		const playerId = event.target.name;
		var player = matchAwayPlayers.find((x) => x.id === playerId);
		if (player) {
			player.rating = newValue;
		}

		const index = matchAwayPlayers.findIndex((x) => x.id === playerId);
		console.log(index);
		console.log(player);
		//var playerUpdated = matchAwayPlayers.splice(index, 1, player)

		setMatchAwayPlayers(matchAwayPlayers.map((el) => (el.id === playerId ? { ...el, rating: newValue } : el)));
		//console.log(temp)
	};

	const handleHomeTeamRatingValueChange = (event, newValue) => {
		const playerId = event.target.name;

		var player = matchHomePlayers.find((x) => x.id === playerId);
		if (player) {
			player.rating = newValue;
		}

		setMatchHomePlayers(matchHomePlayers.map((el) => (el.id === playerId ? { ...el, rating: newValue } : el)));
		console.log(matchHomePlayers);
	};

	return (
        <Root className={classes.root}>
			<Grid container className={classes.root} spacing={3}>
				<Grid item xs={12}>
					<Card>
						<CardActionArea>
							<CardMedia
								className={classes.paperContainer}
								image={bannerPictureURL}
								title='Players Rating'
							/>
							<CardContent>
								<Typography gutterBottom variant='h5' component='h5' align='center'>
									Players Ratings
								</Typography>
								<Typography variant='h6' component='h2' align='center' gutterBottom>
									{banner}
								</Typography>
							</CardContent>
						</CardActionArea>
					</Card>
				</Grid>
				<Grid item xs={12}>
					{completedMatches.size === 0 ? (
						<div>
							<Typography>Player Ratings Are Not Opened</Typography>
						</div>
					) : (
						completedMatches.map((match, index) => (
							<Accordion
								key={index}
								onChange={handleAccordianChange(match.match_id)}
								expanded={expanded === match.match_id}
							>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls='panel1a-content'
									id='panel1a-header'
								>
									<Avatar src={match.hometeam_logo_url} className={classes.small} />{' '}
									<Avatar variant='square' className={classes.square}>
										{match.hometeam_score}
									</Avatar>
									<Avatar variant='square' className={classes.square}>
										-
									</Avatar>
									<Avatar variant='square' className={classes.square}>
										{match.awayteam_score}
									</Avatar>
									<Avatar src={match.awayteam_logo_url} className={classes.small} />
								</AccordionSummary>

								<AccordionDetails>
									<Grid container>
										<Grid item xs={12}>
											<Typography variant='h5' align='center'>
												Rate {match.hometeam_name} Players
											</Typography>
										</Grid>
										<Grid item xs={12}>
											<Box component='span' m={1} />
										</Grid>
										<Grid container spacing={3}>
											{matchHomePlayers.map((player, index) => (
												<Grid key={index} item xs={12}>
													<Card key={index} className={classes.root}>
														<CardContent
															id={player.player_id}
															className={classes.content}
															spacing={3}
														>
															<Grid container spacing={3}>
																<Grid item sx={6} align='center'>
																	<Avatar
																		src={player.photo_url}
																		className={classes.large}
																	/>
																</Grid>
																<Grid item sx={6} align='center'>
																	<Typography variant='body1'>
																		{player.firstname} ({player.squad_number})
																	</Typography>
																	<Typography variant='body2' gutterBottom>
																		{player.position}
																	</Typography>
																</Grid>
																<Grid item sx={6} align='center'>
																	Goal(s): {player.goals_scored} Assist(s):{player.assist}{' '}
																	Yellow: {player.yellow_card} Red: {player.red_card}
																</Grid>
															</Grid>
															<CardActionArea>
																<Box component='span' m={1} />

																<Typography>Give Your Ratings</Typography>
																<Rating
																	name={player.id}
																	max={10}
																	value={matchHomePlayers[index].rating}
																	precision={1}
																	onChange={(event, newValue) => {
																		handleHomeTeamRatingValueChange(
																			event,
																			newValue
																		);
																	}}
																/>
																<Typography>
																	Rating: {matchHomePlayers[index].rating}
																</Typography>
																<Typography>
																	{labels[matchHomePlayers[index].rating]}
																</Typography>
															</CardActionArea>
														</CardContent>
													</Card>
												</Grid>
											))}

											<Grid item xs={12}>
												{matchHomePlayers.length !== 0 ? (
													<div>
														<Button
															variant='contained'
															color='primary'
															fullWidth
															onClick={() => handleHomePlayerRatingSubmit(match.match_id)}
														>
															Submit
														</Button>
														<Grid item xs={12}>
															<Box component='span' m={1}>
																{' '}
															</Box>
														</Grid>
														<Snackbar
															open={openHomeTeamSnackbar}
															autoHideDuration={6000}
															onClose={handleHomeTeamSnackbarClose}
														>
															<Alert
																onClose={handleHomeTeamSnackbarClose}
																severity='success'
															>
																Ratings for {match.hometeam_name} successfully recorded.
															</Alert>
														</Snackbar>

														<Snackbar
															open={openHomeTeamErrorSnackbar}
															autoHideDuration={6000}
															onClose={handleHomeTeamErrorSnackbarClose}
														>
															<Alert
																onClose={handleHomeTeamErrorSnackbarClose}
																severity='error'
															>
																Must rate all {match.hometeam_name} players.
															</Alert>
														</Snackbar>
													</div>
												) : (
													<Typography variant='h6' align='center'>
														No Players {match.hometeam_name} Selected
													</Typography>
												)}
											</Grid>
										</Grid>
										<Grid item xs={12}>
											<Box component='span' m={1}>
												{' '}
											</Box>
										</Grid>
										<Grid item xs={12}>
											<Typography variant='h5' align='center'>
												Rate {match.awayteam_name} Players
											</Typography>
										</Grid>
										<Grid item xs={12}>
											<Box component='span' m={1} />
										</Grid>
										<Grid container spacing={3}>
											{matchAwayPlayers.map((player, index) => (
												<Grid key={index} item xs={12}>
													<Card key={index} className={classes.root}>
														<CardContent
															id={player.player_id}
															className={classes.content}
															spacing={3}
														>
															<Grid container spacing={3}>
																<Grid item sx={6} align='center'>
																	<Avatar
																		src={player.photo_url}
																		className={classes.large}
																	/>
																</Grid>
																<Grid item sx={6} align='center'>
																	<Typography variant='body1'>
																		{player.firstname} ({player.squad_number})
																	</Typography>
																	<Typography variant='body2' gutterBottom>
																		{player.position}
																	</Typography>
																</Grid>
																<Grid item sx={6} align='center'>
																	Goal(s): {player.goals_scored} Assist(s):{player.assist}{' '}
																	Yellow: {player.yellow_card} Red: {player.red_card}
																</Grid>
															</Grid>
															<CardActionArea>
																<Box component='span' m={1} />

																<Typography>Give Your Ratings</Typography>
																<Rating
																	name={player.id}
																	max={10}
																	value={matchAwayPlayers[index].rating}
																	precision={1}
																	onChange={(event, newValue) => {
																		handleAwayTeamRatingValueChange(
																			event,
																			newValue
																		);
																	}}
																/>

																<Typography>
																	Rating: {matchAwayPlayers[index].rating}
																</Typography>
																<Typography>
																	{labels[matchAwayPlayers[index].rating]}
																</Typography>
															</CardActionArea>
														</CardContent>
													</Card>
												</Grid>
											))}
											<Grid item xs={12}>
												{matchAwayPlayers.length !== 0 ? (
													<div>
														<Button
															variant='contained'
															color='primary'
															fullWidth
															onClick={() => handleAwayPlayerRatingSubmit(match.match_id)}
														>
															Submit
														</Button>

														<Snackbar
															open={openAwayTeamSnackbar}
															autoHideDuration={6000}
															onClose={handleAwayTeamSnackbarClose}
														>
															<Alert
																onClose={handleAwayTeamSnackbarClose}
																severity='success'
															>
																Ratings for {match.awayteam_name} successfully recorded.
															</Alert>
														</Snackbar>

														<Snackbar
															open={openAwayTeamErrorSnackbar}
															autoHideDuration={6000}
															onClose={handleAwayTeamErrorSnackbarClose}
														>
															<Alert
																onClose={handleAwayTeamErrorSnackbarClose}
																severity='error'
															>
																Must rate all {match.awayteam_name} players.
															</Alert>
														</Snackbar>
													</div>
												) : (
													<Typography variant='h6' align='center'>
														No Players {match.awayteam_name} Selected
													</Typography>
												)}
											</Grid>
										</Grid>
									</Grid>
								</AccordionDetails>
							</Accordion>
						))
					)}
				</Grid>
			</Grid>
		</Root>
    );
}

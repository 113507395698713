import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';

import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Select from '@mui/material/Select';

//Box
import Box from '@mui/material/Box';

//Menu
import MenuItem from '@mui/material/MenuItem';

//Form
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

//icons
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import anfcLogo from '../icons/anfc_50x50.jpeg';
import bkfcLogo from '../icons/bkfc_50x50.jpeg';
import gifcLogo from '../icons/gifc_50x50.jpeg';
import hufcLogo from '../icons/hufc_50x50.jpeg';
import lcsfLogo from '../icons/lcsf_50x50.jpeg';
import tpfcLogo from '../icons/tpfc_50x50.jpeg';
import trfcLogo from '../icons/trfc_50x50.jpeg';
import ylfcLogo from '../icons/ylfc_50x50.jpeg';

//Card
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';


import * as FirestoreService from '../firebase';
import {CURRENT_WEEK} from '../component/Constants';

const PREFIX = 'FantasyLeagueTotw';

const classes = {
    root: `${PREFIX}-root`,
    cardheader: `${PREFIX}-cardheader`,
    playercard: `${PREFIX}-playercard`,
    media: `${PREFIX}-media`,
    table: `${PREFIX}-table`,
    formControl: `${PREFIX}-formControl`,
    selectEmpty: `${PREFIX}-selectEmpty`,
    button: `${PREFIX}-button`,
    topPlayerCard: `${PREFIX}-topPlayerCard`,
    large: `${PREFIX}-large`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.root}`]: {
		flexGrow: 1
	},

    [`& .${classes.cardheader}`]: {
		maxHeight: 100
	},

    [`& .${classes.playercard}`]: {
		minWidth: 80,
		minHeight: 130,
		backgroundColor: 'transparent',
		border: 'none',
		boxShadow: 'none'
	},

    [`& .${classes.media}`]: {
		height: 140
	},

    [`& .${classes.table}`]: {
		minWidth: 450
	},

    [`& .${classes.formControl}`]: {
		margin: theme.spacing(1),
		minWidth: 120
	},

    [`& .${classes.selectEmpty}`]: {
		marginTop: theme.spacing(2)
	},

    [`& .${classes.button}`]: {
		margin: theme.spacing(1)
	},

    [`& .${classes.topPlayerCard}`]: {
		minHeight: 100,
		backgroundColor: 'transparent',
		border: 'none',
		boxShadow: 'none'
	},

    [`& .${classes.large}`]: {
		width: theme.spacing(7),
		height: theme.spacing(7)
	}
}));

export default function FantasyLeagueTransfer(props) {


	const [ firstElevenGk, setFirstElevenGk ] = useState();

	const [ firstElevenRb, setFirstElevenRb ] = useState();
	const [ firstElevenCb1, setFirstElevenCb1 ] = useState();
	const [ firstElevenCb2, setFirstElevenCb2 ] = useState();
	const [ firstElevenLb, setFirstElevenLb ] = useState();

	const [ firstElevenRm, setFirstElevenRm ] = useState();
	const [ firstElevenLm, setFirstElevenLm ] = useState();
	const [ firstElevenCm1, setFirstElevenCm1 ] = useState();
	const [ firstElevenCm2, setFirstElevenCm2 ] = useState();

	const [ firstElevenAt1, setFirstElevenAt1 ] = useState();
	const [ firstElevenAt2, setFirstElevenAt2 ] = useState();

	const [ selectedWeek, setSelectedWeek ] = useState(CURRENT_WEEK - 1);
	const [ topGoalkeepers, setTopGoalkeepers ] = useState([]);
	const [ topDefenders, setTopDefenders ] = useState([]);
	const [ topMidfielders, setTopMidfielders ] = useState([]);
	const [ topForwards, setTopForwards ] = useState([]);

	function getTeamLogo(player) {
		//TODO: there are different attributes used teamId and team_id.

		//console.log(player.fullname + ' Getting logo for: ' + player.team_id);
		if (player !== undefined) {
			var club = '';
			if (player.teamId === undefined) club = player.team_id;
			else club = player.teamId;

			if (club === 'ANFC') {
				return anfcLogo;
			} else if (club === 'BKFC') {
				return bkfcLogo;
			} else if (club === 'GIFC') {
				return gifcLogo;
			} else if (club === 'HUFC') {
				return hufcLogo;
			} else if (club === 'LCSF') {
				return lcsfLogo;
			} else if (club === 'TPFC') {
				return tpfcLogo;
			} else if (club === 'TRFC') {
				return trfcLogo;
			} else if (club === 'YLFC') {
				return ylfcLogo;
			}
		}
	}

	/**
	 * Fetch top players in various position for the week.
	 * 
	 * Goalkeeper : 1
	 * Defender: 4
	 * Midfielder: 4
	 * Forward: 4
	 */
	useEffect(() => {
		fetchGoalkeeper(selectedWeek);
		fetchTop4Defenders(selectedWeek);
		fetchTop4Midfielders(selectedWeek);
		fetchTop2Forwards(selectedWeek);
		fetchTopPlayers();
	}, []);

	const fetchTopPlayers = async () => {
		const gkRef = FirestoreService.db
			.collectionGroup('2022Players')
			.where('position', '==', 'Goalkeeper')
			.orderBy('fl_points', 'desc')
			.limit(13);
		gkRef
			.get()
			.then((querySnapshot) => {
				setTopGoalkeepers(querySnapshot.docs.map((players) => players.data()));
			})
			.catch((error) => {
				console.log('Error getting documents: ', error);
			});

		const dfRef = FirestoreService.db
			.collectionGroup('2022Players')
			.where('position', '==', 'Defender')
			.orderBy('fl_points', 'desc')
			.limit(13);
		dfRef
			.get()
			.then((querySnapshot) => {
				setTopDefenders(querySnapshot.docs.map((players) => players.data()));
			})
			.catch((error) => {
				console.log('Error getting documents: ', error);
			});

		const mfRef = FirestoreService.db
			.collectionGroup('2022Players')
			.where('position', '==', 'Midfielder')
			.orderBy('fl_points', 'desc')
			.limit(13);
		mfRef
			.get()
			.then((querySnapshot) => {
				setTopMidfielders(querySnapshot.docs.map((players) => players.data()));
			})
			.catch((error) => {
				console.log('Error getting documents: ', error);
			});

		const fwdRef = FirestoreService.db
			.collectionGroup('2022Players')
			.where('position', '==', 'Forward')
			.orderBy('fl_points', 'desc')
			.limit(13);
		fwdRef
			.get()
			.then((querySnapshot) => {
				setTopForwards(querySnapshot.docs.map((players) => players.data()));
			})
			.catch((error) => {
				console.log('Error getting documents: ', error);
			});
	};

	const fetchGoalkeeper = async (week) => {
		const response = FirestoreService.db
			.collection('/FantasyLeague/2022/fl_players_week_' + week)
			.where('position', '==', 'Goalkeeper')
			.orderBy('fl_points', 'desc')
			.limit(1);

		var goalkeeper;
		response
			.get()
			.then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					goalkeeper = doc.data();
				});
				setFirstElevenGk(goalkeeper);
			})
			.catch((error) => {
				console.log('Error getting documents: ', error);
			});
	};

	const fetchTop4Defenders = async (week) => {
		const response = FirestoreService.db
			.collection('/FantasyLeague/2022/fl_players_week_' + week)
			.where('position', '==', 'Defender')
			.orderBy('fl_points', 'desc')
			.limit(4);

		var index = 0;
		response
			.get()
			.then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					console.log(' ----  ' + JSON.stringify(doc.data()));

					console.log(' ----  ' + doc.data().position);
					if (index === 0) {
						setFirstElevenRb(doc.data());
					} else if (index === 1) {
						setFirstElevenLb(doc.data());
					} else if (index === 2) {
						setFirstElevenCb1(doc.data());
					} else if (index === 3) {
						setFirstElevenCb2(doc.data());
					}
					index++;
				});
			})
			.catch((error) => {
				console.log('Error getting documents: ', error);
			});
	};

	const fetchTop4Midfielders = async (week) => {
		const response = FirestoreService.db
			.collection('/FantasyLeague/2022/fl_players_week_' + week)
			.where('position', '==', 'Midfielder')
			.orderBy('fl_points', 'desc')
			.limit(4);

		var index = 0;
		response
			.get()
			.then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					if (index === 0) {
						setFirstElevenLm(doc.data());
					} else if (index === 1) {
						setFirstElevenRm(doc.data());
					} else if (index === 2) {
						setFirstElevenCm1(doc.data());
					} else if (index === 3) {
						setFirstElevenCm2(doc.data());
					}
					index++;
				});
			})
			.catch((error) => {
				console.log('Error getting documents: ', error);
			});
	};

	const fetchTop2Forwards = async (week) => {
		const response = FirestoreService.db
			.collection('/FantasyLeague/2022/fl_players_week_' + week)
			.where('position', '==', 'Forward')
			.orderBy('fl_points', 'desc')
			.limit(2);

		var index = 0;
		response
			.get()
			.then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					if (index === 0) {
						setFirstElevenAt1(doc.data());
					} else if (index === 1) {
						setFirstElevenAt2(doc.data());
					}
					index++;
				});
				//setFirstElevenGk(goalkeeper)
			})
			.catch((error) => {
				console.log('Error getting documents: ', error);
			});
	};

	const handleSelectWeekChange = (event) => {
		setSelectedWeek(event.target.value);
		fetchGoalkeeper(event.target.value);
		fetchTop4Defenders(event.target.value);
		fetchTop4Midfielders(event.target.value);
		fetchTop2Forwards(event.target.value);
	};

	const backgroundTheme = {
		paperContainer: {
			backgroundImage: `url(${'/images/Football-pitch.png'})`, //https://johnburnmurdoch.github.io/football-pitch-tracker/half-rotate.png
			backgroundSize: '100% 100%'
		}
	};

	const backgroundStats = {
		topGoalkeeper: {
			backgroundImage: `url(${'/images/Top-goalkeeper.png'})`,
			backgroundSize: '100% 100%'
		},
		topDefender: {
			backgroundImage: `url(${'/images/Top-defender.png'})`,
			backgroundSize: '100% 100%'
		},
		topMidfielder: {
			backgroundImage: `url(${'/images/Top-midfielder.png'})`,
			backgroundSize: '100% 100%'
		},
		topAttacker: {
			backgroundImage: `url(${'/images/Top-attacker.png'})`,
			backgroundSize: '100% 100%'
		},
		statsBackground: {
			backgroundColor: '#D9D9D9'
		}
	};

	return (
        <Root>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Card className={classes.card_header}>
						<CardMedia
							image="/images/Totw-banner.jpeg" //https://fas-org-sg.s3.ap-southeast-1.amazonaws.com/fas-competition/wp-content/uploads/2021/02/23142945/2021-SPL-8-club-logos-3x2-1.jpg
							title="Totw Header"
							component="img"
						/>
						<Grid container spacing={1} align="center" justifyContent="center" alignItems="center">
							<Grid item xs={12}>
								<Typography variant="h6" align="center">
									Team Of The Week and Statistics
								</Typography>
							</Grid>
						</Grid>
						<CardContent>
							<Grid container spacing={1} align="center" justifyContent="center" alignItems="center">
								<Grid item xs={12}>
									<FormControl className={classes.formControl}>
										<InputLabel id="demo-simple-select-label">Week</InputLabel>
										<Select
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											value={selectedWeek}
											onChange={handleSelectWeekChange}
										>
											<MenuItem value={'1'}>1</MenuItem>
											<MenuItem value={'2'}>2</MenuItem>
											<MenuItem value={'3'}>3</MenuItem>
											<MenuItem value={'4'}>4</MenuItem>
											<MenuItem value={'5'}>5</MenuItem>
											<MenuItem value={'6'}>6</MenuItem>
											<MenuItem value={'7'}>7</MenuItem>
											<MenuItem value={'7.1'}>7.1</MenuItem>
											<MenuItem value={'7.2'}>7.2</MenuItem>
											<MenuItem value={'8'}>8</MenuItem>
											<MenuItem value={'9'}>9</MenuItem>
											<MenuItem value={'10'}>10</MenuItem>
											<MenuItem value={'11'}>11</MenuItem>
											<MenuItem value={'12'}>12</MenuItem>
											<MenuItem value={'12.1'}>12.1</MenuItem>
											<MenuItem value={'13'}>13</MenuItem>
											<MenuItem value={'13.1'}>13.1</MenuItem>
											<MenuItem value={'14'}>14</MenuItem>
											<MenuItem value={'15'}>15</MenuItem>
											<MenuItem value={'15.1'}>15.1</MenuItem>
											<MenuItem value={'16'}>16</MenuItem>
											<MenuItem value={'17'}>17</MenuItem>
											<MenuItem value={'18'}>18</MenuItem>
											<MenuItem value={'18.1'}>18.1</MenuItem>
											<MenuItem value={'19'}>19</MenuItem>
											<MenuItem value={'19.1'}>19.1</MenuItem>
											<MenuItem value={'20'}>20</MenuItem>
											<MenuItem value={'21'}>21</MenuItem>
											<MenuItem value={'21.1'}>21.1</MenuItem>
											<MenuItem value={'21'}>21</MenuItem>
											<MenuItem value={'22'}>22</MenuItem>
											<MenuItem value={'23'}>23</MenuItem>
											<MenuItem value={'24'}>24</MenuItem>
											<MenuItem value={'25'}>25</MenuItem>
											<MenuItem value={'26'}>26</MenuItem>
											<MenuItem value={'27'}>27</MenuItem>
											<MenuItem value={'28'}>28</MenuItem>


















																					{/*

								
										<MenuItem value={22}>22</MenuItem>

										*/}
										</Select>
									</FormControl>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
				</Grid>

				<Grid item xs={12} align="center">
					<Typography variant="h6">Team of the Week</Typography>
				</Grid>

				<span> &nbsp; </span>

				<Grid
					container
					alignContent="center"
					justifyContent="center"
					alignItems="center"
					spacing={2}
					style={backgroundTheme.paperContainer}
				>
					{/* GOALKEEPER */}
					<Grid item xs={3}>
						<Card className={classes.playercard} align="center">
							<Typography align="center" variant="caption">
								GK
							</Typography>
							{firstElevenGk ? (
								<div>
									<Avatar src={getTeamLogo(firstElevenGk)} className={classes.large}>
										{' '}
									</Avatar>
									<Typography variant="caption" gutterBottom>
										{firstElevenGk.fullname}
									</Typography>
									<Box margin={1} />
									<Typography variant="caption" gutterBottom>
										Points: {firstElevenGk.fl_points}
									</Typography>
								</div>
							) : (
								<div />
							)}
						</Card>
					</Grid>
					{/* DEFENDERS SECTION */}
					<Grid item xs={12}>
						<Grid container spacing={1}>
							{/* RightBack */}
							<Grid item xs={3}>
								<Card className={classes.playercard} align="center">
									<Typography align="center" variant="caption" gutterBottom>
										Defenders
									</Typography>

									{firstElevenRb ? (
										<div>
											<Avatar src={getTeamLogo(firstElevenRb)} className={classes.large}>
												{' '}
											</Avatar>
											<Typography variant="caption" gutterBottom>
												{firstElevenRb.fullname}
											</Typography>
											<Box margin={1} />
											<Typography variant="caption" gutterBottom>
												Points: {firstElevenRb.fl_points}
											</Typography>
										</div>
									) : (
										<div />
									)}
								</Card>
							</Grid>

							{/* CenterBack1 */}
							<Grid item xs={3}>
								<Card className={classes.playercard} align="center">
									<Typography align="center" variant="caption" gutterBottom>
										Defenders
									</Typography>

									{firstElevenCb1 ? (
										<div>
											<Avatar src={getTeamLogo(firstElevenCb1)} className={classes.large}>
												{' '}
											</Avatar>
											<Typography variant="caption" gutterBottom>
												{firstElevenCb1.fullname}
											</Typography>
											<Box margin={1} />
											<Typography variant="caption" gutterBottom>
												Points: {firstElevenCb1.fl_points}
											</Typography>
										</div>
									) : (
										<div />
									)}
								</Card>
							</Grid>

							{/* CenterBack2 */}
							<Grid item xs={3}>
								<Card className={classes.playercard} align="center">
									<Typography align="center" variant="caption" gutterBottom>
										Defenders
									</Typography>

									{firstElevenCb2 ? (
										<div>
											<Avatar src={getTeamLogo(firstElevenCb2)} className={classes.large}>
												{' '}
											</Avatar>
											<Typography variant="caption" gutterBottom>
												{firstElevenCb2.fullname}
											</Typography>
											<Box margin={1} />
											<Typography variant="caption" gutterBottom>
												Points: {firstElevenCb2.fl_points}
											</Typography>
										</div>
									) : (
										<div />
									)}
								</Card>
							</Grid>
							{/* Left Defender */}
							<Grid item xs={3}>
								<Card className={classes.playercard} align="center">
									<Typography align="center" variant="caption" gutterBottom>
										Defenders
									</Typography>

									{firstElevenLb ? (
										<div>
											<Avatar src={getTeamLogo(firstElevenLb)} className={classes.large}>
												{' '}
											</Avatar>
											<Typography variant="caption" gutterBottom>
												{firstElevenLb.fullname}
											</Typography>
											<Box margin={1} />
											<Typography variant="caption" gutterBottom>
												Points: {firstElevenLb.fl_points}
											</Typography>
										</div>
									) : (
										<div />
									)}
								</Card>
							</Grid>
						</Grid>
					</Grid>
					{/* MIDFIELDERS SECTION */}
					<Grid item xs={12}>
						<Grid container spacing={1}>
							{/* Right Midfielder */}
							<Grid item xs={3}>
								<Card className={classes.playercard} align="center">
									<Typography align="center" variant="caption" gutterBottom>
										Midfielders
									</Typography>

									{firstElevenRm ? (
										<div>
											<Avatar src={getTeamLogo(firstElevenRm)} className={classes.large}>
												{' '}
											</Avatar>
											<Typography variant="caption" gutterBottom>
												{firstElevenRm.fullname}
											</Typography>
											<Box margin={1} />
											<Typography variant="caption" gutterBottom>
												Points: {firstElevenRm.fl_points}
											</Typography>
										</div>
									) : (
										<div />
									)}
								</Card>
							</Grid>
							{/* Central Midfielder 1 */}
							<Grid item xs={3}>
								<Card className={classes.playercard} align="center">
									<Typography align="center" variant="caption" gutterBottom>
										Midfielders
									</Typography>

									{firstElevenCm1 ? (
										<div>
											<Avatar src={getTeamLogo(firstElevenCm1)} className={classes.large}>
												{' '}
											</Avatar>
											<Typography variant="caption" gutterBottom>
												{firstElevenCm1.fullname}
											</Typography>
											<Box margin={1} />
											<Typography variant="caption" gutterBottom>
												Points: {firstElevenCm1.fl_points}
											</Typography>
										</div>
									) : (
										<div />
									)}
								</Card>
							</Grid>
							{/* Central Midfielder 2 */}
							<Grid item xs={3}>
								<Card className={classes.playercard} align="center">
									<Typography align="center" variant="caption" gutterBottom>
										Midfielders
									</Typography>

									{firstElevenCm2 ? (
										<div>
											<Avatar src={getTeamLogo(firstElevenCm2)} className={classes.large}>
												{' '}
											</Avatar>
											<Typography variant="caption" gutterBottom>
												{firstElevenCm2.fullname}
											</Typography>
											<Box margin={1} />
											<Typography variant="caption" gutterBottom>
												Points: {firstElevenCm2.fl_points}
											</Typography>
										</div>
									) : (
										<div />
									)}
								</Card>
							</Grid>
							{/* Left Midfielder */}
							<Grid item xs={3}>
								<Card className={classes.playercard} align="center">
									<Typography align="center" variant="caption" gutterBottom>
										Midfielders
									</Typography>

									{firstElevenLm ? (
										<div>
											<Avatar src={getTeamLogo(firstElevenLm)} className={classes.large}>
												{' '}
											</Avatar>
											<Typography variant="caption" gutterBottom>
												{firstElevenLm.fullname}
											</Typography>
											<Box margin={1} />
											<Typography variant="caption" gutterBottom>
												Points: {firstElevenLm.fl_points}
											</Typography>
										</div>
									) : (
										<div />
									)}
								</Card>
							</Grid>
						</Grid>
					</Grid>

					{/* ATTACKER SECTION * No Grid Container needed/}
					{/* Attacker 1 */}
					<Grid item xs={3}>
						<Card className={classes.playercard} align="center">
							<Typography align="center" variant="caption" gutterBottom>
								Attacker
							</Typography>

							{firstElevenAt1 ? (
								<div>
									<Avatar src={getTeamLogo(firstElevenAt1)} className={classes.large}>
										{' '}
									</Avatar>
									<Typography variant="caption" gutterBottom>
										{firstElevenAt1.fullname}
									</Typography>
									<Box margin={1} />
									<Typography variant="caption" gutterBottom>
										Points: {firstElevenAt1.fl_points}
									</Typography>
								</div>
							) : (
								<div />
							)}
						</Card>
					</Grid>
					{/* Attacker 2 */}
					<Grid item xs={3}>
						<Card className={classes.playercard} align="center">
							<Typography align="center" variant="caption" gutterBottom>
								Attacker
							</Typography>

							{firstElevenAt2 ? (
								<div>
									<Avatar src={getTeamLogo(firstElevenAt2)} className={classes.large}>
										{' '}
									</Avatar>
									<Typography variant="caption" gutterBottom>
										{firstElevenAt2.fullname}
									</Typography>
									<Box margin={1} />
									<Typography variant="caption" gutterBottom>
										Points: {firstElevenAt2.fl_points}
									</Typography>
								</div>
							) : (
								<div />
							)}
						</Card>
					</Grid>
				</Grid>

				<span> &nbsp; </span>

				<Grid container alignContent="center" justifyContent="center" alignItems="center" spacing={2}>
					<Grid item xs={12}>
						<Card className={classes.root} align="center">
							<Typography variant="h6" gutterBottom>
								Statistics
							</Typography>
						</Card>
					</Grid>
					<Grid item xs={12}>
						<Card className={classes.root} align="center">
							<Grid container spacing={1} align="row">
								{topGoalkeepers.map(
									(player, index) =>
										index === 0 ? (
											<Grid item xs={12} key={index} style={backgroundStats.topGoalkeeper}>
												<Card className={classes.topPlayerCard}>
													<SportsSoccerIcon fontSize="small" />
													<Typography variant="caption">Leader</Typography>
													<SportsSoccerIcon fontSize="small" />
													<Box margin={1} />
													<Typography variant="caption">{index + 1}</Typography>
													<Avatar src={getTeamLogo(player)} className={classes.large}>
														{' '}
													</Avatar>
													<Typography variant="caption" gutterBottom>
														{player.firstname}
													</Typography>
													<Box margin={1} />
													<Typography variant="caption" gutterBottom>
														Points: {player.fl_points}
													</Typography>
												</Card>
											</Grid>
										) : (
											<Grid item xs={3} key={index} style={backgroundStats.statsBackground}>
												<Card className={classes.topPlayerCard}>
													<Box margin={1} />
													<Typography variant="caption">{index + 1}</Typography>
													<Avatar src={getTeamLogo(player)} className={classes.large}>
														{' '}
													</Avatar>
													<Typography variant="caption" gutterBottom>
														{player.firstname}
													</Typography>
													<Box margin={1} />
													<Typography variant="caption" gutterBottom>
														Points: {player.fl_points}
													</Typography>
												</Card>
											</Grid>
										)
								)}
							</Grid>
						</Card>
					</Grid>

					<Grid item xs={12}>
						<Card className={classes.root} align="center">
							<Grid container spacing={1} align="row">
								{topDefenders.map(
									(player, index) =>
										index === 0 ? (
											<Grid item xs={12} key={index} style={backgroundStats.topDefender}>
												<Card className={classes.topPlayerCard}>
													<SportsSoccerIcon fontSize="small" />
													<Typography variant="caption">Leader</Typography>
													<SportsSoccerIcon fontSize="small" />
													<Box margin={1} />
													<Typography variant="caption">{index + 1}</Typography>
													<Avatar src={getTeamLogo(player)}> </Avatar>
													<Typography variant="caption" gutterBottom>
														{player.firstname}
													</Typography>
													<Box margin={1} />
													<Typography variant="caption" gutterBottom>
														Points: {player.fl_points}
													</Typography>
												</Card>
											</Grid>
										) : (
											<Grid item xs={3} key={index} style={backgroundStats.statsBackground}>
												<Card className={classes.topPlayerCard}>
													<Box margin={1} />
													<Typography variant="caption">{index + 1}</Typography>
													<Avatar src={getTeamLogo(player)}> </Avatar>
													<Typography variant="caption" gutterBottom>
														{player.firstname}
													</Typography>
													<Box margin={1} />
													<Typography variant="caption" gutterBottom>
														Points: {player.fl_points}
													</Typography>
												</Card>
											</Grid>
										)
								)}
							</Grid>
						</Card>
					</Grid>

					<Grid item xs={12}>
						<Card className={classes.root} align="center">
							<Grid container spacing={1} align="row">
								{topMidfielders.map(
									(player, index) =>
										index === 0 ? (
											<Grid item xs={12} key={index} style={backgroundStats.topMidfielder}>
												<Card className={classes.topPlayerCard}>
													<SportsSoccerIcon fontSize="small" />
													<Typography variant="caption">Leader</Typography>
													<SportsSoccerIcon fontSize="small" />
													<Box margin={1} />
													<Typography variant="caption">{index + 1}</Typography>
													<Avatar src={getTeamLogo(player)}> </Avatar>
													<Typography variant="caption" gutterBottom>
														{player.firstname}
													</Typography>
													<Box margin={1} />
													<Typography variant="caption" gutterBottom>
														Points: {player.fl_points}
													</Typography>
												</Card>
											</Grid>
										) : (
											<Grid item xs={3} key={index} style={backgroundStats.statsBackground}>
												<Card className={classes.topPlayerCard}>
													<Box margin={1} />
													<Typography variant="caption">{index + 1}</Typography>
													<Avatar src={getTeamLogo(player)}> </Avatar>
													<Typography variant="caption" gutterBottom>
														{player.firstname}
													</Typography>
													<Box margin={1} />
													<Typography variant="caption" gutterBottom>
														Points: {player.fl_points}
													</Typography>
												</Card>
											</Grid>
										)
								)}
							</Grid>
						</Card>
					</Grid>

					<Grid item xs={12}>
						<Card className={classes.root} align="center">
							<Grid container spacing={1} align="row">
								{topForwards.map(
									(player, index) =>
										index === 0 ? (
											<Grid item xs={12} key={index} style={backgroundStats.topAttacker}>
												<Card className={classes.topPlayerCard}>
													<SportsSoccerIcon fontSize="small" />
													<Typography variant="caption">Leader</Typography>
													<SportsSoccerIcon fontSize="small" />
													<Box margin={1} />
													<Typography variant="caption">{index + 1}</Typography>
													<Avatar src={getTeamLogo(player)}> </Avatar>
													<Typography variant="caption" gutterBottom>
														{player.firstname}
													</Typography>
													<Box margin={1} />
													<Typography variant="caption" gutterBottom>
														Points: {player.fl_points}
													</Typography>
												</Card>
											</Grid>
										) : (
											<Grid item xs={3} key={index} style={backgroundStats.statsBackground}>
												<Card className={classes.topPlayerCard}>
													<Box margin={1} />
													<Typography variant="caption">{index + 1}</Typography>
													<Avatar src={getTeamLogo(player)}> </Avatar>
													<Typography variant="caption" gutterBottom>
														{player.firstname}
													</Typography>
													<Box margin={1} />
													<Typography variant="caption" gutterBottom>
														Points: {player.fl_points}
													</Typography>
												</Card>
											</Grid>
										)
								)}
							</Grid>
						</Card>
					</Grid>
				</Grid>
			</Grid>
		</Root>
    );
}

import React, { Component } from 'react';
import CreateMatchSchedule from '../../component/CreateMatchSchedule';

export class creatematchschedule extends Component {
	render() {
		return (
			<div>
				<CreateMatchSchedule />
			</div>
		);
	}
}

export default creatematchschedule;
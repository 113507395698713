import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';

import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Link from '@mui/material/Link';

import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

//Menu
import MenuItem from '@mui/material/MenuItem';

//Form
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';


//icons
import SaveIcon from '@mui/icons-material/Save';
import anfcLogo from '../icons/anfc_50x50.jpeg';
import bkfcLogo from '../icons/bkfc_50x50.jpeg';
import gifcLogo from '../icons/gifc_50x50.jpeg';
import hufcLogo from '../icons/hufc_50x50.jpeg';
import lcsfLogo from '../icons/lcsf_50x50.jpeg';
import tpfcLogo from '../icons/tpfc_50x50.jpeg';
import trfcLogo from '../icons/trfc_50x50.jpeg';
import ylfcLogo from '../icons/ylfc_50x50.jpeg';

//Card
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

//Button
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

//Dialog
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

//List
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';

import * as FirestoreService from '../firebase';
import { useSession } from '../App';

const PREFIX = 'FantasyLeagueTeam';

const classes = {
	root        : `${PREFIX}-root`,
	cardheader  : `${PREFIX}-cardheader`,
	playercard  : `${PREFIX}-playercard`,
	media       : `${PREFIX}-media`,
	table       : `${PREFIX}-table`,
	formControl : `${PREFIX}-formControl`,
	selectEmpty : `${PREFIX}-selectEmpty`,
	button      : `${PREFIX}-button`
};

const StyledGrid = styled(Grid)(({ theme }) => ({
	[`& .${classes.root}`]: {
		flexGrow : 1
	},

	[`& .${classes.cardheader}`]: {
		maxHeight : 100
	},

	[`& .${classes.playercard}`]: {
		minWidth        : 80,
		minHeight       : 130,
		backgroundColor : 'transparent',
		border          : 'none',
		boxShadow       : 'none'
	},

	[`& .${classes.media}`]: {
		height : 140
	},

	[`& .${classes.table}`]: {
		minWidth : 450
	},

	[`& .${classes.formControl}`]: {
		margin   : theme.spacing(1),
		minWidth : 120
	},

	[`& .${classes.selectEmpty}`]: {
		marginTop : theme.spacing(2)
	},

	[`& .${classes.button}`]: {
		margin : theme.spacing(1)
	}
}));

const backgroundTheme = {
	paperContainer : {
		backgroundImage : `url(${'/images/Football-pitch.png'})`, //https://johnburnmurdoch.github.io/football-pitch-tracker/half-rotate.png
		backgroundSize  : '100% 100%'
	}
};

export default function FantasyLeagueTransfer(props) {
	const CURRENT_SEASON = '2022';

	const [ transferWindowStatus, setTransferWindowStatus ] = useState('open');
	const [ currentWeek, setCurrentWeek ] = useState(0);

	const [ teamPlayers, setTeamPlayers ] = useState([]);
	const [ teamName, setTeamName ] = useState('');
	const [ openGkDialog, setOpenGkDialog ] = React.useState(false);
	const [ openRbDialog, setOpenRbDialog ] = React.useState(false);
	const [ openCB1Dialog, setOpenCB1Dialog ] = useState(false);
	const [ openCB2Dialog, setOpenCB2Dialog ] = useState(false);
	const [ openLbDialog, setOpenLbDialog ] = React.useState(false);
	const [ openRmDialog, setOpenRmDialog ] = React.useState(false);
	const [ openCm1Dialog, setOpenCm1Dialog ] = React.useState(false);
	const [ openCm2Dialog, setOpenCm2Dialog ] = React.useState(false);
	const [ openLmDialog, setOpenLmDialog ] = React.useState(false);
	const [ openAt1Dialog, setOpenAt1Dialog ] = React.useState(false);
	const [ openAt2Dialog, setOpenAt2Dialog ] = React.useState(false);

	const [ firstElevenGk, setFirstElevenGk ] = useState();

	const [ firstElevenRb, setFirstElevenRb ] = useState();
	const [ firstElevenCb1, setFirstElevenCb1 ] = useState();
	const [ firstElevenCb2, setFirstElevenCb2 ] = useState();
	const [ firstElevenLb, setFirstElevenLb ] = useState();

	const [ firstElevenRm, setFirstElevenRm ] = useState();
	const [ firstElevenLm, setFirstElevenLm ] = useState();
	const [ firstElevenCm1, setFirstElevenCm1 ] = useState();
	const [ firstElevenCm2, setFirstElevenCm2 ] = useState();

	const [ firstElevenAt1, setFirstElevenAt1 ] = useState();
	const [ firstElevenAt2, setFirstElevenAt2 ] = useState();

	const [ reserveGk, setReserveGk ] = useState();
	const [ reserveDf, setReserveDf ] = useState();
	const [ reserveMf, setReserveMf ] = useState();
	const [ reserveAt, setReserveAt ] = useState();

	const [ captain, setCaptain ] = useState('');
	const [ viceCaptain, setViceCaptain ] = useState('');

	const [ taintDefenders, setTaintDefenders ] = useState(false);

	const [ openSaveDialog, setOpenSaveDialog ] = useState(false);
	const [ saveButtonDialogText, setSaveButtonDialogText ] = useState('');

	const [ openSnackbar, setOpenSnackbar ] = useState(false);
	const [ alertSeverity, setAlertSeverity ] = useState('success');

	const user = useSession();

	const sort_by = (field, reverse, primer) => {
		const key = primer
			? function(x) {
					return primer(x[field]);
				}
			: function(x) {
					return x[field];
				};

		reverse = !reverse ? 1 : -1;

		return function(a, b) {
			return (a = key(a)), (b = key(b)), reverse * ((a > b) - (b > a));
		};
	};

	function getTeamLogo(player) {
		//console.log(player.firstname + ' Getting logo for: ' + player.team_id)
		if (player !== undefined) {
			var club = player.team_id;

			if (club === 'ANFC') {
				return anfcLogo;
			} else if (club === 'BKFC') {
				return bkfcLogo;
			} else if (club === 'GIFC') {
				return gifcLogo;
			} else if (club === 'HUFC') {
				return hufcLogo;
			} else if (club === 'LCSF') {
				return lcsfLogo;
			} else if (club === 'TPFC') {
				return tpfcLogo;
			} else if (club === 'TRFC') {
				return trfcLogo;
			} else if (club === 'YLFC') {
				return ylfcLogo;
			}
		}
	}

	function handleSaveIconClick(event) {
		console.log('Saving the Team Name: ' + teamName);
		var ref = FirestoreService.db
			.collection('FantasyLeague')
			.doc(CURRENT_SEASON)
			.collection('gamers')
			//.doc(user.uid);
			.doc(user.uid);

		// Set the "capital" field of the city 'DC'
		ref
			.update({
				team_name : teamName
			})
			.then(() => {
				console.log('Team name successfully updated!');

				setAlertSeverity('success');
				setOpenSnackbar(true);
			})
			.catch((error) => {
				// The document probably doesn't exist.
				console.error('Error updating team name: ', error);

				setAlertSeverity('error');
				setOpenSnackbar(true);
			});
	}

	const handleSnackbarClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenSnackbar(false);
	};

	function updatePlayerArray(player, updateType, tacticposition) {
		if (player.position === 'Goalkeeper') {
			//check if goalkeeper is filled
			if (updateType === 'change') {
				console.log('Changing Goalkeeper');

				//set the current first 11 to reserve
				var newReserveGk = firstElevenGk;
				newReserveGk.first_eleven = false;
				var index = teamPlayers.findIndex((temp) => temp.player_id === player.player_id);
				teamPlayers.splice(index, 1, player);
				setReserveGk(newReserveGk);

				//set the selected player to first 11
				player.first_eleven = true;
				index = teamPlayers.findIndex((temp) => temp.player_id === player.player_id);
				teamPlayers.splice(index, 1, player);
				setFirstElevenGk(player);
			} else {
				//first time team selection
				player.first_eleven = true;
				index = teamPlayers.findIndex((temp) => temp.player_id === player.player_id);
				teamPlayers.splice(index, 1, player);
				setFirstElevenGk(player);

				//Automatically set reserve GK
				var goalkeeper = teamPlayers.filter(
					(temp) => temp.position === 'Goalkeeper' && temp.first_eleven === false
				);
				console.log(goalkeeper);
				setReserveGk(goalkeeper[0]);
			}
		} else if (player.position === 'Defender') {
			if (updateType === 'change') {
				console.log('Change defender ' + tacticposition);
				if (tacticposition === 'RightBack') {
					//set the current first 11 to reserve
					console.log(firstElevenRb);
					var newReserveDf = firstElevenRb;
					newReserveDf.first_eleven = false;
					index = teamPlayers.findIndex((temp) => temp.player_id === player.player_id);
					teamPlayers.splice(index, 1, newReserveDf);
					setReserveDf(newReserveDf);

					//set the selected player to first 11
					player.first_eleven = true;
					index = teamPlayers.findIndex((temp) => temp.player_id === player.player_id);
					teamPlayers.splice(index, 1, player);
					setFirstElevenRb(player);
				} else if (tacticposition === 'CenterBack1') {
					//set the current first 11 to reserve
					newReserveDf = firstElevenCb1;
					newReserveDf.first_eleven = false;
					index = teamPlayers.findIndex((temp) => temp.player_id === player.player_id);
					teamPlayers.splice(index, 1, newReserveDf);
					setReserveDf(newReserveDf);
					console.log(index + ' New Reserve Df: ' + newReserveDf.firstname);

					//set the selected player to first 11
					player.first_eleven = true;
					index = teamPlayers.findIndex((temp) => temp.player_id === player.player_id);
					teamPlayers.splice(index, 1, player);
					setFirstElevenCb1(player);
					console.log('New CB1 : ' + player.firstname);

					console.log(teamPlayers);
				} else if (tacticposition === 'CenterBack2') {
					//set the current first 11 to reserve
					newReserveDf = firstElevenCb2;
					newReserveDf.first_eleven = false;
					index = teamPlayers.findIndex((temp) => temp.player_id === player.player_id);
					teamPlayers.splice(index, 1, newReserveDf);
					setReserveDf(newReserveDf);

					//set the selected player to first 11
					player.first_eleven = true;
					index = teamPlayers.findIndex((temp) => temp.player_id === player.player_id);
					teamPlayers.splice(index, 1, player);
					setFirstElevenCb2(player);
				} else if (tacticposition === 'LeftBack') {
					//set the current first 11 to reserve
					newReserveDf = firstElevenLb;
					newReserveDf.first_eleven = false;
					index = teamPlayers.findIndex((temp) => temp.player_id === player.player_id);
					teamPlayers.splice(index, 1, newReserveDf);
					setReserveDf(newReserveDf);

					//set the selected player to first 11
					player.first_eleven = true;
					index = teamPlayers.findIndex((temp) => temp.player_id === player.player_id);
					teamPlayers.splice(index, 1, player);
					setFirstElevenLb(player);
				}
			} else {
				console.log('Select a new defender');
				//first time team selection
				player.first_eleven = true;
				index = teamPlayers.findIndex((temp) => temp.player_id === player.player_id);
				teamPlayers.splice(index, 1, player);
				console.log(teamPlayers);

				//Automatically set reserve Defender
				var defenders = teamPlayers.filter(
					(temp) => temp.position === 'Defender' && temp.first_eleven === false
				);

				console.log('Defenders length: ' + defenders.length);
				if (defenders.length === 1) setReserveDf(defenders[0]);
			}
		} else if (player.position === 'Midfielder') {
			if (updateType === 'change') {
				console.log('Change Midfielder ' + tacticposition);
				if (tacticposition === 'RightMidfield') {
					//set the current first 11 to reserve
					var newReserveMf = firstElevenRm;
					newReserveMf.first_eleven = false;
					index = teamPlayers.findIndex((temp) => temp.player_id === player.player_id);
					teamPlayers.splice(index, 1, newReserveMf);
					setReserveMf(newReserveMf);

					//set the selected player to first 11
					player.first_eleven = true;
					index = teamPlayers.findIndex((temp) => temp.player_id === player.player_id);
					teamPlayers.splice(index, 1, player);
					setFirstElevenRm(player);
				} else if (tacticposition === 'CenterMidfield1') {
					//set the current first 11 to reserve
					newReserveMf = firstElevenCm1;
					newReserveMf.first_eleven = false;
					index = teamPlayers.findIndex((temp) => temp.player_id === player.player_id);
					teamPlayers.splice(index, 1, newReserveMf);
					setReserveMf(newReserveMf);

					//set the selected player to first 11
					player.first_eleven = true;
					index = teamPlayers.findIndex((temp) => temp.player_id === player.player_id);
					teamPlayers.splice(index, 1, player);
					setFirstElevenCm1(player);
				} else if (tacticposition === 'CenterMidfield2') {
					//set the current first 11 to reserve
					console.log(firstElevenCm2);
					var newReserveMf = firstElevenCm2;
					newReserveMf.first_eleven = false;
					index = teamPlayers.findIndex((temp) => temp.player_id === player.player_id);
					teamPlayers.splice(index, 1, newReserveMf);
					setReserveMf(newReserveMf);

					//set the selected player to first 11
					player.first_eleven = true;
					index = teamPlayers.findIndex((temp) => temp.player_id === player.player_id);
					teamPlayers.splice(index, 1, player);
					setFirstElevenCm2(player);
				} else if (tacticposition === 'LeftMidfield') {
					//set the current first 11 to reserve
					console.log(firstElevenLm);
					var newReserveMf = firstElevenLm;
					newReserveMf.first_eleven = false;
					index = teamPlayers.findIndex((temp) => temp.player_id === player.player_id);
					teamPlayers.splice(index, 1, newReserveMf);
					setReserveMf(newReserveMf);

					//set the selected player to first 11
					player.first_eleven = true;
					index = teamPlayers.findIndex((temp) => temp.player_id === player.player_id);
					teamPlayers.splice(index, 1, player);
					setFirstElevenLm(player);
				}
			} else {
				console.log('Select a new midfielder');
				//first time team selection
				player.first_eleven = true;
				index = teamPlayers.findIndex((temp) => temp.player_id === player.player_id);
				teamPlayers.splice(index, 1, player);
				console.log(teamPlayers);

				//Automatically set reserve Defender
				var midfielders = teamPlayers.filter(
					(temp) => temp.position === 'Midfielder' && temp.first_eleven === false
				);

				console.log('Midfielder length: ' + midfielders.length);
				if (midfielders.length === 1) setReserveMf(midfielders[0]);
			}
		} else if (player.position === 'Forward') {
			if (updateType === 'change') {
				console.log('Change Attacker ' + tacticposition);
				if (tacticposition === 'Attacker1') {
					//set the current first 11 to reserve
					var newReserveAt = firstElevenAt1;
					newReserveAt.first_eleven = false;
					index = teamPlayers.findIndex((temp) => temp.player_id === player.player_id);
					teamPlayers.splice(index, 1, newReserveAt);
					setReserveAt(newReserveAt);

					//set the selected player to first 11
					player.first_eleven = true;
					index = teamPlayers.findIndex((temp) => temp.player_id === player.player_id);
					teamPlayers.splice(index, 1, player);
					setFirstElevenAt1(player);
				} else if (tacticposition === 'Attacker2') {
					//set the current first 11 to reserve
					var newReserveAt = firstElevenAt2;
					newReserveAt.first_eleven = false;
					index = teamPlayers.findIndex((temp) => temp.player_id === player.player_id);
					teamPlayers.splice(index, 1, newReserveAt);
					setReserveAt(newReserveAt);

					//set the selected player to first 11
					player.first_eleven = true;
					index = teamPlayers.findIndex((temp) => temp.player_id === player.player_id);
					teamPlayers.splice(index, 1, player);
					setFirstElevenAt2(player);
				}
			} else {
				console.log('Select a new forward');
				//first time team selection
				player.first_eleven = true;
				index = teamPlayers.findIndex((temp) => temp.player_id === player.player_id);
				teamPlayers.splice(index, 1, player);
				console.log(teamPlayers);

				//Automatically set reserve Defender
				var forwards = teamPlayers.filter((temp) => temp.position === 'Forward' && temp.first_eleven === false);

				console.log('Forward length: ' + forwards.length);
				if (forwards.length === 1) setReserveAt(forwards[0]);
			}
		}
	}

	const handleListItemClick = (player, position, updateType) => {
		console.log('HandleListItemClick: ' + position);
		if (position === 'Goalkeeper') {
			setOpenGkDialog(false);
			updatePlayerArray(player, updateType, 'Goalkeeper');
		} else if (position === 'RightBack') {
			setOpenRbDialog(false);
			updatePlayerArray(player, updateType, 'RightBack');
			setFirstElevenRb(player);
		} else if (position === 'CenterBack1') {
			setOpenCB1Dialog(false);
			updatePlayerArray(player, updateType, 'CenterBack1');
			setFirstElevenCb1(player);
		} else if (position === 'CenterBack2') {
			setOpenCB2Dialog(false);
			updatePlayerArray(player, updateType, 'CenterBack2');
			setFirstElevenCb2(player);
		} else if (position === 'LeftBack') {
			setOpenLbDialog(false);
			updatePlayerArray(player, updateType, 'LeftBack');
			setFirstElevenLb(player);
		} else if (position === 'RightMidfield') {
			setOpenRmDialog(false);
			updatePlayerArray(player, updateType, 'RightMidfield');
			setFirstElevenRm(player);
		} else if (position === 'CenterMidfield1') {
			setOpenCm1Dialog(false);
			updatePlayerArray(player, updateType, 'CenterMidfield1');
			setFirstElevenCm1(player);
		} else if (position === 'CenterMidfield2') {
			setOpenCm2Dialog(false);
			updatePlayerArray(player, updateType, 'CenterMidfield2');
			setFirstElevenCm2(player);
		} else if (position === 'LeftMidfield') {
			setOpenLmDialog(false);
			updatePlayerArray(player, updateType, 'LeftMidfield');
			setFirstElevenLm(player);
		} else if (position === 'Attacker1') {
			setOpenAt1Dialog(false);
			updatePlayerArray(player, updateType, 'Attacker1');
			setFirstElevenAt1(player);
		} else if (position === 'Attacker2') {
			setOpenAt2Dialog(false);
			updatePlayerArray(player, updateType, 'Attacker2');
			setFirstElevenAt2(player);
		}
	};

	const handleCaptainChange = (event) => {
		setCaptain(event.target.value);
	};

	const handleViceCaptainChange = (event) => {
		setViceCaptain(event.target.value);
	};

	const handleSave = () => {
		console.log('Saving the week team');
		console.log(firstElevenGk);

		//Check
		if (captain === viceCaptain || captain === '' || viceCaptain === '') {
			console.log('Check Captains and Vice Captains');
			setSaveButtonDialogText('Team needs a Captain/Vice Captain and/or NOT the same player.');
			setOpenSaveDialog(true);
		} else if (
			firstElevenGk &&
			firstElevenLb &&
			firstElevenCb1 &&
			firstElevenCb2 &&
			firstElevenLb &&
			firstElevenRm &&
			firstElevenCm1 &&
			firstElevenCm2 &&
			firstElevenLm &&
			firstElevenAt1 &&
			firstElevenAt2
		) {
			console.log('First eleven is set ');
			console.log(firstElevenGk);

			var batch = FirestoreService.db.batch();

			var docRef = FirestoreService.db
				.collection('FantasyLeague')
				.doc(CURRENT_SEASON)
				.collection('gamers')
				.doc(user.uid)
				.collection('team')
				.doc('GK');
			batch.set(docRef, firstElevenGk);

			var docRef = FirestoreService.db
				.collection('FantasyLeague')
				.doc(CURRENT_SEASON)
				.collection('gamers')
				.doc(user.uid)
				.collection('team')
				.doc('RB');
			batch.set(docRef, firstElevenRb);

			var docRef = FirestoreService.db
				.collection('FantasyLeague')
				.doc(CURRENT_SEASON)
				.collection('gamers')
				.doc(user.uid)
				.collection('team')
				.doc('CB1');
			batch.set(docRef, firstElevenCb1);

			var docRef = FirestoreService.db
				.collection('FantasyLeague')
				.doc(CURRENT_SEASON)
				.collection('gamers')
				.doc(user.uid)
				.collection('team')
				.doc('CB2');
			batch.set(docRef, firstElevenCb2);

			var docRef = FirestoreService.db
				.collection('FantasyLeague')
				.doc(CURRENT_SEASON)
				.collection('gamers')
				.doc(user.uid)
				.collection('team')
				.doc('LB');
			batch.set(docRef, firstElevenLb);

			var docRef = FirestoreService.db
				.collection('FantasyLeague')
				.doc(CURRENT_SEASON)
				.collection('gamers')
				.doc(user.uid)
				.collection('team')
				.doc('RM');
			batch.set(docRef, firstElevenRm);

			var docRef = FirestoreService.db
				.collection('FantasyLeague')
				.doc(CURRENT_SEASON)
				.collection('gamers')
				.doc(user.uid)
				.collection('team')
				.doc('CM1');
			batch.set(docRef, firstElevenCm1);

			var docRef = FirestoreService.db
				.collection('FantasyLeague')
				.doc(CURRENT_SEASON)
				.collection('gamers')
				.doc(user.uid)
				.collection('team')
				.doc('CM2');
			batch.set(docRef, firstElevenCm2);

			var docRef = FirestoreService.db
				.collection('FantasyLeague')
				.doc(CURRENT_SEASON)
				.collection('gamers')
				.doc(user.uid)
				.collection('team')
				.doc('LM');
			batch.set(docRef, firstElevenLm);

			var docRef = FirestoreService.db
				.collection('FantasyLeague')
				.doc(CURRENT_SEASON)
				.collection('gamers')
				.doc(user.uid)
				.collection('team')
				.doc('AT1');
			batch.set(docRef, firstElevenAt1);

			var docRef = FirestoreService.db
				.collection('FantasyLeague')
				.doc(CURRENT_SEASON)
				.collection('gamers')
				.doc(user.uid)
				.collection('team')
				.doc('AT2');
			batch.set(docRef, firstElevenAt2);

			var docRef = FirestoreService.db
				.collection('FantasyLeague')
				.doc(CURRENT_SEASON)
				.collection('gamers')
				.doc(user.uid)
				.collection('team')
				.doc('RGK');
			batch.set(docRef, reserveGk);

			var docRef = FirestoreService.db
				.collection('FantasyLeague')
				.doc(CURRENT_SEASON)
				.collection('gamers')
				.doc(user.uid)
				.collection('team')
				.doc('RDF');
			batch.set(docRef, reserveDf);

			var docRef = FirestoreService.db
				.collection('FantasyLeague')
				.doc(CURRENT_SEASON)
				.collection('gamers')
				.doc(user.uid)
				.collection('team')
				.doc('RMF');
			batch.set(docRef, reserveMf);

			var docRef = FirestoreService.db
				.collection('FantasyLeague')
				.doc(CURRENT_SEASON)
				.collection('gamers')
				.doc(user.uid)
				.collection('team')
				.doc('RAT');
			batch.set(docRef, reserveAt);

			var docRef = FirestoreService.db
				.collection('FantasyLeague')
				.doc(CURRENT_SEASON)
				.collection('gamers')
				.doc(user.uid);
			batch.set(docRef, { captain: captain }, { merge: true });

			var docRef = FirestoreService.db
				.collection('FantasyLeague')
				.doc(CURRENT_SEASON)
				.collection('gamers')
				.doc(user.uid);
			batch.set(docRef, { vice_captain: viceCaptain }, { merge: true });

			batch
				.commit()
				.then(function() {
					console.log('Batch writing squad players completed');
					setSaveButtonDialogText('Matchday team selection saved.');
					setOpenSaveDialog(true);
				})
				.catch((error) => {
					console.error('Error writing document: ', error);
					setSaveButtonDialogText('Unable to save your team selection.');
					setOpenSaveDialog(true);
				});
		} else {
			console.log('Team not complete');
		}
	};

	useEffect(() => {
		const fetchTeamPlayers = async () => {
			const response = FirestoreService.db
				.collection('FantasyLeague')
				.doc(CURRENT_SEASON)
				.collection('gamers')
				.doc(user.uid)
				.collection('players')
				.orderBy('position', 'asc');

			response
				.get()
				.then((querySnapshot) => {
					var tempArray = [];
					querySnapshot.forEach((doc) => {
						// doc.data() is never undefined for query doc snapshots
						var player = doc.data();
						tempArray.push({ ...player, first_eleven: false });
					});
					//console.log(tempArray);
					tempArray.sort(sort_by('firstname', false, (a) => a.toUpperCase()));
					setTeamPlayers(tempArray);
				})
				.catch((error) => {
					console.log('Error getting documents: ', error);
				});
		};

		fetchTeamPlayers();
	}, []);

	useEffect(() => {
		const fetchTeamFormation = async () => {
			const response1 = FirestoreService.db
				.collection('FantasyLeague')
				.doc(CURRENT_SEASON)
				.collection('gamers')
				.doc(user.uid)
				.collection('team');

			response1
				.get()
				.then((querySnapshot) => {
					querySnapshot.forEach((doc) => {
						// doc.data() is never undefined for query doc snapshots

						if (doc.id === 'GK') {
							setFirstElevenGk(doc.data());

							//updateFirstEleveStatus(doc.data());
						} else if (doc.id === 'RB') {
							setFirstElevenRb(doc.data());
							//updateFirstEleveStatus(doc.data());
						} else if (doc.id === 'CB1') {
							setFirstElevenCb1(doc.data());
							//updateFirstEleveStatus(doc.data());
						} else if (doc.id === 'CB2') {
							setFirstElevenCb2(doc.data());
							//updateFirstEleveStatus(doc.data());
						} else if (doc.id === 'LB') {
							setFirstElevenLb(doc.data());
							//updateFirstEleveStatus(doc.data());
						} else if (doc.id === 'RM') {
							setFirstElevenRm(doc.data());
							//updateFirstEleveStatus(doc.data());
						} else if (doc.id === 'CM1') {
							setFirstElevenCm1(doc.data());
							//updateFirstEleveStatus(doc.data());
						} else if (doc.id === 'CM2') {
							setFirstElevenCm2(doc.data());
							//updateFirstEleveStatus(doc.data());
						} else if (doc.id === 'LM') {
							setFirstElevenLm(doc.data());
							//updateFirstEleveStatus(doc.data());
						} else if (doc.id === 'AT1') {
							setFirstElevenAt1(doc.data());
							//updateFirstEleveStatus(doc.data());
						} else if (doc.id === 'AT2') {
							setFirstElevenAt2(doc.data());
							//updateFirstEleveStatus(doc.data());
						} else if (doc.id === 'RGK') {
							setReserveGk(doc.data());
						} else if (doc.id === 'RDF') {
							setReserveDf(doc.data());
						} else if (doc.id === 'RMF') {
							setReserveMf(doc.data());
						} else if (doc.id === 'RAT') {
							setReserveAt(doc.data());
						}
					});
					//console.log(teamPlayers);
				})
				.catch((error) => {
					console.log('Error getting documents: ', error);
				});
		};

		fetchTeamFormation();
	}, []);

	useEffect(
		() => {
			const fetchTeamDetails = async () => {
				const docRef = FirestoreService.db
					.collection('FantasyLeague')
					.doc(CURRENT_SEASON)
					.collection('gamers')
					.doc(user.uid);

				docRef
					.get()
					.then((doc) => {
						if (doc.exists) {
							var captain = doc.data().captain;
							var foundIdx = teamPlayers.findIndex((player) => player.player_id === captain);
							//console.log(foundIdx + ' Captain: ' + captain);

							if (foundIdx === -1) setCaptain('');
							else setCaptain(captain);

							var vicecaptain = doc.data().vice_captain;
							foundIdx = teamPlayers.findIndex((player) => player.player_id === vicecaptain);
							if (foundIdx === -1) setViceCaptain('');
							else setViceCaptain(vicecaptain);

							setTeamName(doc.data().team_name);
							//setCaptain(doc.data().captain);
							//setViceCaptain(doc.data().vice_captain);
						} else {
							// doc.data() will be undefined in this case
							console.log('No such document!');
						}
					})
					.catch((error) => {
						console.log('Error getting document:', error);
					});
			};

			fetchTeamDetails();
		},
		[ teamPlayers ]
	);

	useEffect(() => {
		const fetchTransferWindowStatus = async () => {
			const response = FirestoreService.db.collection('FantasyLeague').doc(CURRENT_SEASON);

			response
				.get()
				.then((doc) => {
					if (doc.exists) {
						console.log(doc.data());
						setTransferWindowStatus(doc.data().transfer_window_status);
						setCurrentWeek(doc.data().current_week);
					} else {
						// doc.data() will be undefined in this case
						console.log('No such document!');
					}
				})
				.catch((error) => {
					console.log('Error getting document:', error);
				});
		};

		fetchTransferWindowStatus();
	}, []);

	function firstElevenSetup(position, tactical_position) {
		if (taintDefenders === false) {
			console.log('get all defenders');
			var defenders = teamPlayers.filter(
				(temp) => temp.position === 'Defender' && temp.player_id !== reserveDf.player_id
			);
			console.log(defenders);

			//console.log(player.player_id);
			//console.log(teamPlayers);
			defenders.forEach((defender) => {
				console.log('Marking first 11 ' + defender.firstname);
				var index = teamPlayers.findIndex((temp) => temp.player_id === defender.player_id);
				defender.first_eleven = true;
				console.log(defender.firstname);
				teamPlayers.splice(index, 1, defender);
			});

			console.log('get all midfielders');
			var midfielders = teamPlayers.filter(
				(temp) => temp.position === 'Midfielder' && temp.player_id !== reserveMf.player_id
			);
			console.log(midfielders);

			midfielders.forEach((midfielder) => {
				console.log('Marking first 11 ' + midfielder.firstname);
				var index = teamPlayers.findIndex((temp) => temp.player_id === midfielder.player_id);
				midfielder.first_eleven = true;
				console.log(midfielder.firstname);
				teamPlayers.splice(index, 1, midfielder);
			});

			console.log('get all attackers');
			var attackers = teamPlayers.filter(
				(temp) => temp.position === 'Forward' && temp.player_id !== reserveAt.player_id
			);
			console.log(attackers);

			attackers.forEach((attacker) => {
				console.log('Marking first 11 ' + attacker.firstname);
				var index = teamPlayers.findIndex((temp) => temp.player_id === attacker.player_id);
				attacker.first_eleven = true;
				console.log(attacker.firstname);
				teamPlayers.splice(index, 1, attacker);
			});

			setTaintDefenders(true);
		}

		if (tactical_position === 'RightBack') setOpenRbDialog(true);
		else if (tactical_position === 'CenterBack1') setOpenCB1Dialog(true);
		else if (tactical_position === 'CenterBack2') setOpenCB2Dialog(true);
		else if (tactical_position === 'LeftBack') setOpenLbDialog(true);
		else if (tactical_position === 'LeftMidfield') setOpenLmDialog(true);
		else if (tactical_position === 'CenterMidfield1') setOpenCm1Dialog(true);
		else if (tactical_position === 'CenterMidfield2') setOpenCm2Dialog(true);
		else if (tactical_position === 'RightMidfield') setOpenRmDialog(true);
		else if (tactical_position === 'Attacker1') setOpenAt1Dialog(true);
		else if (tactical_position === 'Attacker2') setOpenAt2Dialog(true);
	}

	const handleCloseSaveDialog = () => {
		setOpenSaveDialog(false);
	};

	return (
		<StyledGrid container alignContent='center' justifyContent='center' alignItems='center' spacing={1}>
			<Grid item xs={12}>
				<Card className={classes.card_header}>
					<CardMedia
						className={classes.media}
						image='/images/generic/fl_team_banner.jpeg'
						title='Fantasy Football Header'
					/>

					<CardContent>
						<Grid container spacing={1} align='center' justifyContent='center' alignItems='center'>
							<Grid item xs={12} align='center'>
								<TextField
									id='standard-helperText'
									label='Team'
									value={teamName}
									helperText='Choose a cool name for your team'
									onChange={(event) => setTeamName(event.target.value)}
								/>
								<IconButton
									className={classes.margin}
									size='small'
									onClick={(e) => handleSaveIconClick(e)}
								>
									<SaveIcon color='action' />
								</IconButton>
								<Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
									<Alert onClose={handleSnackbarClose} severity={alertSeverity}>
										Changes successfully saved.
									</Alert>
								</Snackbar>
							</Grid>
							<Grid item xs={12}>
								<FormControl className={classes.formControl} align='center'>
									<Select
										labelId='demo-simple-select-label'
										id='demo-simple-select'
										value={captain}
										onChange={handleCaptainChange}
									>
										{teamPlayers.map((player, index) => (
											<MenuItem key={index} value={player.player_id}>
												{player.firstname}
											</MenuItem>
										))}
									</Select>
									<FormHelperText>Captain</FormHelperText>
								</FormControl>
								<FormControl className={classes.formControl} align='center'>
									<Select
										labelId='demo-simple-select-label'
										id='demo-simple-select'
										value={viceCaptain}
										onChange={handleViceCaptainChange}
									>
										{teamPlayers.map((player, index) => (
											<MenuItem key={index} value={player.player_id}>
												{player.firstname}
											</MenuItem>
										))}
									</Select>
									<FormHelperText>Vice Captain</FormHelperText>

								</FormControl>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Grid>

			<Grid
				container
				alignContent='center'
				justifyContent='center'
				alignItems='center'
				spacing={2}
				style={backgroundTheme.paperContainer}
			>
				{/* GOALKEEPER */}
				<Grid item xs={3}>
					<Card className={classes.playercard} align='center'>
						<Typography align='center'>GK</Typography>

						{firstElevenGk ? (
							<div>
								<Avatar src={getTeamLogo(firstElevenGk)}> </Avatar>
								<Typography>{firstElevenGk.firstname}</Typography>
								<Typography>SGD {firstElevenGk.value} M</Typography>
								<Typography>FL Pts {firstElevenGk.fl_points}</Typography>
								<Link
									align='center'
									component='button'
									variant='body2'
									onClick={(e) => setOpenGkDialog(true)}
								>
									Change Goalkeeper
								</Link>
								{reserveGk ? (
									<div>
										<Dialog open={openGkDialog} aria-labelledby='form-dialog-title'>
											<DialogTitle id='form-dialog-title'>Goalkeeper Selection</DialogTitle>
											<List>
												<ListItem
													button
													onClick={() =>
														handleListItemClick(reserveGk, 'Goalkeeper', 'change')}
													key={reserveGk.player_id}
												>
													<ListItemAvatar>
														<Avatar
															src={getTeamLogo(reserveGk)}
															className={classes.avatar}
														/>
													</ListItemAvatar>
													<ListItemText
														primary={reserveGk.firstname}
														secondary={
															<React.Fragment>
																<Typography
																	component='span'
																	variant='body2'
																	className={classes.inline}
																	color='textPrimary'
																>
																	Value: $ {reserveGk.value} M FL Points:{' '}
																	{reserveGk.fl_points}
																</Typography>
															</React.Fragment>
														}
													/>
												</ListItem>
											</List>
										</Dialog>
									</div>
								) : (
									<div />
								)}
							</div>
						) : (
							<div>
								<Avatar src='https://www.shareicon.net/data/512x512/2016/06/30/788946_people_512x512.png' />
								<Link
									align='center'
									component='button'
									variant='body2'
									onClick={(e) => setOpenGkDialog(true)}
								>
									Select GoalKeeper
								</Link>
								<Dialog open={openGkDialog} aria-labelledby='form-dialog-title'>
									<DialogTitle id='form-dialog-title'>Goalkeeper Selection</DialogTitle>
									<List>
										{teamPlayers.map(
											(player) =>
												player.position === 'Goalkeeper' && player.first_eleven === false ? (
													<div key={player.player_id}>
														<ListItem
															button
															onClick={() => handleListItemClick(player, 'Goalkeeper')}
															key={player.player_id}
														>
															<ListItemAvatar>
																<Avatar
																	src={getTeamLogo(player)}
																	className={classes.avatar}
																/>
															</ListItemAvatar>
															<ListItemText
																primary={player.firstname}
																secondary={
																	<React.Fragment>
																		<Typography
																			component='span'
																			variant='body2'
																			className={classes.inline}
																			color='textPrimary'
																		>
																			Value: $ {player.value} M FL Points:{' '}
																			{player.fl_points}
																		</Typography>
																	</React.Fragment>
																}
															/>
														</ListItem>
													</div>
												) : (
													<div key={'empty_' + player.player_id} />
												)
										)}
									</List>
								</Dialog>
							</div>
						)}
					</Card>
				</Grid>
				{/* DEFENDERS SECTION */}
				<Grid item xs={12}>
					<Grid container spacing={1}>
						{/* RightBack */}
						<Grid item xs={3}>
							<Card className={classes.playercard} align='center'>
								<Typography align='center'>Defenders</Typography>

								{firstElevenRb ? (
									<div>
										<Avatar src={getTeamLogo(firstElevenRb)}> </Avatar>
										<Typography>{firstElevenRb.firstname}</Typography>
										<Typography>SGD {firstElevenRb.value} M </Typography>
										<Typography>FL Points {firstElevenRb.fl_points}</Typography>
										<Link
											align='center'
											component='button'
											variant='body2'
											onClick={(e) => firstElevenSetup('Defender', 'RightBack')}
										>
											Change Defender
										</Link>
										{reserveDf ? (
											<div>
												<Dialog open={openRbDialog} aria-labelledby='form-dialog-title'>
													<DialogTitle id='form-dialog-title'>Defender Selection</DialogTitle>
													<List>
														{teamPlayers.map(
															(player) =>
																player.position === 'Defender' &&
																player.first_eleven === false ? (
																	<div key={player.player_id}>
																		<ListItem
																			button
																			onClick={() =>
																				handleListItemClick(
																					player,
																					'RightBack',
																					'change'
																				)}
																			key={player.player_id}
																		>
																			<ListItemAvatar>
																				<Avatar
																					src={getTeamLogo(player)}
																					className={classes.avatar}
																				/>
																			</ListItemAvatar>
																			<ListItemText
																				primary={player.firstname}
																				secondary={
																					<React.Fragment>
																						<Typography
																							component='span'
																							variant='body2'
																							className={classes.inline}
																							color='textPrimary'
																						>
																							Value: $ {player.value} M
																							Points: {player.fl_points}
																						</Typography>
																					</React.Fragment>
																				}
																			/>
																		</ListItem>
																	</div>
																) : (
																	<div key={'empty_' + player.player_id} />
																)
														)}
													</List>
												</Dialog>
											</div>
										) : (
											<div>
												<Dialog
													open={openRbDialog}
													aria-labelledby='alert-dialog-title'
													aria-describedby='alert-dialog-description'
												>
													<DialogTitle id='alert-dialog-title'>
														{'Team Setup Message'}
													</DialogTitle>
													<DialogContent>
														<DialogContentText id='alert-dialog-description'>
															No defender reserve for substitution.
														</DialogContentText>
													</DialogContent>
													<DialogActions>
														<Button
															onClick={(e) => setOpenRbDialog(false)}
															color='primary'
															autoFocus
														>
															Close
														</Button>
													</DialogActions>
												</Dialog>
											</div>
										)}
									</div>
								) : (
									<div>
										<Avatar src='https://www.shareicon.net/data/512x512/2016/06/30/788946_people_512x512.png' />
										<Link
											align='center'
											component='button'
											variant='body2'
											onClick={(e) => setOpenRbDialog(true)}
										>
											Select Defender
										</Link>
										<Dialog open={openRbDialog} aria-labelledby='form-dialog-title'>
											<DialogTitle id='form-dialog-title'>Defender Selection</DialogTitle>
											<List>
												{teamPlayers.map(
													(player) =>
														player.position === 'Defender' &&
														player.first_eleven === false ? (
															<div key={player.player_id}>
																<ListItem
																	button
																	onClick={() =>
																		handleListItemClick(player, 'RightBack', 'new')}
																	key={player.player_id}
																>
																	<ListItemAvatar>
																		<Avatar
																			src={getTeamLogo(player)}
																			className={classes.avatar}
																		/>
																	</ListItemAvatar>
																	<ListItemText
																		primary={player.firstname}
																		secondary={
																			<React.Fragment>
																				<Typography
																					component='span'
																					variant='body2'
																					className={classes.inline}
																					color='textPrimary'
																				>
																					Value: $ {player.value} M FL Points:{' '}
																					{player.fl_points}
																				</Typography>
																			</React.Fragment>
																		}
																	/>
																</ListItem>
															</div>
														) : (
															<div key={'empty_' + player.player_id} />
														)
												)}
											</List>
										</Dialog>
									</div>
								)}
							</Card>
						</Grid>

						{/* CenterBack1 */}
						<Grid item xs={3}>
							<Card className={classes.playercard} align='center'>
								<Typography align='center'>Defenders</Typography>

								{firstElevenCb1 ? (
									<div>
										<Avatar src={getTeamLogo(firstElevenCb1)}> </Avatar>
										<Typography>{firstElevenCb1.firstname}</Typography>
										<Typography>SGD {firstElevenCb1.value} M</Typography>
										<Typography>FL Points {firstElevenCb1.fl_points}</Typography>
										<Link
											align='center'
											component='button'
											variant='body2'
											onClick={(e) => firstElevenSetup('Defender', 'CenterBack1')}
										>
											Change Defender
										</Link>
										{reserveDf ? (
											<div>
												<Dialog open={openCB1Dialog} aria-labelledby='form-dialog-title'>
													<DialogTitle id='form-dialog-title'>Defender Selection</DialogTitle>
													<List>
														{teamPlayers.map(
															(player) =>
																player.position === 'Defender' &&
																player.first_eleven === false ? (
																	<div key={player.player_id}>
																		<ListItem
																			button
																			onClick={() =>
																				handleListItemClick(
																					player,
																					'CenterBack1',
																					'change'
																				)}
																			key={player.player_id}
																		>
																			<ListItemAvatar>
																				<Avatar
																					src={getTeamLogo(player)}
																					className={classes.avatar}
																				/>
																			</ListItemAvatar>
																			<ListItemText
																				primary={player.firstname}
																				secondary={
																					<React.Fragment>
																						<Typography
																							component='span'
																							variant='body2'
																							className={classes.inline}
																							color='textPrimary'
																						>
																							Value: $ {player.value} M FL
																							Points: {player.fl_points}
																						</Typography>
																					</React.Fragment>
																				}
																			/>
																		</ListItem>
																	</div>
																) : (
																	<div key={'empty_' + player.player_id} />
																)
														)}
													</List>
												</Dialog>
											</div>
										) : (
											<div>
												<Dialog
													open={openCB1Dialog}
													aria-labelledby='alert-dialog-title'
													aria-describedby='alert-dialog-description'
												>
													<DialogTitle id='alert-dialog-title'>
														{'Team Setup Message'}
													</DialogTitle>
													<DialogContent>
														<DialogContentText id='alert-dialog-description'>
															No defender reserve for substitution.
														</DialogContentText>
													</DialogContent>
													<DialogActions>
														<Button
															onClick={(e) => setOpenCB1Dialog(false)}
															color='primary'
															autoFocus
														>
															Close
														</Button>
													</DialogActions>
												</Dialog>
											</div>
										)}
									</div>
								) : (
									<div>
										<Avatar src='https://www.shareicon.net/data/512x512/2016/06/30/788946_people_512x512.png' />
										<Link
											align='center'
											component='button'
											variant='body2'
											onClick={(e) => setOpenCB1Dialog(true)}
										>
											Select Defender
										</Link>
										<Dialog open={openCB1Dialog} aria-labelledby='form-dialog-title'>
											<DialogTitle id='form-dialog-title'>Defender Selection</DialogTitle>
											<List>
												{teamPlayers.map(
													(player) =>
														player.position === 'Defender' &&
														player.first_eleven === false ? (
															<div key={player.player_id}>
																<ListItem
																	button
																	onClick={() =>
																		handleListItemClick(
																			player,
																			'CenterBack1',
																			'new'
																		)}
																	key={player.player_id}
																>
																	<ListItemAvatar>
																		<Avatar
																			src={getTeamLogo(player)}
																			className={classes.avatar}
																		/>
																	</ListItemAvatar>
																	<ListItemText
																		primary={player.firstname}
																		secondary={
																			<React.Fragment>
																				<Typography
																					component='span'
																					variant='body2'
																					className={classes.inline}
																					color='textPrimary'
																				>
																					Value: $ {player.value} M FL Points:{' '}
																					{player.fl_points}
																				</Typography>
																			</React.Fragment>
																		}
																	/>
																</ListItem>
															</div>
														) : (
															<div key={'empty_new' + player.player_id} />
														)
												)}
											</List>
										</Dialog>
									</div>
								)}
							</Card>
						</Grid>

						{/* CenterBack2 */}
						<Grid item xs={3}>
							<Card className={classes.playercard} align='center'>
								<Typography align='center'>Defenders</Typography>

								{firstElevenCb2 ? (
									<div>
										<Avatar src={getTeamLogo(firstElevenCb2)}> </Avatar>
										<Typography>{firstElevenCb2.firstname}</Typography>
										<Typography>SGD {firstElevenCb2.value} M</Typography>
										<Typography>FL Points {firstElevenCb2.fl_points}</Typography>
										<Link
											align='center'
											component='button'
											variant='body2'
											onClick={(e) => firstElevenSetup('Defender', 'CenterBack2')}
										>
											Change Defender
										</Link>
										{reserveDf ? (
											<div>
												<Dialog open={openCB2Dialog} aria-labelledby='form-dialog-title'>
													<DialogTitle id='form-dialog-title'>Defender Selection</DialogTitle>
													<List>
														{teamPlayers.map(
															(player) =>
																player.position === 'Defender' &&
																player.first_eleven === false ? (
																	<div key={player.player_id}>
																		<ListItem
																			button
																			onClick={() =>
																				handleListItemClick(
																					player,
																					'CenterBack2',
																					'change'
																				)}
																			key={player.player_id}
																		>
																			<ListItemAvatar>
																				<Avatar
																					src={getTeamLogo(player)}
																					className={classes.avatar}
																				/>
																			</ListItemAvatar>
																			<ListItemText
																				primary={player.firstname}
																				secondary={
																					<React.Fragment>
																						<Typography
																							component='span'
																							variant='body2'
																							className={classes.inline}
																							color='textPrimary'
																						>
																							Value: $ {player.value} M
																							Points: {player.fl_points}
																						</Typography>
																					</React.Fragment>
																				}
																			/>
																		</ListItem>
																	</div>
																) : (
																	<div key={'empty_' + player.player_id} />
																)
														)}
													</List>
												</Dialog>
											</div>
										) : (
											<div>
												<Dialog
													open={openCB2Dialog}
													aria-labelledby='alert-dialog-title'
													aria-describedby='alert-dialog-description'
												>
													<DialogTitle id='alert-dialog-title'>
														{'Team Setup Message'}
													</DialogTitle>
													<DialogContent>
														<DialogContentText id='alert-dialog-description'>
															No defender reserve for substitution.
														</DialogContentText>
													</DialogContent>
													<DialogActions>
														<Button
															onClick={(e) => setOpenCB2Dialog(false)}
															color='primary'
															autoFocus
														>
															Close
														</Button>
													</DialogActions>
												</Dialog>
											</div>
										)}
									</div>
								) : (
									<div>
										<Avatar src='https://www.shareicon.net/data/512x512/2016/06/30/788946_people_512x512.png' />
										<Link
											align='center'
											component='button'
											variant='body2'
											onClick={(e) => setOpenCB2Dialog(true)}
										>
											Select Defender
										</Link>
										<Dialog open={openCB2Dialog} aria-labelledby='form-dialog-title'>
											<DialogTitle id='form-dialog-title'>Defender Selection</DialogTitle>
											<List>
												{teamPlayers.map(
													(player) =>
														player.position === 'Defender' &&
														player.first_eleven === false ? (
															<div key={player.player_id}>
																<ListItem
																	button
																	onClick={() =>
																		handleListItemClick(
																			player,
																			'CenterBack2',
																			'new'
																		)}
																	key={player.player_id}
																>
																	<ListItemAvatar>
																		<Avatar
																			src={getTeamLogo(player)}
																			className={classes.avatar}
																		/>
																	</ListItemAvatar>
																	<ListItemText
																		primary={player.firstname}
																		secondary={
																			<React.Fragment>
																				<Typography
																					component='span'
																					variant='body2'
																					className={classes.inline}
																					color='textPrimary'
																				>
																					Value: $ {player.value} M FL Points:{' '}
																					{player.fl_points}
																				</Typography>
																			</React.Fragment>
																		}
																	/>
																</ListItem>
															</div>
														) : (
															<div key={'empty_' + player.player_id} />
														)
												)}
											</List>
										</Dialog>
									</div>
								)}
							</Card>
						</Grid>
						{/* Left Defender */}
						<Grid item xs={3}>
							<Card className={classes.playercard} align='center'>
								<Typography align='center'>Defenders</Typography>

								{firstElevenLb ? (
									<div>
										<Avatar src={getTeamLogo(firstElevenLb)}> </Avatar>
										<Typography>{firstElevenLb.firstname}</Typography>
										<Typography>SGD {firstElevenLb.value} M </Typography>
										<Typography>FL Points {firstElevenLb.fl_points}</Typography>
										<Link
											align='center'
											component='button'
											variant='body2'
											onClick={(e) => firstElevenSetup('Defender', 'LeftBack')}
										>
											Change Defender
										</Link>
										{reserveDf ? (
											<div>
												<Dialog open={openLbDialog} aria-labelledby='form-dialog-title'>
													<DialogTitle id='form-dialog-title'>Defender Selection</DialogTitle>
													<List>
														{teamPlayers.map(
															(player) =>
																player.position === 'Defender' &&
																player.first_eleven === false ? (
																	<div key={player.player_id}>
																		<ListItem
																			button
																			onClick={() =>
																				handleListItemClick(
																					player,
																					'LeftBack',
																					'change'
																				)}
																			key={player.player_id}
																		>
																			<ListItemAvatar>
																				<Avatar
																					src={getTeamLogo(player)}
																					className={classes.avatar}
																				/>
																			</ListItemAvatar>
																			<ListItemText
																				primary={player.firstname}
																				secondary={
																					<React.Fragment>
																						<Typography
																							component='span'
																							variant='body2'
																							className={classes.inline}
																							color='textPrimary'
																						>
																							Value: $ {player.value} M
																							Points: {player.fl_points}
																						</Typography>
																					</React.Fragment>
																				}
																			/>
																		</ListItem>
																	</div>
																) : (
																	<div key={'empty_' + player.player_id} />
																)
														)}
													</List>
												</Dialog>
											</div>
										) : (
											<Dialog
												open={openLbDialog}
												aria-labelledby='alert-dialog-title'
												aria-describedby='alert-dialog-description'
											>
												<DialogTitle id='alert-dialog-title'>
													{'Team Setup Message'}
												</DialogTitle>
												<DialogContent>
													<DialogContentText id='alert-dialog-description'>
														No defender reserve for substitution.
													</DialogContentText>
												</DialogContent>
												<DialogActions>
													<Button
														onClick={(e) => setOpenLbDialog(false)}
														color='primary'
														autoFocus
													>
														Close
													</Button>
												</DialogActions>
											</Dialog>
										)}
									</div>
								) : (
									<div>
										<Avatar src='https://www.shareicon.net/data/512x512/2016/06/30/788946_people_512x512.png' />
										<Link
											align='center'
											component='button'
											variant='body2'
											onClick={(e) => setOpenLbDialog(true)}
										>
											Select Defender
										</Link>
										<Dialog open={openLbDialog} aria-labelledby='form-dialog-title'>
											<DialogTitle id='form-dialog-title'>Defender Selection</DialogTitle>
											<List>
												{teamPlayers.map(
													(player) =>
														player.position === 'Defender' &&
														player.first_eleven === false ? (
															<div key={player.player_id}>
																<ListItem
																	button
																	onClick={() =>
																		handleListItemClick(player, 'LeftBack', 'new')}
																	key={player.player_id}
																>
																	<ListItemAvatar>
																		<Avatar
																			src={getTeamLogo(player)}
																			className={classes.avatar}
																		/>
																	</ListItemAvatar>
																	<ListItemText
																		primary={player.firstname}
																		secondary={
																			<React.Fragment>
																				<Typography
																					component='span'
																					variant='body2'
																					className={classes.inline}
																					color='textPrimary'
																				>
																					Value: $ {player.value} M FL Points:{' '}
																					{player.fl_points}
																				</Typography>
																			</React.Fragment>
																		}
																	/>
																</ListItem>
															</div>
														) : (
															<div key={player.player_id} />
														)
												)}
											</List>
										</Dialog>
									</div>
								)}
							</Card>
						</Grid>
					</Grid>
				</Grid>
				{/* MIDFIELDERS SECTION */}
				<Grid item xs={12}>
					<Grid container spacing={1}>
						{/* Right Midfielder */}
						<Grid item xs={3}>
							<Card className={classes.playercard} align='center'>
								<Typography align='center'>Midfielders</Typography>

								{firstElevenRm ? (
									<div>
										<Avatar src={getTeamLogo(firstElevenRm)}> </Avatar>
										<Typography>{firstElevenRm.firstname}</Typography>
										<Typography>SGD {firstElevenRm.value} M</Typography>
										<Typography>FL Points {firstElevenRm.fl_points}</Typography>
										<Link
											align='center'
											component='button'
											variant='body2'
											onClick={(e) => firstElevenSetup('Midfielder', 'RightMidfield')}
										>
											Change Midfielder
										</Link>
										{reserveMf ? (
											<div>
												<Dialog open={openRmDialog} aria-labelledby='form-dialog-title'>
													<DialogTitle id='form-dialog-title'>
														Midfielder Selection
													</DialogTitle>
													<List>
														{teamPlayers.map(
															(player) =>
																player.position === 'Midfielder' &&
																player.first_eleven === false ? (
																	<div key={player.player_id}>
																		<ListItem
																			button
																			onClick={() =>
																				handleListItemClick(
																					player,
																					'RightMidfield',
																					'change'
																				)}
																			key={player.player_id}
																		>
																			<ListItemAvatar>
																				<Avatar
																					src={getTeamLogo(player)}
																					className={classes.avatar}
																				/>
																			</ListItemAvatar>
																			<ListItemText
																				primary={player.firstname}
																				secondary={
																					<React.Fragment>
																						<Typography
																							component='span'
																							variant='body2'
																							className={classes.inline}
																							color='textPrimary'
																						>
																							Value: $ {player.value} M
																							Points: {player.fl_points}
																						</Typography>
																					</React.Fragment>
																				}
																			/>
																		</ListItem>
																	</div>
																) : (
																	<div key={'new_' + player.player_id} />
																)
														)}
													</List>
												</Dialog>
											</div>
										) : (
											<div>
												<Dialog
													open={openRmDialog}
													aria-labelledby='alert-dialog-title'
													aria-describedby='alert-dialog-description'
												>
													<DialogTitle id='alert-dialog-title'>
														{'Team Setup Message'}
													</DialogTitle>
													<DialogContent>
														<DialogContentText id='alert-dialog-description'>
															No midfield reserve for substitution.
														</DialogContentText>
													</DialogContent>
													<DialogActions>
														<Button
															onClick={(e) => setOpenRmDialog(false)}
															color='primary'
															autoFocus
														>
															Close
														</Button>
													</DialogActions>
												</Dialog>
											</div>
										)}
									</div>
								) : (
									<div>
										<Avatar src='https://www.shareicon.net/data/512x512/2016/06/30/788946_people_512x512.png' />
										<Link
											align='center'
											component='button'
											variant='body2'
											onClick={(e) => setOpenRmDialog(true)}
										>
											Select Midfielder
										</Link>
										<Dialog open={openRmDialog} aria-labelledby='form-dialog-title'>
											<DialogTitle id='form-dialog-title'>Midfielder Selection</DialogTitle>
											<List>
												{teamPlayers.map(
													(player) =>
														player.position === 'Midfielder' &&
														player.first_eleven === false ? (
															<div key={player.player_id}>
																<ListItem
																	button
																	onClick={() =>
																		handleListItemClick(
																			player,
																			'RightMidfield',
																			'new'
																		)}
																	key={player.player_id}
																>
																	<ListItemAvatar>
																		<Avatar
																			src={getTeamLogo(player)}
																			className={classes.avatar}
																		/>
																	</ListItemAvatar>
																	<ListItemText
																		primary={player.firstname}
																		secondary={
																			<React.Fragment>
																				<Typography
																					component='span'
																					variant='body2'
																					className={classes.inline}
																					color='textPrimary'
																				>
																					Value: $ {player.value} M FL Points:{' '}
																					{player.fl_points}
																				</Typography>
																			</React.Fragment>
																		}
																	/>
																</ListItem>
															</div>
														) : (
															<div key={'new_' + player.player_id} />
														)
												)}
											</List>
										</Dialog>
									</div>
								)}
							</Card>
						</Grid>
						{/* Central Midfielder 1 */}
						<Grid item xs={3}>
							<Card className={classes.playercard} align='center'>
								<Typography align='center'>Midfielders</Typography>

								{firstElevenCm1 ? (
									<div>
										<Avatar src={getTeamLogo(firstElevenCm1)}> </Avatar>
										<Typography>{firstElevenCm1.firstname}</Typography>
										<Typography>SGD {firstElevenCm1.value} M</Typography>
										<Typography>FL Points {firstElevenCm1.fl_points}</Typography>
										<Link
											align='center'
											component='button'
											variant='body2'
											onClick={(e) => firstElevenSetup('Midfielder', 'CenterMidfield1')}
										>
											Change Midfielder
										</Link>
										{reserveMf ? (
											<div>
												<Dialog open={openCm1Dialog} aria-labelledby='form-dialog-title'>
													<DialogTitle id='form-dialog-title'>
														Midfielder Selection
													</DialogTitle>
													<List>
														{teamPlayers.map(
															(player) =>
																player.position === 'Midfielder' &&
																player.first_eleven === false ? (
																	<div key={player.player_id}>
																		<ListItem
																			button
																			onClick={() =>
																				handleListItemClick(
																					player,
																					'CenterMidfield1',
																					'change'
																				)}
																			key={player.player_id}
																		>
																			<ListItemAvatar>
																				<Avatar
																					src={getTeamLogo(player)}
																					className={classes.avatar}
																				/>
																			</ListItemAvatar>
																			<ListItemText
																				primary={player.firstname}
																				secondary={
																					<React.Fragment>
																						<Typography
																							component='span'
																							variant='body2'
																							className={classes.inline}
																							color='textPrimary'
																						>
																							Value: $ {player.value} M
																							Points: {player.fl_points}
																						</Typography>
																					</React.Fragment>
																				}
																			/>
																		</ListItem>
																	</div>
																) : (
																	<div key={'notfirsteleven_' + player.player_id} />
																)
														)}
													</List>
												</Dialog>
											</div>
										) : (
											<div>
												<Dialog
													open={openCm1Dialog}
													aria-labelledby='alert-dialog-title'
													aria-describedby='alert-dialog-description'
												>
													<DialogTitle id='alert-dialog-title'>
														{'Team Setup Message'}
													</DialogTitle>
													<DialogContent>
														<DialogContentText id='alert-dialog-description'>
															No midfield reserve for substitution.
														</DialogContentText>
													</DialogContent>
													<DialogActions>
														<Button
															onClick={(e) => setOpenCm1Dialog(false)}
															color='primary'
															autoFocus
														>
															Close
														</Button>
													</DialogActions>
												</Dialog>
											</div>
										)}
									</div>
								) : (
									<div>
										<Avatar src='https://www.shareicon.net/data/512x512/2016/06/30/788946_people_512x512.png' />
										<Link
											align='center'
											component='button'
											variant='body2'
											onClick={(e) => setOpenCm1Dialog(true)}
										>
											Select Midfielder
										</Link>
										<Dialog open={openCm1Dialog} aria-labelledby='form-dialog-title'>
											<DialogTitle id='form-dialog-title'>Midfielder Selection</DialogTitle>
											<List>
												{teamPlayers.map(
													(player) =>
														player.position === 'Midfielder' &&
														player.first_eleven === false ? (
															<div key={player.player_id}>
																<ListItem
																	button
																	onClick={() =>
																		handleListItemClick(
																			player,
																			'CenterMidfield1',
																			'new'
																		)}
																	key={player.player_id}
																>
																	<ListItemAvatar>
																		<Avatar
																			src={getTeamLogo(player)}
																			className={classes.avatar}
																		/>
																	</ListItemAvatar>
																	<ListItemText
																		primary={player.firstname}
																		secondary={
																			<React.Fragment>
																				<Typography
																					component='span'
																					variant='body2'
																					className={classes.inline}
																					color='textPrimary'
																				>
																					Value: $ {player.value} M FL Points:{' '}
																					{player.fl_points}
																				</Typography>
																			</React.Fragment>
																		}
																	/>
																</ListItem>
															</div>
														) : (
															<div key={'empty_new_' + player.player_id} />
														)
												)}
											</List>
										</Dialog>
									</div>
								)}
							</Card>
						</Grid>
						{/* Central Midfielder 2 */}
						<Grid item xs={3}>
							<Card className={classes.playercard} align='center'>
								<Typography align='center'>Midfielders</Typography>

								{firstElevenCm2 ? (
									<div>
										<Avatar src={getTeamLogo(firstElevenCm2)}> </Avatar>
										<Typography>{firstElevenCm2.firstname}</Typography>
										<Typography>SGD {firstElevenCm2.value} </Typography>
										<Typography>FL Points {firstElevenCm2.fl_points}</Typography>
										<Link
											align='center'
											component='button'
											variant='body2'
											onClick={(e) => firstElevenSetup('Midfielder', 'CenterMidfield2')}
										>
											Change Midfielder
										</Link>
										{reserveMf ? (
											<div>
												<Dialog open={openCm2Dialog} aria-labelledby='form-dialog-title'>
													<DialogTitle id='form-dialog-title'>
														Midfielder Selection
													</DialogTitle>
													<List>
														{teamPlayers.map(
															(player) =>
																player.position === 'Midfielder' &&
																player.first_eleven === false ? (
																	<div key={player.player_id}>
																		<ListItem
																			button
																			onClick={() =>
																				handleListItemClick(
																					player,
																					'CenterMidfield2',
																					'change'
																				)}
																			key={player.player_id}
																		>
																			<ListItemAvatar>
																				<Avatar
																					src={getTeamLogo(player)}
																					className={classes.avatar}
																				/>
																			</ListItemAvatar>
																			<ListItemText
																				primary={player.firstname}
																				secondary={
																					<React.Fragment>
																						<Typography
																							component='span'
																							variant='body2'
																							className={classes.inline}
																							color='textPrimary'
																						>
																							Value: $ {player.value} M
																							Points: {player.fl_points}
																						</Typography>
																					</React.Fragment>
																				}
																			/>
																		</ListItem>
																	</div>
																) : (
																	<div key={'empty_' + player.player_id} />
																)
														)}
													</List>
												</Dialog>
											</div>
										) : (
											<div>
												<Dialog
													open={openCm2Dialog}
													aria-labelledby='alert-dialog-title'
													aria-describedby='alert-dialog-description'
												>
													<DialogTitle id='alert-dialog-title'>
														{'Team Setup Message'}
													</DialogTitle>
													<DialogContent>
														<DialogContentText id='alert-dialog-description'>
															No midfield reserve for substitution.
														</DialogContentText>
													</DialogContent>
													<DialogActions>
														<Button
															onClick={(e) => setOpenCm2Dialog(false)}
															color='primary'
															autoFocus
														>
															Close
														</Button>
													</DialogActions>
												</Dialog>
											</div>
										)}
									</div>
								) : (
									<div>
										<Avatar src='https://www.shareicon.net/data/512x512/2016/06/30/788946_people_512x512.png' />
										<Link
											align='center'
											component='button'
											variant='body2'
											onClick={(e) => setOpenCm2Dialog(true)}
										>
											Select Midfielder
										</Link>
										<Dialog open={openCm2Dialog} aria-labelledby='form-dialog-title'>
											<DialogTitle id='form-dialog-title'>Midfielder Selection</DialogTitle>
											<List>
												{teamPlayers.map(
													(player) =>
														player.position === 'Midfielder' &&
														player.first_eleven === false ? (
															<div key={player.player_id}>
																<ListItem
																	button
																	onClick={() =>
																		handleListItemClick(
																			player,
																			'CenterMidfield2',
																			'new'
																		)}
																	key={player.player_id}
																>
																	<ListItemAvatar>
																		<Avatar
																			src={getTeamLogo(player)}
																			className={classes.avatar}
																		/>
																	</ListItemAvatar>
																	<ListItemText
																		primary={player.firstname}
																		secondary={
																			<React.Fragment>
																				<Typography
																					component='span'
																					variant='body2'
																					className={classes.inline}
																					color='textPrimary'
																				>
																					Value: $ {player.value} M FL Points:{' '}
																					{player.fl_points}
																				</Typography>
																			</React.Fragment>
																		}
																	/>
																</ListItem>
															</div>
														) : (
															<div key={player.player_id} />
														)
												)}
											</List>
										</Dialog>
									</div>
								)}
							</Card>
						</Grid>
						{/* Left Midfielder */}
						<Grid item xs={3}>
							<Card className={classes.playercard} align='center'>
								<Typography align='center'>Midfielders</Typography>

								{firstElevenLm ? (
									<div>
										<Avatar src={getTeamLogo(firstElevenLm)}> </Avatar>
										<Typography>{firstElevenLm.firstname}</Typography>
										<Typography>SGD {firstElevenLm.value} </Typography>
										<Typography>FL Points {firstElevenLm.fl_points}</Typography>
										<Link
											align='center'
											component='button'
											variant='body2'
											onClick={(e) => firstElevenSetup('Midfielder', 'LeftMidfield')}
										>
											Change Midfielder
										</Link>
										{reserveMf ? (
											<div>
												<Dialog open={openLmDialog} aria-labelledby='form-dialog-title'>
													<DialogTitle id='form-dialog-title'>
														Midfielder Selection
													</DialogTitle>
													<List>
														{teamPlayers.map(
															(player) =>
																player.position === 'Midfielder' &&
																player.first_eleven === false ? (
																	<div key={player.player_id}>
																		<ListItem
																			button
																			onClick={() =>
																				handleListItemClick(
																					player,
																					'LeftMidfield',
																					'change'
																				)}
																			key={player.player_id}
																		>
																			<ListItemAvatar>
																				<Avatar
																					src={getTeamLogo(player)}
																					className={classes.avatar}
																				/>
																			</ListItemAvatar>
																			<ListItemText
																				primary={player.firstname}
																				secondary={
																					<React.Fragment>
																						<Typography
																							component='span'
																							variant='body2'
																							className={classes.inline}
																							color='textPrimary'
																						>
																							Value: $ {player.value} M
																							Points: {player.fl_points}
																						</Typography>
																					</React.Fragment>
																				}
																			/>
																		</ListItem>
																	</div>
																) : (
																	<div key={'empty_' + player.player_id} />
																)
														)}
													</List>
												</Dialog>
											</div>
										) : (
											<div>
												<Dialog
													open={openLmDialog}
													aria-labelledby='alert-dialog-title'
													aria-describedby='alert-dialog-description'
												>
													<DialogTitle id='alert-dialog-title'>
														{'Team Setup Message'}
													</DialogTitle>
													<DialogContent>
														<DialogContentText id='alert-dialog-description'>
															No midfield reserve for substitution.
														</DialogContentText>
													</DialogContent>
													<DialogActions>
														<Button
															onClick={(e) => setOpenLmDialog(false)}
															color='primary'
															autoFocus
														>
															Close
														</Button>
													</DialogActions>
												</Dialog>
											</div>
										)}
									</div>
								) : (
									<div>
										<Avatar src='https://www.shareicon.net/data/512x512/2016/06/30/788946_people_512x512.png' />
										<Link
											align='center'
											component='button'
											variant='body2'
											onClick={(e) => setOpenLmDialog(true)}
										>
											Select Midfielder
										</Link>
										<Dialog open={openLmDialog} aria-labelledby='form-dialog-title'>
											<DialogTitle id='form-dialog-title'>Midfielder Selection</DialogTitle>
											<List>
												{teamPlayers.map(
													(player) =>
														player.position === 'Midfielder' &&
														player.first_eleven === false ? (
															<div key={player.player_id}>
																<ListItem
																	button
																	onClick={() =>
																		handleListItemClick(
																			player,
																			'LeftMidfield',
																			'new'
																		)}
																	key={player.player_id}
																>
																	<ListItemAvatar>
																		<Avatar
																			src={getTeamLogo(player)}
																			className={classes.avatar}
																		/>
																	</ListItemAvatar>
																	<ListItemText
																		primary={player.firstname}
																		secondary={
																			<React.Fragment>
																				<Typography
																					component='span'
																					variant='body2'
																					className={classes.inline}
																					color='textPrimary'
																				>
																					Value: $ {player.value} M FL Points:{' '}
																					{player.fl_points}
																				</Typography>
																			</React.Fragment>
																		}
																	/>
																</ListItem>
															</div>
														) : (
															<div key={player.player_id} />
														)
												)}
											</List>
										</Dialog>
									</div>
								)}
							</Card>
						</Grid>
					</Grid>
				</Grid>

				{/* ATTACKER SECTION * No Grid Container needed/}
			{/* Attacker 1 */}
				<Grid item xs={3}>
					<Card className={classes.playercard} align='center'>
						<Typography align='center'>Attacker</Typography>

						{firstElevenAt1 ? (
							<div>
								<Avatar src={getTeamLogo(firstElevenAt1)}> </Avatar>
								<Typography>{firstElevenAt1.firstname}</Typography>
								<Typography>SGD {firstElevenAt1.value} M</Typography>
								<Typography>FL Points {firstElevenAt1.fl_points}</Typography>
								<Link
									align='center'
									component='button'
									variant='body2'
									onClick={(e) => firstElevenSetup('Attacker', 'Attacker1')}
								>
									Change Attacker
								</Link>
								{reserveAt ? (
									<div>
										<Dialog open={openAt1Dialog} aria-labelledby='form-dialog-title'>
											<DialogTitle id='form-dialog-title'>Attacker Selection</DialogTitle>
											<List>
												{teamPlayers.map(
													(player) =>
														player.position === 'Forward' &&
														player.first_eleven === false ? (
															<div key={player.player_id}>
																<ListItem
																	button
																	onClick={() =>
																		handleListItemClick(
																			player,
																			'Attacker1',
																			'change'
																		)}
																	key={player.player_id}
																>
																	<ListItemAvatar>
																		<Avatar
																			src={getTeamLogo(player)}
																			className={classes.avatar}
																		/>
																	</ListItemAvatar>
																	<ListItemText
																		primary={player.firstname}
																		secondary={
																			<React.Fragment>
																				<Typography
																					component='span'
																					variant='body2'
																					className={classes.inline}
																					color='textPrimary'
																				>
																					Value: $ {player.value} M FL Points:{' '}
																					{player.fl_points}
																				</Typography>
																			</React.Fragment>
																		}
																	/>
																</ListItem>
															</div>
														) : (
															<div key={'change_' + player.player_id} />
														)
												)}
											</List>
										</Dialog>
									</div>
								) : (
									<div>
										<Dialog
											open={openAt1Dialog}
											aria-labelledby='alert-dialog-title'
											aria-describedby='alert-dialog-description'
										>
											<DialogTitle id='alert-dialog-title'>{'Team Setup Message'}</DialogTitle>
											<DialogContent>
												<DialogContentText id='alert-dialog-description'>
													No reserve(s) for substitution.
												</DialogContentText>
											</DialogContent>
											<DialogActions>
												<Button
													onClick={(e) => setOpenAt1Dialog(false)}
													color='primary'
													autoFocus
												>
													Close
												</Button>
											</DialogActions>
										</Dialog>
									</div>
								)}
							</div>
						) : (
							<div>
								<Avatar src='https://www.shareicon.net/data/512x512/2016/06/30/788946_people_512x512.png' />
								<Link
									align='center'
									component='button'
									variant='body2'
									onClick={(e) => setOpenAt1Dialog(true)}
								>
									Select Attacker
								</Link>
								<Dialog open={openAt1Dialog} aria-labelledby='form-dialog-title'>
									<DialogTitle id='form-dialog-title'>Attacker Selection</DialogTitle>
									<List>
										{teamPlayers.map(
											(player) =>
												player.position === 'Forward' && player.first_eleven === false ? (
													<div key={player.player_id}>
														<ListItem
															button
															onClick={() =>
																handleListItemClick(player, 'Attacker1', 'new')}
															key={player.player_id}
														>
															<ListItemAvatar>
																<Avatar
																	src={getTeamLogo(player)}
																	className={classes.avatar}
																/>
															</ListItemAvatar>
															<ListItemText
																primary={player.firstname}
																secondary={
																	<React.Fragment>
																		<Typography
																			component='span'
																			variant='body2'
																			className={classes.inline}
																			color='textPrimary'
																		>
																			Value: $ {player.value} M FL Points:{' '}
																			{player.fl_points}
																		</Typography>
																	</React.Fragment>
																}
															/>
														</ListItem>
													</div>
												) : (
													<div key={'new_' + player.player_id} />
												)
										)}
									</List>
								</Dialog>
							</div>
						)}
					</Card>
				</Grid>
				{/* Attacker 2 */}
				<Grid item xs={3}>
					<Card className={classes.playercard} align='center'>
						<Typography align='center'>Attacker</Typography>

						{firstElevenAt2 ? (
							<div>
								<Avatar src={getTeamLogo(firstElevenAt2)}> </Avatar>
								<Typography>{firstElevenAt2.firstname}</Typography>
								<Typography>SGD {firstElevenAt2.value} M</Typography>
								<Typography>FL Points {firstElevenAt2.fl_points}</Typography>
								<Link
									align='center'
									component='button'
									variant='body2'
									onClick={(e) => firstElevenSetup('Attacker', 'Attacker2')}
								>
									Change Attacker
								</Link>
								{reserveAt ? (
									<div>
										<Dialog open={openAt2Dialog} aria-labelledby='form-dialog-title'>
											<DialogTitle id='form-dialog-title'>Attacker Selection</DialogTitle>
											<List>
												{teamPlayers.map(
													(player) =>
														player.position === 'Forward' &&
														player.first_eleven === false ? (
															<div key={player.player_id}>
																<ListItem
																	button
																	onClick={() =>
																		handleListItemClick(
																			player,
																			'Attacker2',
																			'change'
																		)}
																	key={player.player_id}
																>
																	<ListItemAvatar>
																		<Avatar
																			src={getTeamLogo(player)}
																			className={classes.avatar}
																		/>
																	</ListItemAvatar>
																	<ListItemText
																		primary={player.firstname}
																		secondary={
																			<React.Fragment>
																				<Typography
																					component='span'
																					variant='body2'
																					className={classes.inline}
																					color='textPrimary'
																				>
																					Value: $ {player.value} M FL Points:{' '}
																					{player.fl_points}
																				</Typography>
																			</React.Fragment>
																		}
																	/>
																</ListItem>
															</div>
														) : (
															<div key={'change_' + player.player_id} />
														)
												)}
											</List>
										</Dialog>
									</div>
								) : (
									<div>
										<Dialog
											open={openAt2Dialog}
											aria-labelledby='alert-dialog-title'
											aria-describedby='alert-dialog-description'
										>
											<DialogTitle id='alert-dialog-title'>{'Team Setup Message'}</DialogTitle>
											<DialogContent>
												<DialogContentText id='alert-dialog-description'>
													No reserve(s) for substitution.
												</DialogContentText>
											</DialogContent>
											<DialogActions>
												<Button
													onClick={(e) => setOpenAt2Dialog(false)}
													color='primary'
													autoFocus
												>
													Close
												</Button>
											</DialogActions>
										</Dialog>
									</div>
								)}
							</div>
						) : (
							<div>
								<Avatar src='https://www.shareicon.net/data/512x512/2016/06/30/788946_people_512x512.png' />
								<Link
									align='center'
									component='button'
									variant='body2'
									onClick={(e) => setOpenAt2Dialog(true)}
								>
									Select Attacker
								</Link>
								<Dialog open={openAt2Dialog} aria-labelledby='form-dialog-title'>
									<DialogTitle id='form-dialog-title'>Attacker Selection</DialogTitle>
									<List>
										{teamPlayers.map(
											(player) =>
												player.position === 'Forward' && player.first_eleven === false ? (
													<div key={player.player_id}>
														<ListItem
															button
															onClick={() =>
																handleListItemClick(player, 'Attacker2', 'new')}
															key={player.player_id}
														>
															<ListItemAvatar>
																<Avatar
																	src={getTeamLogo(player)}
																	className={classes.avatar}
																/>
															</ListItemAvatar>
															<ListItemText
																primary={player.firstname}
																secondary={
																	<React.Fragment>
																		<Typography
																			component='span'
																			variant='body2'
																			className={classes.inline}
																			color='textPrimary'
																		>
																			Value: $ {player.value} M FL Points:{' '}
																			{player.fl_points}
																		</Typography>
																	</React.Fragment>
																}
															/>
														</ListItem>
													</div>
												) : (
													<div key={'new_' + player.player_id} />
												)
										)}
									</List>
								</Dialog>
							</div>
						)}
					</Card>
				</Grid>
			</Grid>

			<span> &nbsp; </span>

			<Grid item xs={12}>
				<Card className={classes.root} align='center'>
					<Typography variant='h6' gutterBottom>
						Reserves
					</Typography>
				</Card>
			</Grid>
			{/* RESERVES SECTION */}
			<Grid item xs={12}>
				<Grid container spacing={1}>
					{/* Reserve Goalkeeper */}
					<Grid item xs={3}>
						<Card className={classes.playercard} align='center'>
							{reserveGk ? (
								<div>
									<Typography align='center'>GoalKeeper</Typography>
									<Avatar src={getTeamLogo(reserveGk)}> </Avatar>
									<Typography>{reserveGk.firstname}</Typography>
									<Typography>SGD {reserveGk.value} M</Typography>
									<Typography>FL Points {reserveGk.fl_points}</Typography>
								</div>
							) : (
								<div>
									<Typography align='center'>GoalKeeper</Typography>
									<Avatar src='https://www.shareicon.net/data/512x512/2016/06/30/788946_people_512x512.png'>
										{' '}
									</Avatar>
								</div>
							)}
						</Card>
					</Grid>

					{/* Reserve Defender */}
					<Grid item xs={3}>
						<Card className={classes.playercard} align='center'>
							{reserveDf ? (
								<div>
									<Typography align='center'>Defender</Typography>
									<Avatar src={getTeamLogo(reserveDf)}> </Avatar>
									<Typography>{reserveDf.firstname}</Typography>
									<Typography>SGD {reserveDf.value} M</Typography>
									<Typography>FL Points {reserveDf.fl_points}</Typography>
								</div>
							) : (
								<div>
									<Typography align='center'>Defender</Typography>
									<Avatar src='https://www.shareicon.net/data/512x512/2016/06/30/788946_people_512x512.png' />
								</div>
							)}
						</Card>
					</Grid>

					{/* Reserve Midfielder */}
					<Grid item xs={3}>
						<Card className={classes.playercard} align='center'>
							{reserveMf ? (
								<div>
									<Typography align='center'>Midfielder</Typography>
									<Avatar src={getTeamLogo(reserveMf)}> </Avatar>
									<Typography>{reserveMf.firstname}</Typography>
									<Typography>SGD {reserveMf.value} M</Typography>
									<Typography>FL Points {reserveMf.fl_points}</Typography>
								</div>
							) : (
								<div>
									<Typography align='center'>Midfielder</Typography>
									<Avatar src='https://www.shareicon.net/data/512x512/2016/06/30/788946_people_512x512.png' />
								</div>
							)}
						</Card>
					</Grid>
					{/* Reserve Attacker */}
					<Grid item xs={3}>
						<Card className={classes.playercard} align='center'>
							{reserveAt ? (
								<div>
									<Typography align='center'>Attacker</Typography>
									<Avatar src={getTeamLogo(reserveAt)}> </Avatar>
									<Typography>{reserveAt.firstname}</Typography>
									<Typography>SGD {reserveAt.value} M</Typography>
									<Typography>FL Points {reserveAt.fl_points}</Typography>
								</div>
							) : (
								<div>
									<Typography align='center'>Attacker</Typography>
									<Avatar src='https://www.shareicon.net/data/512x512/2016/06/30/788946_people_512x512.png' />
								</div>
							)}
						</Card>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				{transferWindowStatus === 'open' ? (
					<Button
						variant='contained'
						color='primary'
						size='small'
						className={classes.button}
						startIcon={<SaveIcon />}
						fullWidth
						onClick={handleSave}
					>
						Save
					</Button>
				) : (
					<Button
						disabled
						variant='contained'
						color='primary'
						size='small'
						className={classes.button}
						startIcon={<SaveIcon />}
						fullWidth
						onClick={handleSave}
					>
						Transfer window is closed.
					</Button>
				)}

				<Dialog
					open={openSaveDialog}
					onClose={handleCloseSaveDialog}
					aria-labelledby='alert-dialog-title'
					aria-describedby='alert-dialog-description'
				>
					<DialogTitle id='alert-dialog-title'>{'Match Day Team'}</DialogTitle>
					<DialogContent>
						<DialogContentText id='alert-dialog-description'>{saveButtonDialogText}</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleCloseSaveDialog} color='primary' autoFocus>
							Close
						</Button>
					</DialogActions>
				</Dialog>
			</Grid>
		</StyledGrid>
	);
}

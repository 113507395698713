import React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';

const PREFIX = 'About';

const classes = {
    root: `${PREFIX}-root`,
    media: `${PREFIX}-media`
};

const StyledGrid = styled(Grid)({
	[`& .${classes.root}`]: {
		maxWidth: 500
	},
	[`& .${classes.media}`]: {
		height: 140
	}
});

export default function MediaCard() {


	return (
        <StyledGrid container alignContent='center'>
			<Grid item alignItems='center' xs={12}>
				<Card className={classes.root}>
					<CardActionArea>
						<CardMedia
							className={classes.media}
							image='https://firebasestorage.googleapis.com/v0/b/voiddeckfootie.appspot.com/o/2020SPL_Players%2Fgifc%2Fvoiddeckfootball.jpg?alt=media&token=397f0574-0ff2-460e-8e0d-23b40e24f42c'
							title='Voiddeck Football'
						/>
						<CardContent>
							<Typography gutterBottom variant='h6' component='h2'>
								Voiddeck Football
							</Typography>
							<Typography variant='subtitle1' component='p'>
								Bringing together football fans through technology. <br />
								<a href='mailto:contact@voiddeck.sg'>Email me</a> for any enquiries.
							</Typography>
						</CardContent>
					</CardActionArea>
				</Card>
			</Grid>
		</StyledGrid>
    );
}

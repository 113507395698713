import firebase from '../firebase';

function submitPrediction(user_id, currentMatch, predictedHomeTeamScore, predictedAwayTeamScore) {
	console.log(currentMatch.match_id + ' ' + predictedHomeTeamScore + ' ' + predictedAwayTeamScore + ' ' + user_id);
	let homeTeamResult = '';
	let awayTeamResult = '';

	if (predictedHomeTeamScore > predictedAwayTeamScore) {
		homeTeamResult = 'Win';
		awayTeamResult = 'Lose';
	} else if (predictedHomeTeamScore < predictedAwayTeamScore) {
		homeTeamResult = 'Lose';
		awayTeamResult = 'Win';
	} else if (predictedHomeTeamScore === predictedAwayTeamScore) {
		homeTeamResult = 'Draw';
		awayTeamResult = 'Draw';
	}

	console.log(user_id);
	console.log(currentMatch.match_id);
	console.log(currentMatch.match_week);
	console.log(currentMatch.match_date);

	console.log(predictedHomeTeamScore);
	console.log(predictedAwayTeamScore);

	console.log(currentMatch.hometeam_id);
	console.log(currentMatch.hometeam_name);
	console.log(currentMatch.hometeam_logo_url);
	console.log(homeTeamResult);

	console.log(currentMatch.awayteam_id);
	console.log(currentMatch.awayteam_name);
	console.log(currentMatch.awayteam_logo_url);
	console.log(awayTeamResult);



	/*
    var temp = myMatchPredictions.find((el) => el.match_id === currentMatch.match_id);
    var index = myMatchPredictions.findIndex((el) => el.match_id === currentMatch.match_id);
    if (temp == undefined) {
        myMatchPredictions.push(data);
        setMyMatchPredictions(myMatchPredictions);
    } else {
        temp.predicted_home_score = predictedHomeTeamScore;
        temp.predicted_away_score = predictedAwayTeamScore;
        console.log(temp);
        myMatchPredictions.splice(index, 1, { ...temp });
        setMyMatchPredictions(myMatchPredictions);
    }
    */

	const db = firebase.firestore();

	if (currentMatch.hometeam_id.length === 4 && currentMatch.awayteam_id.length === 4) {

		let data = {
			match_id              : currentMatch.match_id,
			hometeam_name         : currentMatch.hometeam_name,
			awayteam_name         : currentMatch.awayteam_name,
			hometeam_logo_url     : currentMatch.hometeam_logo_url,
			awayteam_logo_url     : currentMatch.awayteam_logo_url,
			predicted_away_result : awayTeamResult,
			predicted_away_score  : predictedAwayTeamScore,
			predicted_home_result : homeTeamResult,
			predicted_home_score  : predictedHomeTeamScore,
			prediction_points     : -1,
			prediction_timestamp  : Date.now(),
			match_date            : currentMatch.match_date,
			match_week            : currentMatch.match_week,
			uid                   : user_id,
			match_competition_status  : 'SPL'
		};

		let setDoc = db
		.collection('2022Matches')
		.doc(currentMatch.match_id.trim())
		.collection('2022_Match_Predictions')
		.doc(user_id + '_' + currentMatch.match_id.trim())
		.set(data, { merge: true })
		.then(function() {
			var homeTeamWinCount = currentMatch.hometeam_predictions_win;
			var awayTeamWinCount = currentMatch.awayteam_predictions_win;
			var homeTeamDrawCount = currentMatch.hometeam_predictions_draw;

			if (homeTeamResult === 'Win') homeTeamWinCount = homeTeamWinCount + 1;
			else if (awayTeamResult === 'Win') awayTeamWinCount = awayTeamWinCount + 1;
			else if (homeTeamResult === 'Draw') homeTeamDrawCount = homeTeamDrawCount + 1;
			console.log(currentMatch);
			let predictionscountdata = {
				hometeam_predictions_win  : homeTeamWinCount,
				awayteam_predictions_win  : awayTeamWinCount,
				hometeam_predictions_draw : homeTeamDrawCount,
				total_predictions         : homeTeamWinCount + awayTeamWinCount + homeTeamDrawCount
			};

			console.log(predictionscountdata);

			let setDoc = db
				.collection('2022Matches')
				.doc(currentMatch.match_id.trim())
				.set(predictionscountdata, { merge: true })
				.then(function() {
					console.log('Match predictions updated');
					window.location.reload();
				});
		})
		.catch((error) => console.log(error));

	} else {
		let data = {
			match_id              : currentMatch.match_id,
			round				  : currentMatch.round,
			hometeam_name         : currentMatch.hometeam_name,
			awayteam_name         : currentMatch.awayteam_name,
			hometeam_logo_url     : currentMatch.hometeam_logo_url,
			awayteam_logo_url     : currentMatch.awayteam_logo_url,
			predicted_away_result : awayTeamResult,
			predicted_away_score  : predictedAwayTeamScore,
			predicted_home_result : homeTeamResult,
			predicted_home_score  : predictedHomeTeamScore,
			prediction_points     : -1,
			prediction_timestamp  : Date.now(),
			match_date            : currentMatch.match_date,
			match_week            : currentMatch.match_week,
			uid                   : user_id,
			match_competition_status  : 'WSPL'
		};
		let setDoc = db
		.collection('2022WPLMatches')
		.doc(currentMatch.match_id.trim())
		.collection('2022_Match_Predictions')
		.doc(user_id + '_' + currentMatch.match_id.trim())
		.set(data, { merge: true })
		.then(function() {
			var homeTeamWinCount = currentMatch.hometeam_predictions_win;
			var awayTeamWinCount = currentMatch.awayteam_predictions_win;
			var homeTeamDrawCount = currentMatch.hometeam_predictions_draw;

			if (homeTeamResult === 'Win') homeTeamWinCount = homeTeamWinCount + 1;
			else if (awayTeamResult === 'Win') awayTeamWinCount = awayTeamWinCount + 1;
			else if (homeTeamResult === 'Draw') homeTeamDrawCount = homeTeamDrawCount + 1;
			console.log(currentMatch);
			let predictionscountdata = {
				hometeam_predictions_win  : homeTeamWinCount,
				awayteam_predictions_win  : awayTeamWinCount,
				hometeam_predictions_draw : homeTeamDrawCount,
				total_predictions         : homeTeamWinCount + awayTeamWinCount + homeTeamDrawCount,
				match_competition_status  : 'WSPL'
			};

			console.log(predictionscountdata);

			let setDoc = db
				.collection('2022WPLMatches')
				.doc(currentMatch.match_id.trim())
				.set(predictionscountdata, { merge: true })
				.then(function() {
					console.log('Match predictions updated');
					window.location.reload();
				});
		})
		.catch((error) => console.log(error));
	}


}

export { submitPrediction };

import React, { useCallback, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useSession } from '../App';
import MenuItem from '@mui/material/MenuItem';
import { Typography, TextField } from '@mui/material';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import Grid from '@mui/material/Grid';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import { sha1 } from 'crypto-hash';
import firebase from '../firebase';

const PREFIX = 'CreatePlayers';

const classes = {
	root : `${PREFIX}-root`
};

const Root = styled('form')(({ theme }) => ({
	[`&.${classes.root}`]: {
		'& .MuiTextField-root' : {
			margin : theme.spacing(1),
			width  : '25ch'
		}
	}
}));

const teams = [
	{
		value : 'ANFC',
		label : 'Albirex Niigata'
	},
	{
		value : 'BKFC',
		label : 'Balestier Khalsa'
	},
	{
		value : 'GIFC',
		label : 'Geylang International'
	},
	{
		value : 'HUFC',
		label : 'Hougang United'
	},
	{
		value : 'LCSF',
		label : 'Lion City Sailors'
	},
	{
		value : 'TPFC',
		label : 'Tanjong Pagar'
	},
	{
		value : 'TRFC',
		label : 'Tampines Rovers'
	},
	{
		value : 'YLFC',
		label : 'Young Lions'
	}
];

const rounds = [
	{
		value : '1',
		label : 'Round 1'
	},
	{
		value : '2',
		label : 'Round 2'
	},
	{
		value : '3',
		label : 'Round 3'
	},
	{
		value : '4',
		label : 'Round 4'
	}
];

const selections = {
	albirex_niigata       : {
		name   : 'Albirex niigata',
		logo   : '/images/team_logos/anfc.png',
		avatar : 'A'
	},
	balestier_khalsa      : {
		name   : 'Balestier Khalsa',
		logo   : '/images/team_logos/bkfc.png',
		avatar : 'B'
	},
	geylang_international : {
		name   : 'Geylang International',
		logo   : '/images/team_logos/gifc.png',
		avatar : 'G'
	},
	hougang_united        : {
		name   : 'Hougang United',
		logo   : '/images/team_logos/hgfc.png',
		avatar : 'H'
	},
	lion_city_sailors     : {
		name   : 'Lion City Sailors',
		logo   : '/images/team_logos/lcsf.png',
		avatar : 'L'
	},
	tanjong_pagar         : {
		name   : 'Tanjong Pagar',
		logo   : '/images/team_logos/tpfc.png',
		avatar : 'T'
	},
	tampines_rovers       : {
		name   : 'Tampines Rovers',
		logo   : '/images/team_logos/trfc.png',
		avatar : 'T'
	},
	young_lions           : {
		name   : 'Young Lions',
		logo   : '/images/team_logos/ylfc.png',
		avatar : 'Y'
	}
};

function Alert(props) {
	return <MuiAlert elevation={6} variant='filled' {...props} />;
}

export default function CreatePlayers() {
	const user = useSession();
	const [ authorized, setAuthorized ] = useState(false);
	const [ state, setState ] = useState(false);

	const [ homeTeam, setHomeTeam ] = useState('');
	const [ awayTeam, setAwayTeam ] = useState('');
	const [ year, setYear ] = useState(2022);
	const [ round, setRound ] = useState(1);
	const [ week, setWeek ] = useState(1);
	const [ matchDate, setMatchDate ] = useState('');
	const [ matchTime, setMatchTime ] = useState('');
	const [ venue, setVenue ] = useState('');
	const [ open, setOpen ] = React.useState(false);

	const [ playerFullname, setPlayerFullname ] = useState('');
	const [ playerPosition, setPlayerPosition ] = useState(10);
	const [ playerU23, setPlayerU23 ] = useState(20);
	const [ playerValue, setPlayerValue ] = useState(0);
	const [ playerSquadNumber, setPlayerSquadNumber ] = useState(0);

	const handleClick = () => {
		setOpen(true);
	};

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};

	const handleHomeTeamChange = (event) => {
		setHomeTeam(event.target.value);
		console.log('Home Team selected: ' + event.target.value);

		if (event.target.value === 'GIFC' || event.target.value === 'TRFC') {
			setVenue('Our Tampines Hub');
			console.log('Venue auto selected: ' + 'Our Tampines Hub');
		} else if (event.target.value === 'BKFC') {
			setVenue('Toa Payoh Stadium');
			console.log('Venue auto selected: ' + 'Toa Payoh Stadium');
		} else if (event.target.value === 'HUFC') {
			setVenue('Hougang Stadium');
			console.log('Venue auto selected: ' + 'Hougang Stadium');
		} else if (event.target.value === 'TPFC' || event.target.value === 'ANFC') {
			setVenue('Jurong East Stadium');
			console.log('Venue auto selected: ' + 'Jurong East Stadium');
		} else if (event.target.value === 'YLFC' || event.target.value === 'LCSF') {
			setVenue('Jalan Besar Stadium');
			console.log('Venue auto selected: ' + 'Jalan Besar Stadium');
		} else {
			setVenue('');
		}
	};

	const handleAwayTeamChange = (event) => {
		setAwayTeam(event.target.value);
		console.log('Away Team selected: ' + event.target.value);
	};

	const handleYearChange = (event) => {
		setYear(event.target.value);
	};

	const handleRoundChange = (event) => {
		setRound(event.target.value);
		console.log('Round selected: ' + event.target.value);
	};

	const handleWeekChange = (event) => {
		setWeek(event.target.value);
		console.log('Week selected: ' + event.target.value);
	};

	const handleDateChange = (event) => {
		setMatchDate(event.target.value);
		console.log('Date selected: ' + event.target.value);
	};

	const handleTimeChange = (event) => {
		setMatchTime(event.target.value);
		console.log('Time selected: ' + event.target.value);
	};

	const handleVenueChange = (event) => {
		setVenue(event.target.value);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		console.log('submit');
	};

	async function handleAddPlayer() {
		createPlayerInFirestore();
	}

	async function createPlayerInFirestore() {
		let homeTeamLogo = '';
		let homeTeamName = '';
		let homeTeamAvatar = '';
		let photoBaseUrl = '';
		let teamLogoUrl = '';
		let teamName = '';
		let teamShortname = '';
		let collectionName = '';

		if (homeTeam === 'ANFC') {
			homeTeamLogo = selections.albirex_niigata.logo;
			homeTeamName = selections.albirex_niigata.name;
			homeTeamAvatar = selections.albirex_niigata.avatar;
			photoBaseUrl = '/images/players/anfc/';
			teamLogoUrl = '/images/team_logos/anfc.png';
			teamName = 'Albirex Niigata (SG) Football Club';
			teamShortname = 'Albirex Niigata';
			collectionName = '2022Teams/ANFC/2022Players';
		} else if (homeTeam === 'BKFC') {
			homeTeamLogo = selections.balestier_khalsa.logo;
			homeTeamName = selections.balestier_khalsa.name;
			homeTeamAvatar = selections.balestier_khalsa.avatar;
			photoBaseUrl = '/images/players/bkfc/';
			teamLogoUrl = '/images/team_logos/bkfc.png';
			teamName = 'Balestier Khalsa Football Club';
			teamShortname = 'Balestier Khalsa';
			collectionName = '2022Teams/BKFC/2022Players';
		} else if (homeTeam === 'GIFC') {
			homeTeamLogo = selections.geylang_international.logo;
			homeTeamName = selections.geylang_international.name;
			homeTeamAvatar = selections.geylang_international.avatar;
			photoBaseUrl = '/images/players/gifc/';
			teamLogoUrl = '/images/team_logos/gifc.png';
			teamName = 'Geylang International Football Club';
			teamShortname = "Geylang I'ntl";
			collectionName = '2022Teams/GIFC/2022Players';
		} else if (homeTeam === 'HUFC') {
			homeTeamLogo = selections.hougang_united.logo;
			homeTeamName = selections.hougang_united.name;
			homeTeamAvatar = selections.hougang_united.avatar;
			photoBaseUrl = '/images/players/hufc/';
			teamLogoUrl = '/images/team_logos/hufc.png';
			teamName = 'Hougang United Football Club';
			teamShortname = 'Hougang United';
			collectionName = '2022Teams/HUFC/2022Players';
		} else if (homeTeam === 'LCSF') {
			homeTeamLogo = selections.lion_city_sailors.logo;
			homeTeamName = selections.lion_city_sailors.name;
			homeTeamAvatar = selections.lion_city_sailors.avatar;
			photoBaseUrl = '/images/players/lcsf/';
			teamLogoUrl = '/images/team_logos/lcsf.png';
			teamName = 'Lion City Sailors';
			teamShortname = 'Sailors';
			collectionName = '2022Teams/LCSF/2022Players';
		} else if (homeTeam === 'TPFC') {
			homeTeamLogo = selections.tanjong_pagar.logo;
			homeTeamName = selections.tanjong_pagar.name;
			homeTeamAvatar = selections.tanjong_pagar.avatar;
			photoBaseUrl = '/images/players/tpfc/';
			teamLogoUrl = '/images/team_logos/tpfc.png';
			teamName = 'Tanjong Pagar United Football Club';
			teamShortname = 'Tanjong Pagar';
			collectionName = '2022Teams/TPFC/2022Players';
		} else if (homeTeam === 'TRFC') {
			homeTeamLogo = selections.tampines_rovers.logo;
			homeTeamName = selections.tampines_rovers.name;
			homeTeamAvatar = selections.tampines_rovers.avatar;
			photoBaseUrl = '/images/players/trfc/';
			teamLogoUrl = '/images/team_logos/trfc.png';
			teamName = 'Tampines Rovers Football Club';
			teamShortname = 'Tampines Rovers';
			collectionName = '2022Teams/TRFC/2022Players';
		} else if (homeTeam === 'YLFC') {
			homeTeamLogo = selections.young_lions.logo;
			homeTeamName = selections.young_lions.name;
			homeTeamAvatar = selections.young_lions.avatar;
			photoBaseUrl = '/images/players/ylfc/';
			teamLogoUrl = '/images/team_logos/ylfc.png';
			teamName = 'Young Lions';
			teamShortname = 'Young Lions';
			collectionName = '2022Teams/YLFC/2022Players';
		}
		var firstname = '';
		var lastname = '';
		var u23 = false;
		var position = '';

		var nameSplit = playerFullname.split(' ');
		var playerId =
			(await generatePlayerId(playerFullname)) +
			'_' +
			nameSplit[0] +
			'_' +
			nameSplit[1] +
			'_' +
			playerSquadNumber;

		if (playerU23 === 10) u23 = true;

		if (playerPosition === 10)
			position = 'Goalkeeper';
		else if (playerPosition === 20)
			position = 'Defender';
		else if (playerPosition === 30)
			position = 'Midfielder';
		else if (playerPosition === 40)
			position = 'Forward';

		console.log('Sq No: ' + playerSquadNumber);
		console.log('full name: ' + playerFullname);
		console.log('first name: ' + nameSplit[0]);
		console.log('last name: ' + nameSplit[1]);
		console.log('position: :' + position);
		console.log('position id: ' + playerPosition);
		console.log('FL Value: ' + playerValue);
		console.log('Id: ' + playerId);

		let data = {
			active                : true,
			age                   : 0,
			firstname             : nameSplit[0],
			fl_points             : 0,
			fl_weekly_points      : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0, 'week_10':0, 'week_11':0, 'week_12':0, 'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0, 'week_23':0, 'week_24':0 },
			fullname              : playerFullname,
			lastname              : nameSplit[1],
			photo_url             : photoBaseUrl + nameSplit[0] + '_' + nameSplit[1] + '.png',
			player_value          : parseFloat(playerValue),
			player_id             : playerId,
			position              : position,
			position_id           : playerPosition,
			squad_number          : parseInt(playerSquadNumber),
			team_id               : homeTeam,
			team_logo_url         : teamLogoUrl,
			team_name             : teamName,
			team_short_name       : teamShortname,
			total_assists         : 0,
			total_clean_sheets    : 0,
			total_conceded        : 0,
			total_goals           : 0,
			total_minutes_played  : 0,
			total_penalty_missed  : 0,
			total_penalty_saved   : 0,
			total_ratings         : 0,
			total_red_cards       : 0,
			total_shots_saved     : 0,
			total_yellow_cards    : 0,
			u23                   : u23,
			value                 : playerValue,
			weekly_assists        : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0, 'week_10':0, 'week_11':0, 'week_12':0, 'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0,'week_23':0, 'week_24':0},
			weekly_clean_sheets   : { 'week_1': true, 'week_2': true, 'week_3': true, 'week_4': true, 'week_5': true, 'week_6': true, 'week_7': true, 'week_7.1': true, 'week_7.2': true, 'week_8':true, 'week_9':true, 'week_10':true, 'week_11':true, 'week_12':true, 'week_12.1':true,'week_13':true,'week_14':true,'week_15':true,'week_15.1':true, 'week_16':true, 'week_17':true, 'week_18':true, 'week_19':true,'week_20':true, 'week_21':true, 'week_22':true,'week_23':true, 'week_24':true  },
			weekly_conceded       : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0, 'week_10':0, 'week_11':0, 'week_12':0, 'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
			weekly_goals          : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0, 'week_10':0, 'week_11':0, 'week_12':0, 'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
			weekly_minutes_played : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0, 'week_10':0, 'week_11':0, 'week_12':0, 'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
			weekly_penalty_missed  : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0, 'week_10':0, 'week_11':0, 'week_12':0, 'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
			weekly_penalty_saved  : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0, 'week_10':0, 'week_11':0, 'week_12':0, 'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
			weekly_ratings        : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0, 'week_10':0, 'week_11':0, 'week_12':0, 'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
			weekly_red_cards      : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0, 'week_10':0, 'week_11':0, 'week_12':0, 'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0 },
			weekly_shots_saved    : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0, 'week_10':0, 'week_11':0, 'week_12':0, 'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0 },
			weekly_yellow_cards   : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0, 'week_10':0, 'week_11':0, 'week_12':0, 'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0 },
			weekly_own_goals      : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0, 'week_10':0, 'week_11':0, 'week_12':0, 'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0 },
			weekly_selection      : { 'week_1': false, 'week_2': false, 'week_3': false, 'week_4': false, 'week_5': false, 'week_6': false, 'week_7': false, 'week_7.1': false, 'week_7.2': false, 'week_8':false, 'week_9':false, 'week_10':false, 'week_11':false, 'week_12':false, 'week_12.1':false,'week_13':false,'week_14':false ,'week_15':false ,'week_15.1':false, 'week_16':false, 'week_17':false, 'week_18':false, 'week_19':false, 'week_20':false, 'week_21':false, 'week_22':false, 'week_23':false, 'week_24':false  }
		};
		const db = firebase.firestore();

		let setDoc = db
			.collection(collectionName)
			.doc(playerId)
			.set(data, { merge: true })
			.then(function() {
				console.log('Data Written: ' + playerId);
			})
			.catch((error) => console.log(error));
	}

	async function handleLoadPlayers(event) {
		let awayTeamLogo = '';
		let awayTeamName = '';
		let awayTeamAvatar = '';

		let matchAvatar = '';
		let matchCompetitionStatus = 'SPL';
		let matchId = '';
		let matchPredictionStatus = 'open';
		let matchStatus = 'matchday';
		let playerRatingStatus = 'closed';

		event.preventDefault();
		console.log('submit');

		if (homeTeam === 'ANFC') {
			const jsonData = require('../data/2022Players_anfc.json');
			console.log(jsonData);
			var firstname = '';
			var lastname = '';
			var position = '';
			var flValue = '';
			var squadNumber = 0;

			for (const key of Object.keys(jsonData)) {
				squadNumber = Number.parseInt(key);
				var player = jsonData[key];
				if (player.fullname !== null) {
					var nameSplit = player.fullname.split(' ');
					var positionId = 0;
					var playerId =
						(await generatePlayerId(player.fullname)) +
						'_' +
						nameSplit[0] +
						'_' +
						nameSplit[1] +
						'_' +
						squadNumber;

					if (player.position === 'Goalkeeper') positionId = 10;
					else if (player.position === 'Defender') positionId = 20;
					else if (player.position === 'Midfielder') positionId = 30;
					else if (player.position === 'Forward') positionId = 40;
					else console.log('Position Error');

					console.log('Sq No: ' + squadNumber);
					console.log('full name: ' + player.fullname);
					console.log('first name: ' + nameSplit[0]);
					console.log('last name: ' + nameSplit[1]);
					console.log('position: :' + player.position);
					console.log('position id: ' + positionId);
					console.log('FL Value: ' + player.fl_value);
					console.log('Id: ' + playerId);

					let data = {
						active                : true,
						age                   : 0,
						firstname             : nameSplit[0],
						fl_points             : 0,
						fl_weekly_points      : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0, 'week_10':0, 'week_11':0, 'week_12':0, 'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0 },
						fullname              : player.fullname,
						lastname              : nameSplit[1],
						photo_url             : '/images/players/anfc/' + nameSplit[0] + '_' + nameSplit[1] + '.png',
						player_value          : player.fl_value,
						player_id             : playerId,
						position              : player.position,
						position_id           : positionId,
						squad_number          : squadNumber,
						team_id               : 'ANFC',
						team_logo_url         : '/images/team_logos/anfc.png',
						team_name             : 'Albirex Niigata (SG) Football Club',
						team_short_name       : 'Albirex Niigata',
						total_assists         : 0,
						total_clean_sheets    : 0,
						total_conceded        : 0,
						total_goals           : 0,
						total_minutes_played  : 0,
						total_penalty_missed  : 0,
						total_penalty_saved   : 0,
						total_ratings         : 0,
						total_red_cards       : 0,
						total_shots_saved     : 0,
						total_yellow_cards    : 0,
						u23                   : false,
						value                 : player.fl_value,
						weekly_assists        : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0, 'week_10':0, 'week_11':0, 'week_12':0, 'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0 },
						weekly_clean_sheets   : { 'week_1': true, 'week_2': true, 'week_3': true, 'week_4': true, 'week_5': true, 'week_6': true, 'week_7': true, 'week_7.1': true, 'week_7.2': true, 'week_8':true, 'week_9':true, 'week_10':true, 'week_11':true, 'week_12':true, 'week_12.1':true,'week_13':true,'week_14':true,'week_15':true,'week_15.1':true, 'week_16':true, 'week_17':true, 'week_16':true, 'week_17':true},
						weekly_conceded       : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0, 'week_10':0, 'week_11':0, 'week_12':0, 'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0 },
						weekly_goals          : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0, 'week_10':0, 'week_11':0, 'week_12':0, 'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0 },
						weekly_minutes_played : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0, 'week_10':0, 'week_11':0, 'week_12':0, 'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0 },
						weekly_penalty_missed : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0, 'week_10':0, 'week_11':0, 'week_12':0, 'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0 },
						weekly_penalty_saved  : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0, 'week_10':0, 'week_11':0, 'week_12':0, 'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0 },
						weekly_ratings        : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0, 'week_10':0, 'week_11':0, 'week_12':0, 'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0 },
						weekly_red_cards      : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0, 'week_10':0, 'week_11':0, 'week_12':0, 'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0 },
						weekly_shots_saved    : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0, 'week_10':0, 'week_11':0, 'week_12':0, 'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0 },
						weekly_yellow_cards   : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0, 'week_10':0, 'week_11':0, 'week_12':0, 'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0 },
						weekly_own_goals      : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0, 'week_10':0, 'week_11':0, 'week_12':0, 'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0 },
						weekly_selection      : { 'week_1': false, 'week_2': false, 'week_3': false, 'week_4': false, 'week_5': false, 'week_6': false, 'week_7': false, 'week_7.1': false, 'week_7.2': false, 'week_8':false, 'week_9':false, 'week_10':false, 'week_11':false, 'week_12':false, 'week_12.1':false,'week_13':false,'week_13.1':false}
					}
					const db = firebase.firestore();

					let setDoc = db
						.collection('2022Teams/ANFC/2022Players')
						.doc(playerId)
						.set(data, { merge: true })
						.then(function() {
							console.log('Data Written: ' + playerId);
						})
						.catch((error) => console.log(error));
				}
			}
		} else if (homeTeam === 'BKFC') {
			const jsonData = require('../data/2022Players_bkfc.json');
			console.log(jsonData);
			var firstname = '';
			var lastname = '';
			var position = '';
			var flValue = '';
			var squadNumber = 0;

			for (const key of Object.keys(jsonData)) {
				squadNumber = Number.parseInt(key);
				var player = jsonData[key];
				if (player.fullname !== null) {
					var nameSplit = player.fullname.split(' ');
					var positionId = 0;
					var playerId =
						(await generatePlayerId(player.fullname)) +
						'_' +
						nameSplit[0] +
						'_' +
						nameSplit[1] +
						'_' +
						squadNumber;

					if (player.position === 'Goalkeeper') positionId = 10;
					else if (player.position === 'Defender') positionId = 20;
					else if (player.position === 'Midfielder') positionId = 30;
					else if (player.position === 'Forward') positionId = 40;
					else console.log('Position Error');

					console.log('Sq No: ' + squadNumber);
					console.log('full name: ' + player.fullname);
					console.log('first name: ' + nameSplit[0]);
					console.log('last name: ' + nameSplit[1]);
					console.log('position: :' + player.position);
					console.log('position id: ' + positionId);
					console.log('FL Value: ' + player.fl_value);
					console.log('Id: ' + playerId);

					let data = {
						active                : true,
						age                   : 0,
						firstname             : nameSplit[0],
						fl_points             : 0,
						fl_weekly_points      : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0, 'week_10':0, 'week_11':0, 'week_12':0, 'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0 },
						fullname              : player.fullname,
						lastname              : nameSplit[1],
						photo_url             : '/images/players/bkfc/' + nameSplit[0] + '_' + nameSplit[1] + '.png',
						player_value          : player.fl_value,
						player_id             : playerId,
						position              : player.position,
						position_id           : positionId,
						squad_number          : squadNumber,
						team_id               : 'BKFC',
						team_logo_url         : '/images/team_logos/bkfc.png',
						team_name             : 'Balestier Khalsa Football Club',
						team_short_name       : 'Balestier Khalsa',
						total_assists         : 0,
						total_clean_sheets    : 0,
						total_conceded        : 0,
						total_goals           : 0,
						total_minutes_played  : 0,
						total_ratings         : 0,
						total_red_cards       : 0,
						total_yellow_cards    : 0,
						u23                   : false,
						value                 : player.fl_value,
						weekly_assists        : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0,'week_10':0,'week_11':0,'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
						weekly_clean_sheets   : { 'week_1': true, 'week_2': true, 'week_3': true, 'week_4': true, 'week_5': true, 'week_6': true, 'week_7': true, 'week_7.1': true, 'week_7.2': true, 'week_8':true, 'week_9':true, 'week_10':true, 'week_11':true, 'week_12':true, 'week_12.1':true,'week_13':true,'week_14':true,'week_15':true,'week_15.1':true, 'week_16':true, 'week_17':true, 'week_18':true, 'week_19':true,'week_20':true, 'week_21':true, 'week_22':true,'week_23':true, 'week_24':true  },
						weekly_conceded       : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0,'week_10':0,'week_11':0,'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
						weekly_goals          : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0,'week_10':0,'week_11':0,'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
						weekly_minutes_played : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0,'week_10':0,'week_11':0,'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
						weekly_ratings        : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0,'week_10':0,'week_11':0,'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
						weekly_red_cards      : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0,'week_10':0,'week_11':0,'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
						weekly_yellow_cards   : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0,'week_10':0,'week_11':0,'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
						weekly_selection      : { 'week_1': false, 'week_2': false, 'week_3': false, 'week_4': false, 'week_5': false, 'week_6': false, 'week_7': false, 'week_7.1': false, 'week_7.2': false, 'week_8':false, 'week_9':false, 'week_11':false, 'week_12':false, 'week_12.1':false,'week_13':false,'week_14':false ,'week_15':false ,'week_15.1':false, 'week_16':false, 'week_17':false,'week_18':false, 'week_19':false, 'week_20':false, 'week_21':false, 'week_22':false, 'week_23':false, 'week_24':false  }
					};
					const db = firebase.firestore();

					let setDoc = db
						.collection('2022Teams/BKFC/2022Players')
						.doc(playerId)
						.set(data, { merge: true })
						.then(function() {
							console.log('Data Written: ' + playerId);
						})
						.catch((error) => console.log(error));
				}
			}
		} else if (homeTeam === 'GIFC') {
			const jsonData = require('../data/2022Players_gifc.json');
			console.log(jsonData);
			var firstname = '';
			var lastname = '';
			var position = '';
			var flValue = '';
			var squadNumber = 0;

			for (const key of Object.keys(jsonData)) {
				squadNumber = Number.parseInt(key);
				var player = jsonData[key];
				if (player.fullname !== null) {
					var nameSplit = player.fullname.split(' ');
					var positionId = 0;
					var playerId =
						(await generatePlayerId(player.fullname)) +
						'_' +
						nameSplit[0] +
						'_' +
						nameSplit[1] +
						'_' +
						squadNumber;

					if (player.position === 'Goalkeeper') positionId = 10;
					else if (player.position === 'Defender') positionId = 20;
					else if (player.position === 'Midfielder') positionId = 30;
					else if (player.position === 'Forward') positionId = 40;
					else console.log('Position Error');

					console.log('Sq No: ' + squadNumber);
					console.log('full name: ' + player.fullname);
					console.log('first name: ' + nameSplit[0]);
					console.log('last name: ' + nameSplit[1]);
					console.log('position: :' + player.position);
					console.log('position id: ' + positionId);
					console.log('FL Value: ' + player.fl_value);
					console.log('Id: ' + playerId);

					let data = {
						active                : true,
						age                   : 0,
						firstname             : nameSplit[0],
						fl_points             : 0,
						fl_weekly_points      : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0,'week_10':0,'week_11':0,'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
						fullname              : player.fullname,
						lastname              : nameSplit[1],
						photo_url             : '/images/players/gifc/' + nameSplit[0] + '_' + nameSplit[1] + '.png',
						player_value          : player.fl_value,
						player_id             : playerId,
						position              : player.position,
						position_id           : positionId,
						squad_number          : squadNumber,
						team_id               : 'GIFC',
						team_logo_url         : '/images/team_logos/gifc.png',
						team_name             : 'Geylang International Football Club',
						team_short_name       : "Geylang I'ntl",
						total_assists         : 0,
						total_clean_sheets    : 0,
						total_conceded        : 0,
						total_goals           : 0,
						total_minutes_played  : 0,
						total_ratings         : 0,
						total_red_cards       : 0,
						total_yellow_cards    : 0,
						u23                   : false,
						value                 : player.fl_value,
						weekly_assists        : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0,'week_10':0,'week_11':0,'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
						weekly_clean_sheets   : { 'week_1': true, 'week_2': true, 'week_3': true, 'week_4': true, 'week_5': true, 'week_6': true, 'week_7': true, 'week_7.1': true, 'week_7.2': true, 'week_8':true, 'week_9':true, 'week_10':true, 'week_11':true, 'week_12':true, 'week_12.1':true,'week_13':true,'week_14':true,'week_15':true,'week_15.1':true, 'week_16':true, 'week_17':true, 'week_18':true, 'week_19':true,'week_20':true, 'week_21':true, 'week_22':true,'week_23':true, 'week_24':true  },
						weekly_conceded       : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0,'week_10':0,'week_11':0,'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
						weekly_goals          : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0,'week_10':0,'week_11':0,'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
						weekly_minutes_played : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0,'week_10':0,'week_11':0,'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
						weekly_ratings        : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0,'week_10':0,'week_11':0,'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
						weekly_red_cards      : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0,'week_10':0,'week_11':0,'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
						weekly_yellow_cards   : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0,'week_10':0,'week_11':0,'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
						weekly_selection      : { 'week_1': false, 'week_2': false, 'week_3': false, 'week_4': false, 'week_5': false, 'week_6': false, 'week_7': false, 'week_7.1': false, 'week_7.2': false, 'week_8':false, 'week_9':false, 'week_10':false, 'week_11':false, 'week_12':false, 'week_12.1':false,'week_13':false,'week_14':false ,'week_15':false ,'week_15.1':false, 'week_16':false, 'week_17':false,'week_18':false, 'week_19':false, 'week_20':false, 'week_21':false, 'week_22':false, 'week_23':false, 'week_24':false  }
					};
					const db = firebase.firestore();

					let setDoc = db
						.collection('2022Teams/GIFC/2022Players')
						.doc(playerId)
						.set(data, { merge: true })
						.then(function() {
							console.log('Data Written: ' + playerId);
						})
						.catch((error) => console.log(error));
				}
			}
		} else if (homeTeam === 'HUFC') {
			const jsonData = require('../data/2022Players_hufc.json');
			console.log(jsonData);
			var firstname = '';
			var lastname = '';
			var position = '';
			var flValue = '';
			var squadNumber = 0;

			for (const key of Object.keys(jsonData)) {
				squadNumber = Number.parseInt(key);
				var player = jsonData[key];
				if (player.fullname !== null) {
					var nameSplit = player.fullname.split(' ');
					var positionId = 0;
					var playerId =
						(await generatePlayerId(player.fullname)) +
						'_' +
						nameSplit[0] +
						'_' +
						nameSplit[1] +
						'_' +
						squadNumber;

					if (player.position === 'Goalkeeper') positionId = 10;
					else if (player.position === 'Defender') positionId = 20;
					else if (player.position === 'Midfielder') positionId = 30;
					else if (player.position === 'Forward') positionId = 40;
					else console.log('Position Error');

					console.log('Sq No: ' + squadNumber);
					console.log('full name: ' + player.fullname);
					console.log('first name: ' + nameSplit[0]);
					console.log('last name: ' + nameSplit[1]);
					console.log('position: :' + player.position);
					console.log('position id: ' + positionId);
					console.log('FL Value: ' + player.fl_value);
					console.log('Id: ' + playerId);

					let data = {
						active                : true,
						age                   : 0,
						firstname             : nameSplit[0],
						fl_points             : 0,
						fl_weekly_points      : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0,'week_10':0,'week_11':0,'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
						fullname              : player.fullname,
						lastname              : nameSplit[1],
						photo_url             : '/images/players/hufc/' + nameSplit[0] + '_' + nameSplit[1] + '.png',
						player_value          : player.fl_value,
						player_id             : playerId,
						position              : player.position,
						position_id           : positionId,
						squad_number          : squadNumber,
						team_id               : 'HUFC',
						team_logo_url         : '/images/team_logos/hufc.png',
						team_name             : 'Hougang United Football Club',
						team_short_name       : 'Hougang United',
						total_assists         : 0,
						total_clean_sheets    : 0,
						total_conceded        : 0,
						total_goals           : 0,
						total_minutes_played  : 0,
						total_ratings         : 0,
						total_red_cards       : 0,
						total_yellow_cards    : 0,
						u23                   : false,
						value                 : player.fl_value,
						weekly_assists        : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0,'week_10':0,'week_11':0,'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
						weekly_clean_sheets   : { 'week_1': true, 'week_2': true, 'week_3': true, 'week_4': true, 'week_5': true, 'week_6': true, 'week_7': true, 'week_7.1': true, 'week_7.2': true, 'week_8':true, 'week_9':true, 'week_10':true, 'week_11':true, 'week_12':true, 'week_12.1':true,'week_13':true,'week_14':true,'week_15':true,'week_15.1':true, 'week_16':true, 'week_17':true, 'week_18':true, 'week_19':true,'week_20':true, 'week_21':true, 'week_22':true,'week_23':true, 'week_24':true  },
						weekly_conceded       : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0,'week_10':0,'week_11':0,'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
						weekly_goals          : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0,'week_10':0,'week_11':0,'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
						weekly_minutes_played : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0,'week_10':0,'week_11':0,'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
						weekly_ratings        : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0,'week_10':0,'week_11':0,'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
						weekly_red_cards      : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0,'week_10':0,'week_11':0,'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
						weekly_yellow_cards   : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0,'week_10':0,'week_11':0,'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
						weekly_selection      : { 'week_1': false, 'week_2': false, 'week_3': false, 'week_4': false, 'week_5': false, 'week_6': false, 'week_7': false, 'week_7.1': false, 'week_7.2': false, 'week_8':false, 'week_9':false, 'week_10':false, 'week_11':false, 'week_12':false, 'week_12.1':false,'week_13':false,'week_14':false ,'week_15':false ,'week_15.1':false, 'week_16':false, 'week_17':false,'week_18':false, 'week_19':false, 'week_20':false, 'week_21':false, 'week_22':false, 'week_23':false, 'week_24':false  }
					};
					const db = firebase.firestore();

					let setDoc = db
						.collection('2022Teams/HUFC/2022Players')
						.doc(playerId)
						.set(data, { merge: true })
						.then(function() {
							console.log('Data Written: ' + playerId);
						})
						.catch((error) => console.log(error));
				}
			}
		} else if (homeTeam === 'LCSF') {
			const jsonData = require('../data/2022Players_lcsf.json');
			console.log(jsonData);
			var firstname = '';
			var lastname = '';
			var position = '';
			var flValue = '';
			var squadNumber = 0;

			for (const key of Object.keys(jsonData)) {
				squadNumber = Number.parseInt(key);
				var player = jsonData[key];
				if (player.fullname !== null) {
					var nameSplit = player.fullname.split(' ');
					var positionId = 0;
					var playerId =
						(await generatePlayerId(player.fullname)) +
						'_' +
						nameSplit[0] +
						'_' +
						nameSplit[1] +
						'_' +
						squadNumber;

					if (player.position === 'Goalkeeper') positionId = 10;
					else if (player.position === 'Defender') positionId = 20;
					else if (player.position === 'Midfielder') positionId = 30;
					else if (player.position === 'Forward') positionId = 40;
					else console.log('Position Error');

					console.log('Sq No: ' + squadNumber);
					console.log('full name: ' + player.fullname);
					console.log('first name: ' + nameSplit[0]);
					console.log('last name: ' + nameSplit[1]);
					console.log('position: :' + player.position);
					console.log('position id: ' + positionId);
					console.log('FL Value: ' + player.fl_value);
					console.log('Id: ' + playerId);

					let data = {
						active                : true,
						age                   : 0,
						firstname             : nameSplit[0],
						fl_points             : 0,
						fl_weekly_points      : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0,'week_10':0,'week_11':0,'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
						fullname              : player.fullname,
						lastname              : nameSplit[1],
						photo_url             : '/images/players/lcsf/' + nameSplit[0] + '_' + nameSplit[1] + '.png',
						player_value          : player.fl_value,
						player_id             : playerId,
						position              : player.position,
						position_id           : positionId,
						squad_number          : squadNumber,
						team_id               : 'LCSF',
						team_logo_url         : '/images/team_logos/lcsf.png',
						team_name             : 'Lion City Sailors',
						team_short_name       : 'Sailors',
						total_assists         : 0,
						total_clean_sheets    : 0,
						total_conceded        : 0,
						total_goals           : 0,
						total_minutes_played  : 0,
						total_ratings         : 0,
						total_red_cards       : 0,
						total_yellow_cards    : 0,
						u23                   : false,
						value                 : player.fl_value,
						weekly_assists        : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0,'week_10':0,'week_11':0,'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
						weekly_clean_sheets   : { 'week_1': true, 'week_2': true, 'week_3': true, 'week_4': true, 'week_5': true, 'week_6': true, 'week_7': true, 'week_7.1': true, 'week_7.2': true, 'week_8':true, 'week_9':true, 'week_10':true, 'week_11':true, 'week_12':true, 'week_12.1':true,'week_13':true,'week_14':true,'week_15':true,'week_15.1':true, 'week_16':true, 'week_17':true, 'week_18':true, 'week_19':true,'week_20':true, 'week_21':true, 'week_22':true,'week_23':true, 'week_24':true  },
						weekly_conceded       : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0,'week_10':0,'week_11':0,'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
						weekly_goals          : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0,'week_10':0,'week_11':0,'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
						weekly_minutes_played : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0,'week_10':0,'week_11':0,'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
						weekly_ratings        : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0,'week_10':0,'week_11':0,'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
						weekly_red_cards      : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0,'week_10':0,'week_11':0,'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
						weekly_yellow_cards   : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0,'week_10':0,'week_11':0,'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
						weekly_selection      : { 'week_1': false, 'week_2': false, 'week_3': false, 'week_4': false, 'week_5': false, 'week_6': false, 'week_7': false, 'week_7.1': false, 'week_7.2': false, 'week_8':false, 'week_9':false, 'week_10':false, 'week_11':false, 'week_12':false, 'week_12.1':false,'week_13':false,'week_14':false ,'week_15':false,'week_15.1':false, 'week_16':false, 'week_17':false,'week_18':false, 'week_19':false, 'week_20':false, 'week_21':false, 'week_22':false, 'week_23':false, 'week_24':false  }
					};
					const db = firebase.firestore();

					let setDoc = db
						.collection('2022Teams/LCSF/2022Players')
						.doc(playerId)
						.set(data, { merge: true })
						.then(function() {
							console.log('Data Written: ' + playerId);
						})
						.catch((error) => console.log(error));
				}
			}
		} else if (homeTeam === 'TPFC') {
			const jsonData = require('../data/2022Players_tpfc.json');
			console.log(jsonData);
			var firstname = '';
			var lastname = '';
			var position = '';
			var flValue = '';
			var squadNumber = 0;

			for (const key of Object.keys(jsonData)) {
				squadNumber = Number.parseInt(key);
				var player = jsonData[key];
				if (player.fullname !== null) {
					var nameSplit = player.fullname.split(' ');
					var positionId = 0;
					var playerId =
						(await generatePlayerId(player.fullname)) +
						'_' +
						nameSplit[0] +
						'_' +
						nameSplit[1] +
						'_' +
						squadNumber;

					if (player.position === 'Goalkeeper') positionId = 10;
					else if (player.position === 'Defender') positionId = 20;
					else if (player.position === 'Midfielder') positionId = 30;
					else if (player.position === 'Forward') positionId = 40;
					else console.log('Position Error');

					console.log('Sq No: ' + squadNumber);
					console.log('full name: ' + player.fullname);
					console.log('first name: ' + nameSplit[0]);
					console.log('last name: ' + nameSplit[1]);
					console.log('position: :' + player.position);
					console.log('position id: ' + positionId);
					console.log('FL Value: ' + player.fl_value);
					console.log('Id: ' + playerId);

					let data = {
						active                : true,
						age                   : 0,
						firstname             : nameSplit[0],
						fl_points             : 0,
						fl_weekly_points      : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0,'week_10':0,'week_11':0,'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
						fullname              : player.fullname,
						lastname              : nameSplit[1],
						photo_url             : '/images/players/tpfc/' + nameSplit[0] + '_' + nameSplit[1] + '.png',
						player_value          : player.fl_value,
						player_id             : playerId,
						position              : player.position,
						position_id           : positionId,
						squad_number          : squadNumber,
						team_id               : 'TPFC',
						team_logo_url         : '/images/team_logos/tpfc.png',
						team_name             : 'Tanjong Pagar United Football Club',
						team_short_name       : 'Tanjong Pagar United',
						total_assists         : 0,
						total_clean_sheets    : 0,
						total_conceded        : 0,
						total_goals           : 0,
						total_minutes_played  : 0,
						total_ratings         : 0,
						total_red_cards       : 0,
						total_yellow_cards    : 0,
						u23                   : false,
						value                 : player.fl_value,
						weekly_assists        : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0,'week_10':0,'week_11':0,'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
						weekly_clean_sheets   : { 'week_1': true, 'week_2': true, 'week_3': true, 'week_4': true, 'week_5': true, 'week_6': true, 'week_7': true, 'week_7.1': true, 'week_7.2': true, 'week_8':true, 'week_9':true, 'week_10':true, 'week_11':true, 'week_12':true, 'week_12.1':true,'week_13':true,'week_14':true,'week_15':true,'week_15.1':true, 'week_16':true, 'week_17':true  },
						weekly_conceded       : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0,'week_10':0,'week_11':0,'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
						weekly_goals          : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0,'week_10':0,'week_11':0,'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
						weekly_minutes_played : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0,'week_10':0,'week_11':0,'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
						weekly_ratings        : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0,'week_10':0,'week_11':0,'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
						weekly_red_cards      : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0,'week_10':0,'week_11':0,'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
						weekly_yellow_cards   : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0,'week_10':0,'week_11':0,'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
						weekly_selection      : { 'week_1': false, 'week_2': false, 'week_3': false, 'week_4': false, 'week_5': false, 'week_6': false, 'week_7': false, 'week_7.1': false, 'week_7.2': false, 'week_8':false, 'week_9':false, 'week_10':false, 'week_11':false, 'week_12':false, 'week_12.1':false,'week_13':false,'week_14':false ,'week_15':false,'week_15.1':false, 'week_16':false, 'week_17':false, 'week_18':false, 'week_19':false, 'week_20':false, 'week_21':false, 'week_22':false, 'week_23':false, 'week_24':false  }
					};
					const db = firebase.firestore();

					let setDoc = db
						.collection('2022Teams/TPFC/2022Players')
						.doc(playerId)
						.set(data, { merge: true })
						.then(function() {
							console.log('Data Written: ' + playerId);
						})
						.catch((error) => console.log(error));
				}
			}
		} else if (homeTeam === 'TRFC') {
			const jsonData = require('../data/2022Players_trfc.json');
			console.log(jsonData);
			var firstname = '';
			var lastname = '';
			var position = '';
			var flValue = '';
			var squadNumber = 0;

			for (const key of Object.keys(jsonData)) {
				squadNumber = Number.parseInt(key);
				var player = jsonData[key];
				if (player.fullname !== null) {
					var nameSplit = player.fullname.split(' ');
					var positionId = 0;
					var playerId =
						(await generatePlayerId(player.fullname)) +
						'_' +
						nameSplit[0] +
						'_' +
						nameSplit[1] +
						'_' +
						squadNumber;

					if (player.position === 'Goalkeeper') positionId = 10;
					else if (player.position === 'Defender') positionId = 20;
					else if (player.position === 'Midfielder') positionId = 30;
					else if (player.position === 'Forward') positionId = 40;
					else console.log('Position Error');

					console.log('Sq No: ' + squadNumber);
					console.log('full name: ' + player.fullname);
					console.log('first name: ' + nameSplit[0]);
					console.log('last name: ' + nameSplit[1]);
					console.log('position: :' + player.position);
					console.log('position id: ' + positionId);
					console.log('FL Value: ' + player.fl_value);
					console.log('Id: ' + playerId);

					let data = {
						active                : true,
						age                   : 0,
						firstname             : nameSplit[0],
						fl_points             : 0,
						fl_weekly_points      : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0,'week_10':0,'week_11':0,'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
						fullname              : player.fullname,
						lastname              : nameSplit[1],
						photo_url             : '/images/players/trfc/' + nameSplit[0] + '_' + nameSplit[1] + '.png',
						player_value          : player.fl_value,
						player_id             : playerId,
						position              : player.position,
						position_id           : positionId,
						squad_number          : squadNumber,
						team_id               : 'TRFC',
						team_logo_url         : '/images/team_logos/trfc.png',
						team_name             : 'Tampines Rovers Football Club',
						team_short_name       : 'Tampines Rovers',
						total_assists         : 0,
						total_clean_sheets    : 0,
						total_conceded        : 0,
						total_goals           : 0,
						total_minutes_played  : 0,
						total_ratings         : 0,
						total_red_cards       : 0,
						total_yellow_cards    : 0,
						u23                   : false,
						value                 : player.fl_value,
						weekly_assists        : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0,'week_10':0,'week_11':0,'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
						weekly_clean_sheets   : { 'week_1': true, 'week_2': true, 'week_3': true, 'week_4': true, 'week_5': true, 'week_6': true, 'week_7': true, 'week_7.1': true, 'week_7.2': true, 'week_8':true, 'week_9':true, 'week_10':true, 'week_11':true, 'week_12':true, 'week_12.1':true,'week_13':true,'week_14':true,'week_15':true,'week_15.1':true, 'week_16':true, 'week_17':true, 'week_18':true, 'week_19':true,'week_20':true, 'week_21':true, 'week_22':true,'week_23':true, 'week_24':true  },
						weekly_conceded       : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0,'week_10':0,'week_11':0,'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
						weekly_goals          : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0,'week_10':0,'week_11':0,'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
						weekly_minutes_played : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0,'week_10':0,'week_11':0,'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
						weekly_ratings        : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0,'week_10':0,'week_11':0,'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
						weekly_red_cards      : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0,'week_10':0,'week_11':0,'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
						weekly_yellow_cards   : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0,'week_10':0,'week_11':0,'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
						weekly_selection      : { 'week_1': false, 'week_2': false, 'week_3': false, 'week_4': false, 'week_5': false, 'week_6': false, 'week_7': false, 'week_7.1': false, 'week_7.2': false, 'week_8':false, 'week_9':false, 'week_10':false, 'week_11':false, 'week_12':false, 'week_12.1':false,'week_13':false,'week_14':false ,'week_15':false,'week_15.1':false, 'week_16':false, 'week_17':false, 'week_18':false, 'week_19':false, 'week_20':false, 'week_21':false, 'week_22':false, 'week_23':false, 'week_24':false  }
					};
					const db = firebase.firestore();

					let setDoc = db
						.collection('2022Teams/TRFC/2022Players')
						.doc(playerId)
						.set(data, { merge: true })
						.then(function() {
							console.log('Data Written: ' + playerId);
						})
						.catch((error) => console.log(error));
				}
			}
		} else if (homeTeam === 'YLFC') {
			const jsonData = require('../data/2022Players_ylfc.json');
			console.log(jsonData);
			var firstname = '';
			var lastname = '';
			var position = '';
			var flValue = '';
			var squadNumber = 0;

			for (const key of Object.keys(jsonData)) {
				squadNumber = Number.parseInt(key);
				var player = jsonData[key];
				if (player.fullname !== null) {
					var nameSplit = player.fullname.split(' ');
					var positionId = 0;
					var playerId =
						(await generatePlayerId(player.fullname)) +
						'_' +
						nameSplit[0] +
						'_' +
						nameSplit[1] +
						'_' +
						squadNumber;

					if (player.position === 'Goalkeeper') positionId = 10;
					else if (player.position === 'Defender') positionId = 20;
					else if (player.position === 'Midfielder') positionId = 30;
					else if (player.position === 'Forward') positionId = 40;
					else console.log('Position Error');

					console.log('Sq No: ' + squadNumber);
					console.log('full name: ' + player.fullname);
					console.log('first name: ' + nameSplit[0]);
					console.log('last name: ' + nameSplit[1]);
					console.log('position: :' + player.position);
					console.log('position id: ' + positionId);
					console.log('FL Value: ' + player.fl_value);
					console.log('Id: ' + playerId);

					let data = {
						active                : true,
						age                   : 0,
						firstname             : nameSplit[0],
						fl_points             : 0,
						fl_weekly_points      : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0,'week_10':0,'week_11':0,'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
						fullname              : player.fullname,
						lastname              : nameSplit[1],
						photo_url             : '/images/players/ylfc/' + nameSplit[0] + '_' + nameSplit[1] + '.png',
						player_value          : player.fl_value,
						player_id             : playerId,
						position              : player.position,
						position_id           : positionId,
						squad_number          : squadNumber,
						team_id               : 'YLFC',
						team_logo_url         : '/images/team_logos/ylfc.png',
						team_name             : 'Young Lions',
						team_short_name       : 'Young Lions',
						total_assists         : 0,
						total_clean_sheets    : 0,
						total_conceded        : 0,
						total_goals           : 0,
						total_minutes_played  : 0,
						total_ratings         : 0,
						total_red_cards       : 0,
						total_yellow_cards    : 0,
						u23                   : false,
						value                 : player.fl_value,
						weekly_assists        : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0,'week_10':0,'week_11':0,'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
						weekly_clean_sheets   : { 'week_1': true, 'week_2': true, 'week_3': true, 'week_4': true, 'week_5': true, 'week_6': true, 'week_7': true, 'week_7.1': true, 'week_7.2': true, 'week_8':true, 'week_9':true, 'week_10':true, 'week_11':true, 'week_12':true, 'week_12.1':true,'week_13':true,'week_14':true,'week_15':true,'week_15.1':true, 'week_16':true, 'week_17':true, 'week_18':true, 'week_19':true,'week_20':true, 'week_21':true, 'week_22':true,'week_23':true, 'week_24':true  },
						weekly_conceded       : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0,'week_10':0,'week_11':0,'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
						weekly_goals          : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0,'week_10':0,'week_11':0,'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
						weekly_minutes_played : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0,'week_10':0,'week_11':0,'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
						weekly_ratings        : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0,'week_10':0,'week_11':0,'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
						weekly_red_cards      : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0,'week_10':0,'week_11':0,'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
						weekly_yellow_cards   : { 'week_1': 0, 'week_2': 0, 'week_3': 0, 'week_4': 0, 'week_5': 0, 'week_6': 0, 'week_7': 0, 'week_7.1': 0, 'week_7.2': 0, 'week_8':0, 'week_9':0,'week_10':0,'week_11':0,'week_12.1':0,'week_13':0,'week_14':0,'week_15':0,'week_15.1':0, 'week_16':0, 'week_17':0, 'week_18':0, 'week_19':0,'week_20':0, 'week_21':0, 'week_22':0, 'week_23':0, 'week_24':0},
						weekly_selection      : { 'week_1': false, 'week_2': false, 'week_3': false, 'week_4': false, 'week_5': false, 'week_6': false, 'week_7': false, 'week_7.1': false, 'week_7.2': false, 'week_8':false, 'week_9':false, 'week_10':false, 'week_11':false, 'week_12':false, 'week_12.1':false,'week_13':false,'week_14':false ,'week_15':false,'week_15.1':false, 'week_16':false, 'week_17':false, 'week_18':false, 'week_19':false, 'week_20':false, 'week_21':false, 'week_22':false, 'week_23':false, 'week_24':false }
					};
					const db = firebase.firestore();

					let setDoc = db
						.collection('2022Teams/YLFC/2022Players')
						.doc(playerId)
						.set(data, { merge: true })
						.then(function() {
							console.log('Data Written: ' + playerId);
						})
						.catch((error) => console.log(error));
				}
			}
		}

		/*

		let data = {
			awayteam_id              : awayTeam,
			awayteam_logo_url        : awayTeamLogo,
			awayteam_name            : awayTeamName,
			awayteam_score           : awayTeamScore,
			hometeam_id              : homeTeam,
			hometeam_logo_url        : homeTeamLogo,
			hometeam_name            : homeTeamName,
			hometeam_score           : homeTeamScore,
			match_avatar             : homeTeamAvatar + awayTeamAvatar,
			match_competition_status : matchCompetitionStatus,
			match_date               : firebase.firestore.Timestamp.fromDate(new Date(matchDate)), //Convert straight to timestamp
			match_id                 :
				year + '_ROUND_' + round + '_WEEK_' + week + '_' + homeTeam + '_' + awayTeam + '_SPL',
			match_prediction_status  : matchPredictionStatus,
			match_status             : matchStatus,
			match_time               : matchTime,
			match_title              : homeTeamName + ' vs ' + awayTeamName,
			match_venue              : venue,
			match_week               : Number(week),
			player_rating_status     : playerRatingStatus,
			Round                    : round,
      hometeam_predictions_win : 0,
      awayteam_predictions_win : 0,
      hometeam_predictions_draw: 0,
      total_predictions        : 0
		};

		const db = firebase.firestore();

		let setDoc = db
			.collection('2022Matches')
			.doc(year + '_ROUND_' + round + '_WEEK_' + week + '_' + homeTeam + '_' + awayTeam + '_SPL')
			.set(data, { merge: true })
			.then(function() {
				console.log(
					'Data Written: ' +
						year +
						'_ROUND_' +
						round +
						'_WEEK_' +
						week +
						'_' +
						homeTeam +
						'_' +
						awayTeam +
						'_SPL'
				);
				setState(true);
				setOpen(true);
			})
			.catch((error) => console.log(error));
		*/
	}

	function generatePlayerId(hashValue) {
		return new Promise((resolve, reject) => {
			var digest = sha1(hashValue);
			if (digest !== null) resolve(digest);
			else reject('error generating');
		});
	}

	function badCalc(num1, num2) {
		return new Promise((resolve, reject) => {
			if (Math.random() > 0.5) resolve(num1 * num2);
			else reject('Oopsy...');
		});
	}

	useEffect(() => {
		console.log('Checking user: ' + user.uid);
		if (
			user.uid === 'glKicD84lLejtJ5M9qOLaczt5ny1' ||
			user.uid === 'YP1dpgESghPw7WiAoIL1Sd0HARw1' ||
			user.uid === 'MmkWUYFwTwT7DKWfnsgXoKoYgxo2'
		) {
			console.log('Authorized User');
			setAuthorized(true);
		} else {
			console.log('Unauthorized User');
			setAuthorized(false);
		}
	}, []);

	return (
		<Root className={classes.root} autoComplete='off' align='center'>
			{authorized ? (
				<div>
					<div>
						<Typography variant='h5'>Create Players and Bulk Loading</Typography>
					</div>
					<div>
						<TextField
							required={true}
							id='select-year'
							label='Year'
							value={year}
							onChange={handleYearChange}
							helperText='Please select the year'
						/>
					</div>
					<div>
						<TextField
							required={true}
							id='select-home-team'
							select
							label='Home Team'
							value={homeTeam}
							onChange={handleHomeTeamChange}
							helperText='Please select your home team'
						>
							{teams.map((option) => (
								<MenuItem key={option.value} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</TextField>
					</div>
					<div>
						<Button
							variant='contained'
							color='primary'
							className={classes.button}
							startIcon={<CloudUploadIcon />}
							onClick={handleLoadPlayers}
							type='submit'
						>
							Load Players
						</Button>
					</div>
					<Grid container spacing={1}>
						<Grid item xs={12}>
							<Card>
								<CardHeader title='Players Create' />
								<CardContent>
									<FormControl>
										<TextField
											value={playerFullname}
											label='Fullname'
											onChange={(e) => {
												setPlayerFullname(e.target.value);
											}}
										/>
									</FormControl>
									<FormControl>
										<InputLabel id='position-select-inputlabel'>Position</InputLabel>
										<Select
											id='position-select'
											value={playerPosition}
											label='position'
											onChange={(e) => {
												setPlayerPosition(e.target.value);
											}}
										>
											<MenuItem value={10}>Goalkeeper</MenuItem>
											<MenuItem value={20}>Defender</MenuItem>
											<MenuItem value={30}>Midfielder</MenuItem>
											<MenuItem value={40}>Forward</MenuItem>
										</Select>
									</FormControl>
									<FormControl>
										<InputLabel id='u23-select-inputlabel'>U23</InputLabel>
										<Select
											id='u23-select'
											value={playerU23}
											label='u23'
											onChange={(e) => {
												setPlayerU23(e.target.value);
											}}
										>
											<MenuItem value={10}>Yes</MenuItem>
											<MenuItem value={20}>No</MenuItem>
										</Select>
									</FormControl>
									<FormControl>
										<TextField
											type='number'
											label='Value'
											value={playerValue}
											onChange={(e) => {
												setPlayerValue(e.target.value);
											}}
										/>
									</FormControl>
									<FormControl>
										<TextField
											type='number'
											label='Squad Number'
											value={playerSquadNumber}
											onChange={(e) => {
												setPlayerSquadNumber(e.target.value);
											}}
										/>
									</FormControl>
								</CardContent>
								<CardActions>
									<Button variant='contained' align='center' onClick={handleAddPlayer}>
										Add Player
									</Button>
								</CardActions>
							</Card>
						</Grid>
					</Grid>
				</div>
			) : (
				<Typography>Unauthorized</Typography>
			)}
			{state ? (
				<Snackbar open={open} autoHideDuration={6000} onClose={handleClose} message='Success' />
			) : (
				<Snackbar
					open={open}
					autoHideDuration={6000}
					onClose={handleClose}
					message='Match creation unsuccessful!'
				/>
			)}
		</Root>
	);
}

import React, { Component } from 'react';
import MatchAdmin from '../../component/MatchAdmin';

export class matchadmin extends Component {
	render() {
		return (
			<div>
				<MatchAdmin />
			</div>
		);
	}
}

export default matchadmin;
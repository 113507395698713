import React, { Component } from 'react';
import AdminDashboardUsers from '../../component/AdminDashboardUsers';

export class admindashboardusers extends Component {
	render() {
		return (
			<div>
				<AdminDashboardUsers />
			</div>
		);
	}
}

export default admindashboardusers;

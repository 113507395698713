import React, { useState } from 'react';
import MatchRatings from '../component/MatchRatings';

export default function matchrating() {
	return (
		<div>
			<MatchRatings />
		</div>
	);
}

import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

//Import for Forms
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';

//Imports for Select
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { Avatar, Divider } from '@mui/material';
import { useSession } from '../App';

import firebase from '../firebase';
import {submitPrediction} from '../firestore/predictionshandler';

const PREFIX = 'MyPredictions';

const classes = {
	root           : `${PREFIX}-root`,
	media          : `${PREFIX}-media`,
	paperContainer : `${PREFIX}-paperContainer`,
	table          : `${PREFIX}-table`,
	small          : `${PREFIX}-small`,
	margin         : `${PREFIX}-margin`,
	input          : `${PREFIX}-input`,
	formControl    : `${PREFIX}-formControl`,
	selectEmpty    : `${PREFIX}-selectEmpty`
};

const StyledGrid = styled(Grid)(({ theme }) => ({
	[`&.${classes.root}`]: {
		flexGrow : 1
	},

	[`& .${classes.media}`]: {
		height : 140
	},

	[`& .${classes.paperContainer}`]: {
		height : 200
	},

	[`& .${classes.table}`]: {
		maxWidth : 400
	},

	[`& .${classes.small}`]: {
		width  : theme.spacing(3),
		height : theme.spacing(3)
	},

	[`& .${classes.margin}`]: {
		margin : theme.spacing(1)
	},

	[`& .${classes.input}`]: {
		padding : theme.spacing(2)
	},

	[`& .${classes.formControl}`]: {
		margin   : theme.spacing(1),
		minWidth : 50
	},

	[`& .${classes.selectEmpty}`]: {
		marginTop : theme.spacing(2)
	}
}));

export default function MyPredictions() {
	const user = useSession();

	const [ matches, setMatches ] = useState([]); //stores all the matches
	const [womenMatches, setWomenMatches] = useState([])
	const [ myMatchPredictions, setMyMatchPredictions ] = useState([]); //stores all the user predictions
	const [ myRawMatchPredictions, setMyRawMatchPredictions ] = useState([]);
	const [ openDialog, setOpenDialog ] = React.useState(false);
	const [ currentMatch, setCurrentMatch ] = useState([]);
	const [ currentWeek, setCurrentWeek ] = useState(15);
	const [ selectedRound, setSelectedRound ] = useState(4);
	const [ bannerPictureURL, setBannerPictureURL ] = useState(
		'https://en.as.com/en/imagenes/2018/03/21/football/1521654321_614016_1521654533_noticia_normal.jpg'
	);

	const handleSelectedRoundChange = (event) => {
		setSelectedRound(event.target.value);
	};

	var predictedHomeTeamScore = 0;
	var predictedAwayTeamScore = 0;

	useEffect(() => {
		const setPageBanner = () => {
			setBannerPictureURL(
				'https://en.as.com/en/imagenes/2018/03/21/football/1521654321_614016_1521654533_noticia_normal.jpg'
			);
		};
		setPageBanner();
	}, []);

	useEffect(() => {
		const fetchMyMatchPredictions = async () => {
			console.log('Fetching My Predictions ' + user.uid);
			const db = firebase.firestore();
			db
				.collectionGroup('2022_Match_Predictions')
				.where('uid', '==', user.uid)
				.orderBy('match_date', 'asc')
				.get()
				.then(function(querySnapshot) {
					setMyRawMatchPredictions(querySnapshot.docs.map((matches) => matches.data()));
				});
		};
		fetchMyMatchPredictions();
	}, []);

	//Fetch all the matches with status matchday
	useEffect(() => {
		const fetchMatches = () => {
			console.log('Fetching Matchday Matches');
			var myPredictions = [];

			const db = firebase.firestore();
			const matches = db
				.collection('2022Matches')
				.orderBy('match_date', 'asc')
				.get()
				.then(function(querySnapshot) {
					setMatches(querySnapshot.docs.map((matches) => matches.data()));
					setMyMatchPredictions(myPredictions);
				});
		};
		fetchMatches();


		const fetchWomenMatches = () => {
			console.log('Fetching Matchday Matches');
			var myPredictions = [];

			const db = firebase.firestore();
			db
				.collection('2022WPLMatches')
				.orderBy('match_date', 'asc')
				.get()
				.then(function(querySnapshot) {
					setWomenMatches(querySnapshot.docs.map((matches) => matches.data()));
					//setMyMatchPredictions(myPredictions);
				});
		};
		fetchWomenMatches();

		const fetchMatchdayMatches = () => {
			console.log('Fetching Matchday Matches');
			const db = firebase.firestore();
			const matches = db
				.collection('2022Matches')
				.where('match_status', '==', 'matchday')
				.orderBy('match_date', 'asc')
				.get()
				.then(function(querySnapshot) {
					querySnapshot.forEach(function(doc) {
						// doc.data() is never undefined for query doc snapshots
						//console.log(doc.id, ' => ', doc.data());
						setCurrentWeek(doc.data().match_week);
					});
				});
		};
		fetchMatchdayMatches();
	}, []);

	useEffect(
		() => {
			var myPredictions = [];
			var t = matches.concat(womenMatches);
			t.forEach(function(match) {
				var temp = myRawMatchPredictions.find((prediction) => prediction.match_id === match.match_id);
				console.log(match);
				if (temp !== undefined) {
					myPredictions.push({
						...temp,
						match_week               : match.match_week,
						match_date               : match.match_date,
						match_status             : match.match_status,
						hometeam_logo_url        : match.hometeam_logo_url,
						awayteam_logo_url        : match.awayteam_logo_url,
						hometeam_score           : match.hometeam_score,
						awayteam_score           : match.awayteam_score,
						match_competition_status : match.match_competition_status,
						Round                    : match.Round
					});
				} else {
					//console.log(match);
					myPredictions.push({
						match_week               : match.match_week,
						awayteam_logo_url        : match.awayteam_logo_url,
						awayteam_name            : match.awayteam_name,
						awayteam_score           : match.awayteam_score,
						hometeam_logo_url        : match.hometeam_logo_url,
						hometeam_name            : match.hometeam_name,
						hometeam_score           : match.hometeam_score,
						match_competition_status : match.match_competition_status,
						match_date               : match.match_date,
						match_id                 : match.match_id,
						match_status             : match.match_status,
						prediction_points        : '0',
						prediction_timestamp     : 1649320165808,
						uid                      : user.uid,
						predicted_away_score     : 'Prediction',
						predicted_home_score     : 'No',
						Round                    : match.Round
					});
				}
			});
			console.log(myPredictions);
			setMyMatchPredictions(myPredictions);
		},
		[ matches, myRawMatchPredictions ]
	);

	const handleClickOpen = (event, match) => {
		//console.log('Match ', match);
		setCurrentMatch(match);
		setOpenDialog(true);
	};

	const handleClose = () => {
		setOpenDialog(false);
		
	};

	function handleSubmitPrediction(event, match) {
		submitPrediction(user.uid, currentMatch, predictedHomeTeamScore, predictedAwayTeamScore);
		setOpenDialog(false);
	}

	return (
		<StyledGrid container className={classes.root} spacing={3}>
			<Grid item xs={12}>
				<Card>
					<CardActionArea>
						<CardMedia
							className={classes.paperContainer}
							image={bannerPictureURL}
							title='Match Predictions'
						/>
					</CardActionArea>
				</Card>{' '}
			</Grid>

			<Grid item xs={12} sm={6}>
				<Card className={classes.root}>
					<CardActionArea>
						<CardContent>
							<Typography gutterBottom variant='h6'>
								SPL Prediction Records
							</Typography>
							<Divider />
							<Box p={1} bgcolor='background.paper' />
							<FormControl className={classes.formControl}>
								<Select
									labelId='demo-simple-select-label'
									id='demo-simple-select'
									value={selectedRound}
									onChange={handleSelectedRoundChange}
								>
									<MenuItem value={1}>1</MenuItem>
									<MenuItem value={2}>2</MenuItem>
									<MenuItem value={3}>3</MenuItem>
									<MenuItem value={4}>4</MenuItem>

								</Select>
								<FormHelperText>Round</FormHelperText>
							</FormControl>
							<TableContainer component={Paper} p={5}>
								<Table className={classes.table} size='small' aria-label='a dense table' padding='none'>
									<TableHead>
										<TableRow>
											<TableCell align='center'>Wk</TableCell>
											<TableCell align='center'>Home</TableCell>
											<TableCell align='center'>Away</TableCell>
											<TableCell align='center'>Result</TableCell>
											<TableCell align='center'>Prediction</TableCell>
											<TableCell align='center'>Pts Gained</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{myMatchPredictions.map(
											(row, index) =>
												row.match_competition_status === 'SPL' &&
												row.Round === selectedRound ? (
													<TableRow key={index}>
														<TableCell align='center'>{row.match_week}</TableCell>
														<TableCell component='th' scope='row'>
															<Avatar
																src={row.hometeam_logo_url}
																className={classes.small}
															/>
														</TableCell>
														<TableCell align='center'>
															<Avatar
																src={row.awayteam_logo_url}
																className={classes.small}
															/>
														</TableCell>
														{row.match_status !== 'completed' ? (
															<TableCell align='center'>-</TableCell>
														) : (
															<TableCell align='center'>
																{row.hometeam_score} - {row.awayteam_score}
															</TableCell>
														)}

														<TableCell align='center'>
															{row.predicted_home_score} - {row.predicted_away_score}{' '}
														</TableCell>
														{row.prediction_points === -1 ? (
															<TableCell align='center'>-</TableCell>
														) : (
															<TableCell align='center'>
																{row.prediction_points}
															</TableCell>
														)}
													</TableRow>
												) : (
													<TableRow key={'spl' + index} />
												)
										)}
									</TableBody>
								</Table>
							</TableContainer>
							<Box p={1} bgcolor='background.paper' />
							<Divider />
							<Typography variant='caption'>5 Points: Correct Final Score</Typography> <br />
							<Typography variant='caption'>3 Points: Correct Score for Home/Away Team</Typography> <br />
							<Typography variant='caption'>1 Points: Correct Result for Home/Away Team</Typography>
						</CardContent>
					</CardActionArea>
				</Card>
			</Grid>
			<Grid item xs={12} sm={6}>
				<Card className={classes.root}>
					<CardActionArea>
						<CardContent>
							{/*
							<Typography gutterBottom variant='h5' component='h2' align='center'>
							Week {currentWeek}
							</Typography>
							*/}
							<Typography gutterBottom variant='h5' component='h2' align='center'>
								Singapore Premier League
							</Typography>
							<Typography gutterBottom variant='h6' component='h2' align='center'>
								Week {currentWeek}
							</Typography>
							<TableContainer component={Paper}>
								<Table className={classes.table} size='small' aria-label='a dense table' padding='none'>
									<TableHead>
										<TableRow>
											<TableCell align='left'>Date</TableCell>
											<TableCell align='left'>Home</TableCell>
											<TableCell align='left'>Away</TableCell>
											<TableCell align='center'>Status</TableCell>
											<TableCell align='center'>Prediction</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{matches.map(
											(row, index) =>
												row.match_status !== 'matchday' ? (
													<TableRow key={row.match_id} />
												) : (
													<TableRow key={row.match_id}>
														<TableCell component='th' scope='row'>
															<Typography variant='caption' gutterBottom>
																{new Date(row.match_date.seconds * 1000)
																	.toLocaleDateString()
																	.substr(0, 5)}
															</Typography>
														</TableCell>
														<TableCell component='th' scope='row'>
															<Avatar
																src={row.hometeam_logo_url}
																className={classes.small}
															/>
														</TableCell>
														<TableCell align='center'>
															<Avatar
																src={row.awayteam_logo_url}
																className={classes.small}
															/>
														</TableCell>
														<TableCell align='center'>
															<Typography variant='caption' gutterBottom>
																{row.match_status.substr(0, 1).toUpperCase()}
															</Typography>
														</TableCell>
														<TableCell align='center'>
															{row.match_prediction_status === 'closed' ? (
																<Button
																	variant='outlined'
																	size='small'
																	color='primary'
																	disabled
																	className={classes.margin}
																>
																	Closed
																</Button>
															) : (
																<div>
																	<Button
																		variant='outlined'
																		size='small'
																		color='primary'
																		className={classes.margin}
																		onClick={(e) => handleClickOpen(e, row)}
																	>
																		Predict
																	</Button>
																	<Dialog
																		open={openDialog}
																		onClose={handleClose}
																		aria-labelledby='form-dialog-title'
																	>
																		<DialogTitle id='form-dialog-title'>
																			Match Prediction
																		</DialogTitle>
																		<DialogContent>
																			<DialogContentText>
																				<Typography
																					component={'span'}
																					variant='subtitle2'
																				>
																					{currentMatch.match_title}
																				</Typography>
																			</DialogContentText>
																			<Avatar
																				src={currentMatch.hometeam_logo_url}
																			/>
																			<TextField
																				className={classes.input}
																				defaultValue={predictedHomeTeamScore}
																				type='number'
																				variant='outlined'
																				onChange={(event) =>
																					(predictedHomeTeamScore =
																						parseInt(
																							event.target.value,
																							10
																						) || 0)}
																			/>
																			<Avatar
																				src={currentMatch.awayteam_logo_url}
																			/>
																			<TextField
																				className={classes.input}
																				defaultValue={predictedAwayTeamScore}
																				variant='outlined'
																				onChange={(event) =>
																					(predictedAwayTeamScore =
																						parseInt(
																							event.target.value,
																							10
																						) || 0)}
																			/>
																		</DialogContent>
																		<DialogActions>
																			<Button
																				onClick={handleClose}
																				color='primary'
																			>
																				Cancel
																			</Button>
																			<Button
																				onClick={handleSubmitPrediction}
																				color='primary'
																			>
																				Submit
																			</Button>
																		</DialogActions>
																	</Dialog>
																</div>
															)}
														</TableCell>
													</TableRow>
												)
										)}
									</TableBody>
								</Table>
							</TableContainer>
							<br />
							<Divider />
							<Typography variant='caption' gutterBottom>
								Legend
							</Typography>
							<Divider />
							<Typography variant='caption' gutterBottom>
								Status: 'C'-Completed 'M'-Matchday U-'Upcoming'
							</Typography>
						</CardContent>
					</CardActionArea>
				</Card>
			</Grid>

	

			<Grid item xs={12} sm={6}>
				<Card className={classes.root}>
					<CardActionArea>
						<CardContent>
							<Typography gutterBottom variant='h6'>
								Womens' Premier League Prediction Records
							</Typography>
							<Divider />
							<Box p={1} bgcolor='background.paper' />
							<TableContainer component={Paper} p={5}>
								<Table className={classes.table} size='small' aria-label='a dense table' padding='none'>
									<TableHead>
										<TableRow>
											<TableCell align='center'>Wk</TableCell>
											<TableCell align='center'>Home</TableCell>
											<TableCell align='center'>Away</TableCell>
											<TableCell align='center'>Result</TableCell>
											<TableCell align='center'>Prediction</TableCell>
											<TableCell align='center'>Pts Gained</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{myMatchPredictions.map(
											(row, index) =>
												row.match_competition_status === 'WSPL' ? (
													<TableRow key={index}>
														<TableCell align='center'>{row.match_week}</TableCell>
														<TableCell component='th' scope='row'>
															<Avatar
																src={row.hometeam_logo_url}
																className={classes.small}
															/>
														</TableCell>
														<TableCell align='center'>
															<Avatar
																src={row.awayteam_logo_url}
																className={classes.small}
															/>
														</TableCell>
														{row.match_status !== 'completed' ? (
															<TableCell align='center'>-</TableCell>
														) : (
															<TableCell align='center'>
																{row.hometeam_score} - {row.awayteam_score}
															</TableCell>
														)}

														<TableCell align='center'>
															{row.predicted_home_score} - {row.predicted_away_score}{' '}
														</TableCell>
														{row.prediction_points === -1 ? (
															<TableCell align='center'>-</TableCell>
														) : (
															<TableCell align='center'>
																{row.prediction_points}
															</TableCell>
														)}
													</TableRow>
												) : (
													<TableRow key={'spl' + index} />
												)
										)}
									</TableBody>
								</Table>
							</TableContainer>
							<Box p={1} bgcolor='background.paper' />
							<Divider />
							<Typography variant='caption'>5 Points: Correct Final Score</Typography> <br />
							<Typography variant='caption'>3 Points: Correct Score for Home/Away Team</Typography> <br />
							<Typography variant='caption'>1 Points: Correct Result for Home/Away Team</Typography>
						</CardContent>
					</CardActionArea>
				</Card>
			</Grid>

			<Grid item xs={12} sm={6}>
				<Card className={classes.root}>
					<CardActionArea>
						<CardContent>
							{/*
							<Typography gutterBottom variant='h5' component='h2' align='center'>
							Week {currentWeek}
							</Typography>
							*/}
							<Typography gutterBottom variant='h5' component='h2' align='center'>
								Singapore Premier League (Women)
							</Typography>
							<Typography gutterBottom variant='h6' component='h2' align='center'>
								Week {currentWeek}
							</Typography>
							<TableContainer component={Paper}>
								<Table className={classes.table} size='small' aria-label='a dense table' padding='none'>
									<TableHead>
										<TableRow>
											<TableCell align='left'>Date</TableCell>
											<TableCell align='left'>Home</TableCell>
											<TableCell align='left'>Away</TableCell>
											<TableCell align='center'>Status</TableCell>
											<TableCell align='center'>Prediction</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{womenMatches.map(
											(wrow, index) =>
												wrow.match_status !== 'matchday' ? (
													<TableRow key={wrow.match_id} />
												) : (
													<TableRow key={wrow.match_id}>
														<TableCell component='th' scope='row'>
															<Typography variant='caption' gutterBottom>
																{new Date(wrow.match_date.seconds * 1000)
																	.toLocaleDateString()
																	.substr(0, 5)}
															</Typography>
														</TableCell>
														<TableCell component='th' scope='row'>
															<Avatar
																src={wrow.hometeam_logo_url}
																className={classes.small}
															/>
														</TableCell>
														<TableCell align='center'>
															<Avatar
																src={wrow.awayteam_logo_url}
																className={classes.small}
															/>
														</TableCell>
														<TableCell align='center'>
															<Typography variant='caption' gutterBottom>
																{wrow.match_status.substr(0, 1).toUpperCase()}
															</Typography>
														</TableCell>
														<TableCell align='center'>
															{wrow.match_prediction_status === 'closed' ? (
																<Button
																	variant='outlined'
																	size='small'
																	color='primary'
																	disabled
																	className={classes.margin}
																>
																	Closed
																</Button>
															) : (
																<div>
																	<Button
																		variant='outlined'
																		size='small'
																		color='primary'
																		className={classes.margin}
																		onClick={(e) => handleClickOpen(e, wrow)}
																	>
																		Predict
																	</Button>
																	<Dialog
																		open={openDialog}
																		onClose={handleClose}
																		aria-labelledby='form-dialog-title'
																	>
																		<DialogTitle id='form-dialog-title'>
																			Match Prediction
																		</DialogTitle>
																		<DialogContent>
																			<DialogContentText>
																				<Typography
																					component={'span'}
																					variant='subtitle2'
																				>
																					{currentMatch.match_title}
																				</Typography>
																			</DialogContentText>
																			<Avatar
																				src={currentMatch.hometeam_logo_url}
																			/>
																			<TextField
																				className={classes.input}
																				defaultValue={predictedHomeTeamScore}
																				type='number'
																				variant='outlined'
																				onChange={(event) =>
																					(predictedHomeTeamScore =
																						parseInt(
																							event.target.value,
																							10
																						) || 0)}
																			/>
																			<Avatar
																				src={currentMatch.awayteam_logo_url}
																			/>
																			<TextField
																				className={classes.input}
																				defaultValue={predictedAwayTeamScore}
																				variant='outlined'
																				onChange={(event) =>
																					(predictedAwayTeamScore =
																						parseInt(
																							event.target.value,
																							10
																						) || 0)}
																			/>
																		</DialogContent>
																		<DialogActions>
																			<Button
																				onClick={handleClose}
																				color='primary'
																			>
																				Cancel
																			</Button>
																			<Button
																				onClick={handleSubmitPrediction}
																				color='primary'
																			>
																				Submit
																			</Button>
																		</DialogActions>
																	</Dialog>
																</div>
															)}
														</TableCell>
													</TableRow>
												)
										)}
									</TableBody>
								</Table>
							</TableContainer>
							<br />
							<Divider />
							<Typography variant='caption' gutterBottom>
								Legend
							</Typography>
							<Divider />
							<Typography variant='caption' gutterBottom>
								Status: 'C'-Completed 'M'-Matchday U-'Upcoming'
							</Typography>
						</CardContent>
					</CardActionArea>
				</Card>
			</Grid>
		</StyledGrid>
	);
}

import React from 'react';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/styles';
import clsx from 'clsx';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { green } from '@mui/material/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ReactAudioPlayer from 'react-audio-player';
import { Grid } from '@mui/material';

const PREFIX = 'chants';

const classes = {
    card: `${PREFIX}-card`,
    media: `${PREFIX}-media`,
    expand: `${PREFIX}-expand`,
    expandOpen: `${PREFIX}-expandOpen`,
    avatar: `${PREFIX}-avatar`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.card}`]: {
		minWidth: 400,
		marginTop: 20
	},

    [`& .${classes.media}`]: {
		height: 300,
		paddingTop: '56.25%' // 16:9
	},

    [`& .${classes.expand}`]: {
		transform: 'rotate(0deg)',
		marginLeft: 'auto',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest
		})
	},

    [`& .${classes.expandOpen}`]: {
		transform: 'rotate(180deg)'
	},

    [`& .${classes.avatar}`]: {
		backgroundColor: green[500]
	}
}));

export default function RecipeReviewCard() {

	const theme = useTheme();
	const [ expanded, setExpanded ] = React.useState(false);

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	return (
        <Root>
			<Grid container className={classes.root} spacing={2}>
				<Grid item xs={12}>
					<Grid container justifyContent='center' spacing={6}>
						<Grid key={5} item>
							<Card className={classes.card}>
								<CardHeader
									avatar={
										<Avatar aria-label='recipe' className={classes.avatar}>
											O
										</Avatar>
									}
									action={
										<IconButton aria-label='settings' size="large">
											<MoreVertIcon />
										</IconButton>
									}
									title='Geylang Boleh!'
									subheader='Written By: Ultras Eagles'
								/>
								<CardMedia
									className={classes.media}
									image='https://firebasestorage.googleapis.com/v0/b/voiddeckfootie.appspot.com/o/2020SPL_Players%2Fgifc%2Fgeylangboleh_chant.jpg?alt=media&token=800959d5-7c75-4a39-a88e-984cc9c82f48'
									title='Geylang Boleh'
								/>
								<CardContent>
									<Typography variant='body2' color='textSecondary' component='p'>
										Geylang Boleh
									</Typography>
								</CardContent>
								<CardActions disableSpacing>
									<ReactAudioPlayer
										src='https://firebasestorage.googleapis.com/v0/b/voiddeckfootie.appspot.com/o/voiddeckfootie_audio%2Fgifc%2Fgeylangboleh.ogg?alt=media&token=9604f3d2-4aab-4070-abf9-7ee9426e9674'
										controls
									/>
									<IconButton
                                        className={clsx(classes.expand, {
											[classes.expandOpen]: expanded
										})}
                                        onClick={handleExpandClick}
                                        aria-expanded={expanded}
                                        aria-label='show more'
                                        size="large">
										<ExpandMoreIcon />
									</IconButton>
								</CardActions>
								<Collapse in={expanded} timeout='auto' unmountOnExit>
									<CardContent>
										<Typography paragraph variant='body2' gutterBottom>
											Lyrics:
										</Typography>

										<Typography paragraph='true' variant='body2' gutterBottom>
											Geylang Boleh (repeat with speed variation) Alezzz Alezz (repeat)
										</Typography>
									</CardContent>
								</Collapse>
							</Card>
						</Grid>
						<Grid key={0} item>
							<Card className={classes.card}>
								<CardHeader
									avatar={
										<Avatar aria-label='recipe' className={classes.avatar}>
											O
										</Avatar>
									}
									action={
										<IconButton aria-label='settings' size="large">
											<MoreVertIcon />
										</IconButton>
									}
									title='Stand Together'
									subheader='Written By: Ultras Eagles'
								/>
								<CardMedia
									className={classes.media}
									image='https://firebasestorage.googleapis.com/v0/b/voiddeckfootie.appspot.com/o/voiddeckfootie_banner%2Fgifc_chant.jpg?alt=media&token=a714bb25-e9c0-40cf-abca-064c83278619'
									title='Eagles Chant'
								/>
								<CardContent>
									<Typography variant='body2' color='textSecondary' component='p'>
										Stand Together
									</Typography>
								</CardContent>
								<CardActions disableSpacing>
									<IconButton
                                        className={clsx(classes.expand, {
											[classes.expandOpen]: expanded
										})}
                                        onClick={handleExpandClick}
                                        aria-expanded={expanded}
                                        aria-label='show more'
                                        size="large">
										<ExpandMoreIcon />
									</IconButton>
								</CardActions>
								<Collapse in={expanded} timeout='auto' unmountOnExit>
									<CardContent>
										<Typography paragraph='true' variant='body2'>
											We gonna stand together Geylang International <br />
											We gonna stand together Geylang International Go Eagles Go Eagles<br />
											(Capo: Who are we?!) <br />We're Internationals!
										</Typography>
									</CardContent>
								</Collapse>
							</Card>
						</Grid>
						<Grid item>
							<Card className={classes.card}>
								<CardHeader
									avatar={
										<Avatar aria-label='recipe' className={classes.avatar}>
											O
										</Avatar>
									}
									action={
										<IconButton aria-label='settings' size="large">
											<MoreVertIcon />
										</IconButton>
									}
									title='We fall in love with you'
									subheader='Written By: Ultras Eagles'
								/>
								<CardMedia
									className={classes.media}
									image='https://firebasestorage.googleapis.com/v0/b/voiddeckfootie.appspot.com/o/voiddeckfootie_banner%2Fgifc.chant.we_fall_in_love_with_you.jpg?alt=media&token=2b0d2299-4756-4bbb-bcbf-303d5c6cf79d'
									title='common eagle'
								/>
								<CardContent>
									<Typography variant='body2' color='textSecondary' component='p'>
										Eagles Can't You See
									</Typography>
								</CardContent>
								<CardActions disableSpacing>
									<IconButton
                                        className={clsx(classes.expand, {
											[classes.expandOpen]: expanded
										})}
                                        onClick={handleExpandClick}
                                        aria-expanded={expanded}
                                        aria-label='show more'
                                        size="large">
										<ExpandMoreIcon />
									</IconButton>
								</CardActions>
								<Collapse in={expanded} timeout='auto' unmountOnExit>
									<CardContent>
										<Typography paragraph variant='body2' gutterBottom>
											Lyrics:
										</Typography>

										<Typography paragraph='true' variant='body2' gutterBottom>
											Eagles can't you see <br />
											We fell in love with you <br />
											We're a family<br />
											And we owe it all to you <br />
											Forever sing for Eagles <br />
											Forever will be true<br />
											And we owe it all to you <br />
											Our famous boys in Green<br />
											Allez allez allez....<br />
											Allez Allez Allez<br />
											Allez Allez Allez<br />
											Allez Allez Allez<br />
										</Typography>
									</CardContent>
								</Collapse>
							</Card>
						</Grid>
						<Grid key={1} item>
							<Card className={classes.card}>
								<CardHeader
									avatar={
										<Avatar aria-label='recipe' className={classes.avatar}>
											O
										</Avatar>
									}
									action={
										<IconButton aria-label='settings' size="large">
											<MoreVertIcon />
										</IconButton>
									}
									title='GIFC'
									subheader='Written By: Ultras Eagles'
								/>
								<CardMedia
									className={classes.media}
									image='https://firebasestorage.googleapis.com/v0/b/voiddeckfootie.appspot.com/o/voiddeckfootie_banner%2Fgifc_chant_pic.png?alt=media&token=7cfcce8c-af95-45f3-b6f9-8d70cea06cc8'
									title='common eagle'
								/>
								<CardContent>
									<Typography variant='body2' color='textSecondary' component='p'>
										GIFC
									</Typography>
								</CardContent>
								<CardActions disableSpacing>
									<ReactAudioPlayer
										src='https://firebasestorage.googleapis.com/v0/b/voiddeckfootie.appspot.com/o/voiddeckfootie_audio%2Fgifc%2Fgifc.ogg?alt=media&token=027a45ce-59ad-467d-abcb-6fe38c2ffaf5'
										controls
									/>
									<IconButton
                                        className={clsx(classes.expand, {
											[classes.expandOpen]: expanded
										})}
                                        onClick={handleExpandClick}
                                        aria-expanded={expanded}
                                        aria-label='show more'
                                        size="large">
										<ExpandMoreIcon />
									</IconButton>
								</CardActions>
								<Collapse in={expanded} timeout='auto' unmountOnExit>
									<CardContent>
										<Typography paragraph variant='body2' gutterBottom>
											Lyrics:
										</Typography>

										<Typography paragraph='true' variant='body2' gutterBottom>
											G I F C (clap 5 times) <br />
											We're here for you (clap 5 times)<br />
											To support you (clap 5 times)<br />
											Win draw or lose (clap 5 times)<br />
											Allez ah ohhhhh<br />
											Allez ah ohhh<br />
											Allez ah ohhh <br />
											Allez ah ohhh<br />
										</Typography>
									</CardContent>
								</Collapse>
							</Card>
						</Grid>
						<Grid key={2} item>
							<Card className={classes.card}>
								<CardHeader
									avatar={
										<Avatar aria-label='recipe' className={classes.avatar}>
											O
										</Avatar>
									}
									action={
										<IconButton aria-label='settings' size="large">
											<MoreVertIcon />
										</IconButton>
									}
									title='We Love You'
									subheader='Written By: Ultras Eagles'
								/>
								<CardMedia
									className={classes.media}
									image='https://firebasestorage.googleapis.com/v0/b/voiddeckfootie.appspot.com/o/voiddeckfootie_banner%2Fweloveyou_chant.jpg?alt=media&token=78e0d498-57c1-4283-b173-35fd3ca2a787'
									title='common eagle'
								/>
								<CardContent>
									<Typography variant='body2' color='textSecondary' component='p'>
										We Love You
									</Typography>
								</CardContent>
								<CardActions disableSpacing>
									<IconButton
                                        className={clsx(classes.expand, {
											[classes.expandOpen]: expanded
										})}
                                        onClick={handleExpandClick}
                                        aria-expanded={expanded}
                                        aria-label='show more'
                                        size="large">
										<ExpandMoreIcon />
									</IconButton>
								</CardActions>
								<Collapse in={expanded} timeout='auto' unmountOnExit>
									<CardContent>
										<Typography paragraph variant='body2' gutterBottom>
											Lyrics:
										</Typography>

										<Typography paragraph='true' variant='body2' gutterBottom>
											We......love you! We love you! We love you! <br />
											And everywhere we follow! We follow! We follow!<br />
											Cause we support the Eagles! The Eagles! The Eagles! <br />
											And that's the way we like it! We like it! We like it!<br />
											Oh ohoh ohoh ohhhh<br />
											Oh ohoh ohoh ohhhh<br />
											Oh ohoh ohoh ohhhh<br />
											Oh ohoh ohoh ohhhh<br />
										</Typography>
									</CardContent>
								</Collapse>
							</Card>
						</Grid>
						<Grid key={3} item>
							<Card className={classes.card}>
								<CardHeader
									avatar={
										<Avatar aria-label='recipe' className={classes.avatar}>
											O
										</Avatar>
									}
									action={
										<IconButton aria-label='settings' size="large">
											<MoreVertIcon />
										</IconButton>
									}
									title='Eagles'
									subheader='Written By: Ultras Eagles'
								/>
								<CardMedia
									className={classes.media}
									image='https://firebasestorage.googleapis.com/v0/b/voiddeckfootie.appspot.com/o/voiddeckfootie_banner%2Feagles_chant.jpg?alt=media&token=35f1cfa7-0da4-4fba-b099-d6765db47917'
									title='Eagles'
								/>
								<CardContent>
									<Typography variant='body2' color='textSecondary' component='p'>
										Eagles
									</Typography>
								</CardContent>
								<CardActions disableSpacing>
									<IconButton
                                        className={clsx(classes.expand, {
											[classes.expandOpen]: expanded
										})}
                                        onClick={handleExpandClick}
                                        aria-expanded={expanded}
                                        aria-label='show more'
                                        size="large">
										<ExpandMoreIcon />
									</IconButton>
								</CardActions>
								<Collapse in={expanded} timeout='auto' unmountOnExit>
									<CardContent>
										<Typography paragraph variant='body2' gutterBottom>
											Lyrics:
										</Typography>

										<Typography paragraph='true' variant='body2' gutterBottom>
											Capo: EAGLESSSS <br />
											(UE repeat)<br />
											Capo: Come on you boys in Green!<br />
											(UE repeat)<br />
											Capo: Eagles all the way!<br />
											(UE repeat)<br />
											Oh ohoh ohohohoh ohoh ohoh oh oh<br />
											Oh ohoh ohohohoh ohoh ohoh oh oh<br />
										</Typography>
									</CardContent>
								</Collapse>
							</Card>
						</Grid>
						<Grid key={4} item>
							<Card className={classes.card}>
								<CardHeader
									avatar={
										<Avatar aria-label='recipe' className={classes.avatar}>
											O
										</Avatar>
									}
									action={
										<IconButton aria-label='settings' size="large">
											<MoreVertIcon />
										</IconButton>
									}
									title='OH WHEN THE GREENS!'
									subheader='Written By: Ultras Eagles'
								/>
								<CardMedia
									className={classes.media}
									image='https://firebasestorage.googleapis.com/v0/b/voiddeckfootie.appspot.com/o/voiddeckfootie_banner%2Fohwhenthegreens_chant.jpg?alt=media&token=71bd954d-5fd6-49cf-a85a-c9854cdccf76'
									title='OH WHEN THE GREENS!'
								/>
								<CardContent>
									<Typography variant='body2' color='textSecondary' component='p'>
										OH WHEN THE GREENS!
									</Typography>
								</CardContent>
								<CardActions disableSpacing>
									<IconButton
                                        className={clsx(classes.expand, {
											[classes.expandOpen]: expanded
										})}
                                        onClick={handleExpandClick}
                                        aria-expanded={expanded}
                                        aria-label='show more'
                                        size="large">
										<ExpandMoreIcon />
									</IconButton>
								</CardActions>
								<Collapse in={expanded} timeout='auto' unmountOnExit>
									<CardContent>
										<Typography paragraph variant='body2' gutterBottom>
											Lyrics:
										</Typography>

										<Typography paragraph='true' variant='body2' gutterBottom>
											Capo: OH WHEN THE GREENS! <br />
											(UE repeat)<br />
											Capo: GOES MARCHING IN!<br />
											(UE repeat)<br />
											Together: Oh when the Greens goes marching in, I want to be in that number,
											oh when the Green goes marching in!<br />
										</Typography>
									</CardContent>
								</Collapse>
							</Card>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Root>
    );
}


import FantasyLeagueMiniLeague from '../component/FantasyLeagueMiniLeague';
import React, { Component } from 'react';

class fantasyleagueminileague extends Component {
	render() {
		return (
            <FantasyLeagueMiniLeague></FantasyLeagueMiniLeague>
		)
	}
}

export default fantasyleagueminileague;

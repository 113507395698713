import React, { Component } from 'react'
import FantasyLeagueTotw from '../component/FantasyLeagueTotw'

class fantasyleaguetotw extends Component {
    render() {
        return (
          <div>
            <FantasyLeagueTotw/>
          </div>
        )
    }
}

export default fantasyleaguetotw;

import React, { Component, useContext } from 'react';
import Login from '../component/Login';
import { AuthContext } from '../App';

export default class login extends Component {
	render() {
		return (
			<div>
				<Login />
			</div>
		);
	}
}

import React, { useState, useEffect } from 'react';

import { db } from '../firebase';
import { Button, Card, CardHeader, CardActionArea, CardContent } from '@mui/material'; //importing material ui component

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';

//Tabs
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

//Radio
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';

//Snackbar
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

//Select
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import TextField from '@mui/material/TextField';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children : PropTypes.node,
	index    : PropTypes.number.isRequired,
	value    : PropTypes.number.isRequired
};

function a11yProps(index) {
	return {
		id              : `simple-tab-${index}`,
		'aria-controls' : `simple-tabpanel-${index}`
	};
}

const MatchAdmin = () => {
	const [ value, setValue ] = React.useState(0);
	const [ matches, setMatches ] = useState([]);
	const [ selectedMatchId, setSelectedMatchId ] = useState('');
	const [ selectedMatch, setSelectedMatch ] = useState();
	const [ hometeam, setHometeam ] = useState('');
	const [ awayteam, setAwayteam ] = useState('');
	const [ hometeamPlayers, setHometeamPlayers ] = useState([]);
	const [ awayteamPlayers, setAwayteamPlayers ] = useState([]);
	const [ hometeamScore, setHometeamScore ] = useState(0);
	const [ awayteamScore, setAwayteamScore ] = useState(0);
	const [ hometeamShots, setHometeamShots ] = useState(0);
	const [ awayteamShots, setAwayteamShots ] = useState(0);
	const [ matchStatus, setMatchStatus ] = useState('upcoming');
	const [ matchPredictionStatus, setMatchPredictionStatus ] = useState('');
	const [ matchPlayerStatus, setMatchPlayerStatus ] = useState('reserve');

	const [ snackBarOpen, setSnackBarOpen ] = useState(false);
	const [ snackBarMessage, setSnackBarMessage ] = useState('');

	const handleChange = (event, newValue) => {
		var match = matches.find((match) => {
			return match.match_id === event.target.value;
		});

		console.log(match.hometeam_shots);
		setHometeamScore(match.hometeam_score);
		setAwayteamScore(match.awayteam_score);
		setMatchPredictionStatus(match.match_prediction_status);

		if (match.hometeam_shots === undefined) setHometeamShots(0);
		else setHometeamShots(match.hometeam_shots);
		if (match.awayteam_shots === undefined) setAwayteamShots(0);
		else setAwayteamShots(match.awayteam_shots);

		setMatchStatus(match.match_status);

		console.log('Getting Players for the match ' + match.match_id);
		if (match) {
			db
				.collection('2022Matches/' + match.match_id + '/HOME_TEAM_PLAYERS')
				.orderBy('position_id', 'asc')
				.get()
				.then((querySnapshot) => {
					setHometeamPlayers(querySnapshot.docs.map((player) => player.data()));
				})
				.catch((error) => {
					console.log('Error getting documents: ', error);
				});

			db
				.collection('2022Matches/' + match.match_id + '/AWAY_TEAM_PLAYERS')
				.orderBy('position_id', 'asc')
				.get()
				.then((querySnapshot) => {
					setAwayteamPlayers(querySnapshot.docs.map((player) => player.data()));
				})
				.catch((error) => {
					console.log('Error getting documents: ', error);
				});

			setSelectedMatch(match);
			setSelectedMatchId(match.match_id);
			setHometeam(match.hometeam_name);
			setAwayteam(match.awayteam_name);
		}
	};

	const handleTabChange = (event, newValue) => {
		setValue(newValue);
	};

	const handleSnackBarClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setSnackBarOpen(false);
	};

	const snackBarAction = (
		<React.Fragment>
			<Button color='secondary' size='small' onClick={handleSnackBarClose}>
				UNDO
			</Button>
			<IconButton size='small' aria-label='close' color='inherit' onClick={handleSnackBarClose}>
				<CloseIcon fontSize='small' />
			</IconButton>
		</React.Fragment>
	);

	/**
	 * Extracting the matches with status matchday
	 */
	useEffect(() => {
		const fetchMatchdayMatches = async () => {
			db
				.collection('2022Matches')
				.where('match_status', 'in', [ 'matchday', 'ongoing', 'upcoming' ])
				.get()
				.then((querySnapshot) => {
					setMatches(querySnapshot.docs.map((match) => match.data()));
				})
				.catch((error) => {
					console.log('Error getting documents: ', error);
				});
		};

		fetchMatchdayMatches();
	}, []);

	function handleSetupMatch() {
		console.log('Setting up match: ' + selectedMatch.match_title);
		console.log('Home Team Id: ' + selectedMatch.hometeam_id);
		console.log('Away Team Id: ' + selectedMatch.awayteam_id);

		var tempHomePlayers = [];
		var tempAwayPlayers = [];

		var hometeambatch = db.batch();
		db
			.collection('2022Teams/' + selectedMatch.hometeam_id + '/2022Players')
			.orderBy('position', 'asc')
			.get()
			.then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					var player = {
						...doc.data(),
						match_id             : selectedMatch.match_id,
						match_title          : selectedMatch.match_title,
						match_playing_status : 'reserve'
					};
					if (player.player_id === '1373bec467d1ada678ba2217489d48e21c0cd807_Rezza-Rezky_Yacobjan_14')
					tempHomePlayers.push(player);
					var hometeamPlayersRef = db
						.collection('2022Matches/' + selectedMatch.match_id + '/HOME_TEAM_PLAYERS')
						.doc(player.player_id);
					hometeambatch.set(hometeamPlayersRef, player);
				});

				// Commit the batch
				hometeambatch.commit().then(() => {
					console.log('Home Team Players Batch Committed for ' + selectedMatch.hometeam_id);
				});
				setHometeamPlayers([ ...tempHomePlayers ]);
			})
			.catch((error) => {
				console.log('Error getting documents: ', error);
			});

			
		var awayteambatch = db.batch();
		db
			.collection('2022Teams/' + selectedMatch.awayteam_id + '/2022Players')
			.orderBy('position', 'asc')
			.get()
			.then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					console.log(doc.data());
					var player = {
						...doc.data(),
						match_id             : selectedMatch.match_id,
						match_title          : selectedMatch.match_title,
						match_playing_status : 'reserve'
					};
					tempAwayPlayers.push(player);
					var awayteamPlayersRef = db
						.collection('/2022Matches/' + selectedMatch.match_id + '/AWAY_TEAM_PLAYERS')
						.doc(player.player_id);
					awayteambatch.set(awayteamPlayersRef, player);
				});

				// Commit the batch
				awayteambatch.commit().then(() => {
					console.log('Away Team Players Batch Committed for ' + selectedMatch.awayteam_id);
				});
				setAwayteamPlayers([ ...tempAwayPlayers ]);
			})
			.catch((error) => {
				console.log('Error getting documents: ', error);
			});
			
			
	}

	function handleUpdateMatch() {
		console.log('Update match: ' + selectedMatch.match_title);
		selectedMatch.hometeam_score = Number.parseInt(hometeamScore);
		selectedMatch.awayteam_score = Number.parseInt(awayteamScore);
		selectedMatch.hometeam_shots = Number.parseInt(hometeamShots);
		selectedMatch.awayteam_shots = Number.parseInt(awayteamShots);
		selectedMatch.match_status = matchStatus;
		selectedMatch.match_prediction_status = matchPredictionStatus;
	

		console.log(selectedMatch);
		db
			.collection('/2022Matches')
			.doc(selectedMatch.match_id)
			.set({ ...selectedMatch }, { merge: true })
			.then(() => {
				console.log('Document successfully written! -> ' + selectedMatch.match_id);
				setSnackBarOpen(true);
				setSnackBarMessage(selectedMatch.match_title + ' updated successfully.');
			})
			.catch((error) => {
				console.error('Error writing document: ', error);
			});
	}

	/**
	 * Handling Home Team Players 'is Playing' switch
	 * @param {} event 
	 */
	const handleTeamPlayerSwitchChange = (event, selectedPlayer, team) => {
		//console.log(event.target.value);
		console.log(selectedPlayer);

		if (team === 'HOME') {
			var homeplayer = hometeamPlayers.find((player) => {
				if (player.player_id === selectedPlayer.player_id) {
					player.match_playing_status = event.target.value;
					player.match_start_time = 0;
					player.match_end_time = 90;
					player.match_goals = 0;
					player.match_goals_time = '';
					player.match_assists = 0;
					player.match_yellow_cards = 0;
					player.match_red_cards = 0;
					player.match_penalty_misses = 0;
					player.match_penalty_saves = 0;
					player.match_own_goals = 0;
					return player;
				} else return null;
			});

			db
				.collection('/2022Matches/' + homeplayer.match_id + '/HOME_TEAM_PLAYERS')
				.doc(homeplayer.player_id)
				.set(homeplayer, {merge:true})
				.then(() => {
					console.log('Document successfully written! -> ' + homeplayer.player_id);
				});

			setHometeamPlayers([ ...hometeamPlayers ]);
		} else if (team === 'AWAY') {
			var awayplayer = awayteamPlayers.find((player) => {
				if (player.player_id === selectedPlayer.player_id) {
					player.match_playing_status = event.target.value;
					player.match_start_time = 0;
					player.match_end_time = 90;
					player.match_goals = 0;
					player.match_goals_time = '';
					player.match_assists = 0;
					player.match_yellow_cards = 0;
					player.match_red_cards = 0;
					player.match_penalty_misses = 0;
					player.match_penalty_saves = 0;
					player.match_own_goals = 0;
					return player;
				} else return null;
			});
			db
				.collection('/2022Matches/' + awayplayer.match_id + '/AWAY_TEAM_PLAYERS')
				.doc(awayplayer.player_id)
				.set(awayplayer, 'merge:true')
				.then(() => {
					console.log('Document successfully written! -> ' + awayplayer.player_id);
				});

			setAwayteamPlayers([ ...awayteamPlayers ]);
		}
	};

	/**
	 * Handling Away Team Players 'is Playing' switch
	 * @param {} event 
	 
	const handleAwayTeamPlayerSwitchChange = (event) => {
		console.log(event.target.checked);
		console.log(event.target.id);

		var player = awayteamPlayers.find((player) => {
			if (player.player_id === event.target.id) {
				console.log(player);
				player.match_is_playing = event.target.checked;
				return player;
			}
		});

		db
			.collection('/2022Matches/' + player.match_id + '/AWAY_TEAM_PLAYERS')
			.doc(player.player_id)
			.set(player, 'merge:true')
			.then(() => {
				console.log('Document successfully written! -> ' + player.player_id);
			});

		setAwayteamPlayers([ ...awayteamPlayers ]);
	};
	*/

	/**
	 * Handle the changes for the textfield
	 * @param {} selectedPlayer 
	 * @param {*} event 
	 */
	const handleValueChange = (selectedPlayer, event) => {
		console.log(event.target.value);
		console.log(event.target.id);
		//console.log(player.player_id)
		if (event.target.id.indexOf('hometeamplayer') !== -1) {
			var homeplayer = hometeamPlayers.find((player) => {
				if (player.player_id === selectedPlayer.player_id) {
					console.log('found player: ');
					if (event.target.id.indexOf('hometeamplayer-match_start_time') !== -1)
						player.match_start_time = event.target.value;
					else if (event.target.id.indexOf('hometeamplayer-match_end_time') !== -1)
						player.match_end_time = event.target.value;
					else if (event.target.id.indexOf('hometeamplayer-match_goals') !== -1)
						player.match_goals = event.target.value;
					else if (event.target.id.indexOf('hometeamplayer-match_assists') !== -1)
						player.match_assists = event.target.value;
					else if (event.target.id.indexOf('hometeamplayer-match_yellow_cards') !== -1)
						player.match_yellow_cards = event.target.value;
					else if (event.target.id.indexOf('hometeamplayer-match_red_cards') !== -1)
						player.match_red_cards = event.target.value;
					else if (event.target.id.indexOf('hometeamplayer-match-goals-time') !== -1)
						player.match_goals_time = event.target.value;
					else if (event.target.id.indexOf('hometeamplayer-match_own_goals') !== -1)
						player.match_own_goals = event.target.value;
					else if (event.target.id.indexOf('hometeamplayer-match_penalty_saves') !== -1)
						player.match_penalty_saves = event.target.value;
					else if (event.target.id.indexOf('hometeamplayer-match_penalty_misses') !== -1) {
						player.match_penalty_misses = event.target.value;
						console.log('penalty miss');
					}

					return homeplayer;
				} else {
					return null;
				}
			});
			setHometeamPlayers([ ...hometeamPlayers ]);
		} else if (event.target.id.indexOf('awayteamplayer') !== -1) {
			var awayplayer = awayteamPlayers.find((player) => {
				if (player.player_id === selectedPlayer.player_id) {
					if (event.target.id.indexOf('awayteamplayer-match_start_time') !== -1)
						player.match_start_time = event.target.value;
					else if (event.target.id.indexOf('awayteamplayer-match_end_time') !== -1)
						player.match_end_time = event.target.value;
					else if (event.target.id.indexOf('awayteamplayer-match_goals') !== -1)
						player.match_goals = event.target.value;
					else if (event.target.id.indexOf('awayteamplayer-match_assists') !== -1)
						player.match_assists = event.target.value;
					else if (event.target.id.indexOf('awayteamplayer-match_yellow_cards') !== -1)
						player.match_yellow_cards = event.target.value;
					else if (event.target.id.indexOf('awayteamplayer-match_red_cards') !== -1)
						player.match_red_cards = event.target.value;
					else if (event.target.id.indexOf('awayteamplayer-match-goals-time') !== -1)
						player.match_goals_time = event.target.value;
					else if (event.target.id.indexOf('awayteamplayer-match_own_goals') !== -1)
						player.match_own_goals = event.target.value;
					else if (event.target.id.indexOf('awayteamplayer-match_penalty_saves') !== -1)
						player.match_penalty_saves = event.target.value;
					else if (event.target.id.indexOf('awayteamplayer-match_penalty_misses') !== -1)
						player.match_penalty_misses = event.target.value;

					return awayplayer;
				} else return null;
			});
			setAwayteamPlayers([ ...awayteamPlayers ]);
		} else {
			return null;
		}
	};

	/**
	 * Updates the players with the new statistics.
	 * @param {} playertype 
	 * @param {*} selectedPlayer 
	 * @param {*} event 
	 */
	const handleUpdatePlayer = (playertype, selectedPlayer, updateType, event) => {
		console.log(selectedPlayer);
		console.log(playertype);
		if (playertype === 'hometeamplayer-update') {
			var player = hometeamPlayers.find((player) => {
				if (player.player_id === selectedPlayer.player_id) {
					player.match_start_time = parseInt(selectedPlayer.match_start_time);
					player.match_end_time = parseInt(selectedPlayer.match_end_time);
					player.match_goals = parseInt(selectedPlayer.match_goals);
					player.match_goals_time =
						selectedPlayer.match_goals_time === undefined ? '' : selectedPlayer.match_goals_time;
					player.match_assists = parseInt(selectedPlayer.match_assists);
					player.match_yellow_cards = parseInt(selectedPlayer.match_yellow_cards);
					player.match_red_cards = parseInt(selectedPlayer.match_red_cards);
					player.match_penalty_saves = parseInt(selectedPlayer.match_penalty_saves);
					player.match_penalty_misses = parseInt(selectedPlayer.match_penalty_misses);
					player.match_own_goals = parseInt(selectedPlayer.match_own_goals);
					return player;
				} else return null;
			});

			console.log('-- Current Values -- ' + playertype + ' ' + player.player_id);
			console.log(player.match_start_time);
			console.log(player.match_end_time);
			console.log(player.match_goals);
			console.log(player.match_goals_time);
			console.log(player.match_assists);
			console.log(player.match_yellow_cards);
			console.log(player.match_red_cards);
			console.log(player.match_penalty_saves);
			console.log(player.match_penalty_misses);
			console.log(player.match_own_goals);

			db
				.collection('/2022Matches/' + player.match_id + '/HOME_TEAM_PLAYERS')
				.doc(player.player_id)
				.set(player, { merge: true })
				.then(() => {
					console.log('Document successfully written! -> ' + player.player_id);
					setSnackBarOpen(true);
					setSnackBarMessage(player.firstname + ' updated successfully.');
				})
				.catch((error) => {
					console.error('Error writing document: ', error);
				});

			setHometeamPlayers([ ...hometeamPlayers ]);
		} else if (playertype === 'awayteamplayer-update') {
			console.log(selectedPlayer);
			var player = awayteamPlayers.find((player) => {
				if (player.player_id === selectedPlayer.player_id) {
					console.log('here');
					player.match_start_time = parseInt(selectedPlayer.match_start_time);
					player.match_end_time = parseInt(selectedPlayer.match_end_time);
					player.match_goals = parseInt(selectedPlayer.match_goals);
					player.match_goals_time =
						selectedPlayer.match_goals_time === undefined ? '' : selectedPlayer.match_goals_time;
					player.match_assists = parseInt(selectedPlayer.match_assists);
					player.match_yellow_cards = parseInt(selectedPlayer.match_yellow_cards);
					player.match_red_cards = parseInt(selectedPlayer.match_red_cards);
					player.match_penalty_saves = parseInt(selectedPlayer.match_penalty_saves);
					player.match_penalty_misses = parseInt(selectedPlayer.match_penalty_misses);
					player.match_own_goals = parseInt(selectedPlayer.match_own_goals);
					return player;
				} else return null;
			});

			console.log('-- Current Values -- ' + playertype + ' ' + player);
			console.log(player.match_start_time);
			console.log(player.match_end_time);
			console.log(player.match_goals);
			console.log(player.match_goals_time);
			console.log(player.match_assists);
			console.log(player.match_yellow_cards);
			console.log(player.match_red_cards);

			db
				.collection('/2022Matches/' + player.match_id + '/AWAY_TEAM_PLAYERS')
				.doc(player.player_id)
				.set(player, { merge: true })
				.then(() => {
					console.log('Document successfully written! -> ' + player.player_id);
					setSnackBarOpen(true);
					setSnackBarMessage(player.firstname + ' updated successfully.');
				})
				.catch((error) => {
					console.error('Error writing document: ', error);
				});

			setAwayteamPlayers([ ...awayteamPlayers ]);
		}
	};

	const playerTitle = (player) => {
		return (
			<ul style={{ listStyleType: 'none' }}>
				<li>
					{player.firstname} {player.lastname}
				</li>
				<li>{player.squad_number}</li>
				<li>{player.position}</li>
			</ul>
		);
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Card>
					<CardContent>
						<Typography textAlign='center' variant='h4'>
							Match Admin
						</Typography>

						<Grid container direction='row' spacing={2}>
							<Grid item xs={12} />
						</Grid>
					</CardContent>
				</Card>
			</Grid>
			<Grid item xs={6}>
				<FormControl fullWidth>
					<InputLabel id='demo-simple-select-label'>Match</InputLabel>
					<Select
						labelId='demo-simple-select-label'
						id='demo-simple-select'
						label='Match'
						onChange={handleChange}
						value={selectedMatchId}
					>
						{matches.map((match) => {
							return (
								<MenuItem key={match.match_id} value={match.match_id}>
									{match.match_title} - Wk {match.match_week}
								</MenuItem>
							);
						})}
					</Select>
				</FormControl>
			</Grid>
			<Grid item xs={6}>
				<Stack spacing={2} direction='row' alignItems='center'>
					<Button variant='contained' onClick={handleSetupMatch}>
						Setup Match
					</Button>
				</Stack>
			</Grid>
			<Grid item xs={6}>
				<Typography variant='h6'>Match Status</Typography>
			</Grid>
			<Grid item xs={6}>
				<Typography variant='h6'>Score</Typography>
			</Grid>
			<Grid item xs={4}>
				<Select
					labelId='demo-simple-select-label'
					id='demo-simple-select'
					label='Match'
					onChange={(e) => setMatchStatus(e.target.value)}
					value={matchStatus}
				>
					<MenuItem key='upcoming' value='upcoming'>
						Up Coming
					</MenuItem>
					<MenuItem key='matchday' value='matchday'>
						Matchday
					</MenuItem>
					<MenuItem key='ongoing' value='ongoing'>
						On Going
					</MenuItem>
					<MenuItem key='completed' value='completed'>
						Completed
					</MenuItem>
				</Select>
			</Grid>
			
			<Grid item xs={3}>
				<TextField
					id='outlined-basic'
					label='Home Team'
					variant='outlined'
					value={hometeamScore}
					type='number'
					onChange={(e) => setHometeamScore(e.target.value)}
				/>
			</Grid>
			<Grid item xs={3}>
				<TextField
					id='outlined-basic'
					label='Away Team'
					variant='outlined'
					value={awayteamScore}
					onChange={(e) => setAwayteamScore(e.target.value)}
				/>
			</Grid>
			<Grid item xs={6} />
			<Grid item xs={6}>
				<Typography variant='h6'>Shots</Typography>
			</Grid>
			<Grid item xs={4} />
			<Grid item xs={3}>
				<TextField
					id='outlined-basic'
					label='Home Team Shots'
					variant='outlined'
					value={hometeamShots}
					onChange={(e) => setHometeamShots(e.target.value)}
				/>
			</Grid>
			<Grid item xs={3}>
				<TextField
					id='outlined-basic'
					label='Away Team Shots'
					variant='outlined'
					value={awayteamShots}
					onChange={(e) => setAwayteamShots(e.target.value)}
				/>
			</Grid>
			<Grid item xs={6}>
			<InputLabel id="demo-simple-select-label">Match Prediction Status</InputLabel>

			<Select
					labelId='demo-simple-select-label'
					id='demo-simple-select'
					label='Prediction Status'
					onChange={(e) => setMatchPredictionStatus(e.target.value)}
					value={matchPredictionStatus}
				>
					<MenuItem key='open' value='open'>
						Open
					</MenuItem>
					<MenuItem key='closed' value='closed'>
						Closed
					</MenuItem>
	
				</Select>
			</Grid>
			
			<Grid item xs={6}>
				<Stack spacing={2} direction='row' alignItems='end'>
					<Button variant='contained' onClick={handleUpdateMatch}>
						Update Match Stats
					</Button>
				</Stack>
			</Grid>

			<Snackbar
				open={snackBarOpen}
				autoHideDuration={3000}
				onClose={handleSnackBarClose}
				message={snackBarMessage}
				action={snackBarAction}
			/>
			<Grid key='home-team-squad' item xs={12}>
				<Box sx={{ width: '100%' }}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<Tabs value={value} onChange={handleTabChange} variant='fullWidth'>
							<Tab label={hometeam} {...a11yProps(0)} />
							<Tab label={awayteam} {...a11yProps(1)} />
						</Tabs>
					</Box>
					<TabPanel value={value} index={0} key='homeTeamTabPanel'>
						<Grid container spacing={2} key='homeTeamGridContainer'>
							<Grid key='on-pitch' item xs={12}>
								<Card>
									<CardActionArea>
										<CardHeader
											sx={{
												height     : 50,
												textAlign  : 'center',
												background : 'black',
												color      : 'white'
											}}
											title='First 11'
											titleTypographyProps={{ variant: 'h6' }}
										/>
									</CardActionArea>
								</Card>
							</Grid>
							{hometeamPlayers.map(
								(player, id) =>
									player.match_playing_status === 'first_eleven' ? (
										<Grid item key={'hometeam-grid-' + player.player_id} xs={4}>
											<Card sx={{ maxWidth: 345 }} key={'hometeam-card-' + player.player_id}>
												<CardHeader
													avatar={<Avatar src={player.team_logo_url} />}
													title={playerTitle(player)}
												/>
												<FormControl component='fieldset'>
													<FormLabel id='player-match-status-buttons-group-label'>
														Match Selection Status
													</FormLabel>
													<RadioGroup
														value={player.match_playing_status}
														aria-labelledby='player-match-status-buttons-group-label'
														defaultValue='reserve'
														name='radio-buttons-group'
														direction='row'
														key={player.player_id + 'homteam-firsteleven-radiogroup'}
													>
														<FormControlLabel
															key={player.player_id + 'first_eleven'}
															value='first_eleven'
															control={<Radio />}
															label='First Eleven'
														/>
														<FormControlLabel
															key={player.player_id + 'subtitute'}
															value='substitute'
															control={<Radio />}
															label='Substitute'
														/>
														<FormControlLabel
															key={player.player_id + 'reserve'}
															value='reserve'
															control={<Radio />}
															label='Reserve'
														/>
													</RadioGroup>

													<TextField
														id='hometeamplayer-match_start_time'
														label='Time Start'
														variant='standard'
														value={player.match_start_time}
														type='number'
														onChange={(e) => handleValueChange(player, e)}
													/>
													<TextField
														id='hometeamplayer-match_end_time'
														label='Time End'
														variant='standard'
														value={player.match_end_time}
														type='number'
														onChange={(e) => handleValueChange(player, e)}
													/>
													<TextField
														id='hometeamplayer-match_goals'
														label='Goal(s)'
														variant='standard'
														value={player.match_goals}
														type='number'
														onChange={(e) => handleValueChange(player, e)}
													/>
													<TextField
														id='hometeamplayer-match-goals-time'
														label='Goal Time'
														variant='standard'
														value={player.match_goals_time}
														type='string'
														onChange={(e) => handleValueChange(player, e)}
													/>
													<TextField
														id='hometeamplayer-match_assists'
														label='Assist(s)'
														variant='standard'
														value={player.match_assists}
														type='number'
														onChange={(e) => handleValueChange(player, e)}
													/>
													<TextField
														id='hometeamplayer-match_yellow_cards'
														label='Yellow Card'
														variant='standard'
														value={player.match_yellow_cards}
														type='number'
														onChange={(e) => handleValueChange(player, e)}
													/>
													<TextField
														id='hometeamplayer-match_red_cards'
														label='Red Card'
														variant='standard'
														value={player.match_red_cards}
														type='number'
														onChange={(e) => handleValueChange(player, e)}
													/>
													<TextField
														id='hometeamplayer-match_penalty_misses'
														label='Penalty Missed'
														variant='standard'
														value={player.match_penalty_misses}
														onChange={(e) => handleValueChange(player, e)}
													/>
													<TextField
														id='hometeamplayer-match_penalty_saves'
														label='Penalty Saved'
														variant='standard'
														value={player.match_penalty_saves}
														onChange={(e) => handleValueChange(player, e)}
													/>
													<TextField
														id='hometeamplayer-match_own_goals'
														label='Own Goal'
														variant='standard'
														value={player.match_own_goals}
														onChange={(e) => handleValueChange(player, e)}
													/>
													<Button
														id='hometeamplayer-update'
														variant='contained'
														onClick={(e) =>
															handleUpdatePlayer('hometeamplayer-update', player, e)}
													>
														Update
													</Button>
												</FormControl>
											</Card>
										</Grid>
									) : (
										<div />
									)
							)}
							<Grid item xs={12}>
								<Card>
									<CardActionArea>
										<CardHeader
											sx={{
												height     : 50,
												textAlign  : 'center',
												background : 'black',
												color      : 'white'
											}}
											title='Subtitutes'
											titleTypographyProps={{ variant: 'h6' }}
										/>
									</CardActionArea>
								</Card>
							</Grid>
							{hometeamPlayers.map(
								(player, id) =>
									player.match_playing_status === 'substitute' ? (
										<Grid item key={'hometeam-grid-' + player.player_id} xs={4}>
											<Card
												sx={{ maxWidth: 345 }}
												key={'hometeam-substitute-grid-' + player.player_id}
											>
												<CardHeader
													avatar={<Avatar src={player.photo_url} />}
													title={playerTitle(player)}
												/>
												<FormControl component='fieldset'>
													<FormLabel id='player-match-status-buttons-group-label'>
														Match Player Status
													</FormLabel>
													<RadioGroup
														jey={player.player_id + 'radio-group-reserve'}
														aria-labelledby='player-match-status-buttons-group-label'
														name='radio-buttons-group'
														value={player.match_playing_status}
														onChange={(e) => {
															handleTeamPlayerSwitchChange(e, player, 'HOME');
														}}
														row
													>
														<FormControlLabel
															key={
																'hometeam-reserve-radio-firsteleven-' + player.player_id
															}
															value='first_eleven'
															control={<Radio />}
															label='First Eleven'
														/>
														<FormControlLabel
															key={'hometeam-reserve-radio-subtitute-' + player.player_id}
															value='substitute'
															control={<Radio />}
															label='Substitute'
														/>
														<FormControlLabel
															key={'hometeam-reserve-radio-reserve-' + player.player_id}
															value='reserve'
															control={<Radio />}
															label='Reserve'
														/>
													</RadioGroup>

													<TextField
														id='hometeamplayer-match_start_time'
														label='Time Start'
														variant='standard'
														value={player.match_start_time}
														onChange={(e) => handleValueChange(player, e)}
													/>
													<TextField
														id='hometeamplayer-match_end_time'
														label='Time End'
														variant='standard'
														value={player.match_end_time}
														onChange={(e) => handleValueChange(player, e)}
													/>
													<TextField
														id='hometeamplayer-match_goals'
														label='Goal(s)'
														variant='standard'
														value={player.match_goals}
														onChange={(e) => handleValueChange(player, e)}
													/>
													<TextField
														id='hometeamplayer-match-goals-time'
														label='Goal Time'
														variant='standard'
														value={player.match_goals_time}
														type='string'
														onChange={(e) => handleValueChange(player, e)}
													/>
													<TextField
														id='hometeamplayer-match_assists'
														label='Assist(s)'
														variant='standard'
														value={player.match_assists}
														onChange={(e) => handleValueChange(player, e)}
													/>
													<TextField
														id='hometeamplayer-match_yellow_cards'
														label='Yellow Card'
														variant='standard'
														value={player.match_yellow_cards}
														onChange={(e) => handleValueChange(player, e)}
													/>
													<TextField
														id='hometeamplayer-match_red_cards'
														label='Red Card'
														variant='standard'
														value={player.match_red_cards}
														onChange={(e) => handleValueChange(player, e)}
													/>
													<TextField
														id='hometeamplayer-match_penalty_misses'
														label='Penalty Missed'
														variant='standard'
														value={player.match_penalty_misses}
														onChange={(e) => handleValueChange(player, e)}
													/>
													<TextField
														id='hometeamplayer-match_penalty_saves'
														label='Penalty Saved'
														variant='standard'
														value={player.match_penalty_saves}
														onChange={(e) => handleValueChange(player, e)}
													/>
													<TextField
														id='hometeamplayer-match_own_goals'
														label='Own Goal'
														variant='standard'
														value={player.match_own_goals}
														onChange={(e) => handleValueChange(player, e)}
													/>
													<Button
														id='hometeamplayer-update'
														variant='contained'
														onClick={(e) =>
															handleUpdatePlayer('hometeamplayer-update', player, e)}
													>
														Update
													</Button>
												</FormControl>
											</Card>
										</Grid>
									) : (
										<div />
									)
							)}
							<Grid item xs={12}>
								<Card>
									<CardActionArea>
										<CardHeader
											sx={{
												height     : 50,
												textAlign  : 'center',
												background : 'black',
												color      : 'white'
											}}
											title='Reserves'
											titleTypographyProps={{ variant: 'h6' }}
										/>
									</CardActionArea>
								</Card>
							</Grid>
							{hometeamPlayers.map(
								(player, id) =>
									player.match_playing_status === 'reserve' ? (
										<Grid item key={'hometeam-grid-' + player.player_id} xs={4}>
											<Card
												sx={{ maxWidth: 345 }}
												key={'hometeam-reserve-grid-' + player.player_id}
											>
												<CardHeader
													avatar={<Avatar src={player.photo_url} />}
													title={playerTitle(player)}
												/>
												<FormControl component='fieldset'>
													<FormLabel id='player-match-status-buttons-group-label'>
														Match Player Status
													</FormLabel>
													<RadioGroup
														jey={player.player_id + 'radio-group-reserve'}
														aria-labelledby='player-match-status-buttons-group-label'
														defaultValue='reserve'
														name='radio-buttons-group'
														value={matchPlayerStatus}
														onChange={(e) => {
															handleTeamPlayerSwitchChange(e, player, 'HOME');
														}}
														row
													>
														<FormControlLabel
															key={
																'hometeam-reserve-radio-firsteleven-' + player.player_id
															}
															value='first_eleven'
															control={<Radio />}
															label='First Eleven'
														/>
														<FormControlLabel
															key={'hometeam-reserve-radio-subtitute-' + player.player_id}
															value='substitute'
															control={<Radio />}
															label='Substitute'
														/>
														<FormControlLabel
															key={'hometeam-reserve-radio-reserve-' + player.player_id}
															value='reserve'
															control={<Radio />}
															label='Reserve'
														/>
													</RadioGroup>

													<TextField
														id='hometeamplayer-match_start_time'
														label='Time Start'
														variant='standard'
														value={player.match_start_time}
														onChange={(e) => handleValueChange(player, e)}
													/>
													<TextField
														id='hometeamplayer-match_end_time'
														label='Time End'
														variant='standard'
														value={player.match_end_time}
														onChange={(e) => handleValueChange(player, e)}
													/>
													<TextField
														id='hometeamplayer-match_goals'
														label='Goal(s)'
														variant='standard'
														value={player.match_goals}
														onChange={(e) => handleValueChange(player, e)}
													/>
													<TextField
														id='hometeamplayer-match-goals-time'
														label='Goal Time'
														variant='standard'
														value={player.match_goals_time}
														type='string'
														onChange={(e) => handleValueChange(player, e)}
													/>
													<TextField
														id='hometeamplayer-match_assists'
														label='Assist(s)'
														variant='standard'
														value={player.match_assists}
														onChange={(e) => handleValueChange(player, e)}
													/>
													<TextField
														id='hometeamplayer-match_yellow_cards'
														label='Yellow Card'
														variant='standard'
														value={player.match_yellow_cards}
														onChange={(e) => handleValueChange(player, e)}
													/>
													<TextField
														id='hometeamplayer-match_red_cards'
														label='Red Card'
														variant='standard'
														value={player.match_red_cards}
														onChange={(e) => handleValueChange(player, e)}
													/>
													<TextField
														id='hometeamplayer-match_penalty_misses'
														label='Penalty Missed'
														variant='standard'
														value={player.match_penalty_misses}
														onChange={(e) => handleValueChange(player, e)}
													/>
													<TextField
														id='hometeamplayer-match_penalty_saves'
														label='Penalty Saved'
														variant='standard'
														value={player.match_penalty_saves}
														onChange={(e) => handleValueChange(player, e)}
													/>
													<TextField
														id='hometeamplayer-match_own_goals'
														label='Own Goal'
														variant='standard'
														value={player.match_own_goals}
														onChange={(e) => handleValueChange(player, e)}
													/>
													<Button
														id='hometeamplayer-update'
														variant='contained'
														onClick={(e) =>
															handleUpdatePlayer('hometeamplayer-update', player, e)}
													>
														Update
													</Button>
												</FormControl>
											</Card>
										</Grid>
									) : (
										<div />
									)
							)}
						</Grid>
					</TabPanel>
					<TabPanel value={value} index={1}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Card>
									<CardActionArea>
										<CardHeader
											sx={{
												height     : 50,
												textAlign  : 'center',
												background : 'black',
												color      : 'white'
											}}
											title='First Eleven'
											titleTypographyProps={{ variant: 'h6' }}
										/>
									</CardActionArea>
								</Card>
							</Grid>
							{awayteamPlayers.map(
								(player, id) =>
									player.match_playing_status === 'first_eleven' ? (
										<Grid item key={'awayteam-grid-' + id} xs={4}>
											<Card sx={{ maxWidth: 345 }}>
												<CardHeader
													avatar={<Avatar src={player.photo_url} />}
													title={playerTitle(player)}
												/>
												<FormControl component='fieldset'>
													<FormLabel id='player-match-status-buttons-group-label'>
														Match Player Status
													</FormLabel>
													<RadioGroup
														jey={player.player_id + 'radio-group-reserve'}
														aria-labelledby='player-match-status-buttons-group-label'
														defaultValue='reserve'
														name='radio-buttons-group'
														value={player.match_playing_status}
														onChange={(e) => {
															handleTeamPlayerSwitchChange(e, player, 'AWAY');
														}}
														row
													>
														<FormControlLabel
															key={
																'awayteam-reserve-radio-firsteleven-' + player.player_id
															}
															value='first_eleven'
															control={<Radio />}
															label='First Eleven'
														/>
														<FormControlLabel
															key={'awayteam-reserve-radio-subtitute-' + player.player_id}
															value='substitute'
															control={<Radio />}
															label='Substitute'
														/>
														<FormControlLabel
															key={'awayteam-reserve-radio-reserve-' + player.player_id}
															value='reserve'
															control={<Radio />}
															label='Reserve'
														/>
													</RadioGroup>
													<TextField
														id='awayteamplayer-match_start_time'
														label='Time Start'
														variant='standard'
														value={player.match_start_time}
														onChange={(e) => handleValueChange(player, e)}
													/>
													<TextField
														id='awayteamplayer-match_end_time'
														label='Time End'
														variant='standard'
														value={player.match_end_time}
														onChange={(e) => handleValueChange(player, e)}
													/>
													<TextField
														id='awayteamplayer-match_goals'
														label='Goal(s)'
														variant='standard'
														value={player.match_goals}
														onChange={(e) => handleValueChange(player, e)}
													/>
													<TextField
														id='awayteamplayer-match-goals-time'
														label='Goal Time'
														variant='standard'
														value={player.match_goals_time}
														type='string'
														onChange={(e) => handleValueChange(player, e)}
													/>
													<TextField
														id='awayteamplayer-match_assists'
														label='Assist(s)'
														variant='standard'
														value={player.match_assists}
														onChange={(e) => handleValueChange(player, e)}
													/>
													<TextField
														id='awayteamplayer-match_yellow_cards'
														label='Yellow Card'
														variant='standard'
														value={player.match_yellow_cards}
														onChange={(e) => handleValueChange(player, e)}
													/>
													<TextField
														id='awayteamplayer-match_red_cards'
														label='Red Card'
														variant='standard'
														value={player.match_red_cards}
														onChange={(e) => handleValueChange(player, e)}
													/>
													<TextField
														id='awayteamplayer-match_penalty_missed'
														label='Penalty Missed'
														variant='standard'
														value={player.match_penalty_misses}
														onChange={(e) => handleValueChange(player, e)}
													/>
													<TextField
														id='awayteamplayer-match_penalty_saved'
														label='Penalty Saved'
														variant='standard'
														value={player.match_penalty_saves}
														onChange={(e) => handleValueChange(player, e)}
													/>
													<TextField
														id='awayteamplayer-match_own_goals'
														label='Own Goal'
														variant='standard'
														value={player.match_own_goals}
														onChange={(e) => handleValueChange(player, e)}
													/>
													<Button
														id='awayteamplayer-update'
														variant='contained'
														onClick={(e) =>
															handleUpdatePlayer('awayteamplayer-update', player, e)}
													>
														Update
													</Button>
												</FormControl>
											</Card>
										</Grid>
									) : (
										<div />
									)
							)}
							<Grid item xs={12}>
								<Card>
									<CardActionArea>
										<CardHeader
											sx={{
												height     : 50,
												textAlign  : 'center',
												background : 'black',
												color      : 'white'
											}}
											title='Subtitutes'
											titleTypographyProps={{ variant: 'h6' }}
										/>
									</CardActionArea>
								</Card>
							</Grid>
							{awayteamPlayers.map(
								(player, id) =>
									player.match_playing_status === 'substitute' ? (
										<Grid item key={'awayteam-grid-' + player.player_id} xs={4}>
											<Card
												sx={{ maxWidth: 345 }}
												key={'awayteam-substitute-grid-' + player.player_id}
											>
												<CardHeader
													avatar={<Avatar src={player.photo_url} />}
													title={playerTitle(player)}
												/>
												<FormControl component='fieldset'>
													<FormLabel id='player-match-status-buttons-group-label'>
														Match Player Status
													</FormLabel>
													<RadioGroup
														jey={player.player_id + 'radio-group-reserve'}
														aria-labelledby='player-match-status-buttons-group-label'
														name='radio-buttons-group'
														value={player.match_playing_status}
														onChange={(e) => {
															handleTeamPlayerSwitchChange(e, player, 'AWAY');
														}}
														row
													>
														<FormControlLabel
															key={
																'awayteam-reserve-radio-firsteleven-' + player.player_id
															}
															value='first_eleven'
															control={<Radio />}
															label='First Eleven'
														/>
														<FormControlLabel
															key={'awayteam-reserve-radio-subtitute-' + player.player_id}
															value='substitute'
															control={<Radio />}
															label='Substitute'
														/>
														<FormControlLabel
															key={'awayteam-reserve-radio-reserve-' + player.player_id}
															value='reserve'
															control={<Radio />}
															label='Reserve'
														/>
													</RadioGroup>

													<TextField
														id='awayteamplayer-match_start_time'
														label='Time Start'
														variant='standard'
														value={player.match_start_time}
														onChange={(e) => handleValueChange(player, e)}
													/>
													<TextField
														id='awayteamplayer-match_end_time'
														label='Time End'
														variant='standard'
														value={player.match_end_time}
														onChange={(e) => handleValueChange(player, e)}
													/>
													<TextField
														id='awayteamplayer-match_goals'
														label='Goal(s)'
														variant='standard'
														value={player.match_goals}
														onChange={(e) => handleValueChange(player, e)}
													/>
													<TextField
														id='awayteamplayer-match-goals-time'
														label='Goal Time'
														variant='standard'
														value={player.match_goals_time}
														type='string'
														onChange={(e) => handleValueChange(player, e)}
													/>
													<TextField
														id='awayteamplayer-match_assists'
														label='Assist(s)'
														variant='standard'
														value={player.match_assists}
														onChange={(e) => handleValueChange(player, e)}
													/>
													<TextField
														id='awayteamplayer-match_yellow_cards'
														label='Yellow Card'
														variant='standard'
														value={player.match_yellow_cards}
														onChange={(e) => handleValueChange(player, e)}
													/>
													<TextField
														id='awayteamplayer-match_red_cards'
														label='Red Card'
														variant='standard'
														value={player.match_red_cards}
														onChange={(e) => handleValueChange(player, e)}
													/>
													<TextField
														id='awayteamplayer-match_penalty_misses'
														label='Penalty Missed'
														variant='standard'
														value={player.match_penalty_misses}
														onChange={(e) => handleValueChange(player, e)}
													/>
													<TextField
														id='awayteamplayer-match_penalty_saves'
														label='Penalty Saved'
														variant='standard'
														value={player.match_penalty_saves}
														onChange={(e) => handleValueChange(player, e)}
													/>
													<TextField
														id='awayteamplayer-match_own_goals'
														label='Own Goal'
														variant='standard'
														value={player.match_own_goals}
														onChange={(e) => handleValueChange(player, e)}
													/>
													<Button
														id='awayteamplayer-update'
														variant='contained'
														onClick={(e) =>
															handleUpdatePlayer('awayteamplayer-update', player, e)}
													>
														Update
													</Button>
												</FormControl>
											</Card>
										</Grid>
									) : (
										<div />
									)
							)}
							<Grid item xs={12}>
								<Card>
									<CardActionArea>
										<CardHeader
											sx={{
												height     : 50,
												textAlign  : 'center',
												background : 'black',
												color      : 'white'
											}}
											title='Reserves'
											titleTypographyProps={{ variant: 'h6' }}
										/>
									</CardActionArea>
								</Card>
							</Grid>
							{awayteamPlayers.map(
								(player, id) =>
									player.match_playing_status === 'reserve' ? (
										<Grid item key={'awayteam-grid-' + id} xs={4}>
											<Card sx={{ maxWidth: 345 }}>
												<CardHeader
													avatar={<Avatar src={player.photo_url} />}
													title={playerTitle(player)}
												/>
												<FormControl component='fieldset'>
													<FormLabel id='player-match-status-buttons-group-label'>
														Match Player Status
													</FormLabel>
													<RadioGroup
														jey={player.player_id + 'radio-group-reserve'}
														aria-labelledby='player-match-status-buttons-group-label'
														defaultValue='reserve'
														name='radio-buttons-group'
														value={player.match_playing_status}
														onChange={(e) => {
															handleTeamPlayerSwitchChange(e, player, 'AWAY');
														}}
														row
													>
														<FormControlLabel
															key={
																'awayteam-reserve-radio-firsteleven-' + player.player_id
															}
															value='first_eleven'
															control={<Radio />}
															label='First Eleven'
														/>
														<FormControlLabel
															key={'awayteam-reserve-radio-subtitute-' + player.player_id}
															value='substitute'
															control={<Radio />}
															label='Substitute'
														/>
														<FormControlLabel
															key={'awayteam-reserve-radio-reserve-' + player.player_id}
															value='reserve'
															control={<Radio />}
															label='Reserve'
														/>
													</RadioGroup>
													<TextField
														id='awayteamplayer-match_start_time'
														label='Time Start'
														variant='standard'
														value={player.match_start_time}
														onChange={(e) => handleValueChange(player, e)}
													/>
													<TextField
														id='awayteamplayer-match_end_time'
														label='Time End'
														variant='standard'
														value={player.match_end_time}
														onChange={(e) => handleValueChange(player, e)}
													/>
													<TextField
														id='awayteamplayer-match_goals'
														label='Goal(s)'
														variant='standard'
														value={player.match_goals}
														onChange={(e) => handleValueChange(player, e)}
													/>
													<TextField
														id='awayteamplayer-match-goals-time'
														label='Goal Time'
														variant='standard'
														value={player.match_goals_time}
														type='string'
														onChange={(e) => handleValueChange(player, e)}
													/>
													<TextField
														id='awayteamplayer-match_assists'
														label='Assist(s)'
														variant='standard'
														value={player.match_assists}
														onChange={(e) => handleValueChange(player, e)}
													/>
													<TextField
														id='awayteamplayer-match_yellow_cards'
														label='Yellow Card'
														variant='standard'
														value={player.match_yellow_cards}
														onChange={(e) => handleValueChange(player, e)}
													/>
													<TextField
														id='awayteamplayer-match_red_cards'
														label='Red Card'
														variant='standard'
														value={player.match_red_cards}
														onChange={(e) => handleValueChange(player, e)}
													/>
													<TextField
														id='awayteamplayer-match_penalty_misses'
														label='Penalty Missed'
														variant='standard'
														value={player.match_penalty_misses}
														onChange={(e) => handleValueChange(player, e)}
													/>
													<TextField
														id='awayteamplayer-match_penalty_saves'
														label='Penalty Saved'
														variant='standard'
														value={player.match_penalty_saves}
														onChange={(e) => handleValueChange(player, e)}
													/>
													<TextField
														id='awayteamplayer-match_own_goals'
														label='Own Goal'
														variant='standard'
														value={player.match_own_goals}
														onChange={(e) => handleValueChange(player, e)}
													/>
													<Button
														id='awayteamplayer-update'
														variant='contained'
														onClick={(e) =>
															handleUpdatePlayer('awayteamplayer-update', player, e)}
													>
														Update
													</Button>
												</FormControl>
											</Card>
										</Grid>
									) : (
										<div />
									)
							)}
						</Grid>
					</TabPanel>
				</Box>
			</Grid>
		</Grid>
	);
};

export default MatchAdmin;

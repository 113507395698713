import React, { useCallback, useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';
import { useSession } from '../App';
import { Typography, Card, TextField, CardContent, CardHeader, CardActions } from '@mui/material';

import Grid from '@mui/material/Grid';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import SaveIcon from '@mui/icons-material/Save';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';

import firebase from '../firebase';

const PREFIX = 'AdminDashboard';

const classes = {
    root: `${PREFIX}-root`,
    paper: `${PREFIX}-paper`,
    button: `${PREFIX}-button`,
    formControl: `${PREFIX}-formControl`,
    grouplabel: `${PREFIX}-grouplabel`,
    large: `${PREFIX}-large`
};

const StyledBox = styled(Box)((
    {
        theme
    }
) => ({
    [`& .${classes.root}`]: {
		flexgrow : 1
	},

    [`& .${classes.paper}`]: {
		width    : 130,
		height   : 230,
		overflow : 'auto'
	},

    [`& .${classes.button}`]: {
		margin : theme.spacing(1)
	},

    [`& .${classes.formControl}`]: {
		margin   : theme.spacing(3),
		minWidth : 150,
		maxWidth : 250
	},

    [`& .${classes.grouplabel}`]: {
		fontsize : 10
	},

    [`& .${classes.large}`]: {
		width  : theme.spacing(7),
		height : theme.spacing(7)
	}
}));

export default function AdminDashboard() {

	const user = useSession();
	const [ authorized, setAuthorized ] = useState(false);

	const [ teamPlayers, setTeamPlayers ] = useState([]);
	const [ team, setTeam ] = useState('');
	const [ matchSelection, setMatchSelection ] = useState('');
	const [ matchStatus, setMatchStatus ] = useState('');
	const [ matchPredictionStatus, setMatchPredictionStatus ] = useState('');
	const [ playerRatingStatus, setPlayerRatingsStatus ] = useState('');

	const [ matches, setMatches ] = React.useState([]);
	const [ minutesPlayed, setMinutesPlayed ] = useState([]);
	const [ goals, setGoals ] = useState([]);
	const [ assists, setAssists ] = useState([]);
	const [ yellowCards, setYellowCards ] = useState([]);
	const [ redCards, setRedCards ] = useState([]);
	const [ selectedMatch, setSelectedMatch ] = useState('');
	const [ homeScore, setHomeScore ] = useState(0);
	const [ awayScore, setAwayScore ] = useState(0);

	const handleTeamSelection = (e) => {
		const db = firebase.firestore();

		/*
        var playerGames = db.collectionGroup('GIFC_PLAYERS').where('player_id', '==' , '1m9He0HcOBR5cexBGxct');
        playerGames.get().then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
                console.log(doc.id, ' => ', doc.data());
            });
        });
        */

		console.log('Team Selected: ' + e.target.value);
		setTeam(e.target.value);
		var selectedTeam = e.target.value.toUpperCase();
		console.log('Getting the matches: ' + selectedTeam);
		const data = db.collection('2022Matches').orderBy('match_date', 'asc').get().then(function(querySnapshot) {
			if (querySnapshot) {
				//setMatches(querySnapshot.docs.map((prediction) => prediction.data()));
				let teamArray = [];
				if (selectedTeam === 'GIFC') {
					querySnapshot.forEach(function(doc) {
						var match = doc.data();
						console.log(match.match_id);
						if (doc.id.indexOf(selectedTeam) !== -1 && match.match_status === 'matchday') {
							console.log('Adding to Geylang: ' + doc.data().match_id);
							teamArray.push(match);
						}
					});
					setMatches(teamArray);
				} else if (selectedTeam === 'TRFC') {
					querySnapshot.forEach(function(doc) {
						if (doc.id.indexOf(selectedTeam) !== -1) {
							console.log('Adding to Tampines : ' + doc.data().match_id + ' ==> ' + doc.id);
							teamArray.push(doc.data());
						}
					});
					setMatches(teamArray);
				} else if (selectedTeam === 'ANFC') {
					querySnapshot.forEach(function(doc) {
						if (doc.id.indexOf(selectedTeam) !== -1) {
							console.log('Adding to Albirex : ' + doc.data().match_id + ' ==> ' + doc.id);
							teamArray.push(doc.data());
						}
					});
					setMatches(teamArray);
				} else if (selectedTeam === 'BKFC') {
					querySnapshot.forEach(function(doc) {
						if (doc.id.indexOf(selectedTeam) !== -1) {
							console.log('Adding to Balestier : ' + doc.data().match_id + ' ==> ' + doc.id);
							teamArray.push(doc.data());
						}
					});
					setMatches(teamArray);
				} else if (selectedTeam === 'HUFC') {
					querySnapshot.forEach(function(doc) {
						if (doc.id.indexOf(selectedTeam) !== -1) {
							console.log('Adding to Hougang : ' + doc.data().match_id + ' ==> ' + doc.id);
							teamArray.push(doc.data());
						}
					});
					setMatches(teamArray);
				} else if (selectedTeam === 'LCSF') {
					querySnapshot.forEach(function(doc) {
						if (doc.id.indexOf(selectedTeam) !== -1) {
							console.log('Adding to LCS : ' + doc.data().match_id + ' ==> ' + doc.id);
							teamArray.push(doc.data());
						}
					});
					setMatches(teamArray);
				} else if (selectedTeam === 'TPFC') {
					querySnapshot.forEach(function(doc) {
						if (doc.id.indexOf(selectedTeam) !== -1) {
							console.log('Adding to Tanjong Pagar : ' + doc.data().match_id + ' ==> ' + doc.id);
							teamArray.push(doc.data());
						}
					});
					setMatches(teamArray);
				} else if (selectedTeam === 'YLFC') {
					querySnapshot.forEach(function(doc) {
						if (doc.id.indexOf(selectedTeam) !== -1) {
							console.log('Adding to Young Lions : ' + doc.data().match_id + ' ==> ' + doc.id);
							teamArray.push(doc.data());
						}
					});
					setMatches(teamArray);
				} else if (selectedTeam === 'SGFC') {
					querySnapshot.forEach(function(doc) {
						if (doc.id.indexOf(selectedTeam) !== -1) {
							console.log('Adding to SG National Team : ' + doc.data().match_id + ' ==> ' + doc.id);
							teamArray.push(doc.data());
						}
					});
					setMatches(teamArray);
				}
			}
		});
	};

	const handleMatchSelection = (e) => {
		setMatchSelection(e.target.value);

		const foundMatch = matches.find((match) => {
			if (match.match_id === e.target.value) return match;
			return null;
		});
		setSelectedMatch(foundMatch);
		setMatchStatus(foundMatch.match_status);
		setMatchPredictionStatus(foundMatch.match_prediction_status);
		setPlayerRatingsStatus(foundMatch.players_rating_status);
	};

	const handleMatchStatusChange = (event) => {
		setMatchStatus(event.target.value);
	};

	const handleMatchPredictionStatusChange = (event) => {
		setMatchPredictionStatus(event.target.value);
	};

	const handlePlayerRatingsStatusChange = (event) => {
		setPlayerRatingsStatus(event.target.value);
	};

	const handleSaveButton = (event) => {
		console.log(
			'Changing the status for : ' +
				matchSelection +
				' match status: ' +
				matchStatus +
				' match_prediction status: ' +
				matchPredictionStatus +
				' player rating status: ' +
				playerRatingStatus
		);
		const db = firebase.firestore();

		db
			.collection('2022Matches')
			.doc(matchSelection)
			.set(
				{
					match_status            : matchStatus,
					match_prediction_status : matchPredictionStatus,
					players_rating_status   : playerRatingStatus
				},
				{ merge: true }
			)
			.then(function() {
				console.log(matchSelection + ' successfully written!');
			})
			.catch(function(error) {
				console.error('Error writing document: ', error);
			});
	};

	const handleGetPlayers = (event) => {
		console.log('Getting players for ' + team);
		const db = firebase.firestore();
		var playerArray = [];

		const data = db
			.collection('2022Teams/' + team.toUpperCase() + '/2022Players')
			.orderBy('firstname', 'asc')
			.get()
			.then(function(querySnapshot) {
				querySnapshot.forEach(function(doc) {
					let { id } = doc;
					const data = doc.data();
					let firstname = data.firstname;
					let lastname = data.lastname;
					let squadnumber = data.squad_number;
					let photourl = data.photo_url;
					let pos = data.position;
					playerArray.push({
						id,
						firstname,
						lastname,
						position       : pos,
						squad_number   : squadnumber,
						photo_url      : photourl,
						goals_scored   : 0,
						assist         : 0,
						minutes_played : 90,
						red_card       : 0,
						yellow_card    : 0,
						rating         : 0
					});
				});
				setTeamPlayers(playerArray);
			})
			.catch((err) => console.log(err));
	};

	//Handles both calculate predictions score and rank the users accordingly
	const handleCalculationAndRankUpdate = (event) => {
		handleCalculatePredictions();
		setTimeout(updateUsersRanking, 1000); //allow asynchronous event of handleCalculatePrecitions() to end first
	};

	function handleCalculatePredictions() {
		console.log('Setting match score, match status and calculating prediction points : ' + matchSelection);
		const db = firebase.firestore();
		const matchId = selectedMatch.match_id;

		//console.log(matchId, matchStatus, hometeamScore, awayteamScore, playerRatingStatus);
		console.log('Updating the score and status to complete: ' + matchId);

		//Setting the final score and the match status to completed
		db
			.collection('2022Matches')
			.doc(matchId.trim())
			.set(
				{
					hometeam_score : homeScore,
					awayteam_score : awayScore,
					match_status   : 'completed'
				},
				{ merge: true }
			)
			.then(function() {
				console.log('Document successfully written: ' + matchId);
				setMatchStatus('completed');
			})
			.catch(function(error) {
				console.error('Error writing document: ', error);
			});

		//Start to compute the predictions for the game now.
		console.log('Starting to compute predictions: ' + matchId);

		db
			.collection('2022Matches/' + matchId.trim() + '/2022_Match_Predictions')
			.get()
			.then(
				//look at the predicted score vs real score
				function(querySnapshot) {
					querySnapshot.forEach(function(doc) {
						let userScore = 0;

						let homeTeamResult = '';
						let awayTeamResult = '';

						const prediction = doc.data();
						const userId = prediction.uid;
						const predictedAwayTeamScore = parseInt(prediction.predicted_away_score, 10);
						const predictedHomeTeamScore = parseInt(prediction.predicted_home_score, 10);
						const predictedHomeTeamResult = prediction.predicted_home_result;
						const predictedAwayTeamResult = prediction.predicted_away_result;

						if (homeScore > awayScore) {
							homeTeamResult = 'Win';
							awayTeamResult = 'Lose';
						} else if (homeScore < awayScore) {
							homeTeamResult = 'Lose';
							awayTeamResult = 'Win';
						} else if (homeScore === awayScore) {
							homeTeamResult = 'Draw';
							awayTeamResult = 'Draw';
						}

						console.log('user id: ' + userId);
						console.log(
							'PAS: ' +
								predictedAwayTeamScore +
								' PHS: ' +
								predictedHomeTeamScore +
								' PAR: ' +
								predictedAwayTeamResult +
								' PHR: ' +
								predictedHomeTeamResult
						);
						console.log(
							'ATS: ' +
								awayScore +
								' HTS: ' +
								homeScore +
								' AR: ' +
								awayTeamResult +
								'  HR: ' +
								homeTeamResult
						);

						//homeScore === predictedHomeTeamScore ? console.log('true') : console.log('false');
						//awayScore === predictedAwayTeamScore ? console.log('true') : console.log('false');
						//homeTeamResult === predictedHomeTeamResult ? console.log('true') : console.log('false');
						//awayTeamResult === predictedHomeTeamResult ? console.log('true') : console.log('false');

						if (homeScore == predictedHomeTeamScore && awayScore == predictedAwayTeamScore) {
							//accurate both scores are right
							userScore = 5;
							console.log('User earned 5 points');
						} else if (homeScore == predictedHomeTeamScore || awayScore == predictedAwayTeamScore) {
							userScore = 3;
							console.log('User earned 3 points - home/away score accurate');
						} else if (
							homeTeamResult === predictedHomeTeamResult ||
							awayTeamResult === predictedAwayTeamResult
						) {
							//accurate result win/lose/draw but not score
							userScore = 1;
							console.log('User earned 1 points');
						} else {
							userScore = 0;
						}

						if (userScore > 0 && matchId.indexOf('SPL') !== -1) {
							//only update the score when user earns the prediction point

							console.log('Getting user profile: ' + userId);
							db
								.collection('Users')
								.doc(userId)
								.get()
								.then((doc) => {
									if (!doc.exists) {
										console.log('No such document!');
									} else {
										console.log('Document data:', doc.data());
										const data = doc.data();
										if (data) {
											let currentPredictionScore = data.prediction_score_2022;
											console.log('User: ' + userId + ' predictionscore: ' + userScore);
											console.log('User current Score: ' + currentPredictionScore);
											if (data.prediction_score_2022 === undefined)
												var newPredictionScore = userScore;
											else var newPredictionScore = currentPredictionScore + userScore;
											console.log('User new score: ' + newPredictionScore);
											//update the user
											db
												.collection('Users')
												.doc(userId)
												.set({ prediction_score_2022: newPredictionScore }, { merge: true })
												.then(
													console.log(
														'The user total score record updated + ' + newPredictionScore
													)
												)
												.catch((err) => console.log(err));
										}
									}
									return doc.data();
								})
								.catch((err) => {
									console.log('Error getting document', err);
									return false;
								});
						} else if (matchId.indexOf('WCQ') !== -1) {
							console.log('Getting user profile: ' + userId + ' for world cup calculation');
							db
								.collection('Users')
								.doc(userId)
								.get()
								.then((doc) => {
									if (!doc.exists) {
										console.log('No such document!');
									} else {
										//console.log('Document data:', doc.data());
										const data = doc.data();
										if (data) {
											let currentPredictionScore = data.prediction_score_national_team_2022;
											console.log('User: ' + userId + ' predictionscore: ' + userScore);
											//console.log('User current Score: ' + currentPredictionScore);
											var newPredictionScore = 0;
											if (data.prediction_score_national_team_2022 === undefined)
												newPredictionScore = userScore;
											else newPredictionScore = currentPredictionScore + userScore;

											//console.log('User new score: ' + newPredictionScore);
											//update the user
											db
												.collection('Users')
												.doc(userId)
												.set({ prediction_score_nt_2022: newPredictionScore }, { merge: true })
												.then(
													console.log(
														'The user total score record updated + ' + newPredictionScore
													)
												)
												.catch((err) => console.log(err));
										}
									}
									return doc.data();
								})
								.catch((err) => {
									console.log('Error getting document', err);
									return false;
								});
						}

						//log the user prediction history
						//const prediction_result_data = { match_id: matchId, points_earned: userScore };
						const prediction_points_data = { prediction_points: userScore };
						db
							.collection('2022Matches/' + matchId.trim() + '/2022_Match_Predictions')
							.doc(userId.trim() + '_' + matchId.trim())
							.set(prediction_points_data, { merge: true })
							.then(console.log('The match prediction record updated ' + userScore))
							.catch((err) => console.log(err));
					});
				}
			)
			.catch((error) => console.log(error));
	}

	function updateUsersRanking() {
		const db = firebase.firestore();

		console.log('Update Users Ranking');
		db
			.collection('Users')
			.orderBy('prediction_score_2022', 'desc')
			.get()
			.then(function(querySnapshot) {
				let rankCounter = 0;
				querySnapshot.forEach(function(doc) {
					rankCounter++;
					// doc.data() is never undefined for query doc snapshots
					console.log(doc.id, ' => ', doc.data());
					db.collection('Users').doc(doc.id).set(
						{
							rank : rankCounter
						},
						{ merge: true }
					);
				});
			})
			.catch(function(error) {
				console.log('Error getting documents: ', error);
			});
	}

	const handleGamersPredictionAndRanking = (event) => {
		const 	round = 2;
		const 	userPredictions = new Map();
		var 	gamerId = '';

		console.log('Calculating Gamers Predictions and Ranking for Round: ' + round);
		

		const db = firebase.firestore();
		var predictions = db.collectionGroup('2022_Match_Predictions').orderBy('uid');
		predictions.get().then((querySnapshot) => {
			querySnapshot.forEach((doc) => {
				console.log(doc.id, ' => ', doc.data());

				var prediction = doc.data();
				if (gamerId === prediction.uid) {
					//same gamer, add the score
				}
				//if (userPredictions.get(prediction.uid) === undefined) {
				//	userPredictions.set(doc.uid, )
				//}
			});
		});


	};

	const handleGoalsChange = (event, index) => {
		console.log('Goals - index: ' + index + ' value: ' + event.target.value);
		teamPlayers[index].goals_scored = event.target.value;
		//console.log(teamPlayers[index])
	};

	const handleAssistsChange = (event, index) => {
		teamPlayers[index].assists = event.target.value;
	};

	const handleYellowCardsChange = (event, index) => {
		teamPlayers[index].yellow_card = event.target.value;
	};

	const handleRedCardsChange = (event, index) => {
		teamPlayers[index].red_card = event.target.value;
	};

	const handleMinutesChange = (event, index) => {
		teamPlayers[index].minutes_played = event.target.value;
	};

	function handleUpdatePlayer(player, index) {
		console.log(player);
		const db = firebase.firestore();
		db
			.collection('2022Matches/' + matchSelection + '/' + team.toUpperCase() + '_PLAYERS')
			.doc(matchSelection + '_' + player.id)
			.set({ ...player, first_eleven: true, player_id: player.id }, { merge: true })
			.then(function() {
				console.log('Document successfully written!' + player.firstname);
			})
			.catch(function(error) {
				console.error('Error writing document: ', error);
			});
	}

	function handleHomeScoreChange(event) {
		setHomeScore(event.target.value);
	}

	function handleAwayScoreChange(event) {
		setAwayScore(event.target.value);
	}

	function handleUpdateOverallPlayersStats(event) {
		//To update the player overall ratings
		const db = firebase.firestore();
		console.log('Updating Players Team: ' + team);
		db
			.collection('2022Teams/' + team.toUpperCase() + '/2022Players')
			.get()
			.then(function(querySnapshot) {
				querySnapshot.forEach(function(doc) {
					// doc.data() is never undefined for query doc snapshots
					const playerId = doc.id;
					const playerData = doc.data();
					console.log('Getting ' + doc.data().firstname + ' ' + playerId);
					var player = db.collectionGroup(team.toUpperCase() + '_PLAYERS').where('player_id', '==', playerId);

					player.get().then(function(querySnapshot) {
						console.log('');
						console.log('Updating Player: ' + playerData.firstname);
						console.log('No of records: ' + querySnapshot.size);
						var totalAssists = 0;
						var totalGoals = 0;
						var totalRatings = 0;
						var totalAppearance = 0;
						var totalCleansheets = 0;
						var totalYellowCards = 0;
						var totalRedCards = 0;
						var totalMinutesPlayed = 0;
						var totalStart = 0;
						var totalSub = 0;

						querySnapshot.forEach(function(doc) {
							var tempAssists = doc.data().assists === undefined ? 0 : doc.data().assists;
							var tempAppearance = doc.data().minutes_played > 0 ? 1 : 0;
							var tempStart = doc.data().first_eleven === true ? 1 : 0;
							totalCleansheets = totalCleansheets + doc.data().clean_sheet;
							totalAssists = totalAssists + tempAssists;
							totalGoals = totalGoals + doc.data().goals_scored;
							totalRatings = totalRatings + doc.data().ratings;
							totalAppearance = totalAppearance + tempAppearance;
							totalYellowCards = totalYellowCards + doc.data().yellow_card;
							totalRedCards = totalRedCards + doc.data().red_card;
							totalMinutesPlayed = totalMinutesPlayed + parseInt(doc.data().minutes_played);
							totalStart = totalStart + tempStart;
						});

						console.log('Calculation Result');
						console.log('Clean Sheets: ' + totalCleansheets);
						console.log('Assists: ' + totalAssists);
						console.log('Goals: ' + totalGoals);
						console.log('Ratings: ' + totalRatings / totalAppearance);
						console.log('Appearance: ' + totalAppearance);
						console.log('YC: ' + totalYellowCards);
						console.log('RC: ' + totalRedCards);
						console.log('Min Played: ' + totalMinutesPlayed);
						console.log('Total Start: ' + totalStart);

						db
							.collection('2022Teams/' + team + '/2022Players')
							.doc(playerId)
							.set(
								{
									appearance           : totalAppearance,
									total_assists        : totalAssists,
									total_clean_sheet    : totalCleansheets,
									total_goals          : totalGoals,
									total_ratings        : Math.round(totalRatings / totalAppearance * 1000) / 1000,
									total_red_cards      : totalRedCards,
									total_yellow_cards   : totalYellowCards,
									total_minutes_played : totalMinutesPlayed,
									total_start          : totalStart
								},
								{ merge: true }
							)
							.then(function() {
								console.log(matchSelection + ' successfully written!');
							})
							.catch(function(error) {
								console.error('Error writing document: ', error);
							});
					});
				});
			})
			.catch(function(error) {
				console.log('Error getting documents: ', error);
			});
	}

	useEffect(() => {
		console.log('Checking user: ' + user.uid);
		if (
			user.uid === 'glKicD84lLejtJ5M9qOLaczt5ny1' ||
			user.uid === 'YP1dpgESghPw7WiAoIL1Sd0HARw1' ||
			user.uid === 'MmkWUYFwTwT7DKWfnsgXoKoYgxo2'
		) {
			console.log('Authorized User');
			setAuthorized(true);
		} else {
			console.log('Unauthorized User');
			setAuthorized(false);
		}
	}, []);

	return (
        <StyledBox sx={{ flexGrow: 1 }}>
			<Grid container spacing={2}>
				{authorized ? (
					<div>
						<Grid item xs={12}>
							<Card>
								<Typography variant='h4' align='center'>
									Admin Dashboard 2022
								</Typography>
							</Card>
						</Grid>
						<Grid item xs={12}>
							<Card>
								<CardHeader>
									<Typography variant='h5'>Player Loader</Typography>
								</CardHeader>
								<CardContent>
				
								</CardContent>
							</Card>
						</Grid>
						<Grid item xs={12}>
							<Paper>
								<Grid container>
									<Grid item xs={12}>
										<Typography variant='h6'>Match Details</Typography>
									</Grid>
									<Grid item xs={6}>
										<FormControl className={classes.formControl}>
											<InputLabel id='match-select-label'>Team</InputLabel>
											<Select
												labelId='match-select-label'
												id='team-select'
												value={team}
												onChange={handleTeamSelection}
											>
												<MenuItem value='anfc'>Albirex Nigata</MenuItem>
												<MenuItem value='bkfc'>Balestier Khalsa</MenuItem>
												<MenuItem value='gifc'>Geylang Int'l</MenuItem>
												<MenuItem value='hufc'>Hougang Utd</MenuItem>
												<MenuItem value='lcsf'>Lion City Sailors</MenuItem>
												<MenuItem value='tpfc'>Tanjong Pagar</MenuItem>
												<MenuItem value='trfc'>Tampines Rovers</MenuItem>
												<MenuItem value='ylfc'>Young Lions</MenuItem>
												<MenuItem value='sgfc'>SG National Team</MenuItem>
											</Select>
										</FormControl>
									</Grid>
									<Grid item xs={6}>
										<FormControl className={classes.formControl}>
											<InputLabel id='match-select-label'>Match</InputLabel>
											<Select
												labelId='match-select-label'
												id='match-select'
												value={matchSelection}
												onChange={handleMatchSelection}
											>
												{matches.map((match) => {
													return (
														<MenuItem key={match.match_id} value={match.match_id}>
															{match.match_title}
														</MenuItem>
													);
												})}
											</Select>
										</FormControl>
									</Grid>
									<Grid item xs={12}>
										<Typography variant='h6'>Match Status</Typography>
									</Grid>
									<Grid item xs={6}>
										<FormControl className={classes.formControl}>
											<InputLabel id='match-prediction-status-select-label'>Status</InputLabel>
											<Select
												labelId='match-status-select-label'
												id='match-status-select'
												value={matchStatus}
												onChange={handleMatchStatusChange}
											>
												<MenuItem value='upcoming'>Upcoming</MenuItem>
												<MenuItem value='matchday'>Matchday</MenuItem>
												<MenuItem value='completed'>Completed</MenuItem>
											</Select>
										</FormControl>
									</Grid>

									<Grid item xs={6}>
										<FormControl className={classes.formControl}>
											<InputLabel id='match-prediction-status-select-label'>
												Prediction Status
											</InputLabel>
											<Select
												labelId='match-prediction-status-select-label'
												id='match-prediction-status-select'
												value={matchPredictionStatus}
												onChange={handleMatchPredictionStatusChange}
											>
												<MenuItem value='open'>open</MenuItem>
												<MenuItem value='closed'>closed</MenuItem>
											</Select>
										</FormControl>
									</Grid>
									<Grid item xs={12}>
										<FormControl className={classes.formControl}>
											<InputLabel id='player-rating-status-select-label'>
												Player Rating Status
											</InputLabel>
											<Select
												labelId='player-rating-status-select-label'
												id='player-rating-status-select'
												className={classes.grouplabel}
												value={playerRatingStatus}
												onChange={handlePlayerRatingsStatusChange}
											>
												<MenuItem value='open'>open</MenuItem>
												<MenuItem value='closed'>closed</MenuItem>
											</Select>
										</FormControl>
									</Grid>
									<Grid item xs={12}>
										<Button
											align='right'
											variant='contained'
											color='primary'
											size='small'
											className={classes.button}
											startIcon={<SaveIcon />}
											onClick={handleSaveButton}
										>
											Save
										</Button>
									</Grid>
									<Box component='span' m={1}>
										{' '}
									</Box>
									<Grid item xs={12}>
										<Typography variant='h6'>Admin Operations</Typography>
									</Grid>
									<Box component='span' m={1} />
									<Grid item xs={12}>
										<FormControl className={classes.formControl}>
											<TextField
												id='game_home_score'
												label={selectedMatch.hometeam_name + ' Score'}
												onChange={(e) => {
													handleHomeScoreChange(e);
												}}
												value={homeScore}
											/>
										</FormControl>
										<FormControl className={classes.formControl}>
											<TextField
												id='game_away_score'
												label={selectedMatch.awayteam_name + ' Score'}
												onChange={(e) => {
													handleAwayScoreChange(e);
												}}
												value={awayScore}
											/>
										</FormControl>

										<Button
											variant='contained'
											color='primary'
											size='large'
											className={classes.button}
											//onClick={() => { handleCalculatePredictions(); updateUsersRanking();}}
											onClick={handleCalculationAndRankUpdate}
										>
											Calculate Predictions
										</Button>
									</Grid>
								</Grid>
							</Paper>
						</Grid>
						<Grid item xs={12}>
							<Box component='span' m={1}>
								{' '}
							</Box>
							<Paper>
								<Typography align='center' variant='h6' gutterBottom>
									Predictions Updates
								</Typography>
								<Button
									align='right'
									variant='contained'
									color='primary'
									size='small'
									className={classes.button}
									onClick={handleGamersPredictionAndRanking}
								>
									Calculate Gamers Prediction and Ranking
								</Button>
							</Paper>
						</Grid>

						{teamPlayers.map((player, index) => (
							<Grid item xs={12} key={index}>
								<Card>
									<CardContent>
										<Grid container>
											<Grid item xs={6}>
												<Avatar src={player.photo_url} className={classes.large} />
											</Grid>
											<Grid item xs={6}>
												<Typography variant='caption'>
													{player.firstname} {player.lastname} ({player.squad_number})
												</Typography>
											</Grid>

											<Grid item xs={12}>
												<Card>{minutesPlayed[index]}</Card>
												<FormControl className={classes.formControl}>
													<TextField
														id={player.firstname + 'standard-basic'}
														value={minutesPlayed[index]}
														label='Minutes Played'
														onChange={(e) => {
															handleMinutesChange(e, index);
														}}
													/>
												</FormControl>
											</Grid>
											<Grid item xs={6}>
												<FormControl className={classes.formControl}>
													<TextField
														label='Goals Scored'
														value={goals[index]}
														onChange={(e) => {
															handleGoalsChange(e, index);
														}}
													/>
												</FormControl>
											</Grid>
											<Grid item xs={6}>
												<FormControl className={classes.formControl}>
													<TextField
														label='Assists'
														value={assists[index]}
														onChange={(e) => {
															handleAssistsChange(e, index);
														}}
													/>
												</FormControl>
											</Grid>
											<Grid item xs={6}>
												<FormControl className={classes.formControl}>
													<TextField
														label='Yellow Cards'
														value={yellowCards[index]}
														onChange={(e) => {
															handleYellowCardsChange(e, index);
														}}
													/>
												</FormControl>
											</Grid>
											<Grid item xs={6}>
												<FormControl className={classes.formControl}>
													<TextField
														label='Red Card'
														value={redCards[index]}
														onChange={(e) => {
															handleRedCardsChange(e, index);
														}}
													/>
												</FormControl>
											</Grid>
										</Grid>
									</CardContent>
									<CardActions>
										<ButtonGroup>
											<Button size='small' onClick={() => handleUpdatePlayer(player, index)}>
												Update
											</Button>
										</ButtonGroup>
									</CardActions>
								</Card>
							</Grid>
						))}
					</div>
				) : (
					<Typography>Unauthorized</Typography>
				)}
			</Grid>
		</StyledBox>
    );
}

import React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const PREFIX = 'Merchandise';

const classes = {
    root: `${PREFIX}-root`,
    paper: `${PREFIX}-paper`,
    control: `${PREFIX}-control`,
    card: `${PREFIX}-card`,
    media: `${PREFIX}-media`,
    paperContainer: `${PREFIX}-paperContainer`
};

const StyledGrid = styled(Grid)((
    {
        theme
    }
) => ({
    [`&.${classes.root}`]: {
		flexGrow: 1
	},

    [`& .${classes.paper}`]: {
		height: 140,
		width: 100
	},

    [`& .${classes.control}`]: {
		padding: theme.spacing(2)
	},

    [`& .${classes.card}`]: {
		maxWidth: 345
	},

    [`& .${classes.media}`]: {
		height: 400
	},

    [`& .${classes.paperContainer}`]: {
		height: 300,
		backgroundImage: `url(${'https://objectstorage.us-ashburn-1.oraclecloud.com/n/idotv6r6ikxt/b/voiddeck-footie/o/team.bannergeylang_banner.png'})`
	}
}));

export default function Merchandise() {
	const [ spacing, setSpacing ] = React.useState(2);


	const handleChange = (event) => {
		setSpacing(Number(event.target.value));
	};

	return (
        <StyledGrid container className={classes.root} spacing={3}>
			<Grid item xs={12}>
				<Card>
					<CardActionArea>
						<CardMedia
							className={classes.paperContainer}
							image='https://objectstorage.us-ashburn-1.oraclecloud.com/n/idotv6r6ikxt/b/voiddeck-footie/o/ue.merc.merchadise_banner.jpg'
							title='UE Merchandise'
						/>
						<CardContent>
							<Typography gutterBottom variant='h3' component='h2' align='center'>
								Ultra Eagle Merchandise
							</Typography>
							<Typography gutterBottom variant='h6' component='h6' align='center'>
								Support Your Eagles and Tell It To The World
							</Typography>
						</CardContent>
					</CardActionArea>
				</Card>{' '}
			</Grid>
			<Grid item xs={12}>
				<Grid container justifyContent='center' spacing={spacing}>
					<Grid key={0} item>
						<Card className={classes.card}>
							<CardActionArea>
								<CardMedia
									className={classes.media}
									image='https://objectstorage.us-ashburn-1.oraclecloud.com/n/idotv6r6ikxt/b/voiddeck-footie/o/ue.merc.ue_tshirt.png'
									title='UE T Shirt'
								/>
								<CardContent>
									<Typography gutterBottom variant='h5' component='h2'>
										Ultra Eagles T Shirt
									</Typography>
									<Typography variant='body2' color='textSecondary' component='p'>
										Don the Ultra Eagle T-shirt on matchday and cheers the boys proudly and loudly.
									</Typography>
								</CardContent>
							</CardActionArea>
							<CardActions>
								<Button size='small' color='primary'>
									Like
								</Button>
								<Button size='small' color='primary'>
									Buy Now
								</Button>
								<Button size='small' color='primary'>
									Pre-Order
								</Button>
							</CardActions>
						</Card>
					</Grid>
					<Grid key={1} item>
						<Card className={classes.card}>
							<CardActionArea>
								<CardMedia
									className={classes.media}
									image='https://objectstorage.us-ashburn-1.oraclecloud.com/n/idotv6r6ikxt/b/voiddeck-footie/o/ue.merc.ue_tote_bag.png'
									title='UE Tote Bag'
								/>
								<CardContent>
									<Typography gutterBottom variant='h5' component='h2'>
										Ultra Eagles Tote Bag
									</Typography>
									<Typography variant='body2' color='textSecondary' component='p'>
										Carry the Ultra Eagle Tote Bag and let the world know you are part of an awesome
										group of fans.
									</Typography>
								</CardContent>
							</CardActionArea>
							<CardActions>
								<Button size='small' color='primary'>
									Like
								</Button>
								<Button size='small' color='primary'>
									Buy Now
								</Button>
							</CardActions>
						</Card>
					</Grid>
					<Grid key={2} item>
						<Card className={classes.card}>
							<CardActionArea>
								<CardMedia
									className={classes.media}
									image='https://objectstorage.us-ashburn-1.oraclecloud.com/n/idotv6r6ikxt/b/voiddeck-footie/o/ue.merc.ue_mafla.png'
									title='UE Player Mafla'
								/>
								<CardContent>
									<Typography gutterBottom variant='h5' component='h2'>
										Ultra Eagles Mafla
									</Typography>
									<Typography variant='body2' color='textSecondary' component='p'>
										Have a favorite player, request the mafla of your favourite player and it will
										be yours.
									</Typography>
								</CardContent>
							</CardActionArea>
							<CardActions>
								<Button size='small' color='primary'>
									Like
								</Button>
								<Button size='small' color='primary'>
									Buy Now
								</Button>
							</CardActions>
						</Card>
					</Grid>
					<Grid key={3} item>
						<Card className={classes.card}>
							<CardActionArea>
								<CardMedia
									className={classes.media}
									image='https://objectstorage.us-ashburn-1.oraclecloud.com/n/idotv6r6ikxt/b/voiddeck-footie/o/ue.merc.ue_tshirt.png'
									title='UE T Shirt'
								/>
								<CardContent>
									<Typography gutterBottom variant='h5' component='h2'>
										Ultra Eagles T Shirt
									</Typography>
									<Typography variant='body2' color='textSecondary' component='p'>
										Don the Ultra Eagle T-shirt on matchday and cheers the boys proudly and loudly.
									</Typography>
								</CardContent>
							</CardActionArea>
							<CardActions>
								<Button size='small' color='primary'>
									Like
								</Button>
								<Button size='small' color='primary'>
									Buy Now
								</Button>
							</CardActions>
						</Card>
					</Grid>
				</Grid>
			</Grid>
		</StyledGrid>
    );
}

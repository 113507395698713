import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';

import {submitPrediction} from '../firestore/predictionshandler';

export default function PredictionDialog(props) {
	const [ open, setOpen ] = useState(false);
	const [ hometeamScore, setHometeamScore ] = useState(0);
	const [ awayteamScore, setAwayteamScore ] = useState(0);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
        submitPrediction(props.user.uid, props.match, hometeamScore, awayteamScore);
	};

	return (
		<div>
			{props.newPrediction === 'true' ? (
				<Button variant='outlined' onClick={handleClickOpen}>
					Make Prediction
				</Button>
			) : (
				<Button variant='outlined' onClick={handleClickOpen}>
					Update Prediction
				</Button>
			)}

			<Dialog open={open} onClose={handleClose}>
				<DialogTitle>Match Prediction</DialogTitle>
				<DialogContent>
					<DialogContentText>Enter your prediction for match</DialogContentText>
					<Grid container spacing={1}>
						<Grid item xs={3}>
							<Avatar alt='Home Team' src={props.match.hometeam_logo_url} />
						</Grid>
						<Grid item xs={3}>
							<TextField
                                autoFocus
                                defaultValue={hometeamScore}
								id='hometeam_score'
								label='Score'
								type='number'
								variant='filled'
								value={hometeamScore}
								onChange={(e) => {
									setHometeamScore(parseInt(e.target.value, 10) || 0)}
								}
							/>
						</Grid>
						<Grid item xs={3}>
							<Avatar alt='Away Team' src={props.match.awayteam_logo_url} />
						</Grid>
						<Grid item xs={3}>
							<TextField
								id='awayteam_score'
                                defaultValue={awayteamScore}
								label='Score'
								type='number'
								variant='filled'
								value={awayteamScore}
								onChange={(e) => {
									setAwayteamScore(parseInt(e.target.value, 10) || 0)}
								}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
					<Button onClick={handleClose}>Submit</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

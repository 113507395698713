import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import { useSession } from '../App';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

//Card
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';

//Accordian
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

//TextField
import TextField from '@mui/material/TextField';

//Button
import Button from '@mui/material/Button';

//Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

//Avatar
import Avatar from '@mui/material/Avatar';

//color
import { blue } from '@mui/material/colors';

//Dialog
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

//Firebase
import firebase from '../firebase';
import { CodeOutlined, ContactSupportOutlined, Telegram } from '@mui/icons-material';

const PREFIX = 'FantasyLeagueMiniLeague';

const classes = {
    root: `${PREFIX}-root`,
    media: `${PREFIX}-media`,
    expand: `${PREFIX}-expand`,
    expandOpen: `${PREFIX}-expandOpen`,
    avatar: `${PREFIX}-avatar`,
    actions: `${PREFIX}-actions`,
    form: `${PREFIX}-form`,
    ml_card: `${PREFIX}-ml_card`,
    blue: `${PREFIX}-blue`
};

const StyledGrid = styled(Grid)((
    {
        theme
    }
) => ({
    [`&.${classes.root}`]: {
		flexGrow: 1
	},

    [`& .${classes.media}`]: {
		height: 0,
		paddingTop: '56.25%' // 16:9
	},

    [`& .${classes.expand}`]: {
		transform: 'rotate(0deg)',
		marginLeft: 'auto',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest
		})
	},

    [`& .${classes.expandOpen}`]: {
		transform: 'rotate(180deg)'
	},

    [`& .${classes.avatar}`]: {
		backgroundColor: red[500]
	},

    [`& .${classes.actions}`]: {
		display: 'flex',
		justifyContent: 'space-between'
	},

    [`& .${classes.form}`]: {
		display: 'flex',
		direction: 'row',
		justifyContent: 'left'
	},

    [`& .${classes.ml_card}`]: {
		flexGrow: 1,
		minWidth: 275
	},

    [`& .${classes.blue}`]: {
		color: theme.palette.getContrastText(blue[500]),
		backgroundColor: blue[500]
	}
}));

function SimpleDialog(props) {

	const { onClose, selectedValue, open } = props;

	const handleClose = () => {
		onClose(selectedValue);
	};

	const handleListItemClick = (value) => {
		onClose(value);
	};

	return (
		<Dialog onClose={handleClose} aria-labelledby='simple-dialog-title' open={open}>
			{selectedValue ? (
				<div>
					<DialogTitle id='simple-dialog-title'>Error</DialogTitle>
					<ul>
						<li>No Team Found with the given Code</li>
						<li>Do check with the code value and ensure it is case-sensitive</li>
					</ul>
				</div>
			) : (
				<div>
					<DialogTitle id='simple-dialog-title'>How to Create My Mini League Team ? </DialogTitle>
					<ul>
						<li>
							Go on the <a href='https://discord.gg/BQVT536Wje'>link</a>
						</li>
						<li>Request admin to create a mini league team</li>
						<li>You will be given a mini league team code</li>
						<li>Share with your friends the mini league team code</li>
						<li>Enter the mini league code and click 'Join'</li>
					</ul>
				</div>
			)}
		</Dialog>
	);
}

SimpleDialog.propTypes = {
	onClose: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
	selectedValue: PropTypes.string.isRequired
};

export default function FantasyLeagueMiniLeague(props) {
	const user = useSession();

	const [ expanded, setExpanded ] = React.useState(false);

	const [ miniLeagueTeams, setMiniLeagueTeams ] = useState([]);
	const [ miniLeagueTeamMembers, setMiniLeagueTeamMembers ] = useState([]);
	const [ miniLeagueTeamCode, setMiniLeagueTeamCode ] = useState('');

	const [ open, setOpen ] = useState(false);
	const [ selectedValue, setSelectedValue ] = useState('');
	const [ openCreateMiniLeagueDialog, setOpenCreateMiniLeagueDialog ] = useState(false);

	const [ team, setTeam ] = useState('');

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = (value) => {
		setOpen(false);
		setSelectedValue(value);
	};

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	//Fetch all the matches with status matchday
	useEffect(() => {
		fetchMiniLeagueTeams();
	}, []);

	const fetchMiniLeagueTeams = () => {
		console.log('Fetching All MiniLeague Teams ' + user.uid);
		var miniLeagueArray = [];

		const db = firebase.firestore();
		const minileagues = db
			.collectionGroup('MiniLeague_Members')
			.where('uid', '==', user.uid)
			.get()
			.then(function(querySnapshot) {
				var noOfMiniLeagueTeams = querySnapshot.docs.length;
				querySnapshot.forEach((doc) => {
					// doc.data() is never undefined for query doc snapshots
					//console.log(doc.id, " => ", doc.data());
					var docPath = doc.ref.path.substring(0, doc.ref.path.indexOf('MiniLeague_Members') - 22);
					var miniLeagueId = doc.ref.path.substring(docPath.length + 1, docPath.length + 21);
					var getTeamRef = db.collection('/FantasyLeague/2022/MiniLeague/').doc(miniLeagueId);
					getTeamRef
						.get()
						.then((doc2) => {
							if (doc2.exists) {
								miniLeagueArray.push({
									...doc2.data(),
									teamref_path: '/FantasyLeague/2022/MiniLeague/' + miniLeagueId
								});
								console.log(miniLeagueArray.length);
								if (
									miniLeagueArray.length === noOfMiniLeagueTeams // TODO: Due to the async call, this seems to be the way to deal with it. Could be improved
								)
									setMiniLeagueTeams([ ...miniLeagueArray ]);
							} else {
								// doc.data() will be undefined in this case
								console.log('No such Team!');
							}
						})
						.catch((error) => {
							console.log('Error getting document:', error);
						});
				});
			})
			.catch((error) => {
				console.log('Error getting documents: ', error);
			});
	};

	useEffect(
		() => {
			if (miniLeagueTeams) {
				console.log('Getting the rest of team members');
				var teamMembers = [];

				miniLeagueTeams.map((team, index) => {
					console.log(team.teamref_path);
					const db = firebase.firestore();
					var getTeamMembersRef = db
						.collection(team.teamref_path + '/MiniLeague_Members')
						.orderBy('total_fl_points', 'desc');
					getTeamMembersRef
						.get()
						.then((querySnapshot) => {
							var position = 1;
							querySnapshot.forEach((doc) => {
								// doc.data() is never undefined for query doc snapshots
								//console.log(doc.id, " => ", doc.data());
								teamMembers.push({
									...doc.data(),
									MiniLeague_Name: team.MiniLeague_Name,
									position: position
								});
								setMiniLeagueTeamMembers([ ...teamMembers ]);
								position = position + 1;
							});
						})
						.catch((error) => {
							console.log('Error getting documents: ', error);
						});
				});

				//setMiniLeagueTeamMembers();
				//console.log(miniLeagueTeamMembers);
			}
		},
		[ miniLeagueTeams ]
	);

	const handleAccordianChange = (panel) => (event, isExpanded) => {
		console.log(miniLeagueTeamMembers);
	};

	function handleJoinButton(event) {
		if (miniLeagueTeamCode) {
			var db = firebase.firestore();
			//Find the ML Team via the code entered
			var getTeamRef = db
				.collection('/FantasyLeague/2022/MiniLeague')
				.where('MiniLeague_Passcode', '==', miniLeagueTeamCode.trim())
				.get()
				.then((querySnapshot) => {
					if (querySnapshot.empty) {
						console.log('No team found');
						setSelectedValue('No team found with the code');
						setOpen(true);
					} else {
						querySnapshot.forEach((doc) => {
							// doc.data() is never undefined for query doc snapshots
							console.log('Team with code found: ' + doc.data().MiniLeague_Name);
							var teamId = doc.id;

							//get the gamer profile
							var getGamerRef = db
								.collection('/FantasyLeague/2022/gamers')
								.doc(user.uid)
								.get()
								.then((doc2) => {
									if (doc2.exists) {
										console.log('Gamer Uid:', doc2.data().uid);

										//Add gamer to the ML Team
										db
											.collection(
												'/FantasyLeague/2022/MiniLeague/' + teamId + '/MiniLeague_Members'
											)
											.doc(doc2.id)
											.set(
												{
													...doc2.data()
												},
												{ merge: true }
											)
											.then(() => {
												console.log(
													'Gamer ' + doc2.data().uid + 'added to the  team => ' + teamId
												);
												fetchMiniLeagueTeams(); //refresh the accodian
											})
											.catch((error) => {
												console.error('Error adding gamer to minileague: ', error);
												//to open error dialog
											});
									} else {
										// doc.data() will be undefined in this case
										console.log('No such gamer!');
									}
								})
								.catch((error) => {
									console.log('Error getting document:', error);
								});
						});
					}
				})
				.catch((error) => {
					console.log('Error getting documents: ', error);
				});
		} else {
			console.log('Empty -> Show error Dialog');
		}
	}

	function handleTeamCodeTextFieldChange(event) {
		setMiniLeagueTeamCode(event.target.value);
	}

	const handleCreateMiniLeague = (event) => {
		console.log('Creating Mini League Team: ' + team);
		var passcode = user.uid.substring(0, 2) + Math.floor(Math.random() * 999);
		var db = firebase.firestore();
		db
			.collection('/FantasyLeague/2022/MiniLeague')
			.add({
				MiniLeague_Name: team,
				MiniLeague_Passcode: passcode,
				MiniLeague_Admin: user.uid
			})
			.then((docRef) => {
				console.log('Document written with ID: ', docRef.id);
				//get the gamer profile
				var getGamerRef = db
					.collection('/FantasyLeague/2022/gamers')
					.doc(user.uid)
					.get()
					.then((doc2) => {
						if (doc2.exists) {
							console.log('Gamer Uid:', doc2.data().uid);

							//Add gamer to the ML Team
							db
								.collection('/FantasyLeague/2022/MiniLeague/' + docRef.id + '/MiniLeague_Members')
								.doc(doc2.id)
								.set(
									{
										...doc2.data()
									},
									{ merge: true }
								)
								.then(() => {
									console.log('Gamer ' + doc2.data().uid + 'added to the  team => ' + docRef.id);
									setOpenCreateMiniLeagueDialog(false);
									fetchMiniLeagueTeams(); //refresh the accodian
								})
								.catch((error) => {
									console.error('Error adding gamer to minileague: ', error);
									//to open error dialog
								});
						} else {
							// doc.data() will be undefined in this case
							console.log('No such gamer!');
						}
					})
					.catch((error) => {
						console.log('Error getting document:', error);
					});
			})
			.catch((error) => {
				console.error('Error adding document: ', error);
			});
	};

	return (
        <StyledGrid container className={classes.root} spacing={2}>
			<Grid item xs={12} align='center'>
				<Card className={classes.card_header}>
					<CardMedia
						className={classes.media}
						image='https://images.daznservices.com/di/library/GOAL/40/d2/void-deck-football_51l1a2j96qsq117g84mqbcluz.jpeg?quality=60&w=1200'
						title='Fantasy Football Mini League'
					/>
					<CardContent>
						<Typography variant='h6' align='center'>
							My Mini Leagues
						</Typography>
						<Box margin={1} />
						<Typography variant='caption' align='center'>
							A league of its own with your buddies
						</Typography>
						<Box margin={1} />
						<Button
							color='primary'
							onClick={() => {
								setOpenCreateMiniLeagueDialog(true);
							}}
						>
							Create Mini League
						</Button>
						<Dialog
							open={openCreateMiniLeagueDialog}
							onClose={handleClose}
							aria-labelledby='form-dialog-title'
						>
							<DialogTitle id='form-dialog-title'>Create Mini League</DialogTitle>
							<DialogContent>
								<DialogContentText>
									To create a mini-league, please enter your league name here.
								</DialogContentText>
								<TextField
									autoFocus
									margin='dense'
									id='name'
									label='Team Name'
									onChange={(e) => setTeam(e.target.value)}
									fullWidth
								/>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={() => {
										setOpenCreateMiniLeagueDialog(false);
									}}
									color='primary'
								>
									Cancel
								</Button>
								<Button onClick={handleCreateMiniLeague} color='primary'>
									Create
								</Button>
							</DialogActions>
						</Dialog>
					</CardContent>
				</Card>
			</Grid>
			<Grid item xs={12} align='left'>
				<Card>
					<CardHeader subheader='Enter the Mini League Team Code to join' />
					<CardActions disableSpacing>
						<TextField
							id='standard-basic'
							label='Mini League Code'
							onChange={handleTeamCodeTextFieldChange}
						/>
						<Box margin={1} />

						<Button size='medium' variant='contained' color='primary' onClick={handleJoinButton}>
							Join
						</Button>
					</CardActions>
				</Card>
				<SimpleDialog selectedValue={selectedValue} open={open} onClose={handleClose} />
			</Grid>
			<Grid item xs={12}>
				{miniLeagueTeams.map((team, team_id) => (
					<Accordion key={team_id} onChange={handleAccordianChange(team.teamref_path)}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls='panel1a-content'
							id='panel1a-header'
						>
							<Typography className={classes.heading}>{team.MiniLeague_Name}</Typography>
							<Box m={1} />
							<Typography className={classes.heading}>Passcode: {team.MiniLeague_Passcode}</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Grid container spacing={2} direction='column' justifyContent='center'>
								{miniLeagueTeamMembers.map(
									(member, id) =>
										member.MiniLeague_Name === team.MiniLeague_Name ? (
											<div key={id}>
												<Grid item xs={12}>
													<Card key={id + 1}>
														<CardContent>
															<Grid container direction='row' spacing={1}>
																<Grid item xs={3}>
																	<Avatar className={classes.blue}>
																		{member.position}
																	</Avatar>
																	{member.position === 1 ? 'Leader' : ''}
																</Grid>
																<Grid item xs={3}>
																	<Typography variant='caption' align='center'>
																		{member.team_name ? (
																			member.team_name
																		) : (
																			'No Name'
																		)}
																	</Typography>
																</Grid>
																<Grid item xs={3}>
																	<Typography variant='caption' align='center'>
																		Total Points: {member.total_fl_points}
																	</Typography>
																</Grid>
															</Grid>
														</CardContent>
													</Card>
												</Grid>
											</div>
										) : (
											<div key={id} />
										)
								)}
							</Grid>
						</AccordionDetails>
					</Accordion>
				))}
			</Grid>
		</StyledGrid>
    );
}

import React, { useEffect, useState } from 'react';
import { makeStyles, styled } from '@mui/styles';

import { useSession } from '../App';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import Grid from '@mui/material/Grid';

//Firebase
import * as FirestoreService from '../firebase';
import { CURRENT_WEEK } from '../component/Constants';

//Icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { deepPurple } from '@mui/material/colors';

//Table
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';

//Paper
import Paper from '@mui/material/Paper';

//Card
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardHeader from '@mui/material/CardHeader';

//icons
import trophyLogo from '../icons/trophy.png';

const PREFIX = 'FantasyLeagueOverview';

const classes = {
	root  : `${PREFIX}-root`,
	root2 : `${PREFIX}-root2`
};

const StyledGrid = styled(Grid)({
	[`& .${classes.root2}`]: {
		'& > *' : {
			borderBottom : 'unset'
		}
	}
});

const RedTextTypography = Typography;

const useRowStyles = makeStyles({
	[`& .${classes.root2}`]: {
		'& > *' : {
			borderBottom : 'unset'
		}
	}
});

function Row(props) {
	const { row } = props;
	const [ open, setOpen ] = React.useState(false);
	const classes = useRowStyles();

	return (
		<React.Fragment>
			<TableRow className={classes.root}>
				<TableCell style={{ width: '5%' }}>
					<IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
				<TableCell component='th' scope='row'>
					{row.firstname}
				</TableCell>
				<TableCell align='left'>{row.value}</TableCell>
				<TableCell align='left'>{row.fl_points}</TableCell>
			</TableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
					<Collapse in={open} timeout='auto' unmountOnExit>
						<Box margin={1}>
							<Table size='small' aria-label='purchases'>
								<TableHead>
									<TableRow>
										<TableCell> </TableCell>
										<TableCell>Stats</TableCell>
										<TableCell>Points</TableCell>
									</TableRow>
								</TableHead>
								{/*}
								<TableBody>
									{row.gamepoints.map((gamepointsRow) => (
										<TableRow key={gamepointsRow.date}>
											<TableCell component='th' scope='row'>
												{gamepointsRow.game_key}
											</TableCell>
											<TableCell>{gamepointsRow.game_value}</TableCell>
											<TableCell>{gamepointsRow.game_points}</TableCell>
										</TableRow>
									))}
								</TableBody>
									*/}
							</Table>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</React.Fragment>
	);
}

export default function FantasyLeagueOverview(props) {
	const user = useSession();

	const [ expanded, setExpanded ] = React.useState(false);
	const [ teamName, setTeamName ] = useState('');
	const [ totwPlayers, setTotwPlayers ] = useState([]);
	const [ totalPoints, setTotalPoints ] = useState(0);
	const [ weeklyPoints, setWeeklyPoints ] = useState(0);
	const [ currentRanking, setCurrentRanking ] = useState(0);
	const [ overallRanking, setOverallRanking ] = useState(0);
	const [ currentWeek, setCurrentWeek ] = useState(CURRENT_WEEK - 1);
	const [ gamer, setGamer ] = useState();

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	useEffect(() => {
		fetchGamerTeam();
		fetchWeek1Team();
		//fetchOrigSquad();
	}, []);

	const fetchGamerTeam = async () => {
		const response = FirestoreService.db
			.collection('FantasyLeague')
			.doc('2022')
			.collection('gamers')
			//.doc('YP1dpgESghPw7WiAoIL1Sd0HARw1');
			.doc(user.uid);

		response
			.get()
			.then((doc) => {
				if (doc.exists) {
					setTeamName(doc.data().team_name);
					setTotalPoints(doc.data().total_fl_points);
					setOverallRanking(doc.data().current_ranking);
					var weeklyPoints = doc.data().weekly_points === undefined ? 0 : doc.data().weekly_points;
					var points = weeklyPoints['week_1'];
					setWeeklyPoints(points);
					var weeklyRankings = doc.data().weekly_ranking === undefined ? 0 : doc.data().weekly_ranking;
					var counter = 0;
					for (const [ key, value ] of Object.entries(weeklyRankings)) {
						counter = counter + 1;
						//console.log(key, value);
					}

					var ranking = weeklyRankings['week_6'];
					setCurrentRanking(ranking);
					setGamer(doc.data());
				} else {
					// doc.data() will be undefined in this case
					console.log('No such document!');
				}
			})
			.catch((error) => {
				console.log('Error getting document:', error);
			});
	};

	const fetchWeek1Team = async () => {
		const response = FirestoreService.db
			.collection('FantasyLeague')
			.doc('2022')
			.collection('gamers')
			.doc(user.uid)
			.collection('team_week_' + currentWeek)
			.orderBy('position_id', 'asc');

		response
			.get()
			.then((querySnapshot) => {
				setTotwPlayers(querySnapshot.docs.map((player) => player.data()));
			})
			.catch((error) => {
				console.log('Error getting documents: ', error);
			});
	};

	function handleForwardArrow() {
		//console.log('Handle Forward Arrow');
		var temp;
		if (currentWeek === 7) temp = 7.1;
		else if (currentWeek === 7.1) temp = 7.2;
		else if (currentWeek === 7.2) temp = 8;
		else if (currentWeek === 12) temp = 12.1;
		else if (currentWeek === 13) temp = 13.1;
		else if (currentWeek === 15) temp = 15.1;
		else if (currentWeek === 18) temp = 18.1;
		else if (currentWeek === 18.1) temp = 19;
		else if (currentWeek === 19) temp = 19.1;
		else if (currentWeek === 21) temp = 21.1;
		else if (currentWeek === 21.1) temp = 22;
		else {
			temp = parseInt(currentWeek) + 1;
		}

		setCurrentWeek(temp);
		//console.log(gamer.weekly_points['week_' + temp]);
		//var weeklyPoints = gamer.weekly_points['week_' + temp] === undefined ? 0 : gamer.weekly_points['week_' + temp];
		//var points = weeklyPoints['week_' + temp];
		setWeeklyPoints(gamer.weekly_points['week_' + temp]);
		setCurrentRanking(gamer.weekly_ranking['week_' + temp]);

		const response = FirestoreService.db
			.collection('FantasyLeague')
			.doc('2022')
			.collection('gamers')
			//.doc('YP1dpgESghPw7WiAoIL1Sd0HARw1')
			.doc(user.uid)
			.collection('team_week_' + temp.toString());

		response
			.get()
			.then((querySnapshot) => {
				var temp = [];
				//setTotwPlayers(temp);
				querySnapshot.forEach((doc) => {
					// doc.data() is never undefined for query doc snapshots
					console.log(doc.id, ' => ', doc.data());
					temp.push(doc.data());
				});
				setTotwPlayers(temp);
			})
			.catch((error) => {
				console.log('Error getting documents: ', error);
			});
	}

	function handleBackArrow() {
		//console.log('Handle Back Arrow');
		//TODO: Needs to improve
		var temp;
		if (currentWeek === 8) {
			temp = 7.2;
		} else if (currentWeek === 7.2) {
			temp = 7.1;
		} else if (currentWeek === 7.1) {
			temp = 7;
		} else if (currentWeek === 12.1) {
			temp = 12;
		} else if (currentWeek === 12) {
			temp = 11;
		} else if (currentWeek === 13) {
			temp = 12.1;
		} else if (currentWeek === 13.1) {
			temp = 13;
		} else if (currentWeek === 14) {
			temp = 13.1;
		} else if (currentWeek === 16) {
			temp = 15.1;
		} else if (currentWeek === 15.1) {
			temp = 15;
		} else if (currentWeek === 18.1) {
			temp = 18;
		} else if (currentWeek === 19) {
			temp = 18.1;
		} else if (currentWeek === 19.1) {
			temp = 19;
		} else if (currentWeek === 20) {
			temp = 19.1;
		} else if (currentWeek === 21.1) {
			temp = 21;
		} else if (currentWeek === 22) {
			temp = 21.1;
		} else {
			temp = currentWeek - 1;
		}

		//var weeklyPoints = gamer.weekly_points === undefined ? 0 : gamer.data().weekly_points;
		//var points = weeklyPoints['week_' + temp];
		setWeeklyPoints(gamer.weekly_points['week_' + temp]);
		setCurrentRanking(gamer.weekly_ranking['week_' + temp]);
		setCurrentWeek(temp);
		const response = FirestoreService.db
			.collection('FantasyLeague')
			.doc('2022')
			.collection('gamers')
			.doc(user.uid)
			.collection('team_week_' + temp);

		response
			.get()
			.then((querySnapshot) => {
				//console.log('setting players' + querySnapshot.size);
				var temp = [];
				querySnapshot.forEach((doc) => {
					// doc.data() is never undefined for query doc snapshots
					//console.log(doc.id, ' => ', doc.data());
					temp.push(doc.data());
				});
				setTotwPlayers(temp);
			})
			.catch((error) => {
				console.log('Error getting documents: ', error);
			});
	}

	return (
		<StyledGrid container className={classes.root} alignContent='center' spacing={1}>
			<Grid item xs={12}>
				<Typography
					align='center'
					gutterBottom
					variant='h6'
					component='h2'
					sx={{
						height     : 50,
						textAlign  : 'center',
						background : 'black',
						color      : 'white'
					}}
				>
					My Team Overview
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Card>
					<Box display='flex' flexDirection='row' p={1} m={1}>
						<Box p={1} bgcolor='grey.300'>
							<Avatar sx={{ bgcolor: deepPurple[500] }}> {teamName.substring(0, 1).toUpperCase()}</Avatar>
						</Box>
						<Box p={1}>
							<Typography variant='h6' align='center'>
								Team Name: {teamName}
							</Typography>
						</Box>
					</Box>
				</Card>
			</Grid>
			<Grid item xs={12}>
				<Card>
					<Grid container alignItems='center' justifyContent={'center'}>
						<Grid item>
							<Grid container alignItems='center' justifyContent='center'>
								<Grid item>
									<IconButton
										aria-label='delete'
										className={classes.margin}
										onClick={handleBackArrow}
										size='large'
									>
										<ArrowBackIcon fontSize='small' />
									</IconButton>
								</Grid>
								<Grid item>
									<Typography variant='h6'>Week {currentWeek}</Typography>
								</Grid>
								<Grid item>
									<IconButton
										aria-label='delete'
										className={classes.margin}
										onClick={handleForwardArrow}
										size='large'
									>
										<ArrowForwardIcon fontSize='small' />
									</IconButton>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} align='center'>
							<Typography variant='h6' justifyContent={'center'}>
								Overall Statistics
							</Typography>
							{overallRanking === 1 ? (
								<img src={trophyLogo} alt='throphy' height='25px' width='25px' />
							) : (
								<div />
							)}
						</Grid>
						<Grid item xs={6} align='center'>
							<Typography variant='caption'>Total Points: {totalPoints}</Typography>
						</Grid>
						<Grid item xs={6} align='center'>
							<Typography variant='caption'>Week Points: {weeklyPoints}</Typography>
						</Grid>
						<Grid item xs={6} align='center'>
							<Typography variant='caption'>Overall Ranking: {overallRanking}</Typography>
						</Grid>
						<Grid item xs={6} align='center'>
							<Typography variant='caption'>
								Week {currentWeek} Ranking: {currentRanking}
							</Typography>
						</Grid>
					</Grid>
				</Card>
			</Grid>
			<Grid item xs={12}>
				{totwPlayers.length === 0 ? (
					<div>
						<Box p={1} />
						<Typography align='center' variant='h5'>
							No Games Yet in Week {currentWeek}
						</Typography>
					</div>
				) : (
					totwPlayers.map(
						(totwPlayer, index) =>
							totwPlayer.position === 'Goalkeeper' ? (
								<div>
									<Card key={'goalkeeper_' + index} className={classes.playercard}>
										<CardHeader
											avatar={
												<Avatar
													aria-label='recipe'
													className={classes.avatar}
													src={totwPlayer.team_logo_url}
												/>
											}
											title={
												totwPlayer.captain === undefined ? (
													totwPlayer.firstname + ' (' + totwPlayer.position + ')'
												) : (
													totwPlayer.firstname + ' (' + totwPlayer.position + ') - Captain'
												)
											}
											subheader={
												totwPlayer.fl_appearance === true ? (
													'Total Week Points: ' + totwPlayer.fl_points
												) : (
													' Did not feature this week'
												)
											}
										/>
										<CardMedia
											className={classes.media}
											image={totwPlayer.team_logo_url}
											title='Team Logo'
										/>
										<CardContent>
											{totwPlayer.fl_appearance === true ? (
												<Grid container spacing={1}>
													<Grid item xs={12}>
														<TableContainer component={Paper}>
															<Table aria-label='simple table'>
																<TableHead>
																	<TableRow>
																		<TableCell>Stats</TableCell>
																		<TableCell>Actual</TableCell>
																		<TableCell>FL Points</TableCell>
																	</TableRow>
																</TableHead>
																<TableBody>
																	<TableRow
																		key={'minutes_' + index}
																		sx={{
																			'&:last-child td, &:last-child th' : {
																				border : 0
																			}
																		}}
																	>
																		<TableCell component='th' scope='row'>
																			Mins Played
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.minutes}
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.fl_playingtime_pts}
																		</TableCell>
																	</TableRow>
																	<TableRow
																		key={'goalscored_' + index}
																		sx={{
																			'&:last-child td, &:last-child th' : {
																				border : 0
																			}
																		}}
																	>
																		<TableCell component='th' scope='row'>
																			Goals Scored
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.goals}
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.fl_goalsscored_pts}
																		</TableCell>
																	</TableRow>
																	<TableRow
																		key={'assists_' + index}
																		sx={{
																			'&:last-child td, &:last-child th' : {
																				border : 0
																			}
																		}}
																	>
																		<TableCell component='th' scope='row'>
																			Assist(s)
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.assists}{' '}
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.fl_assists_pts}{' '}
																		</TableCell>
																	</TableRow>
																	<TableRow
																		key={'cleansheets_' + index}
																		sx={{
																			'&:last-child td, &:last-child th' : {
																				border : 0
																			}
																		}}
																	>
																		<TableCell component='th' scope='row'>
																			Clean Sheet
																		</TableCell>

																		<TableCell component='th' scope='row'>
																			{totwPlayer.goalsconcede === 0 ? (
																				'Yes'
																			) : (
																				'No'
																			)}
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.fl_cleansheet_pts}{' '}
																		</TableCell>
																	</TableRow>
																	<TableRow
																		key={'concede_' + index}
																		sx={{
																			'&:last-child td, &:last-child th' : {
																				border : 0
																			}
																		}}
																	>
																		<TableCell component='th' scope='row'>
																			Concede
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.goalsconcede}{' '}
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.fl_goalsconcede_pts}{' '}
																		</TableCell>
																	</TableRow>
																	<TableRow
																		key={'shotssaved_' + index}
																		sx={{
																			'&:last-child td, &:last-child th' : {
																				border : 0
																			}
																		}}
																	>
																		<TableCell component='th' scope='row'>
																			Shots Saved
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.shotssaves}{' '}
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.fl_shotsaves_pts}{' '}
																		</TableCell>
																	</TableRow>
																	<TableRow
																		key={'yellowcards_' + index}
																		sx={{
																			'&:last-child td, &:last-child th' : {
																				border : 0
																			}
																		}}
																	>
																		<TableCell component='th' scope='row'>
																			Yellow Cards
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.yc}{' '}
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.fl_yellowcard_pts}{' '}
																		</TableCell>
																	</TableRow>
																	<TableRow
																		key={'redcards_' + index}
																		sx={{
																			'&:last-child td, &:last-child th' : {
																				border : 0
																			}
																		}}
																	>
																		<TableCell component='th' scope='row'>
																			Red Card
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.rc}{' '}
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.fl_redcard_pts}{' '}
																		</TableCell>
																	</TableRow>
																	<TableRow
																		key={'penaltysaves_' + index}
																		sx={{
																			'&:last-child td, &:last-child th' : {
																				border : 0
																			}
																		}}
																	>
																		<TableCell component='th' scope='row'>
																			Penalty Saves
																		</TableCell>
																		{totwPlayer.penaltySave === 'undefined' ? (
																			<TableCell>0</TableCell>
																		) : (
																			<TableCell>
																				{totwPlayer.penaltySave}
																			</TableCell>
																		)}
																		{totwPlayer.fl_penaltysaves_pts ===
																		'undefined' ? (
																			<TableCell>0</TableCell>
																		) : (
																			<TableCell>
																				{totwPlayer.fl_penaltysaves_pts}
																			</TableCell>
																		)}
																	</TableRow>
																	<TableRow
																		key={'penaltymissed_' + index}
																		sx={{
																			'&:last-child td, &:last-child th' : {
																				border : 0
																			}
																		}}
																	>
																		<TableCell component='th' scope='row'>
																			Penalty Missed
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.penaltyMiss}
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.fl_penaltymiss_pts}
																		</TableCell>
																	</TableRow>
																</TableBody>
															</Table>
														</TableContainer>
													</Grid>
												</Grid>
											) : (
												<Box
													sx={{
														bgcolor      : 'red',
														boxShadow    : 1,
														borderRadius : 2,
														p            : 2,
														minWidth     : 300
													}}
													key={'goalkeeper_' + index}
												>
													<Box sx={{ color: 'white', display: 'inline', fontSize: '14' }}>
														Did Not Play
													</Box>
												</Box>
											)}
										</CardContent>
									</Card>
								</div>
							) : (
								<div />
							)
					)
				)}
			</Grid>
			<Grid item xs={12}>
				{totwPlayers.length === 0 ? (
					<div>
						<Box p={1} />
						<Typography align='center' variant='h5'>
							No Games Yet in Week {currentWeek}
						</Typography>
					</div>
				) : (
					totwPlayers.map(
						(totwPlayer, index) =>
							totwPlayer.position === 'Defender' ? (
								<div>
									<Card key={'defender_' + index} className={classes.playercard}>
										<CardHeader
											avatar={
												<Avatar
													aria-label='recipe'
													className={classes.avatar}
													src={totwPlayer.team_logo_url}
												/>
											}
											title={
												totwPlayer.captain === undefined ? (
													totwPlayer.firstname + ' (' + totwPlayer.position + ')'
												) : (
													totwPlayer.firstname + ' (' + totwPlayer.position + ') - Captain'
												)
											}
											subheader={
												totwPlayer.fl_appearance === true ? (
													'Total Week Points: ' + totwPlayer.fl_points
												) : (
													' Did not feature this week'
												)
											}
										/>
										<CardMedia
											className={classes.media}
											image={totwPlayer.team_logo_url}
											title='Team Logo'
										/>
										<CardContent>
											{totwPlayer.fl_appearance === true ? (
												<Grid container spacing={1}>
													<Grid item xs={12}>
														<TableContainer component={Paper}>
															<Table aria-label='simple table'>
																<TableHead>
																	<TableRow>
																		<TableCell>Stats</TableCell>
																		<TableCell>Actual</TableCell>
																		<TableCell>FL Points</TableCell>
																	</TableRow>
																</TableHead>
																<TableBody>
																	<TableRow
																		key={'minutes_' + index}
																		sx={{
																			'&:last-child td, &:last-child th' : {
																				border : 0
																			}
																		}}
																	>
																		<TableCell component='th' scope='row'>
																			Mins Played
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.minutes}
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.fl_playingtime_pts}
																		</TableCell>
																	</TableRow>
																	<TableRow
																		key={'goalscored_' + index}
																		sx={{
																			'&:last-child td, &:last-child th' : {
																				border : 0
																			}
																		}}
																	>
																		<TableCell component='th' scope='row'>
																			Goals Scored
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.goals}
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.fl_goalsscored_pts}
																		</TableCell>
																	</TableRow>
																	<TableRow
																		key={'assists_' + index}
																		sx={{
																			'&:last-child td, &:last-child th' : {
																				border : 0
																			}
																		}}
																	>
																		<TableCell component='th' scope='row'>
																			Assist(s)
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.assists}{' '}
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.fl_assists_pts}{' '}
																		</TableCell>
																	</TableRow>
																	<TableRow
																		key={'cleansheets_' + index}
																		sx={{
																			'&:last-child td, &:last-child th' : {
																				border : 0
																			}
																		}}
																	>
																		<TableCell component='th' scope='row'>
																			Clean Sheet
																		</TableCell>

																		<TableCell component='th' scope='row'>
																			{totwPlayer.goalsconcede === 0 ? (
																				'Yes'
																			) : (
																				'No'
																			)}
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.fl_cleansheet_pts}{' '}
																		</TableCell>
																	</TableRow>
																	<TableRow
																		key={'concede_' + index}
																		sx={{
																			'&:last-child td, &:last-child th' : {
																				border : 0
																			}
																		}}
																	>
																		<TableCell component='th' scope='row'>
																			Concede
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.goalsconcede}{' '}
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.fl_goalsconcede_pts}{' '}
																		</TableCell>
																	</TableRow>
																	<TableRow
																		key={'shotssaved_' + index}
																		sx={{
																			'&:last-child td, &:last-child th' : {
																				border : 0
																			}
																		}}
																	>
																		<TableCell component='th' scope='row'>
																			Shots Saved
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.shotssaves}{' '}
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.fl_shotsaves_pts}{' '}
																		</TableCell>
																	</TableRow>
																	<TableRow
																		key={'yellowcards_' + index}
																		sx={{
																			'&:last-child td, &:last-child th' : {
																				border : 0
																			}
																		}}
																	>
																		<TableCell component='th' scope='row'>
																			Yellow Cards
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.yc}{' '}
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.fl_yellowcard_pts}{' '}
																		</TableCell>
																	</TableRow>
																	<TableRow
																		key={'redcards_' + index}
																		sx={{
																			'&:last-child td, &:last-child th' : {
																				border : 0
																			}
																		}}
																	>
																		<TableCell component='th' scope='row'>
																			Red Card
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.rc}{' '}
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.fl_redcard_pts}{' '}
																		</TableCell>
																	</TableRow>
																	<TableRow
																		key={'penaltysaves_' + index}
																		sx={{
																			'&:last-child td, &:last-child th' : {
																				border : 0
																			}
																		}}
																	>
																		<TableCell component='th' scope='row'>
																			Penalty Saves
																		</TableCell>
																		{totwPlayer.penaltySave === 'undefined' ? (
																			<TableCell>0</TableCell>
																		) : (
																			<TableCell>
																				{totwPlayer.penaltySave}
																			</TableCell>
																		)}
																		{totwPlayer.fl_penaltysaves_pts ===
																		'undefined' ? (
																			<TableCell>0</TableCell>
																		) : (
																			<TableCell>
																				{totwPlayer.fl_penaltysaves_pts}
																			</TableCell>
																		)}
																	</TableRow>
																	<TableRow
																		key={'penaltymissed_' + index}
																		sx={{
																			'&:last-child td, &:last-child th' : {
																				border : 0
																			}
																		}}
																	>
																		<TableCell component='th' scope='row'>
																			Penalty Missed
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.penaltyMiss}
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.fl_penaltymiss_pts}
																		</TableCell>
																	</TableRow>
																</TableBody>
															</Table>
														</TableContainer>
													</Grid>
												</Grid>
											) : (
												<Box
													sx={{
														bgcolor      : 'red',
														boxShadow    : 1,
														borderRadius : 2,
														p            : 2,
														minWidth     : 300
													}}
													key={'goalkeeper_' + index}
												>
													<Box sx={{ color: 'white', display: 'inline', fontSize: '14' }}>
														Did Not Play
													</Box>
												</Box>
											)}
										</CardContent>
									</Card>
								</div>
							) : (
								<div />
							)
					)
				)}
			</Grid>
			<Grid item xs={12}>
				{totwPlayers.length === 0 ? (
					<div>
						<Box p={1} />
						<Typography align='center' variant='h5'>
							No Games Yet in Week {currentWeek}
						</Typography>
					</div>
				) : (
					totwPlayers.map(
						(totwPlayer, index) =>
							totwPlayer.position === 'Midfielder' ? (
								<div>
									<Card key={'midfielder_' + index} className={classes.playercard}>
										<CardHeader
											avatar={
												<Avatar
													aria-label='recipe'
													className={classes.avatar}
													src={totwPlayer.team_logo_url}
												/>
											}
											title={
												totwPlayer.captain === undefined ? (
													totwPlayer.firstname + ' (' + totwPlayer.position + ')'
												) : (
													totwPlayer.firstname + ' (' + totwPlayer.position + ') - Captain'
												)
											}
											subheader={
												totwPlayer.fl_appearance === true ? (
													'Total Week Points: ' + totwPlayer.fl_points
												) : (
													' Did not feature this week'
												)
											}
										/>
										<CardMedia
											className={classes.media}
											image={totwPlayer.team_logo_url}
											title='Team Logo'
										/>
										<CardContent>
											{totwPlayer.fl_appearance === true ? (
												<Grid container spacing={1}>
													<Grid item xs={12}>
														<TableContainer component={Paper}>
															<Table aria-label='simple table'>
																<TableHead>
																	<TableRow>
																		<TableCell>Stats</TableCell>
																		<TableCell>Actual</TableCell>
																		<TableCell>FL Points</TableCell>
																	</TableRow>
																</TableHead>
																<TableBody>
																	<TableRow
																		key={'minutes_' + index}
																		sx={{
																			'&:last-child td, &:last-child th' : {
																				border : 0
																			}
																		}}
																	>
																		<TableCell component='th' scope='row'>
																			Mins Played
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.minutes}
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.fl_playingtime_pts}
																		</TableCell>
																	</TableRow>
																	<TableRow
																		key={'goalscored_' + index}
																		sx={{
																			'&:last-child td, &:last-child th' : {
																				border : 0
																			}
																		}}
																	>
																		<TableCell component='th' scope='row'>
																			Goals Scored
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.goals}
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.fl_goalsscored_pts}
																		</TableCell>
																	</TableRow>
																	<TableRow
																		key={'assists_' + index}
																		sx={{
																			'&:last-child td, &:last-child th' : {
																				border : 0
																			}
																		}}
																	>
																		<TableCell component='th' scope='row'>
																			Assist(s)
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.assists}{' '}
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.fl_assists_pts}{' '}
																		</TableCell>
																	</TableRow>
																	<TableRow
																		key={'cleansheets_' + index}
																		sx={{
																			'&:last-child td, &:last-child th' : {
																				border : 0
																			}
																		}}
																	>
																		<TableCell component='th' scope='row'>
																			Clean Sheet
																		</TableCell>

																		<TableCell component='th' scope='row'>
																			{totwPlayer.goalsconcede === 0 ? (
																				'Yes'
																			) : (
																				'No'
																			)}
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.fl_cleansheet_pts}{' '}
																		</TableCell>
																	</TableRow>
																	<TableRow
																		key={'concede_' + index}
																		sx={{
																			'&:last-child td, &:last-child th' : {
																				border : 0
																			}
																		}}
																	>
																		<TableCell component='th' scope='row'>
																			Concede
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.goalsconcede}{' '}
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.fl_goalsconcede_pts}{' '}
																		</TableCell>
																	</TableRow>
																	<TableRow
																		key={'shotssaved_' + index}
																		sx={{
																			'&:last-child td, &:last-child th' : {
																				border : 0
																			}
																		}}
																	>
																		<TableCell component='th' scope='row'>
																			Shots Saved
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.shotssaves}{' '}
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.fl_shotsaves_pts}{' '}
																		</TableCell>
																	</TableRow>
																	<TableRow
																		key={'yellowcards_' + index}
																		sx={{
																			'&:last-child td, &:last-child th' : {
																				border : 0
																			}
																		}}
																	>
																		<TableCell component='th' scope='row'>
																			Yellow Cards
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.yc}{' '}
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.fl_yellowcard_pts}{' '}
																		</TableCell>
																	</TableRow>
																	<TableRow
																		key={'redcards_' + index}
																		sx={{
																			'&:last-child td, &:last-child th' : {
																				border : 0
																			}
																		}}
																	>
																		<TableCell component='th' scope='row'>
																			Red Card
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.rc}{' '}
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.fl_redcard_pts}{' '}
																		</TableCell>
																	</TableRow>
																	<TableRow
																		key={'penaltysaves_' + index}
																		sx={{
																			'&:last-child td, &:last-child th' : {
																				border : 0
																			}
																		}}
																	>
																		<TableCell component='th' scope='row'>
																			Penalty Saves
																		</TableCell>
																		{totwPlayer.penaltySave === 'undefined' ? (
																			<TableCell>0</TableCell>
																		) : (
																			<TableCell>
																				{totwPlayer.penaltySave}
																			</TableCell>
																		)}
																		{totwPlayer.fl_penaltysaves_pts ===
																		'undefined' ? (
																			<TableCell>0</TableCell>
																		) : (
																			<TableCell>
																				{totwPlayer.fl_penaltysaves_pts}
																			</TableCell>
																		)}
																	</TableRow>
																	<TableRow
																		key={'penaltymissed_' + index}
																		sx={{
																			'&:last-child td, &:last-child th' : {
																				border : 0
																			}
																		}}
																	>
																		<TableCell component='th' scope='row'>
																			Penalty Missed
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.penaltyMiss}
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.fl_penaltymiss_pts}
																		</TableCell>
																	</TableRow>
																</TableBody>
															</Table>
														</TableContainer>
													</Grid>
												</Grid>
											) : (
												<Box
													sx={{
														bgcolor      : 'red',
														boxShadow    : 1,
														borderRadius : 2,
														p            : 2,
														minWidth     : 300
													}}
													key={'goalkeeper_' + index}
												>
													<Box sx={{ color: 'white', display: 'inline', fontSize: '14' }}>
														Did Not Play
													</Box>
												</Box>
											)}
										</CardContent>
									</Card>
								</div>
							) : (
								<div />
							)
					)
				)}
			</Grid>
			<Grid item xs={12}>
				{totwPlayers.length === 0 ? (
					<div>
						<Box p={1} />
						<Typography align='center' variant='h5'>
							No Games Yet in Week {currentWeek}
						</Typography>
					</div>
				) : (
					totwPlayers.map(
						(totwPlayer, index) =>
							totwPlayer.position === 'Forward' ? (
								<div>
									<Card key={'forward_' + index} className={classes.playercard}>
										<CardHeader
											avatar={
												<Avatar
													aria-label='recipe'
													className={classes.avatar}
													src={totwPlayer.team_logo_url}
												/>
											}
											title={
												totwPlayer.captain === undefined ? (
													totwPlayer.firstname + ' (' + totwPlayer.position + ')'
												) : (
													totwPlayer.firstname + ' (' + totwPlayer.position + ') - Captain'
												)
											}
											subheader={
												totwPlayer.fl_appearance === true ? (
													'Total Week Points: ' + totwPlayer.fl_points
												) : (
													' Did not feature this week'
												)
											}
										/>
										<CardMedia
											className={classes.media}
											image={totwPlayer.team_logo_url}
											title='Team Logo'
										/>
										<CardContent>
											{totwPlayer.fl_appearance === true ? (
												<Grid container spacing={1}>
													<Grid item xs={12}>
														<TableContainer component={Paper}>
															<Table aria-label='simple table'>
																<TableHead>
																	<TableRow>
																		<TableCell>Stats</TableCell>
																		<TableCell>Actual</TableCell>
																		<TableCell>FL Points</TableCell>
																	</TableRow>
																</TableHead>
																<TableBody>
																	<TableRow
																		key={'minutes_' + index}
																		sx={{
																			'&:last-child td, &:last-child th' : {
																				border : 0
																			}
																		}}
																	>
																		<TableCell component='th' scope='row'>
																			Mins Played
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.minutes}
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.fl_playingtime_pts}
																		</TableCell>
																	</TableRow>
																	<TableRow
																		key={'goalscored_' + index}
																		sx={{
																			'&:last-child td, &:last-child th' : {
																				border : 0
																			}
																		}}
																	>
																		<TableCell component='th' scope='row'>
																			Goals Scored
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.goals}
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.fl_goalsscored_pts}
																		</TableCell>
																	</TableRow>
																	<TableRow
																		key={'assists_' + index}
																		sx={{
																			'&:last-child td, &:last-child th' : {
																				border : 0
																			}
																		}}
																	>
																		<TableCell component='th' scope='row'>
																			Assist(s)
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.assists}{' '}
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.fl_assists_pts}{' '}
																		</TableCell>
																	</TableRow>
																	<TableRow
																		key={'cleansheets_' + index}
																		sx={{
																			'&:last-child td, &:last-child th' : {
																				border : 0
																			}
																		}}
																	>
																		<TableCell component='th' scope='row'>
																			Clean Sheet
																		</TableCell>

																		<TableCell component='th' scope='row'>
																			{totwPlayer.goalsconcede === 0 ? (
																				'Yes'
																			) : (
																				'No'
																			)}
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.fl_cleansheet_pts}{' '}
																		</TableCell>
																	</TableRow>
																	<TableRow
																		key={'concede_' + index}
																		sx={{
																			'&:last-child td, &:last-child th' : {
																				border : 0
																			}
																		}}
																	>
																		<TableCell component='th' scope='row'>
																			Concede
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.goalsconcede}{' '}
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.fl_goalsconcede_pts}{' '}
																		</TableCell>
																	</TableRow>
																	<TableRow
																		key={'shotssaved_' + index}
																		sx={{
																			'&:last-child td, &:last-child th' : {
																				border : 0
																			}
																		}}
																	>
																		<TableCell component='th' scope='row'>
																			Shots Saved
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.shotssaves}{' '}
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.fl_shotsaves_pts}{' '}
																		</TableCell>
																	</TableRow>
																	<TableRow
																		key={'yellowcards_' + index}
																		sx={{
																			'&:last-child td, &:last-child th' : {
																				border : 0
																			}
																		}}
																	>
																		<TableCell component='th' scope='row'>
																			Yellow Cards
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.yc}{' '}
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.fl_yellowcard_pts}{' '}
																		</TableCell>
																	</TableRow>
																	<TableRow
																		key={'redcards_' + index}
																		sx={{
																			'&:last-child td, &:last-child th' : {
																				border : 0
																			}
																		}}
																	>
																		<TableCell component='th' scope='row'>
																			Red Card
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.rc}{' '}
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.fl_redcard_pts}{' '}
																		</TableCell>
																	</TableRow>
																	<TableRow
																		key={'penaltysaves_' + index}
																		sx={{
																			'&:last-child td, &:last-child th' : {
																				border : 0
																			}
																		}}
																	>
																		<TableCell component='th' scope='row'>
																			Penalty Saves
																		</TableCell>
																		{totwPlayer.penaltySave === 'undefined' ? (
																			<TableCell>0</TableCell>
																		) : (
																			<TableCell>
																				{totwPlayer.penaltySave}
																			</TableCell>
																		)}
																		{totwPlayer.fl_penaltysaves_pts ===
																		'undefined' ? (
																			<TableCell>0</TableCell>
																		) : (
																			<TableCell>
																				{totwPlayer.fl_penaltysaves_pts}
																			</TableCell>
																		)}
																	</TableRow>
																	<TableRow
																		key={'penaltymissed_' + index}
																		sx={{
																			'&:last-child td, &:last-child th' : {
																				border : 0
																			}
																		}}
																	>
																		<TableCell component='th' scope='row'>
																			Penalty Missed
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.penaltyMiss}
																		</TableCell>
																		<TableCell component='th' scope='row'>
																			{totwPlayer.fl_penaltymiss_pts}
																		</TableCell>
																	</TableRow>
																</TableBody>
															</Table>
														</TableContainer>
													</Grid>
												</Grid>
											) : (
												<Box
													sx={{
														bgcolor      : 'red',
														boxShadow    : 1,
														borderRadius : 2,
														p            : 2,
														minWidth     : 300
													}}
													key={'goalkeeper_' + index}
												>
													<Box sx={{ color: 'white', display: 'inline', fontSize: '14' }}>
														Did Not Play
													</Box>
												</Box>
											)}
										</CardContent>
									</Card>
								</div>
							) : (
								<div />
							)
					)
				)}
			</Grid>
		</StyledGrid>
	);
}

import React, { Component } from 'react';
import AdminDashboard from '../../component/AdminDashboard';

export class admindashboard extends Component {
	render() {
		return (
			<div>
				<AdminDashboard />
			</div>
		);
	}
}

export default admindashboard;

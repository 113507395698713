import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import MatchPrediction from '../component/MatchPrediction';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Paper } from '@mui/material';
import { useSession } from '../App';

import firebase from '../firebase';

const PREFIX = 'matches';

const classes = {
    root: `${PREFIX}-root`,
    control: `${PREFIX}-control`,
    paperContainer: `${PREFIX}-paperContainer`,
    card: `${PREFIX}-card`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`&.${classes.root}`]: {
		flexGrow: 1
	},

    [`& .${classes.control}`]: {
		padding: theme.spacing(2)
	},

    [`& .${classes.paperContainer}`]: {
		height: 300,
		backgroundImage: `url(${'https://objectstorage.us-ashburn-1.oraclecloud.com/n/idotv6r6ikxt/b/voiddeck-footie/o/gifc.banner.matchdaymatchday_banner.jpg'})`
	},

    [`& .${classes.card}`]: {
		maxWidth: 600,
		padding: theme.spacing(2)
	}
}));

export default function Matches() {

	const user = useSession();

	const [ matches, setMatches ] = useState([]);
	const [ myPredictions, setMyPredictions ] = useState([]);

	useEffect(() => {
		//localStorage.getItem('myclub') === '10' ? setdisableClubSelection(false) : setdisableClubSelection(true);

		const fetchMatches = async () => {
			const db = firebase.firestore();
			const data = await db.collection('Matches').orderBy('match_date', 'asc').get();
			setMatches(data.docs.map((match) => match.data()));
			console.log(matches);
		};
		fetchMatches();

		const fetchMyPredictions = async () => {
			const db = firebase.firestore();
			const data = await db
				.collection('Users')
				.doc(user.uid)
				.collection('2020Predictions')
				.orderBy('match_date', 'asc')
				.get();
			setMyPredictions(data.docs.map((prediction) => prediction.data()));
		};
		fetchMyPredictions();
	}, []);

	return (
        <Root className={classes.root}>
			<Grid container spacing={2} justifyContent='center'>
				<Grid item xs={12}>
					<Card className={classes.card}>
						<CardActionArea>
							<CardMedia
								className={classes.paperContainer}
								image='https://objectstorage.us-ashburn-1.oraclecloud.com/n/idotv6r6ikxt/b/voiddeck-footie/o/gifc.banner.matchdaymatchday_banner.jpg'
								title='Geylang Eagles Matchday'
							/>
							<CardContent>
								<Typography gutterBottom variant='h3' component='h2' align='center'>
									Common You Boys In Green
								</Typography>
								<Typography gutterBottom variant='h4' component='h4' align='center'>
									Majulah
								</Typography>
							</CardContent>
						</CardActionArea>
					</Card>
				</Grid>
				<Grid container direction='row' justifyContent='center' alignItems='center'>
					<Grid item xs={12}>
						<Paper>
							<Typography variant='h4' align='center'>
								Geylang International Matches
							</Typography>
						</Paper>
					</Grid>
					{matches.map((match) => (
						<Grid key={match.id} item xs={12} s={6} md={6}>
							<Card className={classes.card}>
								<MatchPrediction
									match={match}
									myPrediction={myPredictions.map((myPrediction) => {
										if (myPrediction.match_id === match.match_id) {
											return myPrediction;
										}
									})}
								/>
							</Card>
						</Grid>
					))}
				</Grid>
			</Grid>
		</Root>
    );
}

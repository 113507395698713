import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import './App.css';

//Import pages
import home from './pages/home';
import transfercenter from './pages/fantasyleagueteam';
import matches from './pages/matches';
import matchrating from './pages/matchrating';
import merchandise from './pages/merchandise';
import chants from './pages/chants';
import login from './pages/login';
import dashboard from './pages/dashboard';
import mypredictions from './pages/mypredictions';
import admindashboard from './pages/admin_pages/admindashboard';
import adminfantasyleaguedashboard from './pages/admin_pages/adminfantasyleaguedashboard';
import admindashboardusers from './pages/admin_pages/admindashboardusers';
import adminfantasyleaguediagnosticdashboard from './pages/admin_pages/adminfantasyleaguediagnosticsdashboard';
import adminfantasyleaguestats from './pages/admin_pages/adminfantasyleaguestats';
import creatematchschedule from './pages/admin_pages/creatematchschedule';
import createplayers from './pages/admin_pages/createplayers';
import about from './pages/about';
import fantasyleagueoverview from './pages/fantasyleagueoverview';
import fantasyleagueteam from './pages/fantasyleagueteam';
import fantasyleaguetransfer from './pages/fantasyleaguetransfer';
import fantasyoverview from './pages/fantasyoverview';
import fantasyleaguetotw from './pages/fantasyleaguetotw';
import fantasyleagueminileague from './pages/fantasyleagueminileague';
import predictiontopusers from './pages/predictiontopusers';
import matchadmin from './pages/admin_pages/matchadmin';



//Import components
import Navbar from './component/Navbar';
import firebase from './firebase';


export const whiteListedUsers = [
		'jeff.salleh@gmail.com',
		'muhd92faez@gmail.com',
		'edhirono@gmail.com',
		'six.stomachs@hotmail.com',
		'loy_tony@hotmail.com',
		'ricteoric@gmail.com',
		'yljiang889@hotmail.com',
		'jameshuggett1979@hotmail.com',
		'mohsen38@hotmail.com',
		'adamyeochiawei@gmail.com',
		'nash_13128@yahoo.com.sg',
		'basicorexxxforever@gmail.com',
		'rishiraja31@gmail.com',
		'abd.rman89@live.com',
		'nasnas619@gmail.com',
		'sufian.wahab84@gmail.com',
		'amsyarhanif@gmail.com',
		'donovantanyq@gmail.com',
		'erictyw@gmail.com',
		'chiachia2223@gmail.com',
		'pangjiatai@hotmail.com',
		'powerwinner123@gmail.com',
		'amirammar2a@gmail.com',
		'lloydchengyh@gmail.com',
		'yijiangong@gmail.com',
		'lim.chuan@outlook.com',
		'mohd.nazrul1984@gmail.com',
		'pangjiatai92@gmail.com',
		'javierpeh23@gmail.com',
		'zulhimi45@gmail.com',
		'aaron.chan.jkt@gmail.com',
		'zeeism@gmail.com',
		'ky.kimng@gmail.com',
		'errick.encik@gmail.com',
		'icedwater@gmail.com',
		'yamjy114@gmail.com',
		'yejoojinwonna@google.sg',
		'jeff@voiddeck.sg'
];

export const userContext = React.createContext({
	user: null,
	club: null,
	email: null,
	whitelisted: null
});

export const useSession = (requestType) => {
	const { user } = useContext(userContext);
	// console.log(user.email);
	userContext.email = user.email;
	//userContext.whitelisted = whitelist(user.email);
	// console.log(userContext.whitelisted);
	// console.log(user);

	if (requestType === 'usercontext') return userContext;
	else return user;
};

export const logoutFromApp = () => {
	firebase.auth().signOut();
};

export const useAuth = () => {
	const [ state, setState ] = React.useState(() => {
		const user = firebase.auth().currentUser;
		//userContext.email = user.email;
		return { initializing: !user, user };
	});

	function onChange(user) {
		setState({ initializing: false, user });
	}

	React.useEffect(() => {
		// listen for auth state changes
		const unsubscribe = firebase.auth().onAuthStateChanged(onChange);
		// unsubscribe to the listener when unmounting
		return () => unsubscribe();
	}, []);

	return state;
};

const App = () => {
	const { initializing, user } = useAuth();

	//const {token} = turnOnNotification();
	if (initializing) {
		return <div>Loading</div>;
	}

	console.log({ REACT_APP_API_ENDPOINT: process.env.REACT_APP_API_ENDPOINT })


	return (
                <userContext.Provider value={{ user }}>
                    <div className='App'>
                        <Router>
						{firebase.auth().currentUser ? <Navbar /> : null}



                            <div className='container'>
                                <Switch>
                                    <Route exact path='/login' component={login} />
                                    <Route exact path='/signin' component={home} />
                                    <Route exact path='/about' component={about} />
                                    <AuthenticatedRoute exact path='/' component={dashboard} />
                                    <AuthenticatedRoute exact path='/dashboard' component={dashboard} />
                                    <AuthenticatedRoute
                                        exact
                                        path='/fantasyleague/overview'
                                        component={fantasyleagueoverview}
                                    />
                                    <AuthenticatedRoute exact path='/fantasyleague/totw' component={fantasyleaguetotw} />
                                    <AuthenticatedRoute exact path='/fantasyleague/team' component={fantasyleagueteam} />
                                    <AuthenticatedRoute
                                        exact
                                        path='/fantasyleague/transfer'
                                        component={fantasyleaguetransfer}
                                    />
                                    <AuthenticatedRoute
                                        exact
                                        path='/fantasyleague/flml'
                                        component={fantasyleagueminileague}
                                    />
									

                                    <AuthenticatedRoute exact path='/mypredictions' component={mypredictions} />
                                    <AuthenticatedRoute exact path='/matches' component={matches} />
                                    <AuthenticatedRoute exact path='/rating' component={matchrating} />
                                    <AuthenticatedRoute exact path='/transfercenter' component={transfercenter} />
                                    <AuthenticatedRoute exact path='/merchandise' component={merchandise} />
                                    <AuthenticatedRoute exact path='/chants' component={chants} />
                                    <AuthenticatedRoute exact path='/admin' component={admindashboard} />
                                    <AuthenticatedRoute exact path='/admin/users' component={admindashboardusers} />
                                    <AuthenticatedRoute exact path='/admin/fantasyleague' component={adminfantasyleaguedashboard} />
                                    <AuthenticatedRoute exact path='/admin/fantasyleaguediagnostic' component={adminfantasyleaguediagnosticdashboard} />
									<AuthenticatedRoute exact path='/admin/adminfantasyleaguestats' component={adminfantasyleaguestats} />
                                    <AuthenticatedRoute exact path='/admin/creatematchschedule' component={creatematchschedule} />
                                    <AuthenticatedRoute exact path='/admin/createplayers' component={createplayers} />
									<AuthenticatedRoute exact path='/admin/matchadmin' component={matchadmin} />
                                    <AuthenticatedRoute exact path='/fantasyoverview' component={fantasyoverview} />
                                    <AuthenticatedRoute exact path='/predictiontopusers' component={predictiontopusers} />
									
                                </Switch>
                            </div>
                        </Router>
                    </div>
                </userContext.Provider>
    );
};

/*
const whitelist = (email) => {
	//console.log(whiteListedUsers)
	var index = whiteListedUsers.findIndex((index) => index === email);
	//console.log(index + ' check-whitelist: ' + email);
	if (index === -1) return false;
	else return true;
};
*/

const AuthenticatedRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={(props) =>
			firebase.auth().currentUser ? (
				<Component {...props} />
			) : (
				<Redirect
					to={{
						pathname: '/signin',
						state: { from: props.location }
					}}
				/>
			)}
	/>
);
export default App;

import React, { Component } from 'react';
import About from '../component/About';

export class about extends Component {
	render() {
		return (
			<div>
				<About />
			</div>
		);
	}
}

export default about;

import React, { Component } from 'react';
import Dashboard from '../component/Dashboard';

export default class dashboard extends Component {
	/*
	constructor(props) {
		super(props);
		this.state = {
			loaded: false,
			matches: []
		};
	}

	componentDidMount() {
		const db = firebase.firestore();
		let matchesRef = db.collection('Matches').orderBy('date', 'asc');
		let allMatches = matchesRef
			.get()
			.then((snapshot) => {
				snapshot.forEach((doc) => {
					console.log(doc.id, '=>', doc.data());
				});
				this.setState(snapshot);
			})
			.catch((err) => {
				console.log('Error getting documents', err);
			});
	}
	*/

	render() {
		return (
			<div>
				<Dashboard />
			</div>
		);
	}
}

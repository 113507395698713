import firebase from '../firebase';

const db = firebase.firestore();

function updatePlayersStats(weekCalculation) {
	console.log('Updating Players Stats for Week:  ' + weekCalculation + ' ' + typeof(weekCalculation));

	var noOfPlayersUpdatedWithChanges = 0;
	var noOfPlayersUpdatedWithoutChanges = 0;

	var flPlayers = [];
	var batch = firebase.firestore().batch();

	if (weekCalculation.isInteger)
		var flPlayersRef = db.collection('/FantasyLeague/2022/fl_players_week_' + parseInt(weekCalculation));
	else 
		var flPlayersRef = db.collection('/FantasyLeague/2022/fl_players_week_' + parseFloat(weekCalculation));

	flPlayersRef.get().then((querySnapshot) => {
		querySnapshot.forEach((doc) => {
			console.log(doc.id, ' => ', doc.data());
			flPlayers.push(doc.data());
		});
	});

	//Get all the 2022 Players
	var playersRef = db.collectionGroup('2022Players');
	playersRef.get().then((querySnapshot) => {
		querySnapshot.forEach((doc) => {
			//console.log(doc.id, ' => ', doc.data());
			var player = doc.data();
			var path = db.doc(doc.ref.path);
			console.log('Processing: ' + player.player_id + ' ' + doc.ref.path);

			//check if they are in the fl for the week
			if (player.player_id !== undefined) {
				var foundPlayer = flPlayers.find((flP) => flP.player_id.trim() === player.player_id.trim());
				if (foundPlayer) {
					console.log('Player Played');
					/*
						console.log('Player Found: ' + JSON.stringify(foundPlayer));
						console.log('Player Found: ' + foundPlayer.fl_points);
						console.log('Player Found: ' + foundPlayer.goals);
						console.log('Player Found: ' + foundPlayer.assists);
						console.log('Player Found: ' + foundPlayer.minutes);
						console.log('Player Found: ' + foundPlayer.yc);
						console.log('Player Found: ' + foundPlayer.rc);
						console.log('Player Found: ' + foundPlayer.goalsconcede);
						*/

					//Update overall Statistics including Fantasy League
					var pointsArray = player.fl_weekly_points;
					if (pointsArray !== undefined) {
						var totalpoints = 0;
						var totalassists = 0;
						var totalconceded = 0;
						var totalgoals = 0;
						var totalminutesplayed = 0;
						var totalowngoals = 0;
						var totalpenaltymissed = 0;
						var totalpenaltysaved = 0;
						var totalshotssaved = 0;
						var totalredcards = 0;
						var totalyellowcards = 0;
						var totalcleansheets = 0;

						for (var i = 1; i < weekCalculation; i++) {
							if (player.fl_weekly_points['week_' + i] !== undefined) {
								//console.log('Week ' + i + ' ' + player.fl_weekly_points['week_' + i]);

								totalpoints = totalpoints + player.fl_weekly_points['week_' + i];
								totalassists = totalassists + player.weekly_assists['week_' + i];
								totalconceded = totalconceded + player.weekly_conceded['week_' + i];
								totalgoals = totalgoals + player.weekly_goals['week_' + i];
								totalminutesplayed = totalminutesplayed + player.weekly_minutes_played['week_' + i];
								totalowngoals = totalowngoals + isNaN(parseInt(player.weekly_own_goals['week_' + i])) ? 0 : player.weekly_own_goals['week_' + i];
								totalpenaltymissed = totalpenaltymissed + player.weekly_penalty_missed['week_' + i];
								totalpenaltysaved = isNaN(totalpenaltysaved)
									? 0
									: totalpenaltysaved + player.weekly_penalty_saved['week_' + i];
								totalshotssaved = isNaN(totalshotssaved)
									? 0
									: totalshotssaved + player.weekly_shots_saved['week_' + i] !== undefined
										? player.weekly_shots_saved['week_' + i]
										: 0;
								totalredcards = totalredcards + player.weekly_red_cards['week_' + i];
								totalyellowcards = totalyellowcards + player.weekly_yellow_cards['week_' + i];
								if (
									player.weekly_conceded['week_' + i] === 0 &&
									player.weekly_selection['week_' + i] !== 'reserved'
								) {
									totalcleansheets = totalcleansheets + 1;
								}
							}
						}

						totalpoints = totalpoints + foundPlayer.fl_points;
						totalassists = totalassists + foundPlayer.assists;
						totalconceded = totalconceded + foundPlayer.goalsconcede;
						totalgoals = totalgoals + foundPlayer.goals;
						totalminutesplayed = totalminutesplayed + foundPlayer.minutes;
						totalowngoals = totalowngoals + foundPlayer.og;
						totalpenaltymissed = totalpenaltymissed + foundPlayer.penaltyMiss;
						totalpenaltysaved = totalpenaltysaved + foundPlayer.penaltySave;
						totalshotssaved = totalshotssaved + foundPlayer.shotssaves;
						totalredcards = totalredcards + foundPlayer.rc;
						totalyellowcards = totalyellowcards + foundPlayer.yc;

						noOfPlayersUpdatedWithChanges = noOfPlayersUpdatedWithChanges + 1;
					} else {
						console.log('Adding week ' + weekCalculation + ' points: ' + foundPlayer.fl_points);

						totalpoints = totalpoints + foundPlayer.fl_points;
						totalassists = totalassists + foundPlayer.assists;
						totalconceded = totalconceded + foundPlayer.goalsconcede;
						totalgoals = totalgoals + foundPlayer.goals;
						totalminutesplayed = totalminutesplayed + foundPlayer.minutes;
						totalowngoals = totalowngoals + foundPlayer.og;
						totalpenaltymissed = totalpenaltymissed + foundPlayer.penaltyMiss;
						totalpenaltysaved = totalpenaltysaved + foundPlayer.penaltySave;
						totalshotssaved = totalshotssaved + foundPlayer.shotssaves;
						totalredcards = totalredcards + foundPlayer.rc;
						totalyellowcards = totalyellowcards + foundPlayer.yc;
					}

					/*
						console.log('Player: ' + player.firstname + player.lastname + ' total points: ' + totalpoints);
						console.log('total assists: ' + totalassists);
						console.log('total conceded: ' + totalconceded);
						console.log('total goals: ' + totalgoals);
						console.log('total mins playes: ' + totalminutesplayed);
						console.log('total red cards: ' + totalredcards);
						console.log('total yellow cards: ' + totalyellowcards);
						console.log('total clean sheets: ' + totalcleansheets);
						*/

					player = {
						...player,
						fl_points             : totalpoints === undefined ? 0 : totalpoints,
						total_assists         : totalassists === undefined ? 0 : totalassists,
						total_conceceded      : totalconceded === undefined ? 0 : totalconceded,
						total_goals           : totalgoals === undefined ? 0 : totalgoals,
						total_minutes_played  : totalminutesplayed === undefined ? 0 : totalminutesplayed,
						total_own_goals       : totalowngoals === undefined ? 0 : totalowngoals,
						total_penalty_missed  : totalpenaltymissed === undefined ? 0 : totalpenaltymissed,
						total_penalty_saved   : totalpenaltysaved === undefined ? 0 : totalpenaltysaved,
						total_shots_saved     : totalshotssaved === undefined ? 0 : totalshotssaved,
						total_red_cards       : totalredcards === undefined ? 0 : totalredcards,
						total_yellow_cards    : totalyellowcards === undefined ? 0 : totalyellowcards,
						total_clean_sheets    : totalcleansheets === undefined ? 0 : totalcleansheets,
						fl_weekly_points      : {
							...player.fl_weekly_points,
							['week_' + weekCalculation]: foundPlayer.fl_points
						},
						weekly_selection      : {
							...player.weekly_selection,
							['week_' + weekCalculation]: foundPlayer.firsteleven
						},
						weekly_assists        : {
							...player.weekly_assists,
							['week_' + weekCalculation]: foundPlayer.assists
						},
						weekly_goals          : {
							...player.weekly_goals,
							['week_' + weekCalculation]: foundPlayer.goals
						},
						weekly_minutes_played : {
							...player.weekly_minutes_played,
							['week_' + weekCalculation]: foundPlayer.minutes
						},
						weekly_own_goals      : {
							...player.weekly_own_goals,
							['week_' + weekCalculation]: foundPlayer.og
						},
						weekly_penalty_missed : {
							...player.weekly_penalty_missed,
							['week_' + weekCalculation]: foundPlayer.penaltyMiss
						},
						weekly_penalty_saved  : {
							...player.weekly_penalty_saved,
							['week_' + weekCalculation]: foundPlayer.penaltySave
						},
						weekly_shots_saved    : {
							...player.weekly_shots_saved,
							['week_' + weekCalculation]: foundPlayer.shotssaves
						},
						weekly_yellow_cards   : {
							...player.weekly_yellow_cards,
							['week_' + weekCalculation]: foundPlayer.yc
						},
						weekly_red_cards      : {
							...player.weekly_red_cards,
							['week_' + weekCalculation]: foundPlayer.rc
						},
						weekly_conceded       : {
							...player.weekly_conceded,
							['week_' + weekCalculation]: foundPlayer.goalsconcede
						},
						weekly_clean_sheets   : {
							...player.weekly_clean_sheets,
							['week_' + weekCalculation]: foundPlayer.concede === 0 ? true : false
						}
					};
					console.log(player);
					batch.update(path, player);
				} else {
					//console.log(doc.data());

					console.log('Player reserved');
					player = {
						...player,
						fl_points             : totalpoints === undefined ? 0 : totalpoints,
						total_assists         : totalassists === undefined ? 0 : totalassists,
						total_conceceded      : totalconceded === undefined ? 0 : totalconceded,
						total_goals           : totalgoals === undefined ? 0 : totalgoals,
						total_minutes_played  : totalminutesplayed === undefined ? 0 : totalminutesplayed,
						total_own_goals       : totalowngoals === undefined ? 0 : totalowngoals,
						total_penalty_missed  : totalpenaltymissed === undefined ? 0 : totalpenaltymissed,
						total_penalty_saved   : totalpenaltysaved === undefined ? 0 : totalpenaltysaved,
						total_shots_saved     : totalshotssaved === undefined ? 0 : totalshotssaved,
						total_red_cards       : totalredcards === undefined ? 0 : totalredcards,
						total_yellow_cards    : totalyellowcards === undefined ? 0 : totalyellowcards,
						total_clean_sheets    : totalcleansheets === undefined ? 0 : totalcleansheets,
						fl_weekly_points      : {
							...player.fl_weekly_points,
							['week_' + weekCalculation]: 0
						},
						weekly_selection      : { ...player.weekly_selection, ['week_' + weekCalculation]: 'reserves' },
						weekly_assists        : {
							...player.weekly_assists,
							['week_' + weekCalculation]: 0
						},
						weekly_goals          : {
							...player.weekly_goals,
							['week_' + weekCalculation]: 0
						},
						weekly_minutes_played : {
							...player.weekly_minutes_played,
							['week_' + weekCalculation]: 0
						},
						weekly_own_goals      : {
							...player.weekly_own_goals,
							['week_' + weekCalculation]: 0
						},
						weekly_penalty_missed : {
							...player.weekly_penalty_missed,
							['week_' + weekCalculation]: 0
						},
						weekly_penalty_saved  : {
							...player.weekly_penalty_saved,
							['week_' + weekCalculation]: 0
						},
						weekly_shots_saved    : {
							...player.weekly_shots_saved,
							['week_' + weekCalculation]: 0
						},
						weekly_yellow_cards   : {
							...player.weekly_yellow_cards,
							['week_' + weekCalculation]: 0
						},
						weekly_red_cards      : {
							...player.weekly_red_cards,
							['week_' + weekCalculation]: 0
						},
						weekly_conceded       : {
							...player.weekly_conceded,
							['week_' + weekCalculation]: 0
						},
						weekly_clean_sheets   : {
							...player.weekly_clean_sheets,
							['week_' + weekCalculation]: true
						}
					};
					noOfPlayersUpdatedWithoutChanges = noOfPlayersUpdatedWithoutChanges + 1;
					batch.update(path, player);
				}
				//console.log('Not found addition' + JSON.stringify(player));
				//console.log(path);
			}
			//console.log('Augmented Player: ' + JSON.stringify(player));
			//console.log(path);
		});

		batch
			.commit()
			.then(function() {
				console.log('Batch writing squad squad_week_' + weekCalculation);
				console.log('Players updated with changes: ' + noOfPlayersUpdatedWithChanges);
				console.log('Players updated without changes: ' + noOfPlayersUpdatedWithoutChanges);
			})
			.catch((error) => {
				console.error('Error writing totw: ', error);
			});
	});
}

function updateFantasyTeamsSquadAndTeamPlayers() {
	console.log('Starting to update FL Squad and Team Players');

	var players = [];

	db.collectionGroup('2022Players').get().then((querySnapshot) => {
		querySnapshot.forEach((doc) => {
			players.push(doc.data());
		});
	});


	var batch1 = db.batch();

	db.collectionGroup('players').get().then((querySnapshot) => {
		querySnapshot.forEach((doc) => {
			var path = doc.ref.path;
			var squadPlayer = doc.data();

			var foundSquadPlayer = players.find((player) => player.player_id.trim() === squadPlayer.player_id.trim());

			//console.log(foundSquadPlayer);
			var collectionPath = path.substring(0, path.indexOf(squadPlayer.player_id) - 1);

			db
				.collection(collectionPath)
				.doc(squadPlayer.player_id)
				.set(
					{
						...foundSquadPlayer
					},
					{ merge: true }
				)
				.then(() => {
					console.log('Document successfully written!');
				})
				.catch((error) => {
					console.error('Error writing totw: ', error);
				});
		});
	});
	

	db.collectionGroup('team').get().then((querySnapshot) => {
		querySnapshot.forEach((doc) => {
			var path = doc.ref.path;
			var teamPlayer = doc.data();


			var foundTeamPlayer = players.find((player) => player.player_id.trim() === teamPlayer.player_id.trim());
			//console.log(path.substring(0, path.indexOf('/team/') + 5));
			//console.log(path.substring(path.indexOf('/team/') + 6, path.length));
			//console.log(foundTeamPlayer);
			var collectionPath = path.substring(0, path.indexOf('/team/') + 5);
			var docPath = path.substring(path.indexOf('/team/') + 6, path.length);

			db
				.collection(collectionPath)
				.doc(docPath)
				.set(
					{
						...foundTeamPlayer
					},
					{ merge: true }
				)
				.then(() => {
					console.log('Document successfully written!');
				})
				.catch((error) => {
					console.error('Error writing totw: ', error);
				});
		});
	});
}

export { updatePlayersStats, updateFantasyTeamsSquadAndTeamPlayers };
import React, { useCallback, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useSession } from '../App';
import MenuItem from '@mui/material/MenuItem';
import { Typography, TextField } from '@mui/material';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import firebase from '../firebase';

const PREFIX = 'CreateMatchSchedule';

const classes = {
	root : `${PREFIX}-root`
};

const Root = styled('form')(({ theme }) => ({
	[`&.${classes.root}`]: {
		'& .MuiTextField-root' : {
			margin : theme.spacing(1),
			width  : '25ch'
		}
	}
}));

const teams = [
	{
		value : 'ANFC',
		label : 'Albirex Niigata'
	},
	{
		value : 'BKFC',
		label : 'Balestier Khalsa'
	},
	{
		value : 'GIFC',
		label : 'Geylang International'
	},
	{
		value : 'HUFC',
		label : 'Hougang United'
	},
	{
		value : 'LCSF',
		label : 'Lion City Sailors'
	},
	{
		value : 'TPFC',
		label : 'Tanjong Pagar'
	},
	{
		value : 'TRFC',
		label : 'Tampines Rovers'
	},
	{
		value : 'YLFC',
		label : 'Young Lions'
	}
];

const rounds = [
	{
		value : '1',
		label : 'Round 1'
	},
	{
		value : '2',
		label : 'Round 2'
	},
	{
		value : '3',
		label : 'Round 3'
	},
	{
		value : '4',
		label : 'Round 4'
	}
];

const selections = {
	albirex_niigata       : {
		name   : 'Albirex niigata',
		logo   : '/images/team_logos/anfc.png',
		avatar : 'A'
	},
	balestier_khalsa      : {
		name   : 'Balestier Khalsa',
		logo   : '/images/team_logos/bkfc.png',
		avatar : 'B'
	},
	geylang_international : {
		name   : 'Geylang International',
		logo   : '/images/team_logos/gifc.png',
		avatar : 'G'
	},
	hougang_united        : {
		name   : 'Hougang United',
		logo   : '/images/team_logos/hgfc.png',
		avatar : 'H'
	},
	lion_city_sailors     : {
		name   : 'Lion City Sailors',
		logo   : '/images/team_logos/lcsf.png',
		avatar : 'L'
	},
	tanjong_pagar         : {
		name   : 'Tanjong Pagar',
		logo   : '/images/team_logos/tpfc.png',
		avatar : 'T'
	},
	tampines_rovers       : {
		name   : 'Tampines Rovers',
		logo   : '/images/team_logos/trfc.png',
		avatar : 'T'
	},
	young_lions           : {
		name   : 'Young Lions',
		logo   : '/images/team_logos/ylfc.png',
		avatar : 'Y'
	}
};

function Alert(props) {
	return <MuiAlert elevation={6} variant='filled' {...props} />;
}

export default function CreateMatchSchedule() {
	const user = useSession();
	const [ authorized, setAuthorized ] = useState(false);
	const [ state, setState ] = useState(false);

	const [ homeTeam, setHomeTeam ] = useState('');
	const [ awayTeam, setAwayTeam ] = useState('');
	const [ year, setYear ] = useState(2022);
	const [ round, setRound ] = useState(1);
	const [ week, setWeek ] = useState(7.2);
	const [ matchDate, setMatchDate ] = useState('');
	const [ matchTime, setMatchTime ] = useState('');
	const [ venue, setVenue ] = useState('');
	const [ open, setOpen ] = React.useState(false);

	const handleClick = () => {
		setOpen(true);
	};

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};

	const handleHomeTeamChange = (event) => {
		setHomeTeam(event.target.value);
		console.log('Home Team selected: ' + event.target.value);

		if (event.target.value === 'GIFC' || event.target.value === 'TRFC') {
			setVenue('Our Tampines Hub');
			console.log('Venue auto selected: ' + 'Our Tampines Hub');
		} else if (event.target.value === 'BKFC') {
			setVenue('Toa Payoh Stadium');
			console.log('Venue auto selected: ' + 'Toa Payoh Stadium');
		} else if (event.target.value === 'HUFC') {
			setVenue('Hougang Stadium');
			console.log('Venue auto selected: ' + 'Hougang Stadium');
		} else if (event.target.value === 'TPFC' || event.target.value === 'ANFC') {
			setVenue('Jurong East Stadium');
			console.log('Venue auto selected: ' + 'Jurong East Stadium');
		} else if (event.target.value === 'YLFC' || event.target.value === 'LCSF') {
			setVenue('Jalan Besar Stadium');
			console.log('Venue auto selected: ' + 'Jalan Besar Stadium');
		} else {
			setVenue('');
		}
	};

	const handleAwayTeamChange = (event) => {
		setAwayTeam(event.target.value);
		console.log('Away Team selected: ' + event.target.value);
	};

	const handleYearChange = (event) => {
		setYear(event.target.value);
	};

	const handleRoundChange = (event) => {
		setRound(event.target.value);
		console.log('Round selected: ' + event.target.value);
	};

	const handleWeekChange = (event) => {
		setWeek(event.target.value);
		console.log('Week selected: ' + event.target.value);
	};

	const handleDateChange = (event) => {
		setMatchDate(event.target.value);
		console.log('Date selected: ' + event.target.value);
	};

	const handleTimeChange = (event) => {
		setMatchTime(event.target.value);
		console.log('Time selected: ' + event.target.value);
	};

	const handleVenueChange = (event) => {
		setVenue(event.target.value);
	};

	function handleTop3Picks(event) {
		console.log('Finding Top Picked Players');
		var squadPlayers = [];

		const db = firebase.firestore();
		var players = db.collectionGroup('squad_week_7').get().then((querySnapshot) => {
			querySnapshot.forEach((doc) => {
				//console.log(doc.id, ' => ', doc.data());
				squadPlayers.push(doc.data());
			});

			let arr2 = [];
			squadPlayers.forEach((x) => {
				// Checking if there is any object in arr2
				// which contains the key value
				if (
					arr2.some((val) => {
						return val['fullname'] == x['fullname'];
					})
				) {
					// If yes! then increase the occurrence by 1
					arr2.forEach((k) => {
						if (k['fullname'] === x['fullname']) {
							k['occurrence']++;
						}
					});
				} else {
					// If not! Then create a new object initialize
					// it with the present iteration key's value and
					// set the occurrence to 1
					let a = {};
					a['fullname'] = x['fullname'];
					a['occurrence'] = 1;
					arr2.push(a);
				}
			});
			return arr2.sort(compare);
		});

		console.log(players);
		
	};

	function handleBestPPD(event) {
		console.log('Best Returns for $ - Bang for the buck');
		var filteredPlayers = [];

		const db = firebase.firestore();
		var players = db.collection('/FantasyLeague/2022/fl_players_week_7').get().then((querySnapshot) => {
			querySnapshot.forEach((doc) => {
				var player = doc.data();
				var value = player.value;
				var flPoints = player.fl_points;

				let data = {
					player_fullname: player.fullname,
					player_club: player.teamId,
					player_value: value,
					player_fl_points: flPoints,
					player_ppd: flPoints / value
				};

				//console.log(data);

				filteredPlayers.push(data);

			});
			console.log(filteredPlayers.sort(function(a,b) {
				if ( a.player_ppd < b.player_ppd ){
					return -1;
				  }
				  if ( a.player_ppd > b.player_ppd ){
					return 1;
				  }
				  return 0;
			}));
			return filteredPlayers;
		
		});


		
	};

	function comparePPD( a, b ) {
		if ( a.player_ppd < b.player_ppd ){
		  return -1;
		}
		if ( a.player_ppd > b.occurrence ){
		  return 1;
		}
		return 0;
	  }

	function compare( a, b ) {
		if ( a.occurrence < b.occurrence ){
		  return -1;
		}
		if ( a.occurrence > b.occurrence ){
		  return 1;
		}
		return 0;
	  }

	useEffect(() => {
		console.log('Checking user: ' + user.uid);
		if (
			user.uid === 'glKicD84lLejtJ5M9qOLaczt5ny1' ||
			user.uid === 'YP1dpgESghPw7WiAoIL1Sd0HARw1' ||
			user.uid === 'MmkWUYFwTwT7DKWfnsgXoKoYgxo2'
		) {
			console.log('Authorized User');
			setAuthorized(true);
		} else {
			console.log('Unauthorized User');
			setAuthorized(false);
		}
	}, []);

	return (
		<div>
			{authorized ? (
				<div>
					<div>
						<Typography variant='h5'>Fantasy League Statistics</Typography>
					</div>

					<div>
						<Button
							variant='contained'
							color='primary'
							className={classes.button}
							onClick={handleTop3Picks}
							type='submit'
						>
							Top 3 Picks
						</Button>
					</div>
					<div>
						<Button
							variant='contained'
							color='primary'
							className={classes.button}
							onClick={handleBestPPD}
							type='submit'
						>
							Bang 4 Buck
						</Button>
					</div>
				</div>
			) : (
				<Typography>Unauthorized</Typography>
			)}
			{state ? (
				<Snackbar open={open} autoHideDuration={6000} onClose={handleClose} message='Success' />
			) : (
				<Snackbar
					open={open}
					autoHideDuration={6000}
					onClose={handleClose}
					message='Match creation unsuccessful!'
				/>
			)}
		</div>
	);
}

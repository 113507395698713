import React, { Component } from 'react';
import { makeStyles, withStyles } from '@mui/styles';
import Home from '../component/Home';


class home extends Component {
	render() {


		return (
            <Home></Home>
		)
	}
}

export default home;

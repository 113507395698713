import React, { Component } from 'react';
import CreatePlayers from '../../component/CreatePlayers';

export class createplayers extends Component {
	render() {
		return (
			<div>
				<CreatePlayers />
			</div>
		);
	}
}

export default createplayers;
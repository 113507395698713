import React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import clsx from 'clsx';
import CardHeader from '@mui/material/CardHeader';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { green } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import TextField from '@mui/material/TextField';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import { useSession } from '../App';
import firebase from '../firebase';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';

const PREFIX = 'MatchPrediction';

const classes = {
    card: `${PREFIX}-card`,
    media: `${PREFIX}-media`,
    expand: `${PREFIX}-expand`,
    expandOpen: `${PREFIX}-expandOpen`,
    avatar: `${PREFIX}-avatar`,
    image: `${PREFIX}-image`,
    large: `${PREFIX}-large`,
    input: `${PREFIX}-input`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.card}`]: {
		maxWidth: 600,
		padding: theme.spacing(2)
	},

    [`& .${classes.media}`]: {
		height: 0,
		paddingTop: '40%' // 16:9
	},

    [`& .${classes.expand}`]: {
		transform: 'rotate(0deg)',
		marginLeft: 'auto',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest
		})
	},

    [`& .${classes.expandOpen}`]: {
		transform: 'rotate(180deg)'
	},

    [`& .${classes.avatar}`]: {
		backgroundColor: green[500]
	},

    [`& .${classes.image}`]: {
		height: 80,
		width: 80
	},

    [`& .${classes.large}`]: {
		width: theme.spacing(12),
		height: theme.spacing(12)
	},

    [`& .${classes.input}`]: {
		padding: 10
	}
}));

export default function MatchDetails({ match, myPrediction }) {

	const user = useSession();

	const [ expanded, setExpanded ] = React.useState(false);
	const [ openSnackbar, setOpenSnackbar ] = React.useState(false);

	var homeScore = 0;
	var awayScore = 0;

	const extractPredictions = myPrediction.map((p) => {
		if (p === undefined) console.log('undefined');
		else {
			//console.log('home score' + p.hometeam_score);
			//console.log('away score ' + p.awayteam_score);
			homeScore = p.hometeam_score;
			awayScore = p.awayteam_score;
		}
	});

	const handleSnackbarClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpenSnackbar(false);
	};

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	function handleHomeScoreChange(event) {
		homeScore = event.target.value;
	}

	function handleAwayScoreChange(event) {
		awayScore = event.target.value;
	}

	function handleSubmitPrediction(event) {
		event.preventDefault();

		let homeTeamResult = '';
		let awayTeamResult = '';

		if (homeScore > awayScore) {
			homeTeamResult = 'Win';
			awayTeamResult = 'Lose';
		} else if (homeScore < awayScore) {
			homeTeamResult = 'Lose';
			awayTeamResult = 'Win';
		} else if (homeScore === awayScore) {
			homeTeamResult = 'Draw';
			awayTeamResult = 'Draw';
		}
		/*
		console.log(user.uid);
		console.log(match.hometeam_id);
		console.log(match.hometeam_name);
		console.log(homeScore);

		console.log(awayScore);

		console.log(match.awayteam_name);
		console.log(homeTeamResult);

		console.log(match.awayTeamId);
		console.log(match.awayteam_name);
		console.log(awayTeamResult);
		*/

		let data = {
			awayteam_id: match.awayteam_id,
			awayteam_logo_url: match.awayteam_logo_url,
			awayteam_name: match.awayteam_name,
			awayteam_result: awayTeamResult,
			awayteam_score: awayScore,

			hometeam_id: match.hometeam_id,
			hometeam_logo_url: match.hometeam_logo_url,
			hometeam_name: match.hometeam_name,
			hometeam_result: homeTeamResult,
			hometeam_score: homeScore,

			match_date: match.match_date,
			match_prediction_status: match.match_prediction_status,
			match_status: match.match_status,
			match_id: match.match_id,
			user_id: user.uid
		};

		const db = firebase.firestore();
		let setDoc = db
			.collection('Users')
			.doc(user.uid)
			.collection('2022Predictions')
			.doc(user.uid + '_' + match.match_id)
			.set(data);

		setOpenSnackbar(true);
	}

	return (
        <Root className={classes.root}>
			<Card className={classes.card}>
				<CardHeader
					avatar={
						<Avatar aria-label='recipe' className={classes.avatargreen}>
							G
						</Avatar>
					}
					title={match.hometeam_name + ' vs ' + match.awayteam_name}
					subheader={new Date(match.match_date.seconds * 1000).toLocaleDateString()}
				/>
				<CardContent>
					<Grid container>
						<Grid item xs={6}>
							<Avatar src={match.hometeam_logo_url} className={classes.large} />
						</Grid>
						<Grid item xs={6}>
							<Avatar src={match.awayteam_logo_url} className={classes.large} />
						</Grid>
					</Grid>
					<Typography variant='body2' color='textSecondary' component='p'>
						Wk: {match.match_week}
					</Typography>
					<Typography variant='body2' color='textSecondary' component='p'>
						Results: {match.hometeam_score} - {match.awayteam_score}
					</Typography>
					<Typography variant='body2' color='textSecondary' component='p'>
						Venue: {match.match_venue}
					</Typography>
					<Typography variant='body2' color='textSecondary' component='p'>
						Match Status: {match.status}
					</Typography>
				</CardContent>
				<CardActions disableSpacing>
					<IconButton aria-label='add to favorites' size="large">
						<FavoriteIcon />
					</IconButton>
					<Typography variant='body2' color='secondary' component='p'>
						Predict The Score
					</Typography>
					<IconButton
                        className={clsx(classes.expand, {
							[classes.expandOpen]: expanded
						})}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label='show more'
                        size="large">
						<SportsSoccerIcon />
					</IconButton>
				</CardActions>
				<Collapse in={expanded} timeout='auto' unmountOnExit>
					<form className={classes.root} noValidate autoComplete='off'>
						<Grid container>
							<Grid item xs={6}>
								<TextField
									className={classes.input}
									label='Score'
									defaultValue={homeScore}
									helperText={match.hometeam_name}
									variant='outlined'
									onChange={handleHomeScoreChange}
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									className={classes.input}
									label='Score'
									defaultValue={awayScore}
									helperText={match.awayteam_name}
									variant='outlined'
									onChange={handleAwayScoreChange}
								/>
								<Snackbar
									anchorOrigin={{
										vertical: 'bottom',
										horizontal: 'left'
									}}
									open={openSnackbar}
									autoHideDuration={6000}
									onClose={handleSnackbarClose}
									message='Your Prediction Submitted'
									action={
										<React.Fragment>
											<IconButton
												size='small'
												aria-label='close'
												color='inherit'
												onClick={handleSnackbarClose}
											>
												<CloseIcon fontSize='small' />
											</IconButton>
										</React.Fragment>
									}
								/>
							</Grid>
							{match.match_prediction_status === 'open' ? (
								<div>
									<Button
										fullWidth
										variant='contained'
										color='primary'
										onClick={(e) => handleSubmitPrediction(e)}
									>
										Submit My Prediction
									</Button>
								</div>
							) : (
								<Typography variant='h6' align='center'>
									Prediction is closed
								</Typography>
							)}
						</Grid>
					</form>
				</Collapse>
			</Card>
		</Root>
    );
}

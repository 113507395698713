import React from "react";
import { styled } from '@mui/material/styles';
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { ExternalLink } from "react-external-link";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

const PREFIX = 'FantasyOverview';

const classes = {
  root: `${PREFIX}-root`,
  media: `${PREFIX}-media`,
  paper: `${PREFIX}-paper`
};

const StyledCard = styled(Card)((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    flexGrow: 1,
  },

  [`& .${classes.media}`]: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },

  [`& .${classes.paper}`]: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  }
}));

export default function MediaCard() {


  return (
    <StyledCard className={classes.root}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image="/images/Fantasy-league-banner.png"
          title="Fantasy League Banner"
        />
        <CardContent>
          

          <Grid container spacing={4}>
            <Grid
              item
              xs={12}
              align="center"
              style={{ backgroundColor: "#ebebeb" }}
            >
              <Typography variant="h5">How to start:</Typography>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs>
              <Typography variant="body1">1. Go to 'Transfer' menu</Typography>
              <Typography variant="body1">
                2. Name Your Team with an awesome name
              </Typography>
              <Typography variant="body1">
                3. Build your squad with the SGD 100 M budget
              </Typography>
              <Typography variant="body1">4. Go to 'Team' menu</Typography>
              <Typography variant="body1">
                5. Build your Team of The Week
              </Typography>
            </Grid>
            <Grid
              item
              xs
              style={{
                backgroundImage: `url("/images/Shahril.png")`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
            ></Grid>
            <Grid item xs={12}></Grid>
          </Grid>

          <Divider />
          <Grid container spacing={3}>
            <Grid item xs={12}></Grid>
            <Grid item xs></Grid>
            <Grid item xs>
              <Typography variant="body1">Chat with the team:</Typography>
              <ExternalLink href="https://discord.gg/BQVT536Wje">
                <span>Voiddeck Fantasy Premier League Discord</span>
              </ExternalLink>
            </Grid>
            <Grid item xs>
              <Typography variant="body1">
                Rules and Regulations Guide:
              </Typography>
              <ExternalLink href="https://docs.google.com/document/d/e/2PACX-1vRfibwYLErMbMHFLTuOHUkR-DVW6UC0mqKfEJiAbqWUZwGukeYnPPwELJBCU-AS-dgrlppHCRT8is3i/pub">
                <span>Voiddeck Fantasy Premier League Guidebook</span>
              </ExternalLink>
            </Grid>
            <Grid item xs></Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </StyledCard>
  );
}

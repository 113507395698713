import React from 'react';
import MyPredictions from '../component/MyPredictions';

export default function mypredictions() {
	return (
		<div>
			<MyPredictions />
		</div>
	);
}
